import Vue from 'vue'
import Vuex from 'vuex'

import actions from './actions.js'
import mutations from './mutations.js'

import login from './modules/login.js'
import menus from './modules/menus.js'
import catchData from './modules/catchData.js'
import historyTab from './modules/historyTab.js'
import langData from './modules/langData.js'
import theme from './modules/theme.js'
import sysShow from './modules/sysShow.js'

Vue.use(Vuex)

let YF_BASEURL = "";

//浏览器内容区基本高度
const baseWinHeight = window.innerHeight || document.body.clientHeight;
//表格高度
const tabelHeightCal = (offset) => {
	return (baseWinHeight - offset) > 300 ? (baseWinHeight - offset) : 300
}
const tabelHeightCal2 = (offset,minHeight) => {
	return (baseWinHeight - offset) > minHeight ? (baseWinHeight - offset) : minHeight
}

const store = new Vuex.Store({
	state: {
		//部分接口和客户端有区分
		client: "wms",
		/**
		 * [eleBaseURL description]
		 * @type {String}
		 *
		 *
		 */
		eleBaseURL: YF_BASEURL ? YF_BASEURL : "",
		//PC webURL
		webBaseUrl: "https://www.hytx.com",
		// webBaseUrl: "https://test.hytx.com",
		//移动 webURL
		mobileBaseUrl: "https://app.hytx.com",
		// mobileBaseUrl: "https://test.app.hytx.com",
		/**
		 * [isTestDev 是否验证登录token]
		 * @type {Boolean}
		 *  前端: true
		 *  线上: false
		 *
		 *  开发登录账号密码
		 *  admin001/Huyi123
		 */
		isTestDev: true,
		/**
		 * [staticBaseUrl 基于static路径地址]
		 * @type {String}
		 * 前端: ""
		 * 线上：
		 */
		staticBaseUrl: "",
		/*国际区号*/
		areaCodeList: [{
			label: '常用',
			options: [{
				code: '中国大陆',
				codeText: '86',
			}]
		}, {
			label: '如果没有找到您的所在归属地，请拨打客服电话4008005550解决  ',
			options: []
		}],
		contenWidth: window.innerwidth || document.body.clientWidth,
		contenHeight: baseWinHeight,

		/*内容显示容器高度*/
		frameConHeight: tabelHeightCal(61),
		frameConHeight1: tabelHeightCal(60+120+25),
		/*内容显示明细容器高度*/
		detConHeight: tabelHeightCal(61 + 65),
		/*表格显示容器高度（顶部仅操作或菜单提示）*/
		tableMaxHeight: tabelHeightCal(61 + 120),
		/*表格显示容器高度（顶部仅操作或菜单提示）*/
		tableMaxHeight1: tabelHeightCal(61 + 90),
		/*表格显示容器高度2（含筛选项等）*/
		tableMaxHeight2: tabelHeightCal(61 + 120 + 63),
		/*表格显示容器高度3（顶部含中间筛选容器）*/
		tableMaxHeight3: tabelHeightCal(61 + 120 + 53),
		/*表格显示容器高度4（顶部含中间筛选容器，筛选双层）*/
		tableMaxHeight4: tabelHeightCal(61 + 120 + 53 + 43),
		/*表格显示容器高度5（含筛选项，状态等）*/
		tableMaxHeight5: tabelHeightCal(61 + 120 + 63 + 46),
		/*表格显示容器高度6（含筛选项，状态等）*/
		tableMaxHeight6: tabelHeightCal(61 + 120 + 63 + 46 + 50),
		/*表格显示容器高度7（含筛选项，状态等）*/
		tableMaxHeight7: tabelHeightCal(61 + 120 + 63 + 46 + 50 + 43),
		/*表格显示容器高度9账单专用*/
		tableMaxHeight9: tabelHeightCal(61 + 53 + 63 + 63 + 100 +100),
		/*表格显示容器高度10*/
		tableMaxHeight10: tabelHeightCal(61 + 53 + 63 + 63 + 100 + 20),
		/*表格显示容器高度11一件代发汇总专用*/
		tableMaxHeight11: tabelHeightCal(61 + 53 + 63 + 63 + 100 + 20+5),
		
		//客户端--代理--转运出库
		frameConHeightWh8: tabelHeightCal2(60 + 220 + 56 + 44+110,200),
		frameConHeightWh9: tabelHeightCal2(60 + 220 + 56 + 44+110+250,150),
		
		author: 'nd'
	},
	actions: actions,
	getters: {

	},
	mutations: mutations,
	modules: {

		login,
		menus,
		catchData,
		historyTab,
		langData,
		theme,
		sysShow

	},

})

export default store
