<template>
	<div id="app" :class="'theme_'+themeConfig.colorStyle">
		<!-- <img src="./assets/logo.jpg"> -->
		<router-view ref="appView" />
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	
	export default {
		name: 'App',
		// metaInfo: {
		//   title: 'HYTX-WMS-ADMIN'
		// },
		metaInfo() {
		  return {
		    // title: this.$t("meta.main")
				title: this.$store.state.sysShow.logoInfo.owner+'-WMS-ADMIN'
		  };
		},
		watch: {
			$route() {
				//切换标签时
				this.handleRefreshTable();
			}
		},
		computed: {
			// ...mapGetters(['historyTabData']),
			...mapGetters({ // 对象形式下 改变映射
				themeConfig: "getThemeConfig"
			}),
			// ...mapGetters({ // 对象形式下 改变映射
			// 	menuCollapse: "getMenuCollapse"
			// }),
			// routeArr() {
			// 	return this.$route.matched;
			// }
		},
		created() {
			let _this = this;
			//窗口改变时
			window.onresize = function() {
				_this.handleRefreshTable();
			};
		},
		updated() {
			//数据改变时
			this.handleRefreshTable();
		},
		methods: {
			//表格重绘，房子出现fixed是错位
			handleRefreshTable() {
				this.$nextTick(() => {
					// 获取页面中已注册过ref的所有的子组件。
					let refList = null;
					try{
						refList = this.$refs.appView.$refs;
					}catch(e){
						//TODO handle the exception
						console.log(e);
					}
					// let refList = this.$refs.appView.$refs;
					if (refList) {
						for (let i of Object.keys(refList)) {
							// 根据doLayout方法判断子组件是不是el-table
							if (refList[i] && refList[i].doLayout) {
								// 执行doLayout方法
								refList[i].doLayout();
							}
						}
					}

				})
			}
		}
	};
</script>

<style>
	#nprogress .bar {
		background: #206ce0 !important;
	}
</style>
