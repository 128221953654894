import Vue from 'vue'

import {
		setStorage,
		history_Tab_Data
	} from '@/utils/Storage.js';
	
// let routerArr = [
//   {routeName:"home",title:"主页"},
//   {routeName:"appList",title:"应用列表"},
//   {routeName:"permissionList",title:"权限配置"},
//   {routeName:"listJob",title:"任务列表"},
//   {routeName:"listServers",title:"服务器信息"},
//   {routeName:"divideLogList",title:"分片日志列表"},
//   {routeName:"executeLogList",title:"执行日志列表"}
// ];

const state = {

  historyTabData:[],
}

const mutations = {

  history_Tab_Data(state, val) {
    // sessionStorage.setItem('history_Tab_Data',JSON.stringify(val));
    state.historyTabData = val;
  },

  add_Tab_Data(state, val) {
    //是否存在该路由
    let hasName = false;
    // let historyTabData = state.historyTabData;
    state.historyTabData.forEach(item => {
      if(item.routeName===val.routeName){
        hasName = true;
        // debugger;
        console.log('hasName',val.query);
        console.log('Object.keys1',Object.keys(val.query));
        //存在则替换query
        if(!!val && !!val.query && Object.keys(val.query).length>0){
          console.log('Object.keys2',item.query);
          item.query = val.query;
          console.log('state.historyTabData1',state.historyTabData);
        }
      }
    });
    console.log('state.historyTabData2',state.historyTabData);
    //不存在则添加
    if(hasName===false){
    //   routerArr.forEach(item => {
    //     if(item.routeName===val.routeName){
    //       val.title = item.title;
    //     }
    //   });
      state.historyTabData.push(val);
			// if(state.historyTabData.length>5){
			// 	state.historyTabData = state.historyTabData.slice(state.historyTabData.length-5,state.historyTabData.length)
			// }
			
    }
		
    if(state.historyTabData.length>5){
    	state.historyTabData = state.historyTabData.slice(state.historyTabData.length-5,state.historyTabData.length)
    }
    //存到缓存，防止，刷新时候丢失
    // localStorage.setItem('history_Tab_Data',JSON.stringify(state.historyTabData));
		setStorage('history_Tab_Data',JSON.stringify(state.historyTabData));
  },

  del_Tab_Data(state,index) {
    // state.historyTabData.forEach((item,index) => {
    //   if(item.routeName===routeName){
        state.historyTabData.splice(index,1);
    //   }
    // });
    //存到缓存，防止，刷新时候丢失
    // localStorage.setItem('history_Tab_Data',JSON.stringify(state.historyTabData));
		setStorage('history_Tab_Data',JSON.stringify(state.historyTabData));
  },
}

const getters = {
  //获得菜单信息
  // historyTabData(state) {
  //   return state.historyTabData;
  // },
  historyTabData: state => state.historyTabData
}

const actions = {

  set_history_Tab_Data({
    commit
  }, val) {
    commit('history_Tab_Data', val);
  },
  add_history_Tab_Data({
    commit
  },val) {
    commit('add_Tab_Data',val);
  },
  del_history_Tab_Data({
    commit
  },routeName) {
    commit('del_Tab_Data',routeName);
  },

}

export default {
  state,
  mutations,
  getters,
  actions
}
