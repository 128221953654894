import Vue from 'vue'

import {
	setStorage,
	getStorage,
	delStorage,
	theme_config,
	menuCollapse
} from '@/utils/Storage.js';

// const theme_config = "theme_config";
// const menuCollapse = "menuCollapse";
// //存Storage
// function setStorage(name, value) {
// 	localStorage.setItem(name, value);
// }

// //取Storage
// function getStorage(name) {
// 	return localStorage.getItem(name);
// }

// //删除Storage
// function delStorage(name) {
// 	localStorage.removeItem(name);
// }

const defaultConfig = {
	layoutType: '0', //1-常规,0-分栏,2-浮动
	showTags: '1', //0-隐藏,1-显示
	menuWidth: '240px',
	uniqueOpened: '1' ,//0-不限，1-单个
	colorStyle: '0', //0--默认，蓝色  1-白色 2-绿色 3-青色 4-红色 5-紫色 6-黑色 7-黄色
}

const state = {
	themeConfig: defaultConfig,
	menuCollapse: false, //菜单-是否收起
}

const mutations = {
	UPDATE_THEME_CONFIG(state, data) {
		try {
			setStorage(theme_config, JSON.stringify(data));
		} catch (err) {
			console.log(err);
		}
		state.themeConfig = data;
	},
	UPDATE_MENU_COLLAPSE(state, data) {
		setStorage(menuCollapse, data);
		state.menuCollapse = data;
	},
}

const getters = {
	getThemeConfig(state) {
		let config = getStorage(theme_config);
		if (config) {
			try {
				state.themeConfig = JSON.parse(config);
			} catch (err) {
				console.log(err);
			}
		} else {
			state.themeConfig = defaultConfig;
		}
		console.log('state.themeConfig', state.themeConfig);
		return state.themeConfig;
	},
	getMenuCollapse(state) {
		let config = getStorage(menuCollapse);
		if (config) {
			state.menuCollapse = 'true' == config ? true : false;
		} else {
			state.menuCollapse = false;
		}
		return state.menuCollapse;
	},
}

const actions = {
	UPDATE_THEME_CONFIG({
		commit
	}, val) {
		commit('UPDATE_THEME_CONFIG', val);
	},
	UPDATE_MENU_COLLAPSE({
		commit
	}, val) {
		commit('UPDATE_MENU_COLLAPSE', val);
	},
}

export default {
	state,
	mutations,
	getters,
	actions
}