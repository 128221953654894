import Vue from 'vue'
import AxiosPlugin from '../../axios/axios.js'
Vue.use(AxiosPlugin, '$axios');

import HYConfig from '../../config/config.js'
Vue.use(HYConfig)

import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

const state = {
  userInfo: {},
}

const cookieDate = "72h";

//存cookie
// function setCookie(name, value) {
//   // var Days = 30;
//   var exp = new Date();
//   exp.setTime(exp.getTime() + 30 * 60 * 1000);//半个小时过期
//   document.cookie = name + "=" + escape(value) + ";expires=" + exp.toGMTString();
// }

// //取cookie
// function getCookie(name) {
//   var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
//   if (arr = document.cookie.match(reg)){
//     return unescape(arr[2]);
//   } else {
//     return null;
//   }
    
// }

// //删除cookie
// function delCookie(name) {
//   var exp = new Date();
//   exp.setTime(exp.getTime() - 1);
//   var cval = getCookie(name);
//   if (cval != null){
//     document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString();
//   }  
// }

const mutations = {

  //更新用户登录信息
  UPDATE_USERINFO(state, userInfoParm) {
    // sessionStorage.setItem('account', newUserInfo.account);
    let userInfo = {
      id: userInfoParm.id,
      userName: userInfoParm.userName,
      account: userInfoParm.account,
      // mobile: userInfoParm.mobile,
      // registerTime: userInfoParm.registerTime,
      // oldLoginTime: userInfoParm.oldLoginTime,
      avatar: userInfoParm.avatar,
    };

    if(userInfo.id){
      Vue.prototype.$cookies.set("hy_a_userInfo_id",userInfo.id,cookieDate);
    }

    Vue.prototype.$cookies.set("hy_a_userInfo_name",userInfo.userName,cookieDate);
    Vue.prototype.$cookies.set("hy_a_userInfo_account",userInfo.account,cookieDate);
    Vue.prototype.$cookies.set("hy_a_userInfo_avatar",userInfo.avatar,cookieDate);

    // try {
    //   setCookie('userInfoAdmin', JSON.stringify(userInfo));
    // } catch (err) {
    //   console.log(err);
    // }
    state.userInfo = userInfo;
  },


  //从本对象格式中，更新用户登录信息
  UPDATE_USERINFO_SELF(state, userInfo) {

    Vue.prototype.$cookies.set("hy_a_userInfo_id",userInfo.id,cookieDate);
      Vue.prototype.$cookies.set("hy_a_userInfo_name",userInfo.name,cookieDate);
      Vue.prototype.$cookies.set("hy_a_userInfo_account",userInfo.account,cookieDate);
      Vue.prototype.$cookies.set("hy_a_userInfo_avatar",userInfo.avatar,cookieDate);
    // try {
    //   setCookie('userInfoAdmin', JSON.stringify(userInfoParm));
    // } catch (err) {
    //   console.log(err);
    // }
    state.userInfo = userInfo;
  },

  //删除用户登录信息
  USER_SIGNOUT(state) {
    //登出
    // Vue.prototype.$http.post(Vue.prototype.$urlConfig.HyLoginOut).then(response => {
    //   console.log('登出', response);
    // }).catch(err => {
    //   console.error(err);
    // });
    // delCookie('userInfoAdmin');

    Vue.prototype.$cookies.remove('hy_a_userInfo_id');
    Vue.prototype.$cookies.remove('hy_a_userInfo_name');
    Vue.prototype.$cookies.remove('hy_a_userInfo_account');
    Vue.prototype.$cookies.remove('hy_a_userInfo_avatar');

    Object.keys(state).forEach(k => Vue.delete(state, k))
  }
}

const getters = {
  //获得用户基本信息
  getUserInfo(state) {
    let userInfo = {
      id:Vue.prototype.$cookies.get('hy_a_userInfo_id'),
      name:Vue.prototype.$cookies.get('hy_a_userInfo_name'),
      account:Vue.prototype.$cookies.get('hy_a_userInfo_account'),
      avatar:Vue.prototype.$cookies.get('hy_a_userInfo_avatar'),
    };

    state.userInfo = userInfo;

    return state.userInfo;
  },

  //若缓存用户信息和cookie不一样，从cookie中更新最新登录信息
  updataCacheUserInfo(){
    let userInfo = {};
    if(!!Vue.prototype.$cookies.get('hy_a_userInfo_id') && (!state.userInfo || state.userInfo.id!==Vue.prototype.$cookies.get('hy_a_userInfo_id'))){
    // if(!state.userInfo || Vue.prototype.$cookies.get('userInfo_id') || state.userInfo.id!==Vue.prototype.$cookies.get('userInfo_id')){
    // if(Vue.prototype.$cookies.get('userInfo_id')){
      
      // if(Vue.prototype.$cookies.get('userInfo_id')){
        userInfo = {
          id:Vue.prototype.$cookies.get('hy_a_userInfo_id'),
          name:Vue.prototype.$cookies.get('hy_a_userInfo_name'),
          account:Vue.prototype.$cookies.get('hy_a_userInfo_account'),
          avatar:Vue.prototype.$cookies.get('hy_a_userInfo_avatar'),
        };
        // console.log('userInfo',userInfo);
        // console.log('userInfo_avatar',Vue.prototype.$cookies.get('userInfo_avatar'));
        if(userInfo.id){
          Vue.prototype.$cookies.set("hy_a_userInfo_id",userInfo.id,cookieDate);
        }
        Vue.prototype.$cookies.set("hy_a_userInfo_name",userInfo.name,cookieDate);
        Vue.prototype.$cookies.set("hy_a_userInfo_account",userInfo.account,cookieDate);

        if(userInfo.avatar){
          Vue.prototype.$cookies.set("hy_a_userInfo_avatar",userInfo.avatar,cookieDate);
        }
        

        // console.log('userInfo_avatar',Vue.prototype.$cookies.get('userInfo_avatar'));
      // }
      
    }
    state.userInfo = userInfo;
    return state.userInfo;
  }

}

const actions = {
  //更新用户信息,从登录
  UPDATE_USERINFO: ({ commit }, user) => {
    commit('UPDATE_USERINFO', user);
  },

  //更新用户头像
  UPDATE_USERHEAD: ({ commit }, avatar) => {
    // console.log(avatar);
    state.userInfo.avatar = avatar;
    // console.log(state.userInfo);
    if (state.userInfo && state.userInfo.id) {
      commit('UPDATE_USERINFO_SELF', state.userInfo);
    }

  },

  //更新退出
  USER_SIGNOUT: ({ commit }) => {
    commit('USER_SIGNOUT');
  },

}

export default {
  state,
  mutations,
  getters,
  actions
}
