import Vue from 'vue'

// import VueCookies from 'vue-cookies'
// Vue.use(VueCookies)

// const cookieDate = "72h";

const state = {

  menusInfo: [],
  menusInfoKey:[]
}

//存Storage
function setStorage(name, value) {
  sessionStorage.setItem(name, value);
	// let $cookies = Vue.prototype.$cookies;
	// $cookies.set(name,value,cookieDate);
}

//取Storage
function getStorage(name) {
  return sessionStorage.getItem(name);
	// let $cookies = Vue.prototype.$cookies;
	// return $cookies.get(name);
}

//删除Storage
function delStorage(name) {
  sessionStorage.removeItem(name);
	// let $cookies = Vue.prototype.$cookies;
	// $cookies.remove(name);
}

//测试用，全部菜单

// function allStaticMenu() {

//   // const Frame = resolve => require(['./components/Frame.vue'], resolve) 
//   const Frame = resolve => require(['@/components/Frame'], resolve)
//   // import mainFrame from '@/components/mainFrame'
//   // const mainFrame = resolve => require(['@/components/mainFrame'], resolve)
//   //系统配置
//   const SysConfFrame = resolve => require(['@/components/SysConf/SysConfFrame'], resolve)
//   const UserManage = resolve => require(['@/components/SysConf/UserManage'], resolve)
//   const DeptManage = resolve => require(['@/components/SysConf/DeptManage'], resolve)
//   const RoleManage = resolve => require(['@/components/SysConf/RoleManage'], resolve)
//   const MenuManage = resolve => require(['@/components/SysConf/MenuManage'], resolve)
//   const MenuNewManage = resolve => require(['@/components/SysConf/MenuNewManage'], resolve)

//   const AuthManageFrame = resolve => require(['@/components/SysConf/AuthManageFrame'], resolve)
//   const UserRole = resolve => require(['@/components/SysConf/AuthManage/UserRole'], resolve)
//   const RoleMenu = resolve => require(['@/components/SysConf/AuthManage/RoleMenu'], resolve)
//   const RoleAuth = resolve => require(['@/components/SysConf/AuthManage/RoleAuth'], resolve)

//   const ConfigManageFrame = resolve => require(['@/components/SysConf/ConfigManageFrame'], resolve)
//   const DicConfig = resolve => require(['@/components/SysConf/ConfigManage/DicConfig'], resolve)
//   const ParmConfig = resolve => require(['@/components/SysConf/ConfigManage/ParmConfig'], resolve)
//   const ControlConfig = resolve => require(['@/components/SysConf/ConfigManage/ControlConfig'], resolve)
//   const MessageConfig = resolve => require(['@/components/SysConf/ConfigManage/MessageConfig'], resolve)

//   const LogManageFrame = resolve => require(['@/components/SysConf/LogManageFrame'], resolve)
//   const OperationLog = resolve => require(['@/components/SysConf/LogManage/OperationLog'], resolve)
//   const DispatchLog = resolve => require(['@/components/SysConf/LogManage/DispatchLog'], resolve)

//   //基础配置
//   const BaseConfFrame = resolve => require(['@/components/BaseConf/BaseConfFrame'], resolve)
//   const ParaConf = resolve => require(['@/components/BaseConf/ParaConf'], resolve)
//   const ParaConfAdd = resolve => require(['@/components/BaseConf/ParaConfAdd'], resolve)
//   const PermConfAdmin = resolve => require(['@/components/BaseConf/PermConfAdmin'], resolve)
//   const PermConfAdminAdd = resolve => require(['@/components/BaseConf/PermConfAdminAdd'], resolve)
//   const PermConfPerm = resolve => require(['@/components/BaseConf/PermConfPerm'], resolve)
//   const PermConfPermAdd = resolve => require(['@/components/BaseConf/PermConfPermAdd'], resolve)
//   const MenuConf = resolve => require(['@/components/BaseConf/MenuConf'], resolve)
//   const MenuConfAdd = resolve => require(['@/components/BaseConf/MenuConfAdd'], resolve)
//   const AreaConf = resolve => require(['@/components/BaseConf/AreaConf'], resolve)


//   //首页配置
//   const HomeConfFrame = resolve => require(['@/components/HomeConf/HomeConfFrame'], resolve)
//   const NavLv1Config = resolve => require(['@/components/HomeConf/NavLv1Config'], resolve)
//   const NavLv2Config = resolve => require(['@/components/HomeConf/NavLv2Config'], resolve)
//   const BannerConfig = resolve => require(['@/components/HomeConf/BannerConfig'], resolve)
//   const BannerConfigAdd = resolve => require(['@/components/HomeConf/BannerConfigAdd'], resolve)
//   const HelpCenterConfig = resolve => require(['@/components/HomeConf/HelpCenterConfig'], resolve)
//   const FeedbackManage = resolve => require(['@/components/HomeConf/FeedbackManage'], resolve)
//   //商品中心
//   const GoodsCenterFrame = resolve => require(['@/components/GoodsCenter/GoodsCenterFrame'], resolve)
//   const GoodsSteelMana = resolve => require(['@/components/GoodsCenter/GoodsSteelMana'], resolve)
//   //店铺中心
//   const ShopCenterFrame = resolve => require(['@/components/ShopCenter/ShopCenterFrame'], resolve)
//   const ShopMana = resolve => require(['@/components/ShopCenter/ShopMana'], resolve)
//   //会员中心
//   const MemberCenterFrame = resolve => require(['@/components/MemberCenter/MemberCenterFrame'], resolve)
//   const UserMana = resolve => require(['@/components/MemberCenter/UserMana'], resolve)
//   //订单中心
//   const OrderCenterFrame = resolve => require(['@/components/OrderCenter/OrderCenterFrame'], resolve)
//   const OrderMana = resolve => require(['@/components/OrderCenter/OrderMana'], resolve)
//   //资讯中心
//   const InformationCenterFrame = resolve => require(['@/components/InformationCenter/InformationCenterFrame'], resolve)
//   const InforClassifyMana = resolve => require(['@/components/InformationCenter/InforClassifyMana'], resolve)
//   //物流中心
//   const LogisticsCenterFrame = resolve => require(['@/components/LogisticsCenter/LogisticsCenterFrame'], resolve)
//   const LogisticsMana = resolve => require(['@/components/LogisticsCenter/LogisticsMana'], resolve)

//   //返回的路由
//   let reMenus = {
//     path: '/Frame',
//     name: 'Frame',
//     component: Frame,
//     children: [{
//         path: 'SysConfFrame',
//         name: 'SysConfFrame',
//         component: SysConfFrame,
//         children: [{
//             path: 'UserManage',
//             name: 'UserManage',
//             component: UserManage
//           }, {
//             path: 'RoleManage',
//             name: 'RoleManage',
//             component: RoleManage
//           },
//           {
//             path: 'DeptManage',
//             name: 'DeptManage',
//             component: DeptManage
//           },
//           {
//             path: 'MenuManage',
//             name: 'MenuManage',
//             component: MenuManage
//           },
//           {
//             path: 'MenuNewManage',
//             name: 'MenuNewManage',
//             component: MenuNewManage
//           },
//           {
//             path: 'AuthManageFrame',
//             name: 'AuthManageFrame',
//             component: AuthManageFrame,
//             children: [{
//               path: 'UserRole',
//               name: 'UserRole',
//               component: UserRole
//             }, {
//               path: 'RoleMenu',
//               name: 'RoleMenu',
//               component: RoleMenu
//             }, {
//               path: 'RoleAuth',
//               name: 'RoleAuth',
//               component: RoleAuth
//             }, ]
//           },
//           {
//             path: 'ConfigManageFrame',
//             name: 'ConfigManageFrame',
//             component: ConfigManageFrame,
//             children: [{
//               path: 'DicConfig',
//               name: 'DicConfig',
//               component: DicConfig
//             }, {
//               path: 'ParmConfig',
//               name: 'ParmConfig',
//               component: ParmConfig
//             }, {
//               path: 'ControlConfig',
//               name: 'ControlConfig',
//               component: ControlConfig
//             }, {
//               path: 'MessageConfig',
//               name: 'MessageConfig',
//               component: MessageConfig
//             }, ]
//           },
//           {
//             path: 'LogManageFrame',
//             name: 'LogManageFrame',
//             component: LogManageFrame,
//             children: [{
//               path: 'OperationLog',
//               name: 'OperationLog',
//               component: OperationLog
//             }, {
//               path: 'DispatchLog',
//               name: 'DispatchLog',
//               component: DispatchLog
//             }]
//           },
//         ]
//       },
//       {
//         path: 'BaseConfFrame',
//         name: 'BaseConfFrame',
//         component: BaseConfFrame,
//         children: [{
//             path: 'ParaConf',
//             name: 'ParaConf',
//             component: ParaConf
//           }, {
//             path: 'ParaConfAdd',
//             name: 'ParaConfAdd',
//             component: ParaConfAdd
//           }, {
//             path: 'PermConfAdmin',
//             name: 'PermConfAdmin',
//             component: PermConfAdmin
//           }, {
//             path: 'PermConfAdminAdd',
//             name: 'PermConfAdminAdd',
//             component: PermConfAdminAdd
//           }, {
//             path: 'PermConfPerm',
//             name: 'PermConfPerm',
//             component: PermConfPerm
//           }, {
//             path: 'PermConfPermAdd',
//             name: 'PermConfPermAdd',
//             component: PermConfPermAdd
//           }, {
//             path: 'MenuConf',
//             name: 'MenuConf',
//             component: MenuConf
//           }, {
//             path: 'MenuConfAdd',
//             name: 'MenuConfAdd',
//             component: MenuConfAdd
//           }, {
//             path: 'AreaConf',
//             name: 'AreaConf',
//             component: AreaConf
//           },
//         ]
//       },
//       {
//         path: 'HomeConfFrame',
//         name: 'HomeConfFrame',
//         component: HomeConfFrame,
//         children: [{
//             path: 'NavLv1Config',
//             name: 'NavLv1Config',
//             component: NavLv1Config
//           },
//           {
//             path: 'NavLv2Config',
//             name: 'NavLv2Config',
//             component: NavLv2Config
//           },
//           {
//             path: 'BannerConfig',
//             name: 'BannerConfig',
//             component: BannerConfig
//           },
//           {
//             path: 'BannerConfigAdd',
//             name: 'BannerConfigAdd',
//             component: BannerConfigAdd
//           },

//           {
//             path: 'HelpCenterConfig',
//             name: 'HelpCenterConfig',
//             component: HelpCenterConfig
//           },
//           {
//             path: 'FeedbackManage',
//             name: 'FeedbackManage',
//             component: FeedbackManage
//           },
//         ]
//       },
//       {
//         path: 'GoodsCenterFrame',
//         name: 'GoodsCenterFrame',
//         component: GoodsCenterFrame,
//         children: [{
//           path: 'GoodsSteelMana',
//           name: 'GoodsSteelMana',
//           component: GoodsSteelMana
//         }, ]
//       },
//       {
//         path: 'ShopCenterFrame',
//         name: 'ShopCenterFrame',
//         component: ShopCenterFrame,
//         children: [{
//           path: 'ShopMana',
//           name: 'ShopMana',
//           component: ShopMana
//         }, ]
//       },
//       {
//         path: 'MemberCenterFrame',
//         name: 'MemberCenterFrame',
//         component: MemberCenterFrame,
//         children: [{
//           path: 'UserMana',
//           name: 'UserMana',
//           component: UserMana
//         }, ]
//       },
//       {
//         path: 'OrderCenterFrame',
//         name: 'OrderCenterFrame',
//         component: OrderCenterFrame,
//         children: [{
//           path: 'OrderMana',
//           name: 'OrderMana',
//           component: OrderMana
//         }, ]
//       },
//       {
//         path: 'InformationCenterFrame',
//         name: 'InformationCenterFrame',
//         component: InformationCenterFrame,
//         children: [{
//           path: 'InforClassifyMana',
//           name: 'InforClassifyMana',
//           component: InforClassifyMana
//         }, ]
//       },
//       {
//         path: 'LogisticsCenterFrame',
//         name: 'LogisticsCenterFrame',
//         component: LogisticsCenterFrame,
//         children: [{
//           path: 'LogisticsMana',
//           name: 'LogisticsMana',
//           component: LogisticsMana
//         }, ]
//       },
//     ]
//   }
//   return reMenus;
// }

//格式化路由到vue
//格式化，并添加路由
// function filterRouter(menusParm) {
//   console.log('menusParm', menusParm);
//   console.log('menusParm', menusParm.length);
//   let userMenus = [];
//   for (let i = 0, ln = menusParm.length; i < ln; i++) {
//     let oneObj = menusParm[i];

//     //判断路由合法性
//     // console.log('#',oneObj.request.indexOf("#"));
//     if (!oneObj || oneObj.request.indexOf("#") > -1 || oneObj.request.indexOf(".html") > -1) {
//       continue;
//     } else {
//       //是否存在子路由
//       let children = [];
//       //是否存在子路由
//       if (oneObj.menuDtoList && oneObj.menuDtoList.length > 0) {
//         for (let j = 0, lnn = oneObj.menuDtoList.length; j < lnn; j++) {
//           let oneObj2 = oneObj.menuDtoList[j];
//           //判断路由合法性
//           if (!oneObj2 || oneObj2.request.indexOf("#") > -1 || oneObj2.request.indexOf(".html") > -1) {
//             continue;
//           } else {
//             //路由路径及参数合法性
//             let oneObj2Path = oneObj2.request.split('/');
//             if (oneObj2Path.length <= 0) {
//               continue;
//             }
//             let request = oneObj2.request+'';
//             children.push({
//               path: oneObj2Path[oneObj2Path.length - 1],
//               name: oneObj2Path[oneObj2Path.length - 1],
//               component: resolve => require([request], resolve),
//             });
//           }
//         }
//       }

//       //路由路径及参数合法性
//       let oneObjPath = oneObj.request.split('/');
//       if (oneObjPath.length <= 0) {
//         continue;
//       }
//       let request = oneObj.request+'';
//       let oneRouter = {
//         path: oneObjPath[oneObjPath.length - 1],
//         name: oneObjPath[oneObjPath.length - 1],
//         component: resolve => require([request], resolve),
//         // children:children?children:null
//       };
//       if (children && children.length > 0) {
//         oneRouter.children = children;
//       }
//       userMenus.push(oneRouter)
//     }

//   }
//   let AddRouter = {
//     path: '/Frame',
//     name: 'Frame',
//     component: resolve => require(['@/components/Frame'], resolve),
//     children: userMenus
//   };
//   return AddRouter;
// }

/*处理请求的数据*/
function filterMenusData(menusParm){
  let menusInfo = [];
  let menusInfoKey = [];
      console.log('menusParm', menusParm);
      for (let i = 0, ln = menusParm.length; i < ln; i++) {//一级
        let oneObj = menusParm[i];
        let menuDtoList = [];
        if (!oneObj || oneObj.request.indexOf("#") > -1 || oneObj.request.indexOf(".html") > -1) {//旧配置
          continue;
        }
        if(oneObj.menuDtoList && oneObj.menuDtoList.length>0){//二级
          for (let j = 0, lnn = oneObj.menuDtoList.length; j < lnn; j++) {//二级
            let oneObj2 = oneObj.menuDtoList[j];
            if (!oneObj2 || oneObj2.request.indexOf("#") > -1 || oneObj2.request.indexOf(".html") > -1) {//旧配置
              continue;
            }
            let requestArr2 = oneObj2.request.split('/');//路由跳转，只需要最后一个参数

            if (requestArr2.length <= 0) {//无跳转参数
              continue;
            }
						
						let menuDtoList2 = [];//二级子菜单
						if(oneObj2.menuDtoList && oneObj2.menuDtoList.length>0){//三级
							for (let k = 0, lnk = oneObj2.menuDtoList.length; k < lnk; k++) {//三级
								let oneObj3 = oneObj2.menuDtoList[k];
								// if (!oneObj3 || oneObj3.request.indexOf("#") > -1 || oneObj2.request.indexOf(".html") > -1) {//旧配置
								//   continue;
								// }
								let requestArr3 = oneObj3.request.split('/');//路由跳转，只需要最后一个参数
								if (requestArr3.length <= 0) {//无跳转参数
								  continue;
								}
								menuDtoList2.push({//三级
								  request: oneObj3.request,
								  menuName: oneObj3.menuName,
									menuNameEn: oneObj3.menuNameEn,
								  url: oneObj3.request,
								  isShow: oneObj3.isShow,
									iconcls: oneObj3.iconcls,
								  key: requestArr3[requestArr3.length - 1]
								});
								menusInfoKey.push(requestArr3[requestArr3.length - 1]);
							}
						}
						
            menuDtoList.push({//二级
              request: oneObj2.request,
              menuName: oneObj2.menuName,
							menuNameEn: oneObj2.menuNameEn,
              url: oneObj2.request,
              isShow: oneObj2.isShow,
							iconcls: oneObj2.iconcls,
              key: requestArr2[requestArr2.length - 1],
							menuDtoList:menuDtoList2.length>0?menuDtoList2:null,
            });
            menusInfoKey.push(requestArr2[requestArr2.length - 1]);
          }
        }
        
        let requestArr = oneObj.request.split('/');
        if (requestArr.length <= 0) {
          continue;
        }
        let oneRouter = {
          request: oneObj.request,
          menuName: oneObj.menuName,
					menuNameEn: oneObj.menuNameEn,
          url: oneObj.request,
          isShow: oneObj.isShow,
					iconcls: oneObj.iconcls,
          key: requestArr[requestArr.length - 1]
        };
        if (menuDtoList && menuDtoList.length > 0) {
          oneRouter.menuDtoList = menuDtoList;
        }
        menusInfo.push(oneRouter);
        menusInfoKey.push(requestArr[requestArr.length - 1]);
      }
      return {menusInfo,menusInfoKey};
}

const mutations = {

  //更新菜单菜单权限信息
  UPDATE_MENUS(state, menusParm) {
    // sessionStorage.setItem('account', newmenusInfo.account);
    console.log('menusParm', menusParm);
    
    let menusInfoAll = filterMenusData(menusParm);
    let menusInfoKey = menusInfoAll.menusInfoKey;
    let menusInfo = menusInfoAll.menusInfo;

    console.log('menusInfoAll', menusInfoAll);
    // for (let i = 0,ln = menusParm.length; i < ln; i++) {
    //   let oneObj = menusParm[i];
    //   let menuDtoList = [];
    //   if (!oneObj || oneObj.request.indexOf("#") > -1 || oneObj.request.indexOf(".html") > -1) {
    //     continue;
    //   }
    //   for (let j = 0,lnn = oneObj.menuDtoList.length; j < lnn; j++) {
    //     let oneObj2 = oneObj.menuDtoList[j];
    //     menuDtoList.push({
    //       request: oneObj2.request,
    //       // name: oneObj2.request,
    //       // component: oneObj2.request
    //     })
    //   }
    //   let oneRouter = {
    //     request: oneObj.request,
    //     // name: oneObj.request,
    //     // component: oneObj.request,
    //     // children:children?children:null
    //   };
    //   if(menuDtoList&&menuDtoList.length>0){
    //     oneRouter.menuDtoList = menuDtoList;
    //   }
    //   menusInfo.push(oneRouter)
    // }
    // let AddRouter = {
    //   path: '/Frame',
    //   name: 'Frame',
    //   component: 'Frame',
    //   children: menusInfo
    // };
    try {
      // console.log('JSON.stringify(menusInfo)', JSON.stringify(menusInfo));

      setStorage('menusInfo', JSON.stringify(menusInfo));
      setStorage('menusInfoKey', menusInfoKey.join(','));
      // console.log('setStorage getStorage(menusInf)',getStorage('menusInfo'));
    } catch (err) {
      console.log(err);
    }
    // console.log('menusInfo', menusInfo);
    state.menusInfo = menusInfo;
    state.menusInfoKey = menusInfoKey;
    // console.log('state.menusInfo', state.menusInfo);
  },

  //从本对象格式中，更新菜单登录信息
  UPDATE_MENUSINFO_SELF(state, menusInfoParm) {
    try {
      setStorage('menusInfo', JSON.stringify(menusInfoParm));
    } catch (err) {
      console.log(err);
    }
    state.menusInfo = menusInfoParm;
  },

  //删除菜单登录信息
  MENUS_SIGNOUT(state) {

    // delStorage('menusInfoAdmin');
    delStorage('menusInfo');

    delStorage('menusInfoKey');

    Object.keys(state).forEach(k => Vue.delete(state, k))
  }
}

const getters = {

  //获得菜单信息
  getMenusInfo(state) {
    // try {
      // console.log('getStorage(menusInf)', getStorage('menusInfo'));
      let menusInfo = getStorage('menusInfo');
      state.menusInfo = menusInfo?JSON.parse(menusInfo):[]
      // state.menusInfo = filterRouter(menusInfo);
      
      // state.menusInfo = menusInfo;

      // state.menusInfo = allStaticMenu();
      // console.log('getMenusInfo state.menusInfo', state.menusInfo);
    // } catch (err) {
    //   console.log(err);
    // }
    console.log('getMenusInfo', state.menusInfo);
    return state.menusInfo;
  },
  //获得菜单信息Key
  getMenusInfoKey(state) {
    // let menusInfoKey = [];
    // try {
      // console.log('getStorage(menusInf)', getStorage('menusInfo'));
      let menusInfoKey = getStorage('menusInfoKey');
      state.menusInfoKey = menusInfoKey?menusInfoKey.split(','):[];
      // console.log('menusInfoKey',menusInfoKey);
      // state.menusInfoKey = menusInfoKey;
      // let menusInfoKeys = state.menusInfo;
      // for (var i = 0,ln= menusInfoKeys.length; i < ln; i++) {
      //   menusInfoKeys.push(menusInfoKey);
      // }
      // state.menusInfo = filterRouter(menusInfo);
      
      // state.menusInfo = filterMenusData(menusInfo);

      // state.menusInfo = allStaticMenu();
      // console.log('getMenusInfo state.menusInfo', state.menusInfo);
    // } catch (err) {
    //   console.log(err);
    // }
    // console.log('getMenusInfo', state.menusInfo);
    return state.menusInfoKey;
  },
  // filterMenus(menus){
  //   return  filterMenusData(menus);
  // },
  //若缓存菜单信息和Storage不一样，从Storage中更新最新登录信息
  updataCacheMenusInfo() {
    let menusInfoParm = {};
    try {
      menusInfoParm = JSON.parse(getStorage('menusInfoAdmin'));
    } catch (err) {
      console.log('err', err);
    }
    if (menusInfoParm && (state.menusInfo.id !== menusInfoParm.id)) {
      try {
        setStorage('menusInfo', JSON.stringify(menusInfoParm));
      } catch (err) {
        console.log(err);
      }
    }
    state.menusInfo = menusInfoParm;
    return state.menusInfo;
  }

}

const actions = {

  //更新菜单菜单权限信息
  UPDATE_MENUS: ({ commit }, menus) => {
    commit('UPDATE_MENUS', menus);
  },

  //更新退出
  MENUS_SIGNOUT: ({ commit }) => {
    commit('MENUS_SIGNOUT');
  },

}

export default {
  state,
  mutations,
  getters,
  actions
}
