module.exports = {
	"64fdf49f89960ea7": "对响应数据做些事",
	"2937b66448b05dab": "登录失效 response.data.code",
	"69f9ba0349edc6b2": "登录失效，请重新登录",
	"a679f255dfd94a56": "响应错误时做些事",
	"e8bcc6cb3bf6c21a": "登录权限失效，请重新登录",
	"c02c393ccc6cbb32": "客服电话：400-800-5550",
	"cc62f4bf31d661e3": "提示信息",
	"71827d3678b69b71": "万",
	"9ccf3ec5dedd0911": "(非必填)出库放置区域",
	"3233d4e01025464e": "拖动sku到对应托上，点击‘",
	"1ea9d0b7f11f0733": "’拖动",
	"e66c561f70bd9f8b": "到达仓库：",
	"99ed5f00d1ae48d1": "仓库地址：",
	"d6e21ff0cb0941c3": "点击下方‘",
	"54b11a743e904e2f": "’拖动SKU到对应托上",
	"93f5ca01b006206c": "操作",
	"1787524a3924f9b0": "FBA申请号",
	"c032f6b78808e7f3": "总数",
	"c7653c0832527b93": "剩余",
	"bf0a1c4ff90aadcb": "已分配",
	"01b9ace71d0db12e": "新增托",
	"ad102ed83ed76332": "(会试着新增SKU第一条且剩余不为0的数据)",
	"de7090439acfce3f": "托",
	"73bbf51487ec69e9": "共",
	"922c8542b7d698fb": "个SKU",
	"7431e76029678ec7": "标题",
	"3775920d44088b96": "件数",
	"4f5bb4ff8b3d804b": "确认",
	"4e9fc68608c60999": "关闭",
	"36dd4d8e37f4a446": "SKU剩余数量不为0，请准确分配~",
	"204ffab8a6e01870": "确定",
	"2dc8528200b25ad7": "请删除SKU为0的托~",
	"4c6841ce9043c9fe": "互易仓",
	"57ef7062be4bcd5e": "语言切换",
	"ed9ffec2e58ddb70": "中文简体",
	"8be68daadde8fd16": "切换语言成功~",
	"b4bced00356095dc": "退出",
	"0df90bded79afc49": "产品",
	"408668e7ebee1050": "SKU库存",
	"7981e073e6b198a9": "SKU信息",
	"4a45b6d8b823236e": "SKU流水",
	"fb44baec4150b31f": "预报",
	"4b038d5cbc643471": "发货",
	"83c817669b913ebf": "我的装箱单",
	"f52a39ff3d95336f": "我的发货单",
	"684a2afb069b6016": "一件代发",
	"23529ffcabeca8ea": "报表",
	"64d6ec8ba3450dd8": "我的仓储费",
	"7b1c2b1adc920d9c": "序号",
	"15b3627faddccb1d": "备注",
	"b47c7cf948eb1d37": "添加SKU",
	"1dbc1e4ed1522f17": "导入SKU",
	"e720d3f78642a853": "SKU详情",
	"89d50ef8fe430e48": "选择或搜索商品类目",
	"039b1175cdb03703": "支持关键字模糊搜索",
	"ea5e5a3a3c176681": "建议上传尺寸不小于100*100的图，",
	"c1c4f12e8d35166c": "支持png、jpg等格式，大小建议不超过500k;",
	"7de4da2660845ed2": "只能是数字字母下划线，不超过30字符",
	"dbb039ef07c68443": "产品分类",
	"539728f5dd9cb065": "SKU名称",
	"bb0e2b6a0053154d": "SKU中文标题",
	"2cb3d5af63361796": "SKU英文标题",
	"9fcdcb3a067903d8": "中文",
	"3d1856da86a06def": "英文",
	"efb573c2cb944147": "SKU图片",
	"9db48ed0e8ec64a6": "价值",
	"96f1a4b17e75892d": "重量",
	"b6bf0a07fe26f74f": "单位",
	"0e60303b30d5d4b4": "长",
	"6cd31871f8528dd5": "宽",
	"93c9ea4a0e52c641": "高",
	"551b0a9e48d684f9": "长*宽*高",
	"004617dc47191cb8": "安全库存",
	"594abd886182b662": "产品信息",
	"d48bb83a82237f60": "规格信息",
	"0640e3814d107002": "现有库位库存",
	"68b3bb1ef6931076": "当前锁定库存",
	"d91fd63094e82992": "当前有效库存",
	"d8644e8d60c8bd8a": "单价",
	"2594059036dfede5": "时间",
	"f9db93b87e08763b": "数量",
	"ae61193590bd513d": "单号",
	"184333c81babf2f1": "类型",
	"f925d9b48d8c1d45": "说明",
	"6cdece641436d7ab": "状态",
	"b7a10fe4e9528362": "柜号/跟踪号",
	"844086cd62972687": "创建装箱单",
	"411428188ecb0be8": "导入装箱单",
	"ed47d0c198c61d01": "出发港口",
	"d3d95bf498ae6cdb": "到达港口",
	"04e92fdefcddec84": "出发日期",
	"b5c03c6f1b9b1e28": "到达时间",
	"b799f516e3940e3f": "装箱类型",
	"b9d4d3f6183bf3c1": "装箱件数",
	"c9fbcb0263c9b1f2": "复核件数",
	"f5d43732e3f6cf4d": "附件",
	"ae5d09d5eca25a2b": "增加",
	"8ed435ced73dc9bf": "基本信息",
	"e6bdd31025bb4f9f": "明细",
	"dc0eb5f461534508": "装箱信息",
	"1108b3e1ecd1fdce": "货代客人",
	"97e49d00bcc4c17c": "其他",
	"16853bda54120bf1": "全部",
	"fdd8426d7b8869c5": "未收货",
	"1e2279a00c4336a4": "未入库",
	"3ece0cc00f1da8de": "已入库",
	"5508f750107a3649": "确定发货吗?",
	"a2df50399ce1bf10": "发货编号",
	"2548824ee5f19b0f": "创建发货单",
	"5acbec83efb27445": "仓库编号",
	"5165f3ee9377af83": "出车方式",
	"99b88de52e382ca4": "预计到车日期",
	"0c78dcec39ac44fc": "发货仓库编号",
	"56384dec1b2a9aa8": "提交指令",
	"79784145b384f8da": "下载提货单",
	"dc911ba1ba820fa8": "导入SKU明细",
	"0f859b9b45732564": "到达仓库",
	"e0a4ad52c08d9bdb": "FBA入库申请号",
	"df7602042d7847f9": "到达区域",
	"59f93d534a71e55e": "到达详细地址",
	"9fc252e5a4f5345f": "体积(长*宽*高)",
	"b4f10fe0216dec66": "手动新增SKU",
	"915541755261249f": "草稿",
	"7e14d2cd3996dcd2": "已提交",
	"1ff1fd8ddb3e0e8d": "拣货中",
	"5cec023a4b7d779b": "拣货完成",
	"4841a132787c72d8": "打托中",
	"dcc830098ae4c9fd": "待出库",
	"a342c84e359f0d02": "已出库",
	"a3bd26a97ca5f275": "确定 提交发货指令 吗?",
	"8730be85c2f45b7c": "导入代发单",
	"fb16a4833ec342cc": "批量提交",
	"fc7e87b5dfa6e4b8": "代发数量",
	"7e1de5cb2215dfea": "没有SKU",
	"43a3586339251494": "日期",
	"431d0d251ff90f3f": "发货地",
	"80b704ca1789ae16": "收货地",
	"e44e9a291d80c593": "收件人名称",
	"c3755bab459a2cbc": "公司",
	"c2d9cc88561f8cdc": "联系方式",
	"497e4906e7963cfc": "地址1",
	"9d92f7e2f89961c8": "地址2",
	"e05b5d049b64b040": "城市",
	"5abbc9dfd6289796": "州/省",
	"c4913ab43009b365": "邮编",
	"b166e4e8fe9513fa": "国家",
	"ad4345dbaabe1479": "提交",
	"0c3e84f9f6aa0e52": "已审核",
	"7b80e66b535a3732": "请先选择要操作的数据",
	"8fdeb3c5d820598d": "所有选中为草稿状态才可以提交~",
	"f96b62dfa31cde45": "确定提交",
	"cade329ca6989721": "条数据吗?",
	"b4ea1320f05a2958": "解析失败，数据量过大",
	"209fdd8f47e20fbf": "客户编号",
	"46c3f6e0f657e7a3": "费用类型",
	"d7315fb8114eb446": "费用",
	"4b107048a1f3cfd0": "费用描述",
	"96e86c7f83dd7561": "收费方式",
	"9bfcf061ccdd5c1c": "账单日期",
	"47df8be257c59dde": "加载中...",
	"44bd089554515c4d": "正在提交..",
	"daaaeb1b7b22b126": "提示",
	"d655cebf64ac8df4": "抱歉，请求数据有误",
	"c67e2e19659d381d": "抱歉，请求数据有误，请重试",
	"d3613276a72a5ef7": "数据中存在信息未填写或填写有误，请检查。",
	"9f30371831a98237": "提交成功！",
	"dbe331ab679cd67f": "抱歉，提交失败,请重试",
	"33bae59bb784a109": "抱歉，提交失败,请重试！",
	"81d48fdc2158d55d": "抱歉，查询详情失败",
	"bf360c235e89e2eb": "抱歉，查询详情失败！",
	"04470afdd839db6e": "成功加入购物车",
	"f991921edde2f136": "添加到购物车失败，请重试",
	"e6c6120b5a8acbb8": "添加到购物车，请求失败!",
	"67a39834582ad99a": "暂无数据",
	"d0d544fad4507868": "暂无",
	"a3af82cfacb6447f": "必须为登录才能操作，是否立即去登录?",
	"2c19ac613a4b6a8a": "必须为厂家才能进行该操作，是否认证为厂家?",
	"6bc4d7c40f408273": "必须为厂家才能进行该操作，是否登录?",
	"191a1d1f11bbfb63": "厂家认证通过才能进行商户认证",
	"8a23e83d049d4217": "认证信息有变更，请重新登录",
	"c8840b2ed17fc9ee": "互易天下",
	"bdaf0b4164ce179e": "头像",
	"278ba7ca16ed0689": "商品类型",
	"9f0490bf3edeef88": "钢材",
	"5a90bf08ed58d528": "木材",
	"66d7424398d24f52": "棉花",
	"fe08b328b3f821ce": "棉纱",
	"b4b5b263d22f7793": "棉/纱",
	"95c5b962979af124": "车",
	"bf8ba61af81f99bf": "酒",
	"f190f9795d4d3fa6": "茶",
	"693c7b35bdf3e3a3": "金融",
	"90354e648ee81925": "仓储",
	"ff8e3022b2b9bdb9": "商品标题最多支持60个汉字，超出将不再显示超出后的内容",
	"4b3340f7872b411f": "取消",
	"4ce9979bfb6576d9": "保存",
	"ca5a01a5adf20fe7": "返回",
	"c09a4ec2fe473b0a": "修改",
	"556c0672dcecba13": "设置",
	"70fdf36bdc2b0f58": "开启",
	"a9e1d1c2c5eb6525": "刷新",
	"1e7246dd6ccc5539": "搜索",
	"9eefb7335988c499": "查询",
	"e33c9b93c36fd250": "删除",
	"c0246c55b9cac963": "添加",
	"6267f3aedf895209": "编辑",
	"7fb6ada66a1fccca": "选择",
	"de6344a0a81cf18e": "确定删除吗？",
	"2674282277c3714e": "图片",
	"04e722a308964860": "预览",
	"4c0e3495d9042ebf": "查看明细",
	"228e58fddadbff82": "上传附件",
	"e82c9fff83ec2d91": "手机号",
	"d49a93bb265a311d": "验证码",
	"f1ee1d97e869e447": "请输入验证码",
	"ace46c7b30cb9a6e": "获取验证码",
	"4733de26e1c2e985": "验证码已发送",
	"b367a94ea38173fb": "秒后可重新获取验证码",
	"4afe69876103f780": "密码必须为6-18位，且至少包含一位字母和一位数字",
	"ed9ff5f7d8beaa43": "短信验证码已发送至您的手机，5分钟内有效，请查收！",
	"0f00eaea829fd543": "获得验证码失败",
	"ec04cac591a1850c": "验证码存在错误",
	"1ae7a6f6675d7d09": "密码不一致",
	"5a9aefbc03c778f7": "请输入",
	"2ad108ab2c560530": "请选择",
	"e88a18bb2c03bb64": "请输入或选择",
	"024e0fe5f03408ea": "请上传",
	"d23ea3ece931057a": "请输入二代身份证",
	"057d4e3f1ee2d9bc": "请输入数字",
	"1a6a270dab462dfe": "请输入整数",
	"e69d4402bd9bdefc": "请最多保留三位小数",
	"0cc9c1aa48812e61": "请输入手机号码",
	"b1b26a8c797b6806": "请选择收货地省市",
	"68efabd029c860a9": "选择日期",
	"7519a687c0125f7b": "选择开始日期",
	"0e1880b21fa14752": "选择结束日期",
	"0200bd47bb4bb83d": "今天",
	"84b73bfc6dada445": "昨天",
	"e3f3138cf5ba3f6d": "一周前",
	"58169349e34744e8": "选择日期时间",
	"870c336dcb486d96": "常用国家/地区",
	"6b0ab3e65c3be5f6": "手机区域代码请求失败",
	"f04d208f84e6c343": "省份",
	"cbea4e1393d9e448": "区/县",
	"c944a6686d996ab3": "仓库",
	"538606c127a812d7": "获取省份信息失败",
	"ab46b83080b1bef8": "获取城市信息失败",
	"599c4ec0106e9ec1": "获取区县信息失败",
	"084ee4095468a454": "获取仓库信息失败",
	"42a17cc85f956d55": "选取文件",
	"a67037f1a5e99c8d": "上传到服务器",
	"2f75d79503294e52": "上传文件大小不能超过",
	"8ca9d59119d46247": "上传失败",
	"3ceea1e67d757758": "请上传合适格式的文件",
	"b180cd998ccbe52e": "文件超出个数限制",
	"9cdac116e11d2942": "请先选择文件",
	"4356672f48943143": "未知的图片地址",
	"66261f0ce9d34a49": "未知的文件",
	"2e9f903ca3d96308": "正在上传",
	"1aa9b398f37906a4": "文件名",
	"1ecc8be9ba1c940a": "点击上传",
	"ea5174c2d4777af3": "打开上传文件有误",
	"934df8eb0fce18e9": "浏览器版本过低不支持文件方法，请升级浏览器或联系客服",
	"4ea587366b85f899": "组件加载有误",
	"73dd0d43f580bc68": "导入成功",
	"23ffd33f78e6f41f": "删除图片",
	"2d692b31fafc6424": "确定删除此图片吗，删除将无法恢复。是否确定?",
	"fed4e5b5e96767cb": "请上传 .png,.jpeg,.jpg,.gif格式的图片",
	"4c9af5f1aee9bee2": "选择Excel",
	"1d8178c8a95ccb3b": "上传并预览",
	"e49d33421617c0da": "下载模板",
	"bda76d3849a766ce": "吨",
	"06b5d5b29cf6518c": "立方",
	"65bba0d13269d127": "辆",
	"74afbba17eeff513": "瓶",
	"815cf59b03075baa": "元",
	"01d515661b01a0b3": "互易首页",
	"af52d9dcd723be61": "欢迎来到互易",
	"98b8be246c7d75f4": "请登录",
	"e2d6d5e2822a673a": "免费注册",
	"024adf67cbe459cf": "用户资料",
	"2124c0d1d6d06b07": "账号安全",
	"59e36d01a5abbc90": "账户管理",
	"ca1c2845b3c08111": "认证",
	"a4513a1a48f97338": "我的订单",
	"fb949604ce64a27d": "买家中心",
	"3a4e0063434e0aa7": "买家订单",
	"2a6e768f44ff1335": "我的求购",
	"8e2405ce89d11c64": "我的询价",
	"6e7d05a7f3e2fe77": "厂家中心",
	"6d6faf9c75056074": "厂家订单",
	"c4b724022c1c6849": "商品管理",
	"9a954c4251c670df": "商品发布",
	"a0d9a8bda2c5e1ca": "我的报价",
	"30746594c861e3f9": "合伙人中心",
	"cf03031ed0359ac6": "我的推荐",
	"c68a2435378ac15f": "邀请注册",
	"471a3636b5d91f39": "客户服务",
	"080e622e397bedee": "帮助说明",
	"f855997fac114554": "帮助中心",
	"08cc7d24752ec42c": "在线客服",
	"74f1dfbcbf21ef4f": "联系在线客服",
	"a97cd564413414f8": "客服在线",
	"3e6018b486a4f0b6": "提货码查询",
	"ace012504bc529e0": "消息",
	"bbeda9da4cbc4745": "手机客户端",
	"39e76151a6a7fbaf": "APP下载",
	"6f63b667b53d4ab0": "微信商城",
	"7c990867fe4740ee": "Android下载",
	"85cd7163c73f3541": "IOS下载",
	"f08eba7edcb8b649": "欢迎注册",
	"158eb06e6609b0b7": "用户注册",
	"29799f539ee6b906": "请输入手机号",
	"534c2aade93b99db": "中国大陆",
	"604a0b11e916f387": "密码",
	"acc6253786d62502": "重复密码",
	"53f8f60579ce17a4": "合伙人手机号",
	"ae21e9b65f895153": "阅读并同意",
	"1dfd24199f850f69": "互易天下注册协议",
	"1bc005fe7464ed3f": "注 册",
	"d146dcc24e824603": "已有互易天下账号？",
	"0fff44c827a4f3b6": "登录",
	"4041c67ffdcc3210": "有任何问题请联系客服",
	"2d13b6f878f30be3": "电话",
	"bd2ffae5d1ea1dcd": "请选择手机国际区号",
	"f5a9e0830d476523": "必须为手机号",
	"b978bd71e959b620": "请保持密码一致",
	"47cfeb9a4965a9c3": "注册成功！",
	"8efc7ede2b82d0d2": "欢迎登录",
	"dffe304884c9a6ad": "密码登录",
	"be677facb85e372e": "记住密码",
	"c7609e554782bec4": "登 录",
	"41ad17fdbe906b08": "忘记密码?",
	"0f12dbc1cd5f49e2": "新用户注册",
	"b5af3886627252eb": "手机扫码，安全登录",
	"50f017673319c5db": "打开",
	"17a79a4f51540f5b": " 互易天下APP",
	"07532a765aa30fa5": "扫码登录",
	"c673a194ace6c266": "请输入正确手机号和密码",
	"bf8f960ea394c351": "登录成功！",
	"bbe03be5df1c223f": "登录失败！",
	"1d89b3f39386d872": "找回密码",
	"0f59ee02efe0d401": "验证身份",
	"b09a63f0d59e0df2": "重置密码",
	"25e03120b83ee4ed": "完成",
	"4639f3a6e07c00b3": "下一步",
	"02678cace7540fa0": "新密码",
	"9cb1c9c51e7503d6": "确认密码",
	"c5208cc6dccef8a4": "恭喜您，找回密码成功！",
	"d1d7b80ea41c31bd": "请牢记您的新密码",
	"08806c68bf7fcf6a": "秒后跳转到登录页",
	"ced8f3a726d82f7b": "去登录",
	"6c241c83319c35d6": "找回失败，请返回重试！",
	"a49bf97bd35892ed": "全局导航钩子",
	"d89b1f347399ba38": "不存在 NotFound",
	"d009ef7b75a30a3e": "深拷贝失败",
	"c4e89cc1ae76e3ae": "省份信息请求成功vuex",
	"e97dfb2e53284adf": "省份信息请求失败vuex",
	"20fadea0ce9b979a": "省份信息从vuex缓存读取成功",
	"8f762cb5b5c3b4eb": "底部帮助中心请求成功vuex",
	"4acbfc125c976cec": "底部帮助中心请求失败vuex",
	"8e8aa8b241e4c2e1": "底部帮助中心从vuex缓存读取成功",
	"33a2918595ed9930": "底部关于我们请求成功vuex",
	"9818091176ebc075": "底部关于我们从vuex缓存读取成功",
	"18eb8d07e0e8256e": "商品分类导航请求成功vuex",
	"6e1b8e5a32c67f8c": "商品分类导航请求失败vuex",
	"f7f543cde1cd974c": "商品分类导航从vuex缓存读取成功",
	"f7c9556928fe4ec9": "手机区域代码请求成功vuex",
	"2caa2cb5696d6a37": "常用",
	"a4dd3b2f9d3a0b22": "如果没有找到您的所在归属地，请拨打客服电话4008005550解决  ",
	"1fc977dfed198654": "手机区域代码请求失败vuex",
	"8cc3916e6e3bd181": "手机区域代码从vuex缓存读取成功",
	"5b42b49a689d1bef": "需要登录，立即去登录?",
	"be99aad78a1ac1f0": "需要进行合伙人认证，立即去认证?",
	"b9bc1fee3d9bf118": "需要进行厂家认证，立即去认证?",
	"0951ed7e701d0327": "需要进行商户认证，立即去认证?",
	"a3582c07278e2220": "请从‘合伙人中心-我的推荐’模块进入",
	"hytxs0000001": "请输入(精确搜索)",
	"hytxs0000002": "关闭",
	"hytxs0000003": "打印收货清单",
	"hytxs0000004": "去复核",
	"hytxs0000005": "确认收货",
	"hytxs0000006": "入库时间",
	"hytxs0000007": "到达数量",
	"hytxs0000008": "入库件数",
	"hytxs0000009": "总重量",
	"hytxs0000010": "总体积",
	"hytxs0000011": "查看sku详情",
	"hytxs0000012": "入库",
	"hytxs0000013": "入库信息",
	"hytxs0000014": "入库时间",
	"hytxs0000015": "收费方式",
	"hytxs0000016": "收费价格",
	"hytxs0000017": "入库库位及数量",
	"hytxs0000018": "新增入库库位",
	"hytxs0000019": "仓库信息",
	"hytxs0000020": "选择库位",
	"hytxs0000021": "仓库库位",
	"hytxs0000022": "数量",
	"hytxs0000023": "删除",
	"hytxs0000025": "sku详情",
	"hytxs0000026": "选择库位",
	"hytxs0000027": "入库中",
	"hytxs0000028": "已入库",
	"hytxs0000029": "列表数据有误",
	"hytxs0000030": "列表数据，请求失败！",
	"hytxs0000031": "提交有误,请检查提交数据！",
	"hytxs0000032": "查询数据字典失败,请重试",
	"hytxs0000033": "查询数据字典接口失败,请重试！",
	"hytxs0000034": "区标",
	"hytxs0000035": "库位",
	"hytxs0000036": "库位类型",
	"hytxs0000037": "当前件数",
	"hytxs0000038": "请输入(模糊搜索)",
	"hytxs0000039": "关键字搜索",
	"hytxs0000040": "打印收货清单",
	"hytxs0000041": "去复核",
	"hytxs0000042": "确认收货",
	"hytxs0000043": "确定提交吗?",
	"hytxs0000044": "全部复核",
	"hytxs0000045": "复核状态",
	"hytxs0000046": "单条复核",
	"hytxs0000047": "复核件数",
	"hytxs0000048": "装箱件数",
	"hytxs0000049": "确认复核",
	"hytxs0000050": "工单处理",
	"hytxs0000051": "装箱单",
	"hytxs0000052": "装箱单明细",
	"hytxs0000053": "收货入库",
	"hytxs0000054": "发货单",
	"hytxs0000055": "一件代发",
	"hytxs0000056": "财务记录",
	"hytxs0000057": "库位管理",
	"hytxs0000058": "客户信息",
	"hytxs0000059": "仓租费用",
	"hytxs0000060": "客户名称",
	"hytxs0000061": "快递单号",
	"hytxs0000062": "费用",
	"hytxs0000063": "城市",
	"hytxs0000064": "取消发货",
	"hytxs0000100": "请选择打印机(不选则默认)",
	"hytxs0000101": "建议按照'数字'、'字母'、'-'、'_'组合,不超过20个字符,且保持唯一",
	"hytxs0000102": "导出全部时，不包含SKU CODE一维码图片；如需导出一维码图片，请导出当前页；",
	"i18nn_38d84b35d4836a92": "创建时",
	"i18nn_246e8486702689b8": "初始化微信分享",
	"i18nn_c074db443e169104": "监听路由变化",
	"i18nn_5f5d2d5861866f89": "避免首次获取不到",
	"i18nn_70e6e0b7ca198366": "如果您使用",
	"i18nn_58d2c1bf5829535c": "改写了已有页面的",
	"i18nn_7060ca2fc8279505": "那么建议您在",
	"i18nn_4b0727f8db77be1d": "的",
	"i18nn_373222bafdc59d8c": "统计代码执行前先调用",
	"i18nn_f4b9a92ed6eba312": "将该页面的自动",
	"i18nn_7f60c280ca3cf89e": "统计关闭",
	"i18nn_f47ba4ab8f8737c3": "防止页面的流量被统计双倍",
	"i18nn_1e6fc9d258352566": "用于发送某个",
	"i18nn_ad66ec7a4a1379a4": "统计请求",
	"i18nn_7f8cc5c86bdabe1c": "首次进入页面即执行",
	"i18nn_6b3c6618251b6db5": "生成一维码失败",
	"i18nn_470bbdcc42b395ec": "选择商品类目",
	"i18nn_dd2d9da989e73605": "选择框是否显示所有层级的显示",
	"i18nn_f0306a16ca426bbd": "可选的层级",
	"i18nn_005085930f991fb0": "下拉展示的层级",
	"i18nn_2eccef01f0c2b163": "编译挂载前",
	"i18nn_d6f79d965aae9814": "值改变",
	"i18nn_741a6252538afafe": "返回所有三级类目数据",
	"i18nn_a83b42e33f2b1173": "树形分类信息",
	"i18nn_758239e08104dfb8": "商品类目请求失败",
	"i18nn_81502b2761fec335": "正在生成",
	"i18nn_c31a431d8ed71e3a": "合伙人证书",
	"i18nn_a5e2444030dc17a8": "预览生成的图片",
	"i18nn_d7a226d4df7bee09": "预览有误",
	"i18nn_541218c422851c20": "刷新重试或联系管理员",
	"i18nn_be59529ed5139a67": "选择展示的列弹窗",
	"i18nn_eeeadd6f7ea81f5d": "选中的字段",
	"i18nn_af74800096e892cd": "所有待选的字段",
	"i18nn_5083305255d191f7": "是否可拖拽",
	"i18nn_29f3a276f59714e3": "加水印",
	"i18nn_245883c80f181c4a": "下载",
	"i18nn_a0fde10c3eb9355a": "互易天下合伙人",
	"i18nn_23d5dd4ea17213bc": "需要截图的包裹的",
	"i18nn_9ef87cb36010d25e": "原生的",
	"i18nn_7c805dc66bdc9ed8": "对象",
	"i18nn_97e0de817d74acab": "获取",
	"i18nn_9e24e0e4745f4948": "宽度",
	"i18nn_c5fa0857bc0df2d6": "高度",
	"i18nn_208be425bbda6438": "创建一个",
	"i18nn_d631d93aee55e4e4": "节点",
	"i18nn_e52d10aff3b6a667": "定义任意放大倍数",
	"i18nn_a1e786ef2dada8de": "支持小数",
	"i18nn_5a1aa4d777de0438": "定义",
	"i18nn_eb108fc5434cdb1a": "缩放",
	"i18nn_74aed471f7aeebf8": "在此我是把",
	"i18nn_30cab4bc8c8a1570": "放大了",
	"i18nn_4b601bc837a3703c": "倍",
	"i18nn_bc342cf2cf912e4d": "重要",
	"i18nn_be0b3f3b2a98fc6b": "关闭抗锯齿",
	"i18nn_cd951a589ae6afd4": "转化成",
	"i18nn_b379748a6ce5496b": "格式",
	"i18nn_f66595f0720a11d1": "可上网了解此格式",
	"i18nn_6133c5b3bf59043f": "因为在上面放大了",
	"i18nn_40a5987092f371b7": "生成",
	"i18nn_23fadc97e516d373": "之后要",
	"i18nn_d5d4c82cdb5d9d10": "此可以根据打印的大小进行自动调节",
	"i18nn_138ce5945e0e273a": "分页",
	"i18nn_f8c296ea4bc5dd45": "一页高度",
	"i18nn_2b51275d0b3669da": "未打印内容高度",
	"i18nn_a0e80bb860526889": "页面偏移",
	"i18nn_747f93bdf1f4b8e6": "不需要分页",
	"i18nn_181be7ba092f8b80": "页面高度",
	"i18nn_c966d4f92a75e8ef": "需要分页",
	"i18nn_d9fedb2a9479ca84": "避免添加空白页",
	"i18nn_fdb8ef2b61f6ca70": "添加空白页",
	"i18nn_662e6711e07501da": "保存为",
	"i18nn_f156a9536049f461": "文件",
	"i18nn_811095e72ac19eba": "一般把背景设置为白色",
	"i18nn_9fca1077909d3d2b": "不然会出现图片外无内容的地方出现黑色",
	"i18nn_0bd75225078f343c": "有时候还需要在",
	"i18nn_86274430e1c003db": "样式中设置",
	"i18nn_a8405b6dd99d594b": "背景白色",
	"i18nn_6773ddd95bc0757b": "区",
	"i18nn_92de017242c28a56": "县",
	"i18nn_543a597c8b5342e7": "省份数据",
	"i18nn_826e79803d1bc2fe": "选项框大小",
	"i18nn_f0584015c235d1c1": "布局大小",
	"i18nn_a544f827b30a69f4": "布局间隔",
	"i18nn_43dc54253f7448e0": "初始赋值",
	"i18nn_9075502784cc4b3e": "选择省份",
	"i18nn_c7fee137468893c7": "清空市",
	"i18nn_59c682bd0cf21bca": "清空区县",
	"i18nn_998f2f4329ec0971": "清空仓库",
	"i18nn_94b44280fa1d5493": "清空操作",
	"i18nn_00384cd4bb9a2292": "触发父组件方法",
	"i18nn_e6b1c2a1fc07e7d3": "选择城市",
	"i18nn_0897e206b059fa49": "显示区县",
	"i18nn_f8ca169fd7bcd7b8": "显示仓库",
	"i18nn_82808948d26efcf1": "选择区县",
	"i18nn_c6467851c112980c": "选择仓库",
	"i18nn_f74a3a59a7d5364e": "省份信息",
	"i18nn_7557b5b6a660da92": "省份信息读取成功",
	"i18nn_d9b86f5c1d349e5c": "省份信息请求失败",
	"i18nn_5ec78bef9d15434f": "省份信息请求成功",
	"i18nn_36354660b7fc15b1": "城市信息",
	"i18nn_c08547dbd9309a2e": "空",
	"i18nn_ccf8300d69affc7a": "城市信息请求成功",
	"i18nn_271b7085dfabfab6": "城市信息请求失败",
	"i18nn_ce6f99466b1a54f0": "区县信息",
	"i18nn_205964e5f8c20982": "区县信息请求成功",
	"i18nn_996c5bb9df173125": "区县信息请求失败",
	"i18nn_862c7231ea467e38": "仓库信息请求成功",
	"i18nn_de8931d48511795c": "仓库信息请求失败",
	"i18nn_dfd0cad4cb8eb442": "支持",
	"i18nn_3a74667815adc8bf": "最大",
	"i18nn_09049355527ef6a2": "张",
	"i18nn_f5bcb37f7eeeb184": "图片上传到服务器",
	"i18nn_6ae6dd62d24bcb8a": "文件类型",
	"i18nn_229b11e2f431750a": "阿里云",
	"i18nn_7dcf1197e1d2108c": "文件列表",
	"i18nn_cc8603c183589ab1": "侦听变化可初始化的",
	"i18nn_ad4f1f9c62389f04": "每个文件限定的大小",
	"i18nn_31439cad15cc246e": "可上传的文件数目",
	"i18nn_42d2e83f1c79aa4d": "容器宽度",
	"i18nn_7499c732802bf588": "图片弹窗展示",
	"i18nn_46ddcffae70a5c3b": "清空图片",
	"i18nn_6f579f3a8a1d652f": "缓慢滚动",
	"i18nn_a3851d8f06926278": "滚动到顶部",
	"i18nn_7b108cdca9ee7832": "变量",
	"i18nn_25a0d7bacb0096bc": "滚动",
	"i18nn_46312513d60a4d12": "覆盖默认的上传行为",
	"i18nn_f815e09651e471f8": "实现自定义上传",
	"i18nn_dffbf7d48cf860a2": "上传选项",
	"i18nn_7d35d659ad32a051": "没有返回",
	"i18nn_874b22782fe58a33": "配置信息",
	"i18nn_b3742c8e82f10c3e": "参数失败",
	"i18nn_904b1edd118e1b35": "分片上传文件",
	"i18nn_02a03d22b029f499": "调用此处",
	"i18nn_2e4e32c5dd570bfa": "执行两次",
	"i18nn_7f2f052a5da435ca": "上传",
	"i18nn_5fba7f16556a9197": "文件状态改变时的钩子",
	"i18nn_2a50e3872522d075": "添加文件",
	"i18nn_8389ed823b47a9c9": "上传成功和上传失败时都会被调用",
	"i18nn_18ebda1756cafbe9": "文件尺寸不能大于",
	"i18nn_d9d6266f40f67534": "未知文件",
	"i18nn_21508344d0cf56ec": "重复上传时出现",
	"i18nn_486db80b86de2c1a": "文件上传成功的函数",
	"i18nn_d34e97bbd9523da6": "为",
	"i18nn_99d6111748eae069": "的元素",
	"i18nn_b15f1d04b9c614ee": "为下标",
	"i18nn_dac6d2e1b7f9595e": "原数组",
	"i18nn_f5797a71c8dc42f6": "文件个数和返回",
	"i18nn_b1e0fb9c9e07cee6": "个数相等时",
	"i18nn_5106bcad470f79d3": "多图片会重复调用成功方法",
	"i18nn_b9143f93c45fee7f": "删除重复",
	"i18nn_669600b917b45f04": "方法的实现代码相当酷炫",
	"i18nn_563f7ec62931b0d7": "实现思路",
	"i18nn_a1792e462520f7a7": "获取没重复的最右一值放入新数组",
	"i18nn_5e981dea2122a654": "检测到有重复值时终止当前循环同时进入顶层循环的下一轮判断",
	"i18nn_58179f501fd44ca0": "上传文件之前的钩子",
	"i18nn_63bc87384ee1bdc5": "参数为上传的文件",
	"i18nn_a7c0a534a79af36b": "若返回",
	"i18nn_410278c6a1528e67": "或者返回",
	"i18nn_9f7ace149cfcb06d": "且被",
	"i18nn_48fbbc37d0b71cb7": "则停止上传",
	"i18nn_d60a3f24e8329e20": "小于",
	"i18nn_027eacedab3f8d19": "图片尺寸不能大于",
	"i18nn_987cf2f4c71691ee": "文件超出个数限制时的钩子",
	"i18nn_10a3c6e1c105c148": "文件删除",
	"i18nn_e1ac5c1843d0185e": "定义滚动条轨道",
	"i18nn_73f96ab3340fe950": "内阴影",
	"i18nn_3dca70270d1a74e9": "圆角",
	"i18nn_8a8f72a194eeb1f9": "定义滑块",
	"i18nn_f6e62f9642018cbb": "首页",
	"i18nn_e56ddb7ad8d6586e": "尾页",
	"i18nn_8e2be23dd3b81a31": "信息",
	"i18nn_6a82fdcc3fecddc2": "用",
	"i18nn_be0f3b211080d0b2": "大宗商品共享平台",
	"i18nn_1dc4a69b5a4078eb": "求购大厅",
	"i18nn_8421526d274c0341": "棉",
	"i18nn_5354b8b40c686fc4": "绑定的值",
	"i18nn_b742c82472e2ef35": "表格数据",
	"i18nn_8ccadbd008827b22": "分页数据",
	"i18nn_42abc1e524ae69dd": "排序方式",
	"i18nn_41f871978d079858": "排序字段",
	"i18nn_229f6b78d63e1bac": "降序",
	"i18nn_2536c7b6fb36b80c": "升序",
	"i18nn_bbc49a6941f59913": "分类",
	"i18nn_b8d1610b4726176f": "品名",
	"i18nn_b3853278ad6d5611": "采摘类型",
	"i18nn_05e29fb623110c69": "批号",
	"i18nn_ec834692ec6c8e38": "颜色",
	"i18nn_8d82ee4ab711fb75": "比强",
	"i18nn_ff8a4206aa17e719": "马克隆值",
	"i18nn_6cc2563839443db8": "回潮",
	"i18nn_73ecfc7be38d4bcf": "含杂",
	"i18nn_0bffd1282cb3456e": "交货地",
	"i18nn_266d5c5e4cd4a9f2": "价格区间最小",
	"i18nn_b0301d3bab660115": "价格区间最大",
	"i18nn_0d76cd7a958441df": "每页显示数目变化",
	"i18nn_dd3b072647434206": "每页",
	"i18nn_117aa3f27e5e430c": "条",
	"i18nn_8dfb22636adecc2a": "分页的条数",
	"i18nn_b18897c10c4eae3f": "请求数据",
	"i18nn_abc7a3d149718367": "这里手动触发一下",
	"i18nn_6509d9c0082bc83c": "分页更新",
	"i18nn_b5a8e089049df396": "事件",
	"i18nn_c904eca04ed8d05c": "并且把值传进去",
	"i18nn_9214ca0902929e8e": "页数变化",
	"i18nn_c936d3b74e41b901": "当前页",
	"i18nn_8d369ba038658da9": "请求分页数据",
	"i18nn_2f5702841ed342ab": "当前页显示数目",
	"i18nn_168c2364555142eb": "商品状态",
	"i18nn_5780e6a092c399c3": "下架",
	"i18nn_e0199d6ecc3ee81a": "正常",
	"i18nn_38e8c4d45d6ca3c1": "在库待售",
	"i18nn_ed2b2c9b6ff98b31": "违规",
	"i18nn_31e8ec91f4c1e966": "禁售",
	"i18nn_303124d23b20ec0a": "商品审核",
	"i18nn_91cfca219e1b41ce": "不通过",
	"i18nn_00b3af4c32634497": "通过",
	"i18nn_f2d95c318721642c": "审核中",
	"i18nn_2e3ee00867931849": "市场类型",
	"i18nn_4cf78c5cac4a485f": "互易大宗",
	"i18nn_25e25543c26526d5": "撮合集市",
	"i18nn_674e5faed21eb561": "棉分页",
	"i18nn_776496ec446f21f9": "请求成功",
	"i18nn_4217bc1acbacd2ba": "表格显示数据",
	"i18nn_bc4bced036fdb495": "当前数据总条数",
	"i18nn_2854e5bf29a64ed3": "当前页数",
	"i18nn_d34cf82368ba6d89": "当前页条数",
	"i18nn_323ee425eca7208c": "请求失败",
	"i18nn_2bbc6b12fbdf9019": "参数配置",
	"i18nn_ff6fd823ffab7e6b": "导出",
	"i18nn_a5d230a7299da11c": "设置监听",
	"i18nn_b862f639d95fb8cf": "如果改变就更新",
	"i18nn_ee5a0b69f9323d4a": "加粗",
	"i18nn_d656c6a9c3cbd795": "斜体",
	"i18nn_15881fe9a4aa9352": "下划线",
	"i18nn_412470089bec1dfd": "删除线",
	"i18nn_c812fd61ee56d868": "引用",
	"i18nn_21414c06d3e4ff2c": "代码",
	"i18nn_57a7de477b2ebe97": "有序列表",
	"i18nn_49132db903eec70f": "无序列表",
	"i18nn_4b9484089a14fff2": "段落格式",
	"i18nn_653d92fa8f1011d3": "段落",
	"i18nn_32badce0902c46a7": "字体大小",
	"i18nn_d48da5af833f1c63": "字体",
	"i18nn_dbd7312ad2825e55": "字体颜色",
	"i18nn_03e7724664b15858": "背景颜色",
	"i18nn_2c6b89b19200de97": "对齐",
	"i18nn_5ed646cd1d97c310": "超链接",
	"i18nn_f5817042904f4a12": "视频",
	"i18nn_407a5bd47a532136": "还原",
	"i18nn_27ae8de28d3c3821": "查看源码",
	"i18nn_fae4e00e66853b23": "当前已经输入",
	"i18nn_4ebc25f9925a902b": "个字符",
	"i18nn_513129bb8856e39f": "确",
	"i18nn_138162c29671c3cd": "定",
	"i18nn_fbf379655e68a5a2": "文章源码",
	"i18nn_f86a8104e2fb8111": "图片可收缩",
	"i18nn_736b9d8693e29999": "自定义字体大小",
	"i18nn_c53d0b17c0399897": "自定义字体类型",
	"i18nn_481e4318f3bb1637": "将字体加入到白名单",
	"i18nn_5b27f3993d58438d": "请输入内容",
	"i18nn_4ad7644b0a045896": "建议复制粘贴过来的内容",
	"i18nn_2c39ccf2839171bb": "全选内容后点击最右边的还原再编辑",
	"i18nn_06f0c71344e7183c": "添加图片",
	"i18nn_b79cb734afe08e40": "失去焦点",
	"i18nn_2d0a131edd6feb69": "返回的",
	"i18nn_67acefa1d6a78e23": "获取焦点",
	"i18nn_5a3cd7da611f6553": "可以输入",
	"i18nn_2c94eb948dd388f4": "输入文本改变事件",
	"i18nn_5d2f8ccdc5abf8e7": "返回的编辑对象",
	"i18nn_c5329b923a1a6213": "上传图片",
	"i18nn_9b1a4a3b2513a12d": "上传视频",
	"i18nn_b83b775c30e763e6": "添加视频",
	"i18nn_6c47edbd9e030ff5": "提交上传文件",
	"i18nn_fd7d693ca5ce068b": "上传图片回调",
	"i18nn_cd47fe86827ac5fc": "上传文件成功",
	"i18nn_6f1f00781c566d8f": "上传文件返回的",
	"i18nn_34d2cb61b69affe1": "设置光标在最后",
	"i18nn_4c871e84a0564f9c": "这个方法可以获取光标位置",
	"i18nn_7d0005a132605a5b": "选择文件",
	"i18nn_222caea401e3439a": "上传进度",
	"i18nn_5fb9c387643c5737": "合同地址",
	"i18nn_ee97f044c1f043b9": "在",
	"i18nn_d1c90aa33249c258": "中要为",
	"i18nn_2918e54ff488ec32": "标签添加",
	"i18nn_c4395ffd696b7a01": "并且侦听该事件",
	"i18nn_a85fa7dbdc657d7a": "打开上传文件有误请联系客服",
	"i18nn_adbf16ce2dbbe92b": "浏览器版本过低不支持文件方法",
	"i18nn_75013b3b70e9a3c5": "请升级浏览器或联系客服",
	"i18nn_166108de2d7c2027": "订单合同等",
	"i18nn_fde1f90f1930c21b": "为空",
	"i18nn_4a4497edde0d7206": "请联系客服",
	"i18nn_b2fd3b9c18f697dd": "文件尺寸设置",
	"i18nn_0be4d3c7384011b5": "图片唯一",
	"i18nn_a3159ee97638c70b": "图片处理",
	"i18nn_2f2b1c77f45d1a43": "根据图片信息自动旋转",
	"i18nn_f17d5c1c032e68c3": "图片自动裁剪",
	"i18nn_6dc79342e50cc171": "确定删除此图片吗",
	"i18nn_92aaf4e1f0afae4c": "删除将无法恢复",
	"i18nn_3e4aebc665e89cae": "是否确定",
	"i18nn_97cb71abaca91a13": "打开上传图片有误请联系客服",
	"i18nn_81589c78219b1bb5": "格式的图片",
	"i18nn_af98bda0cb94f7f5": "非头像",
	"i18nn_1b499d5c744131c7": "厂家共享平台",
	"i18nn_ae4b2d07e3367db4": "参考",
	"i18nn_d30b41ab51955fc4": "裁剪",
	"i18nn_156c6fd2ea701c2d": "不裁剪",
	"i18nn_c378a7bc81d60366": "等比缩放",
	"i18nn_263c9f297c574aff": "填充透明",
	"i18nn_1ec3705be58c7df8": "打开上传图片有误",
	"i18nn_2eb3cd460fd45d17": "浏览器版本过低",
	"i18nn_df5b81201f81955b": "不支持文件方法",
	"i18nn_739e5a9ed0c91f9e": "请升级浏览器",
	"i18nn_389498309e60868f": "上传有误",
	"i18nn_7fb9848bb94f4881": "点击查看",
	"i18nn_a889656035b929cd": "无",
	"i18nn_d73c73d13129ee77": "点击下载",
	"i18nn_377fa66b0b28ce31": "查看",
	"i18nn_34bf5c39dd2578eb": "修改弹窗",
	"i18nn_235e127fd6efba7d": "切换显示",
	"i18nn_41413cddfa61538c": "操作提示",
	"i18nn_dc14da6ede09b7f1": "表格显示配置",
	"i18nn_90b7f7d6846dfdce": "导出表格",
	"i18nn_6bf807d0c7a842a5": "表格配置和导出",
	"i18nn_85b1942b3831e5fc": "右边容器",
	"i18nn_07d2a2430ac50e15": "可拖拽",
	"i18nn_5968ed3c7c608542": "不可拖拽",
	"i18nn_08d4a6af7c96969c": "值显示",
	"i18nn_68b65535b51faedd": "图片显示",
	"i18nn_91e471ccefc4766d": "文件显示",
	"i18nn_33fc0ec9f5b6203e": "多个图片",
	"i18nn_c53675110af9fb34": "显示启用还是不启用",
	"i18nn_889b3ef0590d9f54": "启用",
	"i18nn_11419332713360d2": "禁用",
	"i18nn_ac36125b19be0d99": "格式化显示",
	"i18nn_d81d50608932d481": "其他格式转换",
	"i18nn_7ea846c3c2d278b0": "图片展示",
	"i18nn_1b956381e0763d05": "字段",
	"i18nn_03dba9f3d9a93a89": "单元格点击",
	"i18nn_f80ee7aca6ceb210": "选择头展示列",
	"i18nn_b4d980ca4788fa0d": "选择展示列",
	"i18nn_3a7c1bda93906fe9": "全选",
	"i18nn_adbec0a43bad1521": "查看图片",
	"i18nn_62ba1be0ea549540": "列是否可拖拽",
	"i18nn_01af227b74d043eb": "表格头图片时不可用此拖拽",
	"i18nn_f0cf7313369cc153": "是否多选",
	"i18nn_ce6bc235e66bd4b2": "复选框",
	"i18nn_1c08595226658d1a": "暴力重新渲染",
	"i18nn_95ce5520e9a9ea05": "复制头",
	"i18nn_cc1eef3b5ac02fff": "解决表格错位",
	"i18nn_cd94bbe0a2222fa6": "元素",
	"i18nn_ae9c49432e02be76": "展示列表字段",
	"i18nn_03f79798b2ae58b0": "列表显示的子弹",
	"i18nn_e73c9c5607f97963": "至少选择一条",
	"i18nn_538da37c74898b01": "单击单元格",
	"i18nn_c695c8f0e68e65a3": "选择表格行",
	"i18nn_6ebe6ec4eb502f95": "多选",
	"i18nn_0679c6f94f9fba00": "多选行触发",
	"i18nn_71c0553cad2e65de": "后台排序",
	"i18nn_726c51702f70c486": "创建时间",
	"i18nn_fdc34fd7121f9c48": "更新时间",
	"i18nn_8bdefb0ca2cdbd9c": "系统更新时间",
	"i18nn_ded8f8808ac75c6d": "用户注册时间",
	"i18nn_a2c069022c779043": "默认",
	"i18nn_f285c0fd3a4c6c07": "行拖拽",
	"i18nn_fb9424465770cad0": "列拖拽",
	"i18nn_5777d5244019bd22": "不可拖拽在此处",
	"i18nn_48837fdb8d5d6505": "应该不可拖动",
	"i18nn_ec5311a7fb2e1420": "暂时此处写成重置",
	"i18nn_c9415e71fa62ceb6": "先删除移动的节点",
	"i18nn_6a4574d58d1a1854": "再插入移动的节点到原有节点",
	"i18nn_06e7de86935fa403": "还原了移动的操作",
	"i18nn_e1911e9360047dcb": "更新",
	"i18nn_e351b00f4bea9840": "数组",
	"i18nn_b1d828aea8040adb": "下一个",
	"i18nn_e49d6eec201085c0": "就会走",
	"i18nn_5b388deb4a4cbdab": "头部",
	"i18nn_0f0de129ce37f7a9": "内容区域",
	"i18nn_00b0cc21e5cccf84": "路由内容区域",
	"i18nn_ed1a05a71adbe5ee": "永飞",
	"i18nn_9e8d6372f23ed0ae": "版权所有",
	"i18nn_5b6f28d3282af680": "切换语言",
	"i18nn_9f5f783b6d4689f8": "设置顶部菜单",
	"i18nn_fc21b3a79577d149": "获得子菜单数据",
	"i18nn_89aa0b0fe18cba30": "菜单赋值",
	"i18nn_effa44d2cbefe3b3": "是否存在该路由",
	"i18nn_b1177ef53417dc7a": "存在则读取",
	"i18nn_d62e90c3a1a3dc77": "不存在则添加",
	"i18nn_3c8f950d7eddf344": "请确认是否退出",
	"i18nn_74708f3a8699305a": "登出",
	"i18nn_13ac9fa4999a28f3": "获得菜单权限",
	"i18nn_c524cff2488f2ebd": "成功",
	"i18nn_e5368dfd7865a5f1": "登录成功",
	"i18nn_cb1872e68cb6c717": "获得菜单权限成功",
	"i18nn_2e4051588cdc7268": "获得菜单权限失败",
	"i18nn_797fd79ded8f47a0": "访问历史",
	"i18nn_5920542fc20b3263": "后台管理",
	"i18nn_0d93ee2eb17156c9": "互易仓客户端",
	"i18nn_bb801fa91df5d1c2": "确定退出登录吗",
	"i18nn_1237bcd5e3a8f579": "历史记录标签",
	"i18nn_952c01d2653fd0ec": "是否存在登录信息",
	"i18nn_a2e9c78c71a78745": "不存在登出",
	"i18nn_528f3ff5da537927": "获得用户权限菜单",
	"i18nn_48fea1870aa235fd": "获得用户权限菜单成功",
	"i18nn_725d750832726cc3": "获得用户权限菜单失败",
	"i18nn_b125fa6fe5308e6a": "获得用户菜单成功",
	"i18nn_8f0b9b3e6ac16f1b": "设置用户权限菜单失败",
	"i18nn_14d8615847c3bb28": "登录请求失败",
	"i18nn_e92f5def0a0b73b1": "处理菜单的显示",
	"i18nn_30191ae543992f85": "调用",
	"i18nn_9f400fb4cef5c50f": "添加路由",
	"i18nn_67a710b383c8b449": "后台管理系统",
	"i18nn_bc63cb564276221b": "正在登录",
	"i18nn_cc1f108900897a83": "请稍候",
	"i18nn_a1805b0f0117f109": "用户名",
	"i18nn_04152d1213c1a758": "请输入用户名",
	"i18nn_e93cb6cc8d5978ed": "请输入密码",
	"i18nn_a6ab8f96b90f8df1": "登录类型",
	"i18nn_eae360b6837f15e9": "请选择登录类型",
	"i18nn_5cabbc13e4bee21a": "管理员",
	"i18nn_db9cb45c970a316e": "超级管理员",
	"i18nn_a2dce77193d8d064": "登",
	"i18nn_f0b2eacbbd5eafa9": "录",
	"i18nn_4c4e546aa3683ff5": "清空",
	"i18nn_61bd9a8fcc2ea788": "温馨提示",
	"i18nn_2376ef707384772d": "请使用账号密码登录",
	"i18nn_7cded08b7116a70d": "长度在",
	"i18nn_a1f1ba9fb3797498": "到",
	"i18nn_b698f53c03aa9dc7": "正在查询权限",
	"i18nn_5fe6cc0473e0d313": "登录失败",
	"i18nn_747b33bfa850b075": "获得用户权限菜单请求失败",
	"i18nn_807a814ed58a93bc": "清除缓存",
	"i18nn_fb45aad612581e75": "仓配管理中心",
	"i18nn_85f24646cf45f5ee": "获取该用户菜单失败",
	"i18nn_71bc43a0fa1cbad0": "请重试或联系管理员",
	"i18nn_1d18c926cdb19606": "重新设置",
	"i18nn_b20485f2a6e11119": "第二次赋值",
	"i18nn_d0382156496d94ef": "请重试和联系管理员",
	"i18nn_9a67dbf9d75ede6d": "格式化",
	"i18nn_62295d661d690460": "并添加路由",
	"i18nn_5dc4c734e351f7c1": "是否存在子路由",
	"i18nn_26c3c98ef539ae99": "判断路由合法性",
	"i18nn_dc0497912f640aee": "路由路径及参数合法性",
	"i18nn_0f03037ecaf1990d": "找不到页面",
	"i18nn_d25a9b29d0bb390b": "重新登录",
	"i18nn_e097bb9b1216bfd8": "联系客服",
	"i18nn_0f014c5453716e4b": "售卖参数回填",
	"i18nn_c0117e8321811bb4": "清除",
	"i18nn_a363e5f23b1040fe": "校验",
	"i18nn_6576ce14f2a92b83": "排序完成之后记得点击",
	"i18nn_a6dcecbe146242e2": "确认排序",
	"i18nn_a0cf2fef18330d96": "如何一次性添加多张图片",
	"i18nn_cc504b75f45b6c8e": "系统",
	"i18nn_f9f65e4fadee5183": "方式",
	"i18nn_92988957fcbb8456": "键",
	"i18nn_ed635542b3fb8fba": "选中多个图片",
	"i18nn_754c78f5c569030e": "鼠标拖动",
	"i18nn_5f612e85ebd5642e": "选择图片拖拽到",
	"i18nn_38f3e4e3906f4cde": "号处",
	"i18nn_b458f03c43a90ee9": "拣货单号",
	"i18nn_31f887c1ada79a99": "产品编码",
	"i18nn_9eeccc6d6f1ff32f": "产品名称",
	"i18nn_f27f07c66a08b754": "申请出库量",
	"i18nn_1fa934a0815960b6": "库存数量",
	"i18nn_f340f0ba85ea230d": "体积",
	"i18nn_877c5a0e44a0eb07": "产品图片",
	"i18nn_a332a38ed3832d9d": "入库编号",
	"i18nn_0e0f0fa9793ae89d": "上架编号",
	"i18nn_3479171cb6e901db": "错误信息",
	"i18nn_b56fdc27a5fdd70a": "必填",
	"i18nn_da7c594e2f707ed1": "拣货库位",
	"i18nn_d83a23d20001121a": "申请出库体积",
	"i18nn_858e31654677a536": "申请出库重量",
	"i18nn_f7f39a6e6d9d26cb": "只读",
	"i18nn_1b60ee08c82b5d9d": "错误提示",
	"i18nn_7e2929024089363d": "列宽",
	"i18nn_60ba17b93050a4ab": "值为",
	"i18nn_08dae0e0a7266acb": "时",
	"i18nn_ac3d810b75671d57": "行可拖拽至指定行",
	"i18nn_8be7f943d9509bc0": "列可拖拽移动到指定列",
	"i18nn_3919f3d48a7e1c48": "当值为",
	"i18nn_d4980b91d5c8daef": "启用右键菜单",
	"i18nn_2b90bfff8b7e3856": "允许键盘复制",
	"i18nn_f292e1f7ff4f86d6": "允许拖动复制",
	"i18nn_fd3258ae9f2db54e": "只可以垂直拖动复制",
	"i18nn_20c39ac8a6aeb87d": "单元格右击展示菜单",
	"i18nn_8b456c5396713d9a": "上面插入行",
	"i18nn_382c5bafd600d2bd": "下面插入行",
	"i18nn_77054b0e5ea4d30d": "左侧插入列",
	"i18nn_1b2106ded89310ba": "右侧插入列",
	"i18nn_f04cf5507ecc5774": "移除该行",
	"i18nn_327aa3849b3d5c68": "移除该列",
	"i18nn_b4e5993f97fe04ee": "撤销",
	"i18nn_1a42987a0df962e9": "恢复",
	"i18nn_0607472de4525358": "对齐方式",
	"i18nn_ea37e4e88aada736": "边框",
	"i18nn_29f67f0644a78bf3": "复制",
	"i18nn_6b7fbcd658f0ccdb": "剪切",
	"i18nn_c420ac9915ca288d": "清空所有单元格数据",
	"i18nn_98de889dff3208a6": "规格",
	"i18nn_ceebc30416e4b421": "确实生成",
	"i18nn_637e19a84d9ab6e2": "回填格式化",
	"i18nn_dd3b9a53a418f0e4": "数据回填",
	"i18nn_a9c5de692b5d3d8f": "多图上传",
	"i18nn_24c8f46012a25c89": "新增",
	"i18nn_760f75dc397a9cb8": "确定添加",
	"i18nn_598ade86cbdf4868": "光标放置后面",
	"i18nn_217d41539ee86758": "否则自动往前",
	"i18nn_4f62f8d07bb4054f": "批量关闭",
	"i18nn_de57dcdfc3e01860": "完成收货",
	"i18nn_cabc5dc617eef30e": "统计时间",
	"i18nn_5e7740c52e22eefc": "编号",
	"i18nn_18d7892498938435": "客户",
	"i18nn_1df68c34677c2aae": "公司名称",
	"i18nn_00b8608bd21b2160": "介绍人",
	"i18nn_8bdf7efaeff69d77": "合伙人信息",
	"i18nn_dc1706be2ffb6973": "删",
	"i18nn_7b88d9549cff842d": "仓租费",
	"i18nn_619a3387e6517f01": "取消编辑",
	"i18nn_8bafd5202547e50d": "日志",
	"i18nn_6ae7ca889532ec40": "缓存",
	"i18nn_ead881df31939a6e": "原数据",
	"i18nn_62a3b0f685e9962c": "表格选择的数据",
	"i18nn_96fb4a4cdd7de04b": "跳转到第一页",
	"i18nn_7bf4dc759d2719bc": "作为组件选择行数据",
	"i18nn_3a671c04d1dc0d96": "选择行",
	"i18nn_c0a0adbd5c04ac55": "点击选中",
	"i18nn_5ad16932d8972191": "页面",
	"i18nn_bf9e5fb79957d959": "新增联系方式",
	"i18nn_e30e4c1103362d8c": "删除联系方式",
	"i18nn_b4cd5c00f153455d": "请先删除或保存第一条新增数据",
	"i18nn_b2d4c7fffe79258e": "邮箱",
	"i18nn_8758fd50c87d6c9c": "地址",
	"i18nn_eac88e4b6549c80e": "删除新增",
	"i18nn_4d894c107a78fffe": "还原数据",
	"i18nn_9376c9a16c52a4a4": "深拷贝",
	"i18nn_649d9798f7590713": "浅拷贝",
	"i18nn_d1c1b3313ba0a3a1": "接收新数组",
	"i18nn_f57f809cbf01b621": "不刷新数据",
	"i18nn_4424d5414a823632": "仅改变编辑状态",
	"i18nn_328771b9159af957": "恭喜",
	"i18nn_994cb374e601fab3": "编辑成功",
	"i18nn_4ab989bd6abd3ac7": "新增成功",
	"i18nn_84adf48acbd6ad24": "列表",
	"i18nn_2ee4bebcd386a807": "取消编辑时调用",
	"i18nn_a1761c0e88af75ff": "数据有误",
	"i18nn_9e5e4dfbc3ec6aa9": "提交信息",
	"i18nn_bc868e024b80d2e3": "提交成功",
	"i18nn_a7d2e953195a5588": "提交失败",
	"i18nn_4eb3309d6692dbd4": "请重试",
	"i18nn_3bd1ac1378a9cf27": "删除操作",
	"i18nn_18a47a2171c01d1c": "确认删除",
	"i18nn_7495b1a692c585b5": "吗",
	"i18nn_e7f7c8278ceafe72": "删除特定数据",
	"i18nn_de017dafc266aa03": "删除成功",
	"i18nn_54dc58d9d0377bb5": "删除失败",
	"i18nn_785c25ad46aa1a92": "确认完成",
	"i18nn_6bfc5f83ed58481a": "提货单编号",
	"i18nn_e0c16ac094cc2bce": "产品代码",
	"i18nn_64fed2851f79d875": "客户编码",
	"i18nn_7b27c6b2a3f93412": "包装编号",
	"i18nn_8e01866868930d09": "重量单位",
	"i18nn_ad4b0047e16d89ae": "单件重",
	"i18nn_57936a4767ded7f5": "体积单位",
	"i18nn_3eb79cfe71bbae0b": "入库编码",
	"i18nn_bad53577db0da2d3": "总件数",
	"i18nn_0bd31bb65ba068e8": "预计拣货数",
	"i18nn_06c0936eb86ae31b": "实际拣货数",
	"i18nn_ae34bf9aa8b77864": "上架件数",
	"i18nn_ed482b9aef60900b": "产品图片上传",
	"i18nn_218ff32b6139e98b": "图片上传",
	"i18nn_90322478b34cd272": "等图片格式",
	"i18nn_aa326abf563f1185": "大小不超过",
	"i18nn_ef6eeb5bf7c4a821": "选择入库数据",
	"i18nn_306b9a84a664e731": "图片放大",
	"i18nn_bb8f8c1e0b6e7000": "打开入库选择框",
	"i18nn_87619aa3fd09e8c2": "上架完成",
	"i18nn_dcf1675624490b2c": "部门数据",
	"i18nn_34f5f82ff9c257b5": "打开选择数据",
	"i18nn_55a803f40c4b3790": "选择数据后回调",
	"i18nn_1dacc6a4a7e86357": "打开图片上传",
	"i18nn_5d4a393cc159da1e": "清除图片",
	"i18nn_dbe10ab99c80eb65": "图片改变",
	"i18nn_a236026c65338284": "就是子组件传过来的值",
	"i18nn_b071e67922639ea1": "实际上架数",
	"i18nn_bb0b485a98f17aea": "上架总体积",
	"i18nn_4157ea5a8c62064b": "上架总重量",
	"i18nn_5d43cbeb679e9830": "单条",
	"i18nn_1df24953a2a13e57": "多条",
	"i18nn_6858c7b5e8d82691": "待编辑的数据",
	"i18nn_8a2312876de02fd7": "直接赋值为浅拷贝",
	"i18nn_2de08c29da81f5f3": "也是浅拷贝",
	"i18nn_9e36893d5736abb4": "订单列表",
	"i18nn_2bfebf0495b9cdcb": "本页切换列表",
	"i18nn_e8564657bbe9ca53": "创建",
	"i18nn_a51a59d19387d567": "操作编号",
	"i18nn_862bfc135547f78e": "拣货单名称",
	"i18nn_b9cf6fa157d27973": "商品类目",
	"i18nn_0ae3aed4e3b5423d": "处理中",
	"i18nn_a0acfaee54f06458": "已完成",
	"i18nn_ab6ea90b9164b20a": "描述",
	"i18nn_ef122ce66f8b5dcf": "开始拣货",
	"i18nn_02f57850a3029222": "本页切换",
	"i18nn_57d71ea1aa426464": "查看等",
	"i18nn_b83597729ab29694": "选择客户",
	"i18nn_96617ace367556e8": "请选择费用类型",
	"i18nn_6afbe4c6dcf87a6c": "费用明细",
	"i18nn_16aa273f2941c9b5": "数据",
	"i18nn_a4eca6ff2588d791": "费用名称",
	"i18nn_c5a57cce12401a00": "拣货产品信息",
	"i18nn_9a42179fb9922dc1": "产品库位",
	"i18nn_16658a855f07d171": "金额",
	"i18nn_442a9ac138407a28": "受益方",
	"i18nn_bb5ac0c49150f3b0": "合伙人类型",
	"i18nn_f7e0da8748d5c821": "分配方式",
	"i18nn_ed085b86f07e3e99": "值",
	"i18nn_819a0e214d58c0c3": "代表",
	"i18nn_966f3d0952773d84": "请输入备注",
	"i18nn_d552aa9f1c14363f": "保存编辑",
	"i18nn_0ddb67d8d6d01ad4": "保存新增",
	"i18nn_86eac36cf4283081": "注销弹窗",
	"i18nn_dad95a93a594366e": "注销",
	"i18nn_ba0732214679e13b": "配置名称",
	"i18nn_2345771b0cea8487": "生效时间",
	"i18nn_0f1834c450f21a65": "注销日期",
	"i18nn_b45b4338ee939ad0": "详情弹窗",
	"i18nn_3d76412ed771dbe2": "二级配置详情",
	"i18nn_2afd7f6f1166341d": "结算主体",
	"i18nn_c0adeddd1792480d": "表格显示",
	"i18nn_0d6140ad7af4ed36": "拣货单编号",
	"i18nn_0bd7d983fe70ea56": "拣货数",
	"i18nn_9cee201b82dbc9cb": "未开始",
	"i18nn_821531c6e29bd318": "全部完成",
	"i18nn_cadfbfa511d00eb1": "强制完成",
	"i18nn_4b95be19dadf6f0a": "部分完成",
	"i18nn_5599155c3578c611": "废弃",
	"i18nn_a8f9e2b4dcb1c4a4": "生效日期",
	"i18nn_517d6a5d35a1989c": "日期选项",
	"i18nn_62a1adfe6207b3c5": "详情数据",
	"i18nn_fecdd1b2ab21bef3": "新增时",
	"i18nn_3193004ef6dd6bbf": "编辑时必传",
	"i18nn_9df79d7120a0a741": "分配值",
	"i18nn_2aeed2ab99c446d7": "失效日期",
	"i18nn_10f91e600df6414f": "仅做显示",
	"i18nn_f5822cb6006a60f0": "类目选择",
	"i18nn_4c6bd84cb427086e": "直接打开页面",
	"i18nn_22105cf6781b0f09": "打开新增编辑",
	"i18nn_c91d861ccbaddcd5": "查询数据",
	"i18nn_75fb4d6c9f31a6e0": "当前页显示数目编号",
	"i18nn_a13684cbb78b8e81": "商家账单列表",
	"i18nn_09f074ad0a9bfb6e": "打开二级分配弹窗",
	"i18nn_9c0ace2b5d32f74c": "请求数据有误",
	"i18nn_2ba96cbfad324580": "弹窗更新",
	"i18nn_dc2f299dc3aeffb3": "重置",
	"i18nn_fa91b99043676081": "打开编辑",
	"i18nn_3bd29fdff03f23e3": "分成模板",
	"i18nn_e16831d9f37ce74d": "请选择需要删除的数据",
	"i18nn_ee32decd972008a6": "请选择需要注销的数据",
	"i18nn_31ce64e52ed71628": "非必填",
	"i18nn_373dcbacd938fcf3": "编辑时",
	"i18nn_6e06968321e13384": "验证省市县必输入",
	"i18nn_e386c58d13efe5af": "对象属性的合并",
	"i18nn_b93f7c8c4e2346f8": "保存成功",
	"i18nn_9fb513abc029d610": "下拉列表数据请求失败",
	"i18nn_db210f50b5fde38d": "更新特定数据",
	"i18nn_d96afd0c0de3686c": "确定删除吗",
	"i18nn_2e58cb9b52e2a214": "已取消删除",
	"i18nn_70678b883f1517f5": "提交数据",
	"i18nn_25593efaf75ce5af": "财务记录明细",
	"i18nn_dceb9f39f0d789cc": "货币",
	"i18nn_1d5fb99b98e26365": "条形码",
	"i18nn_dd8b4f0bf9ce1e74": "查询数据字典",
	"i18nn_3c144f7fa49aeaf8": "查询数据字典失败",
	"i18nn_9742b74b5a4c77d4": "查询数据字典接口",
	"i18nn_104709397ba68e7e": "表格",
	"i18nn_33d4cfe5cfd81678": "兼容性有问题",
	"i18nn_c517fbaec10de990": "富文本编辑器样式",
	"i18nn_399a045892a8f9a0": "拖动图片会转化为",
	"i18nn_d79b54a98b79b8c1": "位",
	"i18nn_ce6e0b43e891250d": "后端不支持",
	"i18nn_cc446c9868b9bda0": "位转图片并上传",
	"i18nn_e24642aea868fc42": "前端上传转换为",
	"i18nn_8bf1de8dc482bdf1": "标签才可以",
	"i18nn_3547ab0eda19fe64": "添加图片有问题",
	"i18nn_ed1dbfbdb704fc24": "添加表格",
	"i18nn_3a09b6f334222d7c": "拖拽保存",
	"i18nn_e2ef45e045968e11": "导入",
	"i18nn_2a2e2fbcc567efc6": "附件上传",
	"i18nn_b95858c63542921f": "附件列表",
	"i18nn_d54012286fece209": "下载地址",
	"i18nn_e127ec3da1353c98": "收货账单",
	"i18nn_79fd9ff9de54ef02": "初始化上传控件",
	"i18nn_17a941b7c4c108fe": "打印预览",
	"i18nn_886072e393710563": "打印",
	"i18nn_6839940dac08c794": "注册",
	"i18nn_fb666618a592dd15": "客户库存信息",
	"i18nn_e66a14f7b414a00c": "请输入店铺名称",
	"i18nn_1af050f1d11dee5b": "请输入关键词后选择",
	"i18nn_a28112c8cca3514f": "历史总库存",
	"i18nn_e6a250894a04db4c": "详情",
	"i18nn_c5820dce4b05019f": "库存总价",
	"i18nn_7c99ec8184b2b9d1": "库位详情",
	"i18nn_3def3d09b6b0b42a": "库位信息",
	"i18nn_de53ab10322cd38a": "库存",
	"i18nn_16d01744a65f4531": "店铺默认可选数据",
	"i18nn_07e0f8f59293e78d": "请先选择客户",
	"i18nn_4045c7be6edea082": "客户模糊搜索",
	"i18nn_9ab1e759ed215b97": "客户模糊请求",
	"i18nn_398644c781d3f039": "名称搜索",
	"i18nn_7aecdd9e65ae5c7c": "店铺名称搜索数据有误",
	"i18nn_a575e56eb92b451c": "店铺名称搜索数据",
	"i18nn_0bf3695809741cae": "未知的",
	"i18nn_988794ecaef531dd": "或",
	"i18nn_993c4e2a71557fe7": "审核通过",
	"i18nn_a048bb76469dd141": "自动拣货",
	"i18nn_1e2ddc6db3db8200": "打印拣货单",
	"i18nn_7f12d7a3ac88b55e": "修改运单",
	"i18nn_19ec51d5bd5fc832": "没有",
	"i18nn_c301de1e444a3e90": "待发货",
	"i18nn_fcf5923a042705f3": "待召回",
	"i18nn_190dca403b6f540a": "已取消",
	"i18nn_7f18cb0ceb569eb0": "个附件",
	"i18nn_acea67a875570f7f": "去拣货",
	"i18nn_55b8714a870ee316": "查看拣货",
	"i18nn_76cd201aeb72dba2": "打托明细",
	"i18nn_26130eea27bcb952": "去打托",
	"i18nn_112b0c5486af47fd": "重新打托",
	"i18nn_a395bd8229786778": "打托完成",
	"i18nn_360398d06d6845c8": "设置出库位置",
	"i18nn_104421e8ad4f7c4f": "出库完成",
	"i18nn_d2041ca7c242cd64": "拣货",
	"i18nn_87d3b81a6d6723d8": "批量复核",
	"i18nn_83f8f6bdfb9fc3db": "上架",
	"i18nn_fd61d6c2cefddb41": "打托信息",
	"i18nn_4531476fa35570f0": "运单号",
	"i18nn_f7045d8fb5d91e53": "批量录入运单",
	"i18nn_268e50d838264ca8": "类目",
	"i18nn_878e6c82032b80d5": "费率",
	"i18nn_e2e9f6fe81c1a369": "拣货弹窗",
	"i18nn_0e240c5802d17fea": "单件体积",
	"i18nn_11e41b2ec3d872ed": "单件重量",
	"i18nn_76dcdaaea25414c6": "拣货日期",
	"i18nn_f069714744defaba": "拣货明细信息",
	"i18nn_1fb1693779a2d16c": "新增拣货库位",
	"i18nn_a26a1c375abb4285": "拣货件数",
	"i18nn_16c5afb8e869abda": "附件查看",
	"i18nn_97ec3bb319c68844": "接口",
	"i18nn_8854b9baff9494a5": "拣货数据",
	"i18nn_6d8cbf8c49d6a7e6": "拣货总体积",
	"i18nn_3e1652df125209de": "拣货总重量",
	"i18nn_4647ba3fc60f0151": "入库日期",
	"i18nn_3cce40fc2b7d333c": "表格点击行展开",
	"i18nn_7414f31517552941": "里",
	"i18nn_cbc1a1fd9e6c9d70": "我们已经设置",
	"i18nn_663d98f9fed21ec3": "值设置为每行数据",
	"i18nn_9e22ab6de55df607": "打开附件上传",
	"i18nn_7b23bac6ffe353b9": "所有选中为已提交状态才可以审核",
	"i18nn_3e48b039f79c39a0": "条数据",
	"i18nn_97939133a6d30da8": "取消成功",
	"i18nn_ea760847f7cf2e57": "审核批量通过",
	"i18nn_383c2d8909230165": "审核通过吗",
	"i18nn_0b96be3d99dc3206": "所有选中为已审核状态才可以拣货",
	"i18nn_6ea7bcc065a76da5": "自动拣货吗",
	"i18nn_493d12b09fa54b95": "所有选中为待发货状态才可以发货",
	"i18nn_5c6f06e49c5e6188": "发货吗",
	"i18nn_5cbd41b354689581": "所有选中为拣货中状态才可以拣货完成",
	"i18nn_7c5912ab3762973a": "批量录入",
	"i18nn_c3acacf493ad6a41": "运单",
	"i18nn_5965027c91e708d0": "提交录入的运单",
	"i18nn_f7e93d88810feb36": "确定发货编号",
	"i18nn_8ae9ba38aa4182dd": "全部拣货完成",
	"i18nn_6a70ed428cf73159": "查看打托明细",
	"i18nn_6495b78f59be9d4a": "全部打托完成",
	"i18nn_502658ab7e58b6af": "设置出库未知",
	"i18nn_ab87ac4b5567a2dd": "计算总重量和总体积",
	"i18nn_e2b44fd7a8f1aacc": "计算总重量",
	"i18nn_4b3684d07411df81": "计算总体积",
	"i18nn_fa4b8c97858f967d": "计算",
	"i18nn_e6560efaa437bead": "请求明细列表",
	"i18nn_a3b3a614f0e2b584": "打开导入弹窗",
	"i18nn_f880656430c365f3": "文件不能超过",
	"i18nn_4dadf13ebce2167d": "未知的文件格式",
	"i18nn_c8e99e36cefd743a": "文件读取失败",
	"i18nn_15da46e90a2c3e3b": "异常件处理",
	"i18nn_abf9f4b8c93d641e": "订单号",
	"i18nn_e5569530cce59efe": "拖拽测试",
	"i18nn_92c54ea7edec1dce": "签收",
	"i18nn_d1e7cc74b33a490e": "异常件入库",
	"i18nn_cc09153fb6e7d447": "退货运单录入",
	"i18nn_3f27967056329300": "退货录入",
	"i18nn_851e87b9fe311af7": "打开选择框",
	"i18nn_c5cdbe2717d0885c": "打开库位选择",
	"i18nn_885c3cdb72cb2e0b": "已签收",
	"i18nn_abe96316be45d385": "确定签收吗",
	"i18nn_6921bc1023f16466": "异常件收货入库",
	"i18nn_0b29495c591994a9": "入库名称",
	"i18nn_a6412f1996e4a96c": "完成时间",
	"i18nn_1bbe44c20effdd20": "打印明细",
	"i18nn_87912cccd1e9b132": "装箱单打印",
	"i18nn_a6c2cf7ad91292ac": "原入库件数",
	"i18nn_66880274fe53fdb2": "建议上传尺寸不小于",
	"i18nn_38bae5210404815f": "的图",
	"i18nn_2bbd3825f5d84377": "等格式",
	"i18nn_c9494357a4632b3f": "大小建议不超过",
	"i18nn_a058372131d1eb0d": "复核",
	"i18nn_e4cd341c63cb9f12": "未复核",
	"i18nn_d7d633d548c8d2ea": "已复核",
	"i18nn_6e20769a5a1dcf67": "单挑复核",
	"i18nn_d1ccda856600ae3a": "重置输入框",
	"i18nn_66bfec97a0d9bbae": "分托信息",
	"i18nn_226086d3a02580ba": "查看打托",
	"i18nn_07cac86ffb75046b": "发货编号",
	"i18nn_e0c689d09fab9404": "目的仓库",
	"i18nn_f22715c4a2b7630b": "提货单状态",
	"i18nn_42a92582f098713f": "托号索引",
	"i18nn_466acbdbf0d357de": "放置区域",
	"i18nn_a5046acba2ced5e0": "分托明细",
	"i18nn_facbddd33662a8c2": "已分托",
	"i18nn_bb40488af04ed09a": "指定托",
	"i18nn_85d7199b591e553c": "发货单全部",
	"i18nn_35fb6b462cf31828": "分托",
	"i18nn_6d4774e43b0e2a8f": "提交出库信息",
	"i18nn_deb1cce28643a520": "仓库地址",
	"i18nn_66d65a315a762976": "操作按钮",
	"i18nn_28ab2b0d85fa756f": "开始分托",
	"i18nn_e41f20e73d172bbe": "个托",
	"i18nn_fe3466680d9d8d20": "请输入出库位置",
	"i18nn_2e3d588c04ef832e": "出库位置",
	"i18nn_ae1d0428288eeec8": "托编号",
	"i18nn_2ec0f364e8bbaa4c": "发货件数",
	"i18nn_94d68bad9f60445f": "打托",
	"i18nn_907d5dd39a2bdfd8": "拖拽打托",
	"i18nn_8eaa1e53e8fbe252": "分托完成",
	"i18nn_174a161a6ab78443": "请将",
	"i18nn_0d4d7e978c7d8f51": "全部分托完成",
	"i18nn_223662eee6115fc3": "再提交",
	"i18nn_8b240c7ceece2079": "设置默认出库位置",
	"i18nn_6a3f752e6c5923a0": "如果有的话",
	"i18nn_0772a37dc4042001": "发货明细",
	"i18nn_89413403ccaade4a": "到达国家",
	"i18nn_0607c7a5960734f1": "到达省",
	"i18nn_7cab79716236ebb0": "州",
	"i18nn_ba8ce6a27a2ce7ef": "到达城市",
	"i18nn_30ca47ff019be06f": "到达地址",
	"i18nn_daa23bc02afc02a7": "到达邮编",
	"i18nn_2a89532e280a805d": "手动拣货",
	"i18nn_bcc14b0ce9338094": "拣货明细",
	"i18nn_e2c4b9b00d105e39": "拣货明细弹窗",
	"i18nn_b77298f9f158fb58": "自动拣货弹窗",
	"i18nn_ec2da13066ef0351": "自动拣货回调成功",
	"i18nn_794cc17f5d24dbe0": "弹窗",
	"i18nn_55043e046c4de8b4": "自动拣货明细",
	"i18nn_b29bf013aaec9ce6": "收件人号码",
	"i18nn_f9514183570a10fe": "省",
	"i18nn_8db45b615b72b7a8": "地址一",
	"i18nn_fe0e2bcbae565a93": "打印拣货清单",
	"i18nn_2f66aa86e2aa1c1e": "标签",
	"i18nn_93bf7c9249938506": "提交打托信息",
	"i18nn_1272ee58ec6ee1ad": "申请号",
	"i18nn_d4263545654ce610": "使用",
	"i18nn_37035e9562ec6bcf": "组件",
	"i18nn_8ad21658621a64bc": "拖动",
	"i18nn_b430b32f6e542047": "到对应托上",
	"i18nn_bb346b646af55bd8": "点击",
	"i18nn_c08f45e8d7723648": "点击下方",
	"i18nn_f4b368568f664eae": "会试着新增",
	"i18nn_840cf164b0b46099": "第一条且剩余不为",
	"i18nn_4155c83b6d3bc02b": "的数据",
	"i18nn_0a8c7b53565de4f4": "个",
	"i18nn_b4918924ef5887be": "出库放置区域",
	"i18nn_eb47d78018466a06": "定义要被拖拽对象的数组",
	"i18nn_cda8720e92408ff6": "不允许停靠",
	"i18nn_f61fff93d0819b58": "锅锅锅锅",
	"i18nn_a379abcf1154098a": "碗碗碗碗",
	"i18nn_3a6ea07d88deba69": "瓢瓢瓢瓢",
	"i18nn_69cca7059011676d": "盆盆盆盆",
	"i18nn_70180b416ca792e5": "不允许拖拽",
	"i18nn_2913f1ad38625b27": "饭饭饭饭",
	"i18nn_ca1a84683c9bf71b": "常用菜单",
	"i18nn_216f463b7ce4215a": "这一步最关键",
	"i18nn_bc31df4de59f616f": "没处理好",
	"i18nn_f5fab291e2bdfcb0": "后面会数据混乱",
	"i18nn_7f49b11e275b090c": "通过转成字符串",
	"i18nn_1977cc34417d9160": "让他变成一个新对象",
	"i18nn_e7631d389bd59d26": "不然拖拽第二个组件将会和第一个组件一模一样",
	"i18nn_441bbac4c41b5ed8": "改变第一个组件第二个",
	"i18nn_3478ca879ecefbae": "第三个也会跟着变动",
	"i18nn_a3502344f06f5b38": "根据",
	"i18nn_9b05290064fa6b92": "全部计算",
	"i18nn_e096accc09ed9b55": "推荐打托的",
	"i18nn_b238120036242f81": "删除托",
	"i18nn_4e512ae4de36d74f": "左边往右边拖动时的事件",
	"i18nn_7c085826fd4fb451": "如果左边",
	"i18nn_760c7a887a6afc3e": "右边往左边拖动时的事件",
	"i18nn_392002493ad6f113": "回调方法",
	"i18nn_bdb19cd21161c810": "不得重复",
	"i18nn_92aff4ef421db044": "剩余数量不为",
	"i18nn_4087c7aebcdd2979": "请准确分配",
	"i18nn_289a4232e2595995": "请删除",
	"i18nn_bc6ac9ce32252fb9": "的托",
	"i18nn_20e9d386d07026ca": "总数量",
	"i18nn_f566f50ef680920c": "工单类型",
	"i18nn_1ee5a245fd386e54": "分派",
	"i18nn_8039faf6d63be278": "退回",
	"i18nn_b402009cd5e58e1a": "处理账户",
	"i18nn_647ebbeff5de7017": "分派方式",
	"i18nn_d8669386ff40ccf1": "分派值",
	"i18nn_2f09651c43c2a375": "已处理",
	"i18nn_34e22b977de24408": "打开二级分派弹窗",
	"i18nn_8ccf7ec6c5c949de": "分派成功",
	"i18nn_e5462a1be765de05": "工单详情",
	"i18nn_3e358d140504ab46": "工单内容",
	"i18nn_8200a25ab641ba97": "附件信息",
	"i18nn_10f9cf44daa70871": "工单日志",
	"i18nn_1f4c28db7c80a5f5": "富文本",
	"i18nn_dd0bec40af232754": "中文标题",
	"i18nn_209a1dc79c180718": "英文标题",
	"i18nn_a7e730af52ab52b1": "收货清单",
	"i18nn_643e08ba53f72f39": "装箱类型",
	"i18nn_336569a8af640d81": "拣货清单",
	"i18nn_cba303e79da77222": "拣货数量",
	"i18nn_c152190d960a3ff7": "代发拣货清单",
	"i18nn_16abbd0c63637be3": "客户搜索数据有误",
	"i18nn_1cf15e3e5f830da1": "客户搜索数据",
	"i18nn_35c31bde1e2c8b2c": "注意",
	"i18nn_c0e3a584888c5ea6": "最多可上传",
	"i18nn_c70b6428abb8c93d": "件",
	"i18nn_24799f4c6ce60dbd": "请选取完文件后点击上传到服务器",
	"i18nn_71229f26cb88e30e": "校验格式",
	"i18nn_a974598ef531812b": "不校验格式",
	"i18nn_a05ac587acc6763a": "校验文件大小",
	"i18nn_b23004db5349dfd2": "产品中文名",
	"i18nn_05141ac0139eaf01": "产品英文名称",
	"i18nn_791e36963e37617a": "自定义编码",
	"i18nn_198738759379a3c0": "海关编码",
	"i18nn_73d237ea940222ce": "申报中文名称",
	"i18nn_9894786aad05f849": "申报英文名称",
	"i18nn_fe28507421c4c3b3": "申报价值",
	"i18nn_2cba96917484569a": "长度",
	"i18nn_619d7e563f879811": "收货中",
	"i18nn_1dfb3a065d3ffe39": "收货完成",
	"i18nn_c24587738eb25651": "跳转页面",
	"i18nn_5cbf7e07f157572f": "确定要确认通过该笔账单",
	"i18nn_ed492a621bbf4ecf": "点击状态为非出账弹出窗",
	"i18nn_e741d17b1fd891c2": "仅",
	"i18nn_ec54decc8e274bbc": "已出账",
	"i18nn_d6f109b7bee2f33f": "状态的数据进行",
	"i18nn_002e3156942b6047": "请核实",
	"i18nn_e8ec92802315a287": "确定要",
	"i18nn_e969f83400f78bc5": "通过该笔账单",
	"i18nn_aefbfa41c779d472": "特定数据",
	"i18nn_7a4aff235c9a01dd": "失败",
	"i18nn_6e1b866b2add32bb": "上传回调",
	"i18nn_3144aad544eb9ac4": "上传成功",
	"i18nn_d63854ffd4e0f2df": "共上传",
	"i18nn_4195755cd53e871a": "查询成功",
	"i18nn_d74d0bd89431d6d5": "查询失败",
	"i18nn_e12ec0fbad09bac1": "关键词搜索数据有误",
	"i18nn_61d304ff72c5c155": "关键词搜索数据",
	"i18nn_785ee8efb6d09b2a": "打单明细",
	"i18nn_36edafcc55cf50f6": "面单查询",
	"i18nn_4ca88dd2e7071844": "撤销打单",
	"i18nn_06dd7cb65641390b": "出单状态",
	"i18nn_90429ac39f4f4634": "打单进度",
	"i18nn_48ebfc4319cbac59": "打单单号",
	"i18nn_b0c50b6ff9674173": "快递公司",
	"i18nn_1f90c9eb506ddf15": "取消运单",
	"i18nn_3c2605e54bb69e38": "打单费率",
	"i18nn_3d6502c491aca17a": "提交打单",
	"i18nn_d4d1e037938ae65e": "提交打单吗",
	"i18nn_1cb9e763fc0324fa": "提交打单成功",
	"i18nn_f0cb028847831431": "面单查询吗",
	"i18nn_d0bb84f55c074e32": "撤销打单吗",
	"i18nn_327144b46bd2854b": "查询面单",
	"i18nn_e6d7aa8c4f2fc0c7": "正在查询",
	"i18nn_32aff432f169204e": "查询面单信息",
	"i18nn_34e1e57771d4bd81": "已刷新",
	"i18nn_ab719d5d91ae7b5b": "查询面单失败",
	"i18nn_0da14d270576fc82": "查询面单接口",
	"i18nn_0b3917ac0797b5b3": "默认可选数据",
	"i18nn_762ffdac60685e7e": "一件代发明细",
	"i18nn_1324a1d91ae3de53": "面单地址",
	"i18nn_d99d790ec4383bfb": "操作时间",
	"i18nn_e02e5da233bd816b": "打单",
	"i18nn_db48f5ae63408e0f": "这是一段内容",
	"i18nn_7ce351f5edbbf8c0": "激活",
	"i18nn_f69f323dc7e9ec22": "洛杉矶始发",
	"i18nn_65deb1358842a262": "打单费率查询",
	"i18nn_a33831a969cda728": "打单选择",
	"i18nn_77cf8937f28bff9b": "获取选中数据",
	"i18nn_6cf7f1b329fdc67f": "查询打单费率信息",
	"i18nn_ea8cd49587f7e54a": "查询查询打单费率信息",
	"i18nn_0bd52db433d771f1": "查询打单费率信息失败",
	"i18nn_0cdf81c9c7f64805": "查询打单费率信息接口",
	"i18nn_d1df2dbe81a6de76": "一件代发汇总",
	"i18nn_c3fa539dca493ed4": "延期两天以上",
	"i18nn_490aceb0639604e5": "延期一天",
	"i18nn_9e10475eb3e1d075": "当天新增数",
	"i18nn_b22be862e5bc215c": "总计",
	"i18nn_e4fce3e8821ac4e3": "管理后台",
	"i18nn_7cbe47cdcaa28905": "列表数据",
	"i18nn_ebe6f6c25130b09a": "仓租收费类型",
	"i18nn_c8239d27c661e123": "仓租单件",
	"i18nn_1b4525c800280581": "正在提交",
	"i18nn_10229dd4c7509cdb": "如果不填的话系统会自动生成",
	"i18nn_4ff968abd6e2fc12": "编辑时此字段不可编辑",
	"i18nn_b954f8829728e9d2": "正在加载",
	"i18nn_f4a19c201427a0b1": "装箱编号",
	"i18nn_e513671dd2474a29": "到达日期",
	"i18nn_38bc71c0dc55904b": "尺寸单位",
	"i18nn_367f1e0c18ec661a": "装箱数量",
	"i18nn_b713a5cc2e38eb89": "附件上传成功回调",
	"i18nn_634b4be09ed29f37": "确定发货吗",
	"i18nn_5beb6ed7e8fdff3e": "此为录入仓库的",
	"i18nn_e0f97fb9747905dc": "编码",
	"i18nn_b9aa51e503858bf1": "建议上传尺寸不小于的图",
	"i18nn_c9b828ca934ba6c6": "只能是数字字母下划线",
	"i18nn_d5b3460b4b868e7b": "不超过",
	"i18nn_0c9acfecfc4ec2eb": "字符",
	"i18nn_ad8c1e33ebc7b51c": "建议不超过",
	"i18nn_a8f12f1452cf72c8": "商品类目变化",
	"i18nn_462f2e7b92066e43": "该类目商品基本属性",
	"i18nn_f353b1ebbdc25be4": "模糊搜索",
	"custom_enterfuzzysearch": "请输入(模糊搜索)",
	"i18nn_851c121d787993a2": "库存统计明细",
	"i18nn_d42355e6aef72227": "时间范围",
	"i18nn_4d3765c415bb4462": "库龄",
	"i18nn_cffd7c9783c11047": "请选择日期",
	"i18nn_350662278f221eee": "库存统计汇总",
	"i18nn_ef71790e2ba5798c": "待提取",
	"i18nn_61204dd04df0ad13": "已提取",
	"i18nn_81821301b16c8a7f": "退货",
	"i18nn_57495c18e2baebdc": "转运",
	"i18nn_34ffc91fe81fa585": "客户自提",
	"i18nn_5eb29146a398224e": "卡派",
	"i18nn_6373cb1d1204d580": "自提",
	"i18nn_87bef133f8b3638c": "仓库费用统计",
	"i18nn_3c4a40675f59cd1c": "费用合计",
	"i18nn_375acecdfac5c204": "费用日期",
	"i18nn_25761be67f072851": "操作记录",
	"i18nn_9e33ad4200cd1405": "批量确认",
	"i18nn_a4c9e42169129983": "当前查询条件下全部添加到账单",
	"i18nn_7ab9966b8fbbf3bf": "选择操作记录添加到已有账单",
	"i18nn_3f8f460475a0854f": "操作状态",
	"i18nn_4b5536ea9955714b": "记录编号",
	"i18nn_6235565b185f0725": "工单编号",
	"i18nn_69220152f0875d9a": "操作名称",
	"i18nn_37ddb899b42e6e6a": "添加选中记录到已有账单",
	"i18nn_0cc30e8af9f94edb": "已选择的操作",
	"i18nn_06058087984d7f57": "已有的账单",
	"i18nn_e9669e4a9661c203": "选择已有的账单",
	"i18nn_e35175fcc579b438": "账单编号",
	"i18nn_daf169d1f7dceda0": "工单号",
	"i18nn_9f44ef2f7b7d8178": "按查询条件全部添加到账单",
	"i18nn_c4fc947a88b7ef75": "操作记录添加到已有账单",
	"i18nn_abdf300f02a919dc": "打开账单选择",
	"i18nn_3b43d8f1ba1c2287": "在已有账单添加操作记录",
	"i18nn_cd39e1ad92c5a24f": "账单",
	"i18nn_c44b77fdd0a0914d": "查询仓库",
	"i18nn_a0caf27419f2effd": "查询仓库失败",
	"i18nn_4e55f242c71cef9a": "库位条形码",
	"i18nn_ce391aeb2469ee01": "库位条形码下载",
	"i18nn_582f1ff343f74d13": "最多下载前五千条数据",
	"i18nn_26e732981f29709a": "打开弹窗",
	"i18nn_e5b0d7939b714f1a": "下载库位二维码",
	"i18nn_6ac98f81bd978520": "请先选择仓库",
	"i18nn_1c9746bd61ebaa8c": "账号绑定仓库",
	"i18nn_b00576fed9b3a1f2": "账号",
	"i18nn_b17342e22700eb32": "选择账号",
	"i18nn_f1e02877d27204c8": "打开账号选择弹窗",
	"i18nn_8f574078dfe6e56c": "请求",
	"i18nn_a36eda2f87d65f7b": "角色",
	"i18nn_0f864f84a5e810d6": "账户信息",
	"i18nn_831ab31568a78285": "充值",
	"i18nn_cefda9657773f55f": "查询全部客户",
	"i18nn_a9320232a40b10dc": "生成预付账户",
	"i18nn_49504f74aad443ce": "账户名称",
	"i18nn_10d660564497918d": "查询全部",
	"i18nn_169a0780408f1cce": "账户",
	"i18nn_4a0cacddeb2e38b1": "账户类型",
	"i18nn_a06e84801d6582be": "充值金额",
	"i18nn_c38333badc226309": "用户",
	"i18nn_01571203859d6b8c": "描述文字",
	"i18nn_f7549b7e760a39cc": "生成预付账户成功",
	"i18nn_cd79f3c6084173d1": "充值成功",
	"i18nn_54082523ac9409ca": "账户流水",
	"i18nn_80d0adba3c341069": "记录类型",
	"i18nn_cd173f918d077f02": "记录名称",
	"i18nn_5706df6392c2b9ef": "记录金额",
	"i18nn_b1813b05de28548d": "总托数",
	"i18nn_e7b838a996147e21": "总箱数",
	"i18nn_85a10d3e5ea0e103": "每箱件数",
	"i18nn_ae802858dbd29efa": "外箱尺寸",
	"i18nn_89d99a1a68608f3b": "整箱重量",
	"i18nn_736c4f14b7c228e8": "下载页",
	"i18nn_8d565f4ef805f787": "请安装不小于",
	"i18nn_a902ce087be9d96c": "的版本",
	"i18nn_869467a93c7dd4a1": "安装说明",
	"i18nn_8abb75d7c36c9668": "测试页",
	"i18nn_83178fcd6fe3c803": "若测试页打印异常",
	"i18nn_7c496b7f90c2fbb1": "则打印控件",
	"i18nn_aa5bf45727be9c66": "可能异常",
	"i18nn_cf417fd16cf53e8b": "请按下方提示检查",
	"i18nn_5e016c05078fb5a4": "执行安装或升级",
	"i18nn_7968855735d75a74": "安装后请刷新页面或重新进入",
	"i18nn_2597d782dc405773": "下载后",
	"i18nn_ff21cd07e7a01504": "安装",
	"i18nn_26dcfdda4a82bb24": "安装时一直点下一步即可",
	"i18nn_23cd29704e4461cd": "成功安装后",
	"i18nn_4913ccaad8c4cae5": "刷新浏览器",
	"i18nn_4df69796238d2be7": "如果被浏览器拦截",
	"i18nn_b9112cb81e44855e": "请查看网址栏最右侧",
	"i18nn_110656956d1d0165": "点击允许加载后刷新浏览器",
	"i18nn_3e73f05539beaf1b": "如下图所示",
	"i18nn_d2edb127f71c2998": "其他情况请联系客服或管理员",
	"i18nn_9b6fc3dc177f566c": "其他说明",
	"i18nn_a030f529d8c03632": "安装更新方式",
	"i18nn_c5810f3ceadf7f0c": "如果此前正常",
	"i18nn_ec0d6a9a594f7bcd": "仅因浏览器升级或重安装而出问题",
	"i18nn_cabc624b3ab6ec1e": "需重新执行以上安装",
	"i18nn_98ba86c55fc76cd4": "如曾安装过",
	"i18nn_e5bc41ba848585dd": "旧版附件",
	"i18nn_bd42088d06715f22": "请在",
	"i18nn_fa909a5cd6252661": "工具",
	"i18nn_d172f2b3474629f9": "附加组件",
	"i18nn_b8033d17c41b08f3": "扩展",
	"i18nn_3137c37384c02ae4": "中先卸它",
	"i18nn_630a9924533f6653": "若此前已安装过",
	"i18nn_1c6d906aac2fc13f": "可",
	"i18nn_07672c898e0f679f": "点这里直接再次启动",
	"i18nn_d88d2c774b992fff": "官网",
	"i18nn_bcafd1d256ec2117": "本地预览配置页",
	"i18nn_6b1a265012492058": "打印调试设计",
	"i18nn_a4346358e98aea80": "打印控件未安装",
	"i18nn_db4f2068895975f9": "点击这里",
	"i18nn_953f4232ebdf7eb2": "执行安装",
	"i18nn_87aa0a1ae8a43312": "打印控件需要升级",
	"i18nn_7fd5cb82192c32c2": "执行升级",
	"i18nn_4d9c8b1271b74f74": "升级后请重新进入",
	"i18nn_f7dc31d13b03f431": "打印服务",
	"i18nn_6e7e50639b462aa4": "未安装启动",
	"i18nn_e4772dc8568564ed": "下载执行安装",
	"i18nn_c3285ca681070371": "成功后请刷新本页面",
	"i18nn_7667eacd2bbcd3cf": "需升级",
	"i18nn_08cbc831c7629d39": "升级后请刷新页面",
	"i18nn_9cad0c89a033c539": "初始化",
	"i18nn_05d718c95a72fa48": "请先升级或下载",
	"i18nn_086a73016c53d692": "异常",
	"i18nn_89df29c83fd68a9b": "解析成",
	"i18nn_797bcc6c4af7fa88": "解析后的数据",
	"i18nn_13647b4031487392": "如下图",
	"i18nn_fe735bc696bccb15": "个文件",
	"i18nn_105937855b43aef9": "正在准备",
	"i18nn_db2a83c04d0d85ee": "一",
	"i18nn_4820b88f87f4332e": "基本服务收费",
	"i18nn_669de9a6d032bb1f": "收费项目",
	"i18nn_6867f16a2ac5e825": "价格",
	"i18nn_aec87e7239d5a6a6": "折扣",
	"i18nn_482bbc1edfb4212b": "即",
	"i18nn_6fda2cab86ef5cc3": "折",
	"i18nn_c9e78713e31e5c0c": "二",
	"i18nn_f80dedf7c601059f": "增值服务",
	"i18nn_7e95458d2a2eef81": "卸货费",
	"i18nn_4b98d0a614266407": "订单处理费",
	"i18nn_5ef418dc89a48756": "打托费",
	"i18nn_8766a8425c9fc7da": "出库费",
	"i18nn_4b1a6ff16878595b": "包材费",
	"i18nn_ecbdeee4451a9621": "重新包装",
	"i18nn_4fdda8fb0985d084": "贴标费",
	"i18nn_b43b2c2848adc46b": "检查费",
	"i18nn_95fbe2b6d26e92bc": "清单费",
	"i18nn_3fdd0d4d9a7310c2": "清点费",
	"i18nn_ec4432df79807d32": "模块编码",
	"i18nn_a6c64785365eba23": "服务费用类型",
	"i18nn_04082505d6c655d4": "服务费用编码",
	"i18nn_881c8c98311f4ef1": "页签类型",
	"i18nn_9dc7842043356291": "中转",
	"i18nn_b4b553687e5ffaeb": "基础",
	"i18nn_3cf44bfd6bba96c0": "增值",
	"i18nn_2c1e8bfea2b4a66d": "提取配置的",
	"i18nn_262120d7cf7f97c3": "将数组字典赋值",
	"i18nn_780302aa0482685b": "区分基础和增值分组",
	"i18nn_e4b7d3708af73a94": "设置默认值",
	"i18nn_5b177a1b00c63503": "港口提柜",
	"i18nn_e2116489db0ae58b": "转运费用组成",
	"i18nn_55c4ce60e1e2a8f6": "出库操作费",
	"i18nn_7e5c4599cfa54cca": "运费",
	"i18nn_9454732776dd845a": "如需",
	"i18nn_592ba2f32d800195": "仓储费",
	"i18nn_09552ac5725aa0de": "海运进口操作费用",
	"i18nn_d85ba9ab4e6427c4": "清关",
	"i18nn_f609585cedb25279": "提柜",
	"i18nn_bef6d3f3719bac17": "外州",
	"i18nn_f9e9644219d3f75f": "报价配置",
	"i18nn_5c9f84e4f267dd19": "中转配置",
	"i18nn_87e4d2a2afee5f6b": "中转服务",
	"i18nn_199f5bded0b23976": "退货换标",
	"i18nn_d7af7ac5cfab3cf4": "退货贴标费用组成",
	"i18nn_926ff3559ae0f7e1": "开箱",
	"i18nn_ff85bdffd9d663cc": "清点",
	"i18nn_58b12a522f8a4505": "换标贴标费",
	"i18nn_4451ef6efa66781b": "打包费",
	"i18nn_3155418abb05309e": "出库",
	"i18nn_4181240879167208": "开箱费",
	"i18nn_24a08f5b0d5b5b7b": "分拣清点上架",
	"i18nn_224de294b46466af": "种类分拣",
	"i18nn_eb8383f1ad65f63a": "重新包装费",
	"i18nn_3f126633b4bfea56": "合并包装",
	"i18nn_6b09c7002805bd30": "取出配件",
	"i18nn_61f33b788a44c915": "拍照费",
	"i18nn_570125be2fff2e6d": "销毁处理费",
	"i18nn_d60632d7f06399fa": "费用构成",
	"i18nn_6b521743986aaec3": "入仓费",
	"i18nn_16ad5a8bda3fbe6c": "派送费",
	"i18nn_4d02523e5d95b88b": "复核费",
	"i18nn_b74a51743a2a06e3": "退件重新上架",
	"i18nn_73195b8b5e85d58a": "配置管理",
	"i18nn_41d67b94402699ed": "角色管理",
	"i18nn_41f35b4ad3af9df5": "定时任务补偿",
	"i18nn_62d1f13b854eb1f7": "账单管理",
	"i18nn_fc708500e3251bac": "未确认",
	"i18nn_5534c1866fe37029": "已确认",
	"i18nn_1164fc3766b98658": "调整金额",
	"i18nn_18f2e59ad31254be": "金额调整",
	"i18nn_c6b8047d8c2eff05": "调整后金额",
	"i18nn_b44a4a27cac7433e": "未出单",
	"i18nn_27372599992ff988": "已出单",
	"i18nn_cce168301447d1ba": "进行中",
	"i18nn_bcef26d98d690aba": "取消面单",
	"i18nn_53ff6abaec297551": "货物类型",
	"i18nn_d9b62506e8e01149": "转运工单",
	"i18nn_32f88191102e9648": "发货单明细",
	"i18nn_7edc8f4b9d8f2435": "出库方式",
	"i18nn_15e0074dc8713a02": "必传",
	"i18nn_ca5862cb3b0eff3e": "工单号为空",
	"i18nn_1bea7a5b9ac9b8df": "出库完成打开弹窗",
	"i18nn_ca5180fb4e706f0c": "收费标准配置",
	"i18nn_fd416cd66f25eaa2": "添加配置",
	"i18nn_336f0c0952c94654": "收费类型",
	"i18nn_5b3e07a3dae222c4": "折即",
	"i18nn_a861c2514faa683b": "配置",
	"i18nn_c8d60a88161de6da": "通用配置",
	"i18nn_294ea7a0c08d79ad": "新",
	"i18nn_fcba4b5591a04cbe": "请先保存新增的数据",
	"i18nn_74ed28f373ea14da": "商品编号",
	"i18nn_fc72e5de884434f5": "收费方式写死掉",
	"i18nn_7079c6a7861b3dca": "本地",
	"i18nn_df6f9110d032d8f9": "批量打印面单",
	"i18nn_1cbfbf4afdd75282": "批量下载附件",
	"i18nn_adea92839a4c22b0": "生成面单",
	"i18nn_2b52b35b48065b9b": "至",
	"i18nn_7d2ffc27a09ff792": "开始日期",
	"i18nn_46f7be1133b3e2c6": "结束日期",
	"i18nn_49bff759e9b3498f": "本地批量打印",
	"i18nn_c688937c592c594f": "最近一周",
	"i18nn_a2db995132295e05": "最近一个月",
	"i18nn_502cb8bd057f1605": "最近三个月",
	"i18nn_a1c05de3b3c1d3af": "在当前路由改变",
	"i18nn_2d75009010a4b35d": "但是该组件被复用时调用",
	"i18nn_245df9bea6e8ad3d": "举例来说",
	"i18nn_bf52d6fc866035de": "对于一个带有动态参数的路径",
	"i18nn_f302ed3011ec4e3a": "和",
	"i18nn_6246cc4b005a2f15": "之间跳转的时候",
	"i18nn_c2b47bdf46331312": "由于会渲染同样的",
	"i18nn_4f04df1634a24e1c": "因此组件实例会被复用",
	"i18nn_36bfbdf25a9d4c23": "而这个钩子就会在这个情况下被调用",
	"i18nn_863a97c7dbcf6a49": "可以访问组件实例",
	"i18nn_5871ca463c7c5038": "本地打印",
	"i18nn_2de6c4eb2e40cf1f": "请使用谷歌浏览器",
	"i18nn_73845584be23a298": "文件可以打印",
	"i18nn_0759f172945714a6": "确定打印吗",
	"i18nn_5542a647676b34b2": "没有附件可以下载",
	"i18nn_b7819b9d931008b0": "正在压缩",
	"i18nn_9c63dfa3ec2d8024": "批量提交打单",
	"i18nn_fdbfdd6b62e582b1": "批量打单支持的服务级别为",
	"i18nn_157f5b25af1adf94": "如果打单失败",
	"i18nn_89d5f61bf8d059a1": "可以进行单条打单",
	"i18nn_52def4f590ebd639": "选择适用的服务级别",
	"i18nn_422d8aa4b5756fa4": "确定提交打单",
	"i18nn_9404be77588b0259": "条数据吗",
	"i18nn_3cc534923cd57f47": "请求面单",
	"i18nn_ec2bb3dbcfef94ad": "入库申请工单",
	"i18nn_e7b4fae7cec06141": "收货完成时间",
	"i18nn_bdc361ba04506136": "箱数",
	"i18nn_18c1c1a3ea76e9d3": "托盘总数",
	"i18nn_f8fdea45f59d1627": "外箱重量",
	"i18nn_eb8af1b7e16e2069": "外箱长",
	"i18nn_64e6eb209a8a2cff": "外箱宽",
	"i18nn_2a02dbcab12c9ee8": "外箱高",
	"i18nn_d11aca919045a1bc": "外箱复核",
	"i18nn_a3b1e5efc70b46eb": "库位查询",
	"i18nn_f2727d808fbeb34d": "查看库位明细",
	"i18nn_036240f3e205cce2": "库位明细",
	"i18nn_7f6fb8c8bbd8d300": "开始移库",
	"i18nn_c5fcbc314ea993e1": "目的库位",
	"i18nn_50838d78708562c0": "选择目的库位",
	"i18nn_f3178ce0fdbae662": "请选择目的库位",
	"i18nn_8cc424a5448c923b": "原库位",
	"i18nn_5ed60ec54dbdc1d8": "批量添加",
	"i18nn_20c5097839a5654c": "原仓库",
	"i18nn_a02853ebb27ea44f": "移出数量",
	"i18nn_a2af45b9be0761c3": "确定移库",
	"i18nn_7837b1dec1a2825d": "移库",
	"i18nn_0dc7e221eb87de4c": "移出仓库",
	"i18nn_f4e7721d7bffa60c": "移出库位",
	"i18nn_33aa8f4f99900ddf": "移出",
	"i18nn_7a6a82c1525f7b14": "移库选择的回调",
	"i18nn_86403b2da007f2c8": "显示移库",
	"i18nn_9bbc838d781b8399": "单个添加原库位",
	"i18nn_2a02e80d0a30ad77": "库存为",
	"i18nn_9b483a6110e422f1": "的不能移库",
	"i18nn_f530fe18b6c7eaf6": "全部添加",
	"i18nn_275c1a1b9cdfc981": "选择目的仓库",
	"i18nn_4460b377f745fdd9": "删除待移库数据",
	"i18nn_000785781fb38c21": "提交移库操作",
	"i18nn_2e5176b9dc8f4449": "库位选择的默认仓库",
	"i18nn_977ed6286b514ff6": "货柜尺寸",
	"i18nn_a0f2924544ff3ad8": "操作仓库",
	"i18nn_b41186fa3480c6dc": "导入的选择仓库",
	"i18nn_cab185a8f0e5b0c2": "转运公单",
	"i18nn_079e4ad054aecdb4": "一件代发工单",
	"i18nn_98b9372724332102": "批量打印测试",
	"i18nn_1810ae5cb9c84f46": "统计信息",
	"i18nn_9144ddc68d05d528": "开始时间",
	"i18nn_8b5d709462c8e71e": "结束时间",
	"i18nn_8bbeb2b4db9f282e": "创建日期",
	"i18nn_2e4ec5fa375c846a": "开始处理",
	"i18nn_5715d8eb76147f98": "处理",
	"i18nn_f5ad3484ae46cdb1": "去处理页面",
	"i18nn_a9aa576fa6cc8db0": "一件代发工单页面",
	"i18nn_64534a523dc0e8fc": "转运工单页面",
	"i18nn_17051c76a9c7c424": "未查询到面单地址",
	"i18nn_e7e014b9b622da06": "确定批量",
	"i18nn_4800bde602c5979c": "请求所有数据",
	"i18nn_e80826b3be85d896": "查询参数有误",
	"i18nn_3ee4c9b76289e93a": "请输入关键字",
	"i18nn_c944a6686d996ab3": "仓库",
	"i18nn_ad4345dbaabe1479": "保存",
	"i18nn_6b1945319eb18d5f": "自提单",
	"i18nn_66f0e38b9285894b": "出发方式",
	"i18nn_c2e3d79e5e3050f0": "创建自提单",
	"i18nn_51177b2d7ce0d883": "自提单明细",
	"i18nn_4b2b4820019ef897": "单箱数量",
	"i18nn_b19f7906dab47f9c": "派送方式",
	"i18nn_55ed30102ca735c1": "箱毛重",
	"i18nn_b5b5d2437dca1e76": "仓库代码",
	"i18nn_fa6ae0aebfee7efa": "发货条码",
	"i18nn_32df9cb3d1681477": "箱唛",
	"i18nn_5fb84776e29f5b72": "操作备注",
	"i18nn_3374a2514ef7dc5d": "自提单编辑",
	"i18nn_f6283c46ba925ab4": "发货仓库",
	"i18nn_e44e9a291d80c593": "收件人名称",
	"i18nn_5acbec83efb27445": "仓库编号",
	"i18nn_c4913ab43009b365": "邮编",
	"i18nn_b166e4e8fe9513fa": "国家",
	"i18nn_5165f3ee9377af83": "出车方式",
	"i18nn_5d77e5949de8eef9": "自提单导入",
	"i18nn_e05b5d049b64b040": "城市",
	"i18nn_2d13b6f878f30be3": "电话",
	"i18nn_2ad108ab2c560530": "请选择",
	"i18nn_e6bdd31025bb4f9f": "明细",
	"i18nn_ebd257df8f7efc53": "面单导入",
	"i18nn_bd6d1ded173dc477": "上传自供面单",
	"i18nn_e712a380cef4e31e": "面单单号和文件名要相同才可以匹配",
	"i18nn_6c3dd9349932c839": "面单文件",
	"i18nn_5fefbf45c9ca6464": "上传并校验",
	"i18nn_ac71ea3f789a778c": "校验面单",
	"i18nn_582446272516f554": "匹配状态",
	"i18nn_cf0c41b879762cdf": "面单单号",
	"i18nn_70e058bc23d52c1e": "上一步",
	"i18nn_aaf7f7f3df61a6a5": "重新上传",
	"i18nn_a93e095e532cd424": "保存匹配成功的面单",
	"i18nn_f9104346c336651d": "面单保存成功",
	"i18nn_e5664b79012a5cc1": "您可以去",
	"i18nn_df43e4fc4ab96cfb": "模块列表查看",
	"i18nn_826ff6f5d853fee5": "面单",
	"i18nn_658d7f0cc43ab866": "去一件代发",
	"i18nn_b172f8fd2a3c5ce4": "我知道了",
	"i18nn_abdbf97e2d5bdb25": "周日",
	"i18nn_c2acf27a87ce160a": "周一",
	"i18nn_48ecd1e7be525c75": "周二",
	"i18nn_62551a706d64f9c9": "周三",
	"i18nn_7c08f11a440bfb1c": "周四",
	"i18nn_38b82f33d30ed560": "周五",
	"i18nn_54904ecee1cf2823": "周六",
	"i18nn_aa3b760147bd92e6": "下标",
	"i18nn_a0ba0a35323754ae": "上标",
	"i18nn_9765f9d440d9715c": "缩进",
	"i18nn_ef31da179c71e165": "缩减",
	"i18nn_b8c98f42caa15ca5": "方向",
	"i18nn_ffc679c4b1ea3d2a": "左菜单",
	"i18nn_fcb613b8ba83ced9": "常规",
	"i18nn_ac089483cf2b4a0c": "分栏",
	"i18nn_89304b37afa2f1b0": "当前",
	"i18nn_578842e17fea3b6a": "线路一",
	"i18nn_cb143acd6c929826": "中国",
	"i18nn_4fd9ca7a902c7edb": "线路二",
	"i18nn_09b2a7a32b3c8db1": "美国",
	"i18nn_c84e312946608267": "共拆分出",
	"i18nn_34051a0fa4c8b001": "和文件名",
	"i18nn_d11719409666a169": "显示测试",
	"i18nn_bb77a02f2157ee4b": "下载并压缩",
	"i18nn_e61eff8fdd759f9d": "单号列表",
	"i18nn_267cd7e59f3b0549": "一行一个",
	"i18nn_ec9fcd75e3d2b843": "且按",
	"i18nn_fe072dfa8e77ac15": "顺序匹配",
	"i18nn_cb48ac138393dec7": "一行一个单号",
	"i18nn_037ffd217472355a": "按",
	"i18nn_b6bdc839bdc262f3": "每个文件",
	"i18nn_57998aee70aea45f": "秒左右",
	"i18nn_a8980879d3a4c82a": "请输入并选择",
	"i18nn_671663add1f18f05": "若该订单已完成线下快递面单生成",
	"i18nn_8e258808e2a13395": "请在此处上传对应面单",
	"i18nn_b6f0167ad2fd3cee": "订单单号和文件名要相同才可以匹配",
	"i18nn_8f2e8ec212959586": "好的",
	"i18nn_90db8de2b4a16c9d": "易仓平台订单拉取",
	"i18nn_3f9e9a6fe6f4f971": "易仓订单状态",
	"i18nn_590686fdff40723b": "参考号",
	"i18nn_380912455c6e2f06": "可多个查询",
	"i18nn_cd18d57f3bd7779f": "一行输入一个",
	"i18nn_68a6337017ca7d67": "销售单号",
	"i18nn_a3b6f85b4a9530ff": "仓库编码",
	"i18nn_a456e74e3c8a95f2": "拉取",
	"i18nn_e99e48ab45c5e800": "更新发货仓库",
	"i18nn_4f26196e75e6eb1a": "第三方订单",
	"i18nn_ab29edcb476d7186": "生成发货单",
	"i18nn_e9d55d41fee9a273": "撤销发货单",
	"i18nn_09124c6c5acf6c8a": "易仓平台",
	"i18nn_79205a47cd5d529c": "拉取最新订单",
	"i18nn_b30717f74d1d91b3": "第三方订单编号",
	"i18nn_737061cefdb6d0af": "电商平台单号",
	"i18nn_b6b08a7daa1de1ab": "店铺名称",
	"i18nn_d2dd21a61c559695": "第三方平台",
	"i18nn_bdf9dc871938ad08": "是否同步",
	"i18nn_e691da78ee2e02f5": "地区",
	"i18nn_b7f95c7fddc0d602": "市",
	"i18nn_ea4756bc1642e0f1": "名称",
	"i18nn_5b954c149f0b1bdf": "第三方平台名称",
	"i18nn_0f85a73e30bce3c5": "快递渠道",
	"i18nn_bb74b80c21dcc1a5": "推送日期",
	"i18nn_2d78a37c0efc2723": "易仓订单拉取",
	"i18nn_b83011b3bfea8797": "拆分",
	"i18nn_90faee419c9a32ae": "易仓",
	"i18nn_a6abf1dd9e065e27": "新建配置",
	"i18nn_0e3b6b0b53dd94e8": "易仓用户名",
	"i18nn_8af36bb95bcac474": "易仓密码",
	"i18nn_b306361a5aeda4e8": "账号备注",
	"i18nn_62519f438e8f64b5": "是否回传单号",
	"i18nn_296b2c6a46764af7": "是否自动同步",
	"i18nn_d165fe5a9a65fb8c": "同步方法",
	"i18nn_ecb75613330d89fa": "服务商",
	"i18nn_27aaf0520109d72e": "服务商编码",
	"i18nn_44b27e0a8476a256": "更新仓库",
	"i18nn_3a6fdc59b1cd84c1": "要分拆的",
	"i18nn_02ccd2cf723f9272": "是",
	"i18nn_e76d885ae1c74d4f": "否",
	"i18nn_60f5b585fd34c2bd": "导出排序",
	"i18nn_0b2cd5cfcfebe08e": "易仓库存同步",
	"i18nn_c7948588841d75cc": "库存同步",
	"i18nn_dfd9cde6fbd425c7": "是否可同步",
	"i18nn_3bae5212138a7193": "差异数",
	"i18nn_ddecfa4a8d6bb743": "易仓可用数",
	"i18nn_73e14fb8e120ce61": "库存来源",
	"i18nn_07048291fdb26b6e": "同步",
	"i18nn_54e4d834b7e2c26a": "盘亏",
	"i18nn_38624a6aa0667143": "盘盈",
	"i18nn_21d58958f280d153": "快速转运",
	"i18nn_6b42f3e7ab6e4c7e": "更多筛选",
	"i18nn_c617dfa4c0b5ecb4": "系统编号",
	"i18nn_3d3bc466f13154d4": "柜号",
	"i18nn_a31d32ac4015d196": "跟踪号",
	"i18nn_6aa78c268e87527b": "货物单号",
	"i18nn_611f87335433e0c3": "到仓方式",
	"i18nn_17768c25d95d04ca": "转运类型",
	"i18nn_f56c111456a351be": "到仓日期",
	"i18nn_e69b8beb7706725b": "收货日期",
	"i18nn_42274ccae6a91180": "预约出库编号",
	"i18nn_0bbce74881c893df": "地址类型",
	"i18nn_61c33362d88eb0ba": "来源",
	"i18nn_ea881f28aae703af": "预约类型",
	"i18nn_b7a786a287eb00e4": "是否预约",
	"i18nn_fb6105f4b294a9d0": "提货时间",
	"i18nn_c8773d8d74202801": "托数",
	"i18nn_54f17bfe03d79b8a": "箱标",
	"i18nn_9168144190f66f5d": "关联编号",
	"i18nn_ed74196efa90cded": "编辑转运单",
	"i18nn_004bc5eedbcc75e0": "新增转运单",
	"i18nn_2f19f86ff8be446f": "售后",
	"i18nn_43f2b49eef6bda66": "预约明细",
	"i18nn_515bc077445e40dc": "预约状态",
	"i18nn_cc335ba8f4b2cc0d": "预约编号",
	"i18nn_e1e2b21c7b4b951f": "转运方式",
	"i18nn_fa02547543cff0f7": "是否换标",
	"i18nn_886ea69af099215d": "预约托数",
	"i18nn_08e5062e3affb676": "贴标数",
	"i18nn_b7f9db36db186f72": "预约操作",
	"i18nn_da1ca7369f4bde15": "预约单",
	"i18nn_68fd8440c5a9dd35": "提货完成",
	"i18nn_2bcdd4344979655f": "预约完成",
	"i18nn_e90a876d882d595a": "展开预约明细",
	"i18nn_db9b1bd30ea188f8": "打印拆柜单",
	"i18nn_e99e3c2ab95512c3": "拆柜完成",
	"i18nn_1f1bd51a9a045025": "转运入库",
	"i18nn_786593149c0bf9f8": "新建",
	"i18nn_c4ca802cf2230b0b": "筛选",
	"i18nn_559fed47b0e95407": "转运单号",
	"i18nn_8af1fd820046d3c1": "入库仓库",
	"i18nn_8221d63993501b1c": "送仓方式",
	"i18nn_9be246e60da2a0a2": "到仓日期",
	"i18nn_33a85575edeaacaa": "入库托盘数",
	"i18nn_daa60c502de1462f": "物流单号",
	"i18nn_696eca4149742942": "是否展示箱子内产品",
	"i18nn_46b944e39511234b": "附加服务",
	"i18nn_0ae081c3b8c4d4a1": "提交日期",
	"i18nn_e137950d1897817e": "是否现场卸货",
	"i18nn_9e9277ad9980fd7d": "预计到仓",
	"i18nn_f904142c2f2c8106": "创建转运出库",
	"i18nn_6c1ebb1581a6828e": "选择要运送的货物",
	"i18nn_35ec768075eb8f4e": "入库单号",
	"i18nn_73fc38e67381ae73": "外箱标识",
	"i18nn_5caaadc380029e3f": "本次转运箱数",
	"i18nn_3f0ecebbd9d2f23b": "可用",
	"i18nn_d4b6bcb26128130e": "已入库明细",
	"i18nn_e1363c8a04ba532e": "在库数",
	"i18nn_678824ae3065f35b": "锁定数",
	"i18nn_94fb3830c62f2c1c": "可用数",
	"i18nn_51c7caf81e915eb4": "可用箱数",
	"i18nn_48bc6e8b56cba243": "在库箱数",
	"i18nn_0ddc3b0b28d12b29": "锁定箱数",
	"i18nn_53f9f6f694a5b0a8": "单箱尺寸",
	"i18nn_9e168db87b716866": "单箱重量",
	"i18nn_c5ef0b068e92e23d": "箱内",
	"i18nn_fd6620afef3fab05": "出库仓库",
	"i18nn_e1d76a34da86da92": "是否",
	"i18nn_7110aa855f2767a1": "物流号",
	"i18nn_e98a6f2582037336": "装运类型",
	"i18nn_c301b10948889cb5": "计划出库日期",
	"i18nn_e520453b3f22ad66": "地址选择",
	"i18nn_59764ba919e3d70d": "地址名称",
	"i18nn_5a9fb0915ecea987": "联系电话",
	"i18nn_fa4d50bc26e39b26": "外箱标",
	"i18nn_4de0a112bb772da8": "入库明细",
	"i18nn_7fba76c83eec6d09": "选项",
	"i18nn_e186babc2e9cadf4": "保存为草稿",
	"i18nn_6e33a5e062b119ff": "是否展示箱内物品",
	"i18nn_3dc377fecc5acdb1": "收货列表",
	"i18nn_202e232a73f0abe1": "查看出库明细",
	"i18nn_b1d3482f4406f513": "出库明细",
	"i18nn_885206600df4fb3d": "编辑转运入库",
	"i18nn_f6272b352b3f3061": "新增转运入库",
	"i18nn_1f6b9a150926ba64": "到仓时间",
	"i18nn_8a43886ff356ed78": "箱子标识",
	"i18nn_53fa7ff1ea383b2f": "箱子数",
	"i18nn_70b5c883b227851b": "箱子长",
	"i18nn_0a389c3a5e3938d3": "箱子宽",
	"i18nn_5c31dfdb8f4781ec": "箱子高",
	"i18nn_91263f8fa1a94da9": "箱子重",
	"i18nn_3107835382623271": "请用英文逗号分隔多个物流单号",
	"i18nn_ffe3865fea625e35": "查询地址",
	"i18nn_266578312b060df9": "转运货物信息",
	"i18nn_1baf81dc415067f6": "可批量复制",
	"i18nn_925eaf37d939c1f4": "中数据填入",
	"i18nn_a449b9611b8c347c": "可鼠标右键表格编辑",
	"i18nn_b05fb36dbc990e3b": "保存时请删除空行",
	"i18nn_bc1e00ff29953589": "同操作",
	"i18nn_4eaadbbbdcab5fe5": "一样",
	"i18nn_84858479e2b2e839": "可复制粘贴",
	"i18nn_7b2dbb677a1ccc93": "单个数据输入时",
	"i18nn_ea777e88d4a6de0f": "单击可直接输入信息",
	"i18nn_069d3b178e94b830": "若字段为下拉选项",
	"i18nn_a81fdd0b73709368": "双击字段则出现选项值",
	"i18nn_2063a37475d39272": "添加箱子",
	"i18nn_514377f02a8481e6": "保存并提交",
	"i18nn_65fe75ea422bdc50": "转运出库",
	"i18nn_b6cb663839a53fd9": "转运转运单号",
	"i18nn_b1131a0cc3945b5f": "出库单号",
	"i18nn_579f43c8c657bbbc": "目的仓库编号",
	"i18nn_f2896032570c4d41": "地址信息",
	"i18nn_eed89121cdfb33c3": "备货完成日期",
	"i18nn_54bfc3e9958727d8": "出库完成日期",
	"i18nn_f4af8c42d7478c6f": "更新目的地",
	"i18nn_71a647a484c3f5c2": "发货列表",
	"i18nn_f88b93f4fd02974c": "托盘总标数",
	"i18nn_95fb447f1661affd": "编辑出库",
	"i18nn_bab35eeb688f9eae": "新增出库",
	"i18nn_8e23aeb472e03f34": "待出库列表",
	"i18nn_6feb2a7c40070fa2": "打单信息",
	"i18nn_80755d56db88fca2": "请填写出库箱数",
	"i18nn_4118399786a5251a": "库存列表",
	"i18nn_0950e42c6423c2a7": "转运库存",
	"i18nn_f608e17fc712987a": "在库库存",
	"i18nn_53976ac09b57e51e": "转运入库的数据",
	"i18nn_92958dfd9227a641": "尾程信息",
	"i18nn_7836f25126b0e111": "若存在多个物流单号时",
	"i18nn_d9081425db9f2c5f": "请用英文逗号将物流单号分开",
	"i18nn_d420ca5d51e2fc22": "要运送的货物",
	"i18nn_4672c6113ffede32": "预约出库",
	"i18nn_5cd591b578350972": "地址编号",
	"i18nn_13247dc8e601ddef": "转运入库柜号",
	"i18nn_934695e82afbe756": "出库指令是否下达",
	"i18nn_1d9d50fca9ffeb39": "预约是否完成",
	"i18nn_e00cd157c0ac4d1f": "新增地址",
	"i18nn_2d34f0565cfc4473": "选择已有库存的",
	"i18nn_b2e9a0ce00229898": "可明确操作指令",
	"i18nn_b195f5d99d869b3f": "比如",
	"i18nn_43cef0e6de330163": "贴标要求",
	"i18nn_137d90eaafe33693": "贴标数量",
	"i18nn_0f03549000bc5f2d": "贴标方式",
	"i18nn_b95b66c11ead8aac": "标",
	"i18nn_1d5ba91957adcff9": "托盘标",
	"i18nn_c8c17c7e3fbf62f1": "等",
	"i18nn_34cc4b37bb2d93a7": "打托要求",
	"i18nn_4721a0daf4241a40": "是否需要拍照",
	"i18nn_6c1e4bd2907c0a3d": "体积测量",
	"i18nn_10abe7527d9d0f26": "重量测量等其他需要仓库操作的事项",
	"i18nn_59550e3d8c8088f9": "预约",
	"i18nn_a29209bd396e4bdd": "预约方式",
	"i18nn_d6cecadea664b088": "显示数据仅供参考",
	"i18nn_58830b2f3e6b548e": "建议重新上传模板",
	"i18nn_7f6c721b57ed0e7b": "出库编号",
	"i18nn_af48c37c06629ec5": "出库箱数",
	"i18nn_ee62c49d9dae1b96": "转运入库明细",
	"i18nn_7c27cb7b39aa1d82": "已转运入库的货物",
	"i18nn_158db9252e21d1a6": "关联单号",
	"i18nn_dae2f81921b3b3c0": "编辑预约",
	"i18nn_d992d16df3748947": "新增预约",
	"i18nn_c6bf739e696c2bfc": "下次选择的名称",
	"i18nn_c996cb376f253cb5": "批量导入",
	"i18nn_844521f361060167": "单条录入",
	"i18nn_67e91d786f25ae66": "代理操作面板",
	"i18nn_8e3e8fb05382614e": "编辑等操作",
	"i18nn_c39fd3d48389bea7": "入库预报",
	"i18nn_74a5def3116ecd89": "分割",
	"i18nn_45855d1e066e1764": "旋转",
	"i18nn_f82bf2cacfa6042f": "工单列表",
	"i18nn_a38569d545d8e238": "创建工单",
	"i18nn_2a714652d0b17e0d": "创建增值服务",
	"i18nn_c508489f1b628c1c": "工单代理",
	"i18nn_14b5f4f572736efe": "工单日期",
	"i18nn_9b73b93c1607cd97": "提交工单",
	"i18nn_f37cb25b8f6a5486": "可下载模板后",
	"i18nn_a6278bd3526e47e6": "直接导入",
	"i18nn_23b9e07b17dfefc2": "也可以复制粘贴",
	"i18nn_705fc0e58821d525": "中数据",
	"i18nn_6172b2d3ca888226": "鼠标右键可添加",
	"i18nn_898fe40df4262eea": "编辑表格",
	"i18nn_6aada61bec5df499": "保存时请删除空白行",
	"i18nn_2a52107e50015b89": "双击可选择或选中数据",
	"i18nn_3644a90f9f13f0b8": "工单信息",
	"i18nn_52a8c38184d84581": "系统单号",
	"i18nn_32b164c53fa35e6d": "模块名称",
	"i18nn_61fc9e07fff97930": "服务类型",
	"i18nn_68493357463cb368": "多个订单号搜索",
	"i18nn_05e6db2372e82816": "订单号搜索",
	"i18nn_d5031b2bd42d2229": "订单号输入框",
	"i18nn_33bb5e57aba192e1": "订单号输入",
	"i18nn_b92065474c13bf38": "一行输入一个订单号",
	"i18nn_b5511889be42a3da": "确定并搜索",
	"i18nn_c3525eb110db58aa": "更多",
	"i18nn_dc628291530d00b9": "签收附件",
	"i18nn_108c7dc6debca017": "柜型",
	"i18nn_e4c6b981fdd27703": "出发时间",
	"i18nn_76e82afd9c2b6ca1": "提交时间",
	"i18nn_31519f8b1453a53e": "出库类型",
	"i18nn_716dcf4d38910c35": "出库时间",
	"i18nn_7e4fc5ad9e8a3d62": "预计到车时间",
	"i18nn_da7eef74bb8e0f6d": "仓库完成时间",
	"i18nn_b4296642bcc9f428": "退货仓库",
	"i18nn_d7aef993e73ebf60": "发货信息",
	"i18nn_3601b5a98803f4d7": "出单方式",
	"i18nn_4851293ecdd539ed": "收件人信息",
	"i18nn_8b9b30a5bc0eab3a": "邮件",
	"i18nn_3e429a7c7cf3cab2": "地址二",
	"i18nn_10c015e3241e842e": "州简称",
	"i18nn_388b46f93fbfbf4d": "追踪信息",
	"i18nn_245a46426329f070": "追踪单号",
	"i18nn_b2b1ac4f554665b1": "保险和签名服务",
	"i18nn_0ffca7433738453f": "只用于平台出单",
	"i18nn_7b16a04efec1728b": "保险金额",
	"i18nn_488e817afcd3b95e": "是否签名",
	"i18nn_3e8b33480ff7e0f7": "外箱信息",
	"i18nn_e366dc49c78c8e3d": "收货地址",
	"i18nn_fc554f12d8734681": "是否启用",
	"i18nn_f085596674018f3e": "关联",
	"i18nn_08b0ac0e59879bb8": "编辑保存时",
	"i18nn_9cdb5c847968eb64": "可直接导入",
	"i18nn_53b13e07568ca635": "商品",
	"i18nn_3e764f7ae7f5e850": "退货物流单号",
	"i18nn_35b7a8c5deb39056": "外箱规格",
	"i18nn_1697895cfac3573c": "当一箱一件时",
	"i18nn_cc492a06237b3169": "外箱规格可不填",
	"i18nn_f51d697e212aaa0c": "选择的",
	"i18nn_4c72d6edbaf60363": "入库单",
	"i18nn_a1ef1454c217a90d": "手动录入",
	"i18nn_f1d6776242105f03": "增值类型",
	"i18nn_ad36057ff6a7ce6b": "添加附件",
	"i18nn_f4c19062aa935c68": "客户管理",
	"i18nn_4438ee6b6104e469": "子账号",
	"i18nn_3e98a7801f9d8a6d": "归属仓库",
	"i18nn_f4e9f3da4fc75304": "不支持登录账号",
	"i18nn_decbca7186d5d34a": "手机号模糊",
	"i18nn_cdd4901a665110a9": "是否子账户",
	"i18nn_e4842b8507dd415c": "公司简称",
	"i18nn_bf5a6019eaff1a94": "登录账号",
	"i18nn_dae8d92a749abc4e": "修改密码",
	"i18nn_1afc53810c8d999b": "客户账号",
	"i18nn_fbad90bef41ba586": "选择用户账号",
	"i18nn_010e0573f7c0a8cd": "示例",
	"i18nn_45313d26d1c74281": "输入邮箱",
	"i18nn_b906982cc26ae11d": "电话等",
	"i18nn_39128cd2a6819be3": "联系方式内容",
	"i18nn_4580fd803dfe44d5": "输入具体内容",
	"i18nn_6a78da7fb21a7380": "仓库费用重算",
	"i18nn_158ecb8cbcabc069": "费用业务",
	"i18nn_748bca3d68c277f5": "计费周期",
	"i18nn_3258bbe488af3e79": "天",
	"i18nn_aa0fc2e0cf8a9331": "标记",
	"i18nn_eb6d28d29b20a456": "费用重算",
	"i18nn_ab7c1e41cdae24c5": "重新计算",
	"i18nn_82b5bd967d494be3": "操作类型",
	"i18nn_c6e98c83801b5198": "商品名称",
	"i18nn_599710d641d92957": "货柜号",
	"i18nn_ed6ece9e43eef321": "种类",
	"i18nn_656e30fd838de0c2": "附加操作",
	"i18nn_b0a8a31be81514ba": "收费",
	"i18nn_33e215cab320c32f": "查看费用明细",
	"i18nn_600a0535e21fdb62": "居住地",
	"i18nn_6ddb75919426c9e8": "苏州市",
	"i18nn_61f6a5148fa3704c": "学校",
	"i18nn_4cf3d3e9cdca9259": "联系地址",
	"i18nn_16e95bf041f1a325": "江苏省苏州市吴中区吴中大道",
	"i18nn_4335d39df11a6971": "号",
	"i18nn_90194c3c809141d4": "费用明细附件",
	"i18nn_f14c1e5fe58824bc": "附加费记录",
	"i18nn_eebc23576f87cbb2": "确定选择",
	"i18nn_6c2519a4169a3bd4": "下载提示",
	"i18nn_9618edd493c9d6ea": "当前共",
	"i18nn_83130aa2fa9470ae": "数据量大时",
	"i18nn_f57015be50941d22": "浏览器会卡住",
	"i18nn_98feeaa7602d644a": "请稍待",
	"i18nn_1c4cee3493d17175": "等待时间过长时",
	"i18nn_35c67777896ab842": "建议关闭页面重新进入",
	"i18nn_88acc688b8e99581": "然后分批下载",
	"i18nn_5f12f9d48f1551af": "最多下载前",
	"i18nn_8dd194f4fe9000f0": "超过",
	"i18nn_034351a2ccf9a040": "建议筛选后分批下载",
	"i18nn_9ecdcf15b4299bb8": "库位导入",
	"i18nn_e8deb376cbd8558f": "客户仓库绑定",
	"i18nn_9d8eca19feef6cb6": "仓库名称",
	"i18nn_4926f0dc61317402": "变更金额",
	"i18nn_93e87666aa87fa25": "需要增加或者减少的金额",
	"i18nn_6ab4144b540a8a6a": "变更类型",
	"i18nn_1466b1690d2ae404": "费用时间",
	"i18nn_41d2148209b5edc8": "充值记录",
	"i18nn_df8b6d3f1ea2753f": "最后更新日期",
	"i18nn_7b7058faea31b99f": "添加充值记录",
	"i18nn_89221ad15d2ec113": "文件名称",
	"i18nn_8c49a4bfd3a78dc2": "充值汇款材料",
	"i18nn_fa642f1b3c8159cd": "当前版本",
	"i18nn_6a6a43ebb138860a": "建议版本",
	"i18nn_7f7ce15a429dbf34": "请确定密码",
	"i18nn_3ba51d48e0c6865b": "打印尺寸",
	"i18nn_1e24cf708a14ce81": "测试",
	"i18nn_e373ed3890866bf8": "打印测试",
	"i18nn_6648c86d7226f1e2": "请选择打印机",
	"i18nn_bf0c35e9445b35ee": "不选则默认",
	"i18nn_a3bda85154d251b6": "纸张尺寸",
	"i18nn_777032b851831702": "是否预览",
	"i18nn_08cfa554ac74bc3f": "面单预览",
	"i18nn_54672b9140a3afe0": "打印机",
	"i18nn_17702b3b5666ee6b": "停止打印",
	"i18nn_a85af4ba66084d28": "尺寸",
	"i18nn_fdc1d71066710361": "打印面单",
	"i18nn_6052fe73b8339cd3": "打印中",
	"i18nn_6e57f4deb066020b": "预览打印",
	"i18nn_9a888b8bf3ba1d24": "停止并关闭打印",
	"i18nn_e3de3b77067f6a24": "单数",
	"i18nn_ed6f76dee1d4e5be": "面单数量",
	"i18nn_a7daecd641c95b4d": "单个打印",
	"i18nn_1a493bfd083dfde4": "单个预览打印",
	"i18nn_c21d10868d2cfbc2": "打印失败的面单",
	"i18nn_69b1b60d3a29bb0f": "重新打印",
	"i18nn_27e57d9202ec74fc": "重新预览打印",
	"i18nn_61b790003a44b0f2": "原序号",
	"i18nn_23bfb1f8c0bfa5c8": "单个打印面单",
	"i18nn_bdac055c1651b9f6": "正在打印",
	"i18nn_07480238e18d3b89": "或图片",
	"i18nn_52a808e5eb9cc79d": "打单序列号查询",
	"i18nn_9643a3d4e7104ad3": "查询方式",
	"i18nn_6eeebe9748c133d4": "范围查询",
	"i18nn_a5f8ba14adddd7f0": "精细查询",
	"i18nn_0b0a9b957660d0ea": "起始",
	"i18nn_3a687c8baff0dddb": "起始序列",
	"i18nn_38af6326c94fd116": "结束",
	"i18nn_f135e419f43a6c8b": "结束序列",
	"i18nn_27e24ff9191e254d": "有",
	"i18nn_66e4e9fd7415a919": "打单序列号",
	"i18nn_65e32ce7238e46e7": "合并下载面单",
	"i18nn_c4d4d7d025f1d47d": "序列号",
	"i18nn_e63fc078a7baf7c9": "快递信息",
	"i18nn_e83443dd6c718cf9": "物流轨迹状态",
	"i18nn_41d020489ecacbf0": "物流更新日期",
	"i18nn_198d8fd115bfc3d9": "自供面单",
	"i18nn_15bc1e82df3428d8": "系统出单",
	"i18nn_ba5c84ce26d97fb2": "重新打印面单",
	"i18nn_8eb6e92d013f9555": "暂停",
	"i18nn_7a9b4f96510fb0e1": "已提交可以暂停和恢复",
	"i18nn_a9eaee9d97fb6c43": "已暂停可以恢复",
	"i18nn_9f88e3e6b1e719bd": "选择渠道",
	"i18nn_ab5902ee5c645e01": "不可用",
	"i18nn_ecc3d56be2ae0baf": "其他参数",
	"i18nn_7e80cfa637ec486b": "签名服务",
	"i18nn_e716ca411b2142e3": "渠道编码",
	"i18nn_2ed454931e1c1f73": "请输入后选择",
	"i18nn_a87302a8cd63eae8": "模板已更新",
	"i18nn_daef27ebf42d4adb": "选择需要导出的列",
	"i18nn_055a45ea5fef0d11": "筛选项数据",
	"i18nn_26ad8f1f07292f65": "导出各个账单前",
	"i18nn_21bae2bd339406af": "数据越多越慢",
	"i18nn_83a93e396379a993": "导出全部",
	"i18nn_5734a059a4622f20": "前",
	"i18nn_5d6f695ebedef0a4": "分批导出所有页",
	"i18nn_45605a8587b5efab": "分批导出",
	"i18nn_f71701ed55a2e712": "每批",
	"i18nn_8d8c79fcfaa246f5": "导出前",
	"i18nn_018de66d9d708dc7": "含合计",
	"i18nn_be331b9c430a360b": "导出第",
	"i18nn_0d9a272b3124998c": "导出当前页",
	"i18nn_c432271c995f31a5": "确定导出",
	"i18nn_dd3438b8ee62f146": "导出所有页",
	"i18nn_fc8ab6ca436ce57e": "全部附件预览",
	"i18nn_e8c8c5ee90b04380": "已上传",
	"i18nn_c1bdca8d2544fee9": "已上传附件",
	"i18nn_2bea3f02bd4a8c8c": "附件预览",
	"i18nn_520e48e008363ef7": "搜索关键词后选择",
	"i18nn_7c3fc1953013745c": "物流轨迹查询",
	"i18nn_f32034adcbc2c3e5": "业务收费项",
	"i18nn_76dc341edac65885": "业务类型",
	"i18nn_c5400c966a9be32d": "操作模块",
	"i18nn_2e53a931fe17b287": "淡季计算参数",
	"i18nn_fa3279450bc594f7": "淡季价格",
	"i18nn_aeb910ebbdad440f": "旺季计算参数",
	"i18nn_19e3ed244a2eb1c3": "旺季价格",
	"i18nn_0e914402e9c804dd": "是否启用正则",
	"i18nn_a89500c5feb306cd": "正则",
	"i18nn_b85bec86fa809471": "业务",
	"i18nn_67709252ea4322d2": "选择业务",
	"i18nn_6ffc5b58796093bb": "计算参数",
	"i18nn_d2b3d0f99eca4fb1": "指定",
	"i18nn_6f140c7fab44b6d2": "是否启用公式",
	"i18nn_2694c4443e3fe806": "不启用",
	"i18nn_d766c2e817285f0a": "公式",
	"i18nn_54d26313aeff4235": "价格配置",
	"i18nn_37d1e22dd3233494": "新增价格时间",
	"i18nn_ac6555f08d4fd109": "当大于等于",
	"i18nn_274045338eb039af": "天时",
	"i18nn_3514db4364576458": "当",
	"i18nn_4e5d40d707d90d7d": "淡季收费价格",
	"i18nn_d5beb7f89f0e6b61": "淡季折扣",
	"i18nn_b40390257aa6c985": "旺季收费价格",
	"i18nn_30e41d362a584108": "旺季折扣",
	"i18nn_397c4e137c9c7c81": "新增价格配置",
	"i18nn_7c554958c96fab58": "选择复制到指定客户",
	"i18nn_61bd7405863443de": "复制到指定客户",
	"i18nn_27e3e67c62eaa392": "指定客户等",
	"i18nn_a407a2fe067a20e6": "原客户名称",
	"i18nn_7a806e59f1e812b7": "费用类型配置",
	"i18nn_94a6bbd4c69bda08": "费用类型编码",
	"i18nn_90656db9cda8e9e5": "操作费",
	"i18nn_f9abbe1d9c185407": "快递打单费率",
	"i18nn_81f6eaf23b9c56eb": "快递",
	"i18nn_f7c6fd5cfe8ef5e5": "机构绑定用户",
	"i18nn_9ce5ada346da7c39": "企业名称",
	"i18nn_21a9ea9e8872f02e": "企业",
	"i18nn_4c9736c24ed29b16": "用户账号",
	"i18nn_1145acd2ac058642": "机构企业",
	"i18nn_3469860ee3533708": "机构绑定仓库",
	"i18nn_b3766d43261ef72d": "机构仓库价格",
	"i18nn_d5942292f805c023": "机构仓库新增",
	"i18nn_5ddc19a148f5e5c1": "退货入库",
	"i18nn_5151fd7875058ddf": "仓租",
	"i18nn_fc9eb3ba06096cbe": "条件",
	"i18nn_8f7ac0044d3ded94": "旺季",
	"i18nn_7b97262a9d0258b3": "淡季",
	"i18nn_8104a3554e1a3034": "收费项",
	"i18nn_4584f1f5c7cc6e72": "收费明细",
	"i18nn_a88502dfaa1c0f1e": "企业管理",
	"i18nn_85da697a473926e3": "解冻",
	"i18nn_8c919e34ddb841f3": "冻结",
	"i18nn_50c0cc32890919fa": "申请企业",
	"i18nn_10fcff51464fe101": "证书",
	"i18nn_7fe92f41627d9a70": "审核不通过",
	"i18nn_60456fdef41cac4c": "冻结状态",
	"i18nn_18a470eeff7a2bed": "审核状态",
	"i18nn_f6152345aa67d0e5": "完善状态",
	"i18nn_8845c7472bacf9fd": "注册时间",
	"i18nn_0a442b86842d8f5a": "企业星级",
	"i18nn_2ccd11027b844bf2": "合伙人手机",
	"i18nn_1aa424e1fb1f2313": "所属合伙人手机",
	"i18nn_7c4924f55ce55589": "用户手机",
	"i18nn_901d401d431913a0": "请输入用户手机",
	"i18nn_0aef54c2f76d42dd": "身份标签",
	"i18nn_08c2e44bd2e49e57": "请选择身份标签",
	"i18nn_cc68ee555944f23d": "厂家",
	"i18nn_9793ebd267f312aa": "代理商",
	"i18nn_20bce8cda928de93": "请选择企业星级",
	"i18nn_4622f53565591dbd": "请输入公司名称",
	"i18nn_97cb2e8579a289d8": "公司所在地",
	"i18nn_090e2ea2d973d9ec": "详细地址",
	"i18nn_7d823020b0799877": "请输入公司详细地址",
	"i18nn_689d5dfe51037e00": "公司电话",
	"i18nn_a0dbea5c5ee32b72": "请输入公司电话",
	"i18nn_20650eaf339e1fe6": "联系人",
	"i18nn_8935a7931669cfde": "请输入联系人",
	"i18nn_fd722075b445907a": "请输入合伙人手机号",
	"i18nn_2d8b2a9b74b67dd4": "统一社会信用码",
	"i18nn_b5fab46e6e28ca45": "请输入社会统一信用码",
	"i18nn_f76ad974fe64cbc9": "企业邮箱",
	"i18nn_0da2b8e83a58c95c": "请输入企业邮箱",
	"i18nn_4442771eba7574b1": "上传营业执照证",
	"i18nn_d181d689ca3e0fc3": "上传开户证明",
	"i18nn_c732c477b91864bf": "上传企业认证授权书",
	"i18nn_27ae0ed997c04daa": "库存证明",
	"i18nn_8b2af441b1291795": "纳税证明",
	"i18nn_f352f204c0d4ec65": "企业机构管理",
	"i18nn_a71629b70679f124": "企业标签",
	"i18nn_558389543b97cb9b": "企业电话",
	"i18nn_699a5577be729dde": "企业联系人",
	"i18nn_8c3434f7035f8d40": "合伙人名字",
	"i18nn_dcc52d1264a9722b": "合伙人电话",
	"i18nn_b11cc38d93b92f1c": "商户认证",
	"i18nn_cef78ab0fe758fbd": "星级",
	"i18nn_3d5ce01c5f6e820a": "营业执照",
	"i18nn_0ee39403e4a88684": "开户证明",
	"i18nn_5acf4b4f6e330dad": "企业认证授权书",
	"i18nn_ff2df2d80b2fdce5": "统一社会信用代码",
	"i18nn_20ba48c609b26cfa": "电子邮箱",
	"i18nn_49f50229125a8a51": "历史记录弹窗",
	"i18nn_c67f820bdcd91a9d": "日志管理",
	"i18nn_1cedf24d2e4fd2a0": "申请",
	"i18nn_d4cfcb57dd2bad40": "企",
	"i18nn_98465bf8d5a398d7": "业",
	"i18nn_e9444828251ef5cd": "法人手机号码",
	"i18nn_6b476b8f0e7c5e8f": "组织机构代码",
	"i18nn_1ba21db0a2e4a389": "企业冻结状态",
	"i18nn_d3a2d4f5a3174de0": "企业冻结状态操作",
	"i18nn_a7d4eccb211cb6d6": "注册电话",
	"i18nn_c0a404d1f7558e6d": "当前状态",
	"i18nn_15585d51ec229bda": "永久冻结",
	"i18nn_4e1c0d9b7f56ed80": "未知状态",
	"i18nn_29fa673774a28f1b": "封禁",
	"i18nn_4b832774a18f4806": "一般冻结",
	"i18nn_70868662be221c2e": "合伙人绑定仓库",
	"i18nn_3f621a26b8218f81": "合伙人",
	"i18nn_650ac628d61a9cc4": "合伙人名称",
	"i18nn_c764976f063a67f2": "请扫描或输入",
	"i18nn_b42006e76f7ab948": "当前扫码历史",
	"i18nn_098f32f54c38955e": "当前扫描的信息",
	"i18nn_2e885d00d595e429": "已扫件数",
	"i18nn_8858352f1fa85f37": "才会自动打单",
	"i18nn_a2984348f5b674aa": "扫描状态",
	"i18nn_eb8407fcf599c7e9": "未完成",
	"i18nn_9b61f70b3098b686": "查询异常",
	"i18nn_92fce53540b740f8": "打印异常",
	"i18nn_6692e48729688461": "当前是否完成",
	"i18nn_13f180f311a15217": "已打次数",
	"i18nn_f84d899216c242b6": "面单解析结果",
	"i18nn_74cb497a2bc3955f": "单号信息",
	"i18nn_9ccb286534db9538": "扫描的",
	"i18nn_4a06d4093d28db10": "当前扫描的",
	"i18nn_2b0a6beffb55e690": "历史扫描的",
	"i18nn_58213be577a36611": "打单结果",
	"i18nn_db2c3e5fbf7e8716": "已打印",
	"i18nn_8b7f3722921430ec": "打印次数",
	"i18nn_4930700ae08b8ce9": "未打印",
	"i18nn_8e889f5d61f01af9": "自动生成批次",
	"i18nn_8c1398ec3f931421": "按当前搜索条件",
	"i18nn_1a48aa3ac5484ce5": "自动生成",
	"i18nn_c3f5d0b14db8e5f0": "只对一票一件的发货单",
	"i18nn_dbea1a6d85f337e5": "各批次的最多发货单数",
	"i18nn_9c83fb25e5b52f0f": "大于",
	"i18nn_5ec7e78c96d85677": "且小于",
	"i18nn_d8acaa9f7fa3780f": "修改收件地址",
	"i18nn_1bab2e4f2c65c40f": "入库打托数",
	"i18nn_3f587beffe3d37f1": "退货处理明细",
	"i18nn_62acd8019074883b": "退货上架",
	"i18nn_aa70fc026e3878ab": "上架时间",
	"i18nn_48e4efd421dad5b7": "退货单号",
	"i18nn_bbb236c847f5d948": "已处理件数",
	"i18nn_99bdc541f0c8e85c": "待处理件数",
	"i18nn_338b82cfb2144745": "处理进度",
	"i18nn_330d028642043f28": "已销毁件数",
	"i18nn_4f0e045b2f905acc": "装箱总件数",
	"i18nn_0e96a24e353951fa": "处理明细",
	"i18nn_c6a187c1e4cb202e": "已上架件数",
	"i18nn_674e806b27aeb130": "待上架件数",
	"i18nn_49ed1205788ae14e": "退货换标信息",
	"i18nn_1e3bd079ae689aba": "上架信息",
	"i18nn_35c6a24828ffaf57": "处理类型",
	"i18nn_80c8ebbab22bee8f": "销毁信息",
	"i18nn_196943f1dd80dcc6": "销毁数量",
	"i18nn_9bf67649c36061b1": "上架库位及数量",
	"i18nn_449b0dd4808c9211": "新增上架库位",
	"i18nn_d3d6315eb562cea4": "原库位数量",
	"i18nn_69783641ed87b618": "新仓库",
	"i18nn_f7c17f0f7ad9ba90": "退货列表",
	"i18nn_b685200d9e29e2aa": "去签收",
	"i18nn_ef712dc1dd7c6dac": "签收照片",
	"i18nn_df8e7d6b41623197": "增值/售后服务",
	"i18nn_64f869f8b24510bc": "退货换标详情",
	"i18nn_c0245eb64fff2671": "全部正常签收",
	"i18nn_cddbe40f9813ab94": "签收时间",
	"i18nn_b1467e06606a8471": "实际签收数量",
	"i18nn_e4342b5989a0c5ac": "入库状态",
	"i18nn_a47468d856f5ab14": "单个签收",
	"i18nn_96441274d7db7020": "签收类型",
	"i18nn_6a538a850c37009a": "正常签收",
	"i18nn_efbb5d5eaaddf3e5": "异常签收",
	"i18nn_5e0724c62e0a56d1": "签收件数",
	"i18nn_51cfacfe7320fd10": "确定签收",
	"i18nn_e4713d4209db1f88": "快递面单账号",
	"i18nn_a9dc5000637ff647": "是否可用",
	"i18nn_1cacee9f09138a3d": "三方渠道",
	"i18nn_ade48dee835e25c0": "签名",
	"i18nn_dbcffdf31e86d2d8": "排序",
	"i18nn_8d337267addff5d5": "余额查询",
	"i18nn_eb763bfab5d5a930": "快递面单明细",
	"i18nn_21b22a8018f3cba2": "批量下载面单",
	"i18nn_5b7f75f3443354a2": "提交打单时间",
	"i18nn_afe446fb3a8e3e40": "清空订单号",
	"i18nn_50dcfc837d43b873": "精确搜索",
	"i18nn_8004917a89ed807b": "重",
	"i18nn_3d6d2d670fa63b7c": "渠道",
	"i18nn_316e16926411681a": "渠道商",
	"i18nn_3ad90a648c6bfa99": "服务级别",
	"i18nn_310c8b0f4df16e08": "是否下单",
	"i18nn_82b1c8e36ef45bb8": "是否出账",
	"i18nn_728bf6a36a68e566": "打单进度状态",
	"i18nn_c6b76f2746d7dc6f": "打单流水号",
	"i18nn_e5c3fd867ea4c8e6": "账号编码",
	"i18nn_ab6994d16b9b4366": "运单费",
	"i18nn_673767ab6ddb37cd": "运单费描述",
	"i18nn_d353bf2887ca0603": "服务费",
	"i18nn_0deaa0a62ac136b6": "销售合伙人服务费",
	"i18nn_5f6b842a6ff748ab": "出单时间",
	"i18nn_e4f53ced9388c487": "发货轨迹查询",
	"i18nn_526831ff87464f45": "快递面单",
	"i18nn_75087cd54a97247c": "运费查询",
	"i18nn_79de6d14b424271b": "导入运单",
	"i18nn_7cdf6a7f9798699b": "添加运单",
	"i18nn_e020fbe62b0c9833": "渠道代码",
	"i18nn_1f4c2f1c8fcb66b7": "订单日期",
	"i18nn_0a84f4853ab4d1ed": "运单费用",
	"i18nn_037f5845b7a88b16": "费用详情",
	"i18nn_b98aefdd52a12eee": "包裹信息",
	"i18nn_b7367a5ccf9facf3": "发件人",
	"i18nn_ab3ce045a4241cb0": "收件人",
	"i18nn_7b8ed340400a6e2b": "名",
	"i18nn_549a44bae725f4a2": "姓",
	"i18nn_ab653780491c0d4b": "公司名",
	"i18nn_8b90c4cfdf339af8": "二字码",
	"i18nn_6b57a5faf337b87b": "未知",
	"i18nn_a9cbcfc68126f75a": "商业地址",
	"i18nn_af200a78a2a44559": "住宅地址",
	"i18nn_6a4dc4a49612041e": "校验地址",
	"i18nn_479ea0356ec72989": "发件人信息",
	"i18nn_2bfaf322a81c4e8c": "选择地址",
	"i18nn_f1e789e4700e15e3": "选择发件人地址",
	"i18nn_79dc0cb30753ec26": "发件人名称",
	"i18nn_19252a10a7c4755e": "面单尺寸",
	"i18nn_45b0c5bc56871f57": "查询费率结果",
	"i18nn_ff89821d70424147": "选择此渠道并保存",
	"i18nn_ea74b89efa9dbdb8": "查询费率",
	"i18nn_1ffc6ecc6d0685b6": "确认收货时",
	"i18nn_107dd8e36bc7c397": "是否现场收货",
	"i18nn_7e06b928579ba3cd": "新增编辑",
	"i18nn_ab546359d111c7f4": "导入运单模板",
	"i18nn_48e17cdf3460fb7f": "最小",
	"i18nn_aa7237f43c313974": "参照",
	"i18nn_b6a8725d78c586be": "未上线快递运单",
	"i18nn_8f89632c68d3b9a2": "延期天数",
	"i18nn_8b9618de2e85645e": "快递号",
	"i18nn_04ea6d4ff8e5c4bb": "最多",
	"i18nn_d88844033912c1fc": "报错信息",
	"i18nn_2678054e53eeefdd": "报错编码",
	"i18nn_094903a0e1dd5849": "物流轨迹",
	"i18nn_032abee95f72da18": "其他信息",
	"i18nn_eca5c29ad1a845c1": "目的地址",
	"i18nn_93ba6023a4128d75": "托运人信息",
	"i18nn_e29ea9e7cb029d6c": "服务信息",
	"i18nn_847f063538ae47c2": "简称",
	"i18nn_ca9b892a79dfa7ee": "服务命令信息",
	"i18nn_ceffcc237c2608b9": "合计",
	"i18nn_50a95a5f20c112cf": "包裹标识",
	"i18nn_38482e62a277f304": "最新状态详情",
	"i18nn_5360545085fcff1f": "其他详情",
	"i18nn_a3a09445e8b04a4f": "授权测试",
	"i18nn_fc7197a70838eeeb": "授权",
	"i18nn_06ef5de26edd9e88": "交货信息",
	"i18nn_d721bca263c00924": "日期节点",
	"i18nn_19ce8e68aa3bde0c": "应付款管理",
	"i18nn_70e8592f684aeb7d": "费用项目",
	"i18nn_1d4b6488790ad72c": "付款方",
	"i18nn_2306f9f2f9197dd4": "账单号",
	"i18nn_9f105a36ff4efa98": "收款方",
	"i18nn_589bc0f7e4769a3e": "库存趋势统计",
	"i18nn_5c98d407627f383d": "账单汇总",
	"i18nn_5203265398bc2ae1": "账单明细",
	"i18nn_0c306145232cff32": "汇总统计图",
	"i18nn_e3a9b63372854968": "客户数",
	"i18nn_da35456de5403862": "合计金额",
	"i18nn_726d411f60e6631d": "合计客户数",
	"i18nn_0d35fa57b3aab0f7": "按客户汇总",
	"i18nn_14b9b51812811e15": "总费用",
	"i18nn_194d733acdedb737": "一件代发操作",
	"i18nn_9695030363addb97": "一件代发出库",
	"i18nn_56b48b3b6de43bde": "分批转运入库",
	"i18nn_e69eafd9946571ed": "分批转运出库",
	"i18nn_636d7192b222277c": "附加费",
	"i18nn_e19ca5d2e0c83887": "查看汇总明细",
	"i18nn_ac53fd8c761abf7e": "汇总明细",
	"i18nn_2f281726defd00db": "查看账单明细",
	"i18nn_cf84be5a2f8c584b": "按仓库汇总",
	"i18nn_07d4648dfc7810d5": "仓库账单",
	"i18nn_45eae388d108d4fe": "操作记录状态",
	"i18nn_c2c4e3a31c61dc7f": "记录数",
	"i18nn_6e9c4e33131b18a7": "申请数",
	"i18nn_723f3d8f11c640b1": "托盘最大承重",
	"i18nn_c65deeaa92c96268": "总金额",
	"i18nn_5ffda681afa71861": "单件",
	"i18nn_621091be3bb350a9": "单价体积",
	"i18nn_c726626501fc8bc1": "导出明细",
	"i18nn_c11109ccca2c19cf": "账单统计",
	"i18nn_48c855b33061cbb9": "按类型",
	"i18nn_d099c84ccfd8a4bb": "按客户",
	"i18nn_428b0a18700aa88e": "按类型汇总",
	"i18nn_87f891127ffff1f8": "仓库货量统计",
	"i18nn_051e757f5c7e8026": "仓库货量",
	"i18nn_37181c74559db182": "订单",
	"i18nn_9954ce6437e66f41": "月",
	"i18nn_bb3d670df5390f32": "个月",
	"i18nn_ac2c5e612fb71dbb": "日",
	"i18nn_c232abd55b5719fb": "按月",
	"i18nn_fadfaed787cc4acf": "按日",
	"i18nn_52b2ff9675f77b3f": "工作台",
	"i18nn_a967bc023b5157c4": "本月订单统计",
	"i18nn_de44e8c1830a426c": "货量",
	"i18nn_ee110e3c7304b62d": "其他服务",
	"i18nn_72917c44e787864a": "报价",
	"i18nn_55806c9c59b87e47": "询价",
	"i18nn_3b4e0cc90e264c6f": "提货",
	"i18nn_b5f81f005ec90ee8": "海外仓报价",
	"i18nn_d8f295864833fc57": "卡车尾程询价",
	"i18nn_19918f72bcdca413": "空柜预约",
	"i18nn_c0eb5cb77d8024b6": "文档",
	"i18nn_fdb7b865dfa87747": "资源库",
	"i18nn_54153ccc0c756e9f": "帮助文档",
	"i18nn_8861e53cbc04161f": "海外仓",
	"i18nn_b5e2fe92fe54ace2": "总部电话",
	"i18nn_ab0778ba44a8f3c1": "卡车部电话",
	"i18nn_e0a806b3165119e6": "微信公众号",
	"i18nn_0f63cc67b9017a81": "微信扫一扫关注",
	"i18nn_c12ab070fe3cbeac": "微信扫一扫添加",
	"i18nn_f600a6d1dbcfce47": "微信号",
	"i18nn_b33f004fe453ef3b": "客服手机",
	"i18nn_8292010ff4193c50": "微信",
	"i18nn_a4365f7edaab7453": "系统优化反馈",
	"i18nn_f9f51c08f4fadac8": "打单控件",
	"i18nn_e65143a1620ea3df": "其他链接",
	"i18nn_411e2be4b5bc7080": "互易物流",
	"i18nn_d59bf3e617c83b91": "互易仓管理端",
	"i18nn_f5924c3c9ec8fb0b": "线路",
	"i18nn_6d2294fdbfbb6b85": "端",
	"i18nn_7d6f758a93cb33b5": "公告",
	"i18nn_1595bdab4d7bf7c2": "当日出库情况",
	"i18nn_396cb19960e15651": "总单量",
	"i18nn_16d3ec8d8ecbac75": "已超时",
	"i18nn_7d814d9a1e592965": "未处理",
	"i18nn_a22056d71c960f0e": "拦截",
	"i18nn_fd445581cff561bf": "增值服务工单",
	"i18nn_dd697b8fa767da8b": "待客服处理",
	"i18nn_42d98013e99e7380": "待仓库处理",
	"i18nn_4f5a6083d34bdccd": "共享文件",
	"i18nn_af1a2cc986597e90": "申请入库",
	"i18nn_15aeae6cfcd5725a": "收货数",
	"i18nn_7689a837889ed035": "快递运单",
	"i18nn_28c0ca2da0ad71dc": "未上线",
	"i18nn_221fcaddc4cb040a": "快链接",
	"i18nn_ca43d9909e9fcd5c": "确认收货清单",
	"i18nn_3d2dd59c0c3efda2": "收货信息",
	"i18nn_eced406a05a38b33": "非现场卸货",
	"i18nn_e8e55e3612f95a85": "现场卸货",
	"i18nn_70620f04c8129aa1": "收货时间",
	"i18nn_b22e04c70b19a9eb": "已上传的附件",
	"i18nn_d6e8213d96d6018d": "保存收货清单",
	"i18nn_40859a22ab42e313": "到仓库日期",
	"i18nn_e8ee69190e3c6ddf": "批量上架",
	"i18nn_132d087e57aebb7d": "第一步",
	"i18nn_160393458e0a8b07": "查询待上架数据",
	"i18nn_9c17e9aea46e0c8a": "第二步",
	"i18nn_d2acd7e99625d37f": "提交上架数据",
	"i18nn_42b17a0bf7d4ad0b": "上架日期",
	"i18nn_db745f83cbb0a3f6": "上架库位",
	"i18nn_deedfcad4cbb3a00": "上架数量",
	"i18nn_6e961a14e09d9784": "消息通知列表",
	"i18nn_b40debd910bb74f2": "通知对象",
	"i18nn_2528332bfcac14b5": "消息类型",
	"i18nn_db1f861ffd10f028": "通知标题",
	"i18nn_bd0db0ad240cc83b": "内容",
	"i18nn_7df9df0664f60cd8": "内容查看",
	"i18nn_741bf985d2d42039": "消息通知管理",
	"i18nn_0cc0c5206402c130": "合作方式",
	"i18nn_ea519c59cd7bec7d": "托盘标打印",
	"i18nn_55793d80c64c2dc7": "系统配置",
	"i18nn_fcd37bf760bc15c3": "菜单管理",
	"i18nn_6218b3fea806918d": "主界面",
	"i18nn_577ba81af19408f2": "按钮",
	"i18nn_01f47f2bd0c79f92": "菜单",
	"i18nn_fe054fb92fbd6690": "菜单名",
	"i18nn_f3e476303b47045b": "一级",
	"i18nn_8e5aa3f56700e4b0": "二级",
	"i18nn_f49f0068c43d8f20": "三级",
	"i18nn_af76f74cc6174a6d": "英文名称",
	"i18nn_a5c7073db104f92e": "是否隐藏",
	"i18nn_d79e2ed6a2fc66fd": "显示",
	"i18nn_b978a8259ecfca8f": "隐藏",
	"i18nn_ba14fba14998cbd6": "菜单信息",
	"i18nn_f18bbc3b19a8d345": "上级菜单",
	"i18nn_f241d2c8737ad7ba": "请选择上级菜单",
	"i18nn_a6356aab65f8178c": "菜单名称",
	"i18nn_32fc9710fbb0291b": "请输入菜单名称",
	"i18nn_b7004d634b60c827": "请输入菜单英文名称",
	"i18nn_4af676d69de62f2b": "顶级菜单",
	"i18nn_6eda3e16989e48c7": "必须含",
	"i18nn_973a0a2f334d7703": "便于区分是否是仓库菜单",
	"i18nn_61e631acdbe6900b": "请输入排序",
	"i18nn_ec1a4c45b0b20622": "权限设置",
	"i18nn_e40dab1482ecbe86": "权限组",
	"i18nn_76c9af09324c78c7": "登录名",
	"i18nn_55e9328f2e63cea3": "上次登录",
	"i18nn_6c4ee02a7ce0e0be": "累计登录",
	"i18nn_c4418aa4798740f5": "权限配置",
	"i18nn_7b82edfeee93b9f7": "编辑管理员",
	"i18nn_67cfb454fd187462": "表单提示",
	"i18nn_476a108ec1e1ddc6": "支持添加一名后台管理员",
	"i18nn_0b271bc5f9a73661": "设置其后台登录用户名及密码",
	"i18nn_faeeaa921e4fcbf8": "但不可登录网站前台",
	"i18nn_288fab15c7304b09": "管理员必须下属某个权限组",
	"i18nn_15b2d542d7f1043a": "如无权限组选择请先完成",
	"i18nn_43c65725d5d64ee9": "添加权限组",
	"i18nn_f88c31ed3971ca4d": "步骤",
	"i18nn_db7aa54d2e90e5b8": "请输入用户账号",
	"i18nn_cf9cf589607ad707": "请输入真实姓名",
	"i18nn_719aa883348659cb": "用户密码",
	"i18nn_ecf43f86317f199f": "请输入用户密码",
	"i18nn_6a76fe48d020d59b": "请输入确认密码",
	"i18nn_f7043fa3372747f8": "所属权限组",
	"i18nn_f23e001dbc74dcba": "请选择所属权限组",
	"i18nn_e325beec788727cd": "性别",
	"i18nn_557796858a347c06": "男",
	"i18nn_b1749374a7524a59": "女",
	"i18nn_b9ff7fc2f7dfa2c7": "请输入邮箱",
	"i18nn_2a27e61375e7fea8": "出生日期",
	"i18nn_e22d367ea23d6fae": "详细住址",
	"i18nn_15f4f186d9700760": "请输入详细住址",
	"i18nn_dddf8b41201d952c": "隶属部门",
	"i18nn_c6703669fc78ffe6": "请输入隶属部门",
	"i18nn_d6a54f83ce3cb04a": "职位",
	"i18nn_6bbdffcf9d86218c": "请输入职位",
	"i18nn_c14037ef764cfeae": "备注描述",
	"i18nn_4eed7667ef911a72": "请输入备注描述",
	"i18nn_5f01d91d44f46ffb": "所在部门",
	"i18nn_0b6ece8a034c7796": "可添加一个权限组",
	"i18nn_df812a0da6bcd476": "并为其命名",
	"i18nn_7a9be273020481b8": "方便添加管理员时使用",
	"i18nn_d4573844836678d9": "可在标题处全选所有功能或根据功能模块逐一选择操作权限",
	"i18nn_124961995dbe464b": "提交保存后生效",
	"i18nn_886cc0a2040c65a4": "请输入权限组名称",
	"i18nn_f13b5a3276612c1e": "发货地址",
	"i18nn_ed66f1feaedb8f00": "其他费用",
	"i18nn_4bc20b08caf342f6": "进行中且出单未成功",
	"i18nn_9e724924dd8b83dc": "编辑服务类型",
	"i18nn_22f9df183cbbca11": "售后记录",
	"i18nn_4dc6f04018e0f73b": "问题类型",
	"i18nn_9666c59aedbfa70e": "模块类型",
	"i18nn_3af42243b3f54e9b": "创建方",
	"i18nn_05682dbef5eeb0a8": "客服",
	"i18nn_f4c5829c71fcd8bf": "进度节点数",
	"i18nn_627df7b14df32cdb": "申请人",
	"i18nn_dee186b860d4cb4f": "跟踪账号",
	"i18nn_10a6a2a6124bbb9a": "查看进度",
	"i18nn_8f8b0799ae9827e0": "便于客服对接",
	"i18nn_c62590f388b2ce71": "服务描述",
	"i18nn_466677324278a9a1": "概述",
	"i18nn_805c8f776e461e39": "详细描述",
	"i18nn_ef8d08b45c898979": "更新跟踪账号",
	"i18nn_2fc1c1e30d69c8c7": "服务进度",
	"i18nn_3c7beea052b8230c": "新增进度",
	"i18nn_354482714c59064a": "更多内容",
	"i18nn_0f6916ca53218662": "新增服务进度",
	"i18nn_b95047968d1358d4": "进度状态",
	"i18nn_786280a019b8a1b7": "服务详细描述",
	"i18nn_caf2eff6e258a06d": "服务记录",
	"i18nn_1ba6fbd520bc16df": "增值服务记录",
	"i18nn_6c6c882d02a35cb3": "增值服务新增",
	"i18nn_ec153a16f143e276": "代理",
	"i18nn_0512b13b7e3eee11": "打印流水",
	"i18nn_b8906d9824b52a7b": "留言板",
	"i18nn_ac3f40a45db7c05a": "标签数",
	"i18nn_2f9954db5ba06447": "拣货类型",
	"i18nn_7b1927e9f5c2b1a1": "拣货时间",
	"i18nn_6fba545981478392": "备货完成和出库完成图片",
	"i18nn_6c1a30d60ca12a5f": "备货完成的图片",
	"i18nn_8139c1fa7c3b121f": "出库完成的图片",
	"i18nn_3b9fcbeb6011f7eb": "全部自动拣货",
	"i18nn_d6a5d5c63900e166": "自提单信息",
	"i18nn_4429a015eeb36653": "托盘数量",
	"i18nn_4f1444e2d3ca67a2": "扫码打印附件",
	"i18nn_0b7094d813283593": "自提单明细搜索",
	"i18nn_2cd83801592ec2f1": "待出库时间",
	"i18nn_5188115ad64c0874": "操作附件",
	"i18nn_b3cc9e7df0c87798": "备货完成附件",
	"i18nn_82dd2957184fa298": "出库附件",
	"i18nn_3fae3719c2e02ccf": "查询自提单明细",
	"i18nn_55a13021a8604d26": "留言",
	"i18nn_305b4f079b790079": "上传出库完成图片或附件",
	"i18nn_03a135502cffa9fb": "标签总数",
	"i18nn_d3a9b09bd2f020d8": "上传备货完成图片",
	"i18nn_cc7fcae614d9722f": "已上传的备货完成图片",
	"i18nn_1fe8e8eece311626": "备货完成图片",
	"i18nn_5239f60a9c2145f7": "已上传备货完成图片",
	"i18nn_33ba2cabce60048d": "请扫描或输入发货编号",
	"i18nn_53f8f97f65af0a5e": "自提类型",
	"i18nn_63b637700eeaf5a3": "只打印",
	"i18nn_9e8e8134d43fee59": "和图片",
	"i18nn_fd13de5896760ebc": "查看已打未打",
	"i18nn_71955b9b4416485f": "锁定库存",
	"i18nn_2fc069db0549ff40": "库存详情",
	"i18nn_46ed817ece2a5540": "已锁定库存",
	"i18nn_f1c73718e1c8fcb7": "入库来源",
	"i18nn_1829e02aab962bd5": "收货编号",
	"i18nn_c62b0d9279f5e7cd": "第三方库存信息",
	"i18nn_27f0ca34ddd11deb": "原库位数据",
	"i18nn_028e4de6cb4de767": "新库位",
	"i18nn_11e354526dca680c": "移库数量",
	"i18nn_f3828f9b73412e7d": "自定义",
	"i18nn_d5d972e6063a3180": "长度计算",
	"i18nn_6d8f9b0c398319ff": "是否含电池",
	"i18nn_824815ceb3f6c6db": "已选择",
	"i18nn_1ff0f0c0745be010": "已显示",
	"i18nn_f7fac56017a0ca84": "更多请",
	"i18nn_650abf9b436446ba": "选择库存",
	"i18nn_2b2633c44fbf02b8": "出库数量",
	"i18nn_67d0b6c398583e40": "锁定数量",
	"i18nn_6c86c295863f5218": "收货单号",
	"i18nn_2140e9438c2d1633": "解锁",
	"i18nn_b6ff7f85ee235684": "库存解锁",
	"i18nn_467c8054005f46e1": "解锁数量",
	"i18nn_97d0138b2632e9c0": "正为增加",
	"i18nn_7366500a28c55788": "负为扣除",
	"i18nn_6187084ee2cbb89b": "扫关联编号换新",
	"i18nn_911974004d5d7e10": "单号类型",
	"i18nn_0d17574a2e68d656": "扫码换",
	"i18nn_9700e172aec17e89": "拆分编号",
	"i18nn_bf64e6a6859f2692": "分拆完成时间",
	"i18nn_87daab1c4e709403": "查看分拆后的",
	"i18nn_baccbf2a2e9b5938": "分拆完成",
	"i18nn_9a2003ebc60baac4": "回退",
	"i18nn_6726a8914c9083a3": "分拆后的",
	"i18nn_2a1d7a54b0818a4b": "实际分拆件数数量",
	"i18nn_e3ad85bef8be1a4c": "拆分上架",
	"i18nn_2832cf1e501780d2": "上架进度",
	"i18nn_a6b5087ca10391ab": "拆分信息",
	"i18nn_588fde1c3de565ab": "统计图",
	"i18nn_c1b676e21af630d2": "总库存",
	"i18nn_2c5ae1686e1c4aa2": "趋势统计",
	"i18nn_bced63832451e050": "库存实时查询",
	"i18nn_2f47c3d499a6e740": "订单统计",
	"i18nn_9c7e841fa06de079": "出库报表",
	"i18nn_63ee7265156e13ac": "统计方式",
	"i18nn_f551589093f4bbfb": "商品关键字",
	"i18nn_bb78423a37ae875d": "单个体积",
	"i18nn_30d971603621c78d": "单个重量",
	"i18nn_a7162d05268c0fe9": "隐藏所有库位",
	"i18nn_22905878d9b15acc": "显示所有库位",
	"i18nn_28b7dbfbaf281deb": "显示件数",
	"i18nn_4415614666ad24cb": "显示库位值",
	"i18nn_62f1c7e4748e6c28": "区域",
	"i18nn_8f9184cef1644784": "总库位数",
	"i18nn_a5606fce0b131a1b": "有货库位",
	"i18nn_b4c5f9946aeeaf70": "货总数量",
	"i18nn_4e0d087d88156795": "货架",
	"i18nn_750722ca673dfa57": "托位",
	"i18nn_52e1fa389a9ced75": "控制配置",
	"i18nn_762cf41ecbb3ee58": "执行",
	"i18nn_243a6ecd4e231c9c": "启动",
	"i18nn_cbf03affa6d6ca3d": "字典配置",
	"i18nn_2fb94604c67cb05c": "调度配置",
	"i18nn_b0b291800a22292a": "短信配置",
	"i18nn_8ed9142b991183f8": "所属系统",
	"i18nn_70b9676c2dd63579": "执行代码",
	"i18nn_05ee04f297da360d": "频率",
	"i18nn_043d67a736a50795": "运行时间",
	"i18nn_eacf3e797aa9647b": "最后运行",
	"i18nn_06c0f8db93cf78c4": "堵塞",
	"i18nn_714305346b0dbfb6": "提醒给",
	"i18nn_b8621f081ba09c7c": "请输入所属系统",
	"i18nn_75dcca76e9759f0f": "执行对象",
	"i18nn_c15ee49569aa67a3": "请输入执行对象",
	"i18nn_b8f5a409163c99eb": "执行方法",
	"i18nn_fcd432862f71ff63": "请输入执行方法",
	"i18nn_3b1b41a5d269d87f": "执行类型",
	"i18nn_df9911ca9c694630": "单行",
	"i18nn_cde07561d01ba5d5": "阻塞",
	"i18nn_5c720579cbdce8e3": "并行",
	"i18nn_75bff8ec707c8591": "非阻塞",
	"i18nn_f0bb599bc05cd23e": "执行器类型",
	"i18nn_92388e2b578b2316": "远程",
	"i18nn_c82f4615f444bde6": "执行频率",
	"i18nn_8441f1f7f6d6954d": "表达式",
	"i18nn_7aae192c0ef52155": "通知给人员",
	"i18nn_fc2a95852c846bae": "请输入隶属人员",
	"i18nn_01e460e0c10324aa": "选择用户",
	"i18nn_43753ad5329348d6": "关键字",
	"i18nn_24b9c5e694c18344": "昵称",
	"i18nn_bf55171bd3928132": "所属主账号",
	"i18nn_7f28764b786320f9": "企业认证",
	"i18nn_09737906ddb3e95e": "合伙人认证",
	"i18nn_16e71584163b9bf7": "合伙人姓名",
	"i18nn_daa52e38a6df288d": "所属事业部",
	"i18nn_475412859bb2048e": "用户详情",
	"i18nn_5841c2904805147a": "中文名称",
	"i18nn_b123d7a0a2181bb2": "所属区域",
	"i18nn_5f24dcf49cbc6b69": "库管",
	"i18nn_f7db73b2f3f1f0bf": "所属公司名称",
	"i18nn_c77a882ff6fa16b9": "面积",
	"i18nn_bd16e84498ab145f": "平方英尺",
	"i18nn_4c14f792298e4e94": "字典管理",
	"i18nn_2f8d01a5e86ae412": "刷新缓存",
	"i18nn_907e5f417e2967fd": "请选择类型",
	"i18nn_6e7a6111b87ee282": "选项描述",
	"i18nn_eca4e9acff89221b": "选项值",
	"i18nn_d0acc6aa5293bb22": "根据类型",
	"i18nn_1482a8297c1c085e": "查",
	"i18nn_15d0f5dfea526482": "询",
	"i18nn_596f184984001117": "父类型",
	"i18nn_ca93d1765ac4dd1d": "父选项值",
	"i18nn_b5ff6d3c4d78b76b": "修改时间",
	"i18nn_89beeeac1bb28e32": "设为默认地址",
	"i18nn_dba7512a76fd42d7": "用户管理",
	"i18nn_b3a5f4352ef652b5": "可模糊",
	"i18nn_9423dd99b6cd497d": "修改手机号",
	"i18nn_ebacdd8daa50b7c3": "导入弹窗",
	"i18nn_2f647e039073d7d4": "请选择国际区号号",
	"i18nn_ea411a18dd6aface": "事业部",
	"i18nn_66ed6b56e3438d24": "新增弹窗",
	"i18nn_ca8b02c7ab9cc5c5": "新增用户",
	"i18nn_4d20e95ad039b9b3": "用户信息",
	"i18nn_94a8da065245abe4": "账号类型",
	"i18nn_aef488a45f17e7cd": "请输入登录账号",
	"i18nn_9ee02e0fc3a8784e": "建议邮箱",
	"i18nn_7ef9e1dfbf42bba6": "新增用户和企业商户信息",
	"i18nn_ff6570b63cd14f9d": "只新增用户信息",
	"i18nn_f8c2fff93a98c5d9": "可新增企业商户信息",
	"i18nn_4b401066ab552393": "企业信息",
	"i18nn_b164d8e831bc84df": "请输入企业名称",
	"i18nn_f58f4664e290c476": "请输入统一社会信用代码",
	"i18nn_304864e12f05f734": "更新人",
	"i18nn_395edce59b8bf304": "仓库管理",
	"i18nn_1ed7cdc1233483b5": "唯一不可修改",
	"i18nn_9426df1162e7b5fa": "头图",
	"i18nn_1ddfdcf5b21699d5": "仓库新增",
	"i18nn_526f935030526185": "展示图片",
	"i18nn_f3f39bae61e74a8c": "库位图形化",
	"i18nn_f1720b14c8b2293a": "入库托数",
	"i18nn_7ec061870e40cbf0": "预约修改",
	"i18nn_922e8b4c9ee71753": "预约受理",
	"i18nn_1c99f4e064cb3b85": "上传收货清单",
	"i18nn_4e4bec1fd140fcf7": "入库箱数",
	"i18nn_9d3249981866af60": "货物来源",
	"i18nn_43f1b30ab1c1efe6": "打印收货单",
	"i18nn_1c50833bf647e4ef": "设置库位",
	"i18nn_4d4a22cd70f7c145": "复核收货",
	"i18nn_16a4f2646be08433": "去设置库位",
	"i18nn_a99f3bb60a3c008e": "托盘标打印预览",
	"i18nn_1699795038a5e623": "及收货清单",
	"i18nn_018cea07957567ef": "复核及收货",
	"i18nn_65ff76c08629a2c8": "复核箱数",
	"i18nn_913e044950ff558b": "外箱体积",
	"i18nn_da669f87b133d0ae": "以逗号分开",
	"i18nn_32e717822a28001b": "打印转运清单",
	"i18nn_5f20abb4ee2efb33": "上传拣货照片",
	"i18nn_885b56265946eacf": "上传提货单据",
	"i18nn_a2b0e3d5bbc2591e": "备货",
	"i18nn_f8e4e5693c8edc82": "操作完成",
	"i18nn_7fe010a57f969108": "提货单据上传",
	"i18nn_6026969aae4ce7ce": "文件上传",
	"i18nn_baa5d4309421d040": "拣货完成日期",
	"i18nn_1ddf8fca05634d4f": "请完善转运货物拣货时实际使用的耗材信息",
	"i18nn_5bbf6928eab32e30": "是否二次拣货",
	"i18nn_8ed10bfaa8885638": "照片上传",
	"i18nn_7d9664098f76ed4f": "详情查看",
	"i18nn_1e262c44c1504e10": "提货日期",
	"i18nn_f7e99b8c203e2b07": "订单详情",
	"i18nn_b2d95e887d5425b7": "预约详情",
	"i18nn_92f2ebbe14794f38": "一件代发详情",
	"i18nn_148c3451c7861e8f": "批次",
	"i18nn_ce764b74be1b9a90": "工单",
	"i18nn_83b1b50eb00a9fb6": "第三方单号",
	"i18nn_994551e2ae3176ca": "明细列表",
	"i18nn_74c2b69060be9bfe": "收货清单列表",
	"i18nn_859fd2c643fde5a3": "客户端菜单管理",
	"i18nn_7803ff939c276311": "菜单名英文",
	"i18nn_893f64784c70bd2a": "是否显示",
	"i18nn_d8917510efdc82c9": "子账号管理",
	"i18nn_4b2d7b585777fa99": "主账户",
	"i18nn_2489ac08975334e4": "客户或公司名称",
	"i18nn_f5765bd7956321f2": "查看密码",
	"i18nn_8a2be54a2e609dcc": "子账号账户",
	"i18nn_9ea9185268cf2519": "是否自动账号",
	"i18nn_e78b4579ca11c47f": "子账户简称",
	"i18nn_8664edb3936db8b0": "是否自动生成子账号",
	"i18nn_d02053ea2ba9ff5d": "客户菜单配置",
	"i18nn_7732c57a7fb0fe19": "子用户",
	"i18nn_f34439ef133ee53d": "库存修改",
	"i18nn_888c8eeffccf0077": "显示零库存",
	"i18nn_9db83859e4338779": "库存编辑",
	"i18nn_3d2f43b3ef9fbac6": "流水",
	"i18nn_e782953406e15421": "账号模板",
	"i18nn_13def55da64470b3": "生成发货批次",
	"i18nn_8088a92b8b5f8f4d": "收货仓库",
	"i18nn_3a1ffab7c81c5d22": "发货单类型",
	"i18nn_ce30b55b8325b8a2": "输入订单号",
	"i18nn_6fb0b3e58872e910": "多个订单号",
	"i18nn_9abd60b54d001c53": "渠道公司",
	"i18nn_68ab250470bd3e4f": "选择批量打单",
	"i18nn_b74df69000311063": "批量打单",
	"i18nn_0a56058bd549a476": "修改发货地址",
	"i18nn_ba0dd389f81225fa": "面单批量打印",
	"i18nn_5cce32d9f8fcc8c9": "面单单个打印",
	"i18nn_e1384ed0c924041b": "流水查询",
	"i18nn_d2ad76d380f6aaf1": "一件代发批次",
	"i18nn_d50e79aeea569249": "发货地仓库",
	"i18nn_8bf7c69409f84506": "收货地仓库",
	"i18nn_924c113ef86babef": "已打面单",
	"i18nn_8afe1671ff878bf1": "审核数量",
	"i18nn_46c4526201c8f1cb": "待出库数",
	"i18nn_e45682a295cc8d27": "已提取数",
	"i18nn_fde6698b89672ed0": "退货数量",
	"i18nn_637b82edf600c14d": "暂停数量",
	"i18nn_33104bbb28852199": "取消数量",
	"i18nn_6b55cfb713e28ca0": "拣货单",
	"i18nn_743322012e31b427": "汇总",
	"i18nn_2263ad3ad61335cd": "汇总和明细",
	"i18nn_739d2c266c165132": "快捷打单",
	"i18nn_1ac7d281d8ceaa24": "快捷出库",
	"i18nn_40e32dfaf7721550": "拣货单明细",
	"i18nn_1432b6fd3fd542c5": "扫码打单",
	"i18nn_254b1f2eaf7007a1": "合并下载附件",
	"i18nn_fe5694a607ddfbd3": "批量",
	"i18nn_3928eb88a75a5cd9": "前端",
	"i18nn_bac349e62b3dcc5e": "打印本地",
	"i18nn_4da5b63ad4c676d2": "打印面单并自动拣货",
	"i18nn_4cca3d88465a08a1": "输入订单号搜索",
	"i18nn_f0a2e1e1c93ee971": "查看打印记录",
	"i18nn_cc94f80dfacda656": "移出该批次",
	"i18nn_1fc7dfdc5251f81e": "当前显示",
	"i18nn_7a832466d6f61c60": "快递类型",
	"i18nn_156e7ed12f5d4b35": "选择快递公司",
	"i18nn_851730a3963df484": "只打印汇总",
	"i18nn_74225b7a0edee665": "打印汇总和明细",
	"i18nn_f9f381e5b5ff1f88": "合并并下载面单",
	"i18nn_1bd7adf3f641e2d5": "是否下载原文件",
	"i18nn_a73f09a050db50e5": "请扫描或输入拣货单号",
	"i18nn_b0929817d371ed27": "总单数",
	"i18nn_89ecaa622b09b1a0": "可扫描单数",
	"i18nn_dd05e02f1d454c5c": "已扫描单数",
	"i18nn_3f907fa53cbe61e3": "查看已打",
	"i18nn_494ffc9644d87900": "未打",
	"i18nn_7327bf9113497eb5": "单条模式",
	"i18nn_e4300f029469a8c2": "多条模式",
	"i18nn_aae671b49dde2bba": "基础信息",
	"i18nn_2bc1d5d63b619188": "入库货柜号",
	"i18nn_dfd180c9fc784ac6": "收件人地址",
	"i18nn_d7786305cfd1b23d": "仓库签收",
	"i18nn_9c3b3985298f22f1": "关联号",
	"i18nn_0b2370aa8d088368": "异常件上架",
	"i18nn_eb366645fe3f7a20": "异常件信息",
	"i18nn_80702accb94bb27d": "港口",
	"i18nn_10bc8a2b45aa5636": "服务反馈",
	"i18nn_871a4f15148cc1b3": "保存附件",
	"i18nn_b799f516e3940e3f": "装箱单类型",
	"i18nn_695d318c3b1ae5ea": "全部确认",
	"i18nn_43c20b6ea93b4b20": "是否新品",
	"i18nn_3aea187873780514": "单条确认",
	"i18nn_b46afeb645c05b06": "全部复核完才能确认收货",
	"i18nn_ce985bcb5f28b6de": "收货上架",
	"i18nn_560595129574513f": "处理日志",
	"i18nn_7d05cde2e8464496": "增值服务列表",
	"i18nn_b4c1d75c7228b765": "改为直接提交",
	"i18nn_34026f16e115b40a": "处理结果",
	"i18nn_a2d0e11a2b9f07d5": "指派给仓库",
	"i18nn_b9ee0408e39cb451": "建议按照数字字母编码组合",
	"i18nn_e729e61f9c82621b": "且保持唯一",
	"i18nn_93425399dd0a7b54": "建议按照",
	"i18nn_5d819cd0462cbe89": "数字",
	"i18nn_dfa40fe9cb22797d": "字母",
	"i18nn_1786e81d2ba2694a": "组合",
	"i18nn_d9df5070c410e81d": "系统将根据",
	"i18nn_418709fadc1e6c54": "生成标准",
	"i18nn_cef61d13aefc83cf": "仓库将按标准",
	"i18nn_ebcd402850731325": "导出全部时",
	"i18nn_9b303c33a756ca45": "不包含",
	"i18nn_a8e47905b55a7fd5": "一维码图片",
	"i18nn_083b4e410bb4174e": "如需导出一维码图片",
	"i18nn_8888f6735d4b5717": "请导出当前页",
	"i18nn_45d1826d9048ca94": "菜单搜索",
	"i18nn_bc9dde3526b4dcd0": "搜索并选择",
	"i18nn_e81d5203b18447dc": "主题配置",
	"i18nn_e6c086cadd8732c6": "活动名称",
	"i18nn_9c5a41d10078e21e": "布局",
	"i18nn_2a51888e02ba42a5": "浮动",
	"i18nn_abdfd58e3b842a11": "菜单宽度",
	"i18nn_43f72026b0c0699b": "菜单展开",
	"i18nn_7a22cefca1bca21d": "单个",
	"i18nn_c3a6ecdfb0f15d2a": "不限",
	"i18nn_cf47a8351ab58361": "活动时间",
	"i18nn_00473f5ef0955636": "选择时间",
	"i18nn_612dc996c02cae5d": "即时配送",
	"i18nn_593242ce3c67a074": "活动性质",
	"i18nn_fd0f2ba821918f4e": "美食",
	"i18nn_543782932b959d8c": "餐厅线上活动",
	"i18nn_8cb171a4912f9ae5": "地推活动",
	"i18nn_3e2af40ab8939ecd": "线下主题活动",
	"i18nn_c4c6d0906f0299fa": "单纯品牌曝光",
	"i18nn_03b50caa388efd40": "特殊资源",
	"i18nn_888a17c3151ea785": "线上品牌商赞助",
	"i18nn_478e6c7637d0f1a6": "线下场地免费",
	"i18nn_9d5fa522fb8a6c37": "活动形式",
	"i18nn_9527abe40244bd22": "添加附加费",
	"i18nn_468267e2f309f146": "主题",
	"i18nn_6b8d659c9bd2f861": "模板",
	"i18nn_e8c4c868d3115139": "菜单搜索框",
	"i18nn_6f8f60e16e726ccc": "类别",
	"i18nn_09012b6c20617148": "转运入库编号",
	"i18nn_7cebbad6657bdbf4": "预计到货日期",
	"i18nn_b97a417f08e79456": "申请出库日期",
	"i18nn_18eac2972dd87064": "获取用户权限菜单失败",
	"i18nn_0c901139f9896f14": "已删除",
	"i18nn_7178a20888e962fc": "没有匹配的面单",
	"i18nn_b49ec02534e4f9b9": "未下单",
	"i18nn_139eee335633c377": "已下单",
	"i18nn_b12f88738181c217": "确定生成发货单吗",
	"i18nn_adfbc9ddacfd7f42": "确定撤销发货单吗",
	"i18nn_35f54f309c93f9e8": "已接收",
	"i18nn_4413cff8f8a5be70": "部分转运",
	"i18nn_d58a804e17a620ae": "已转运",
	"i18nn_ab894a37bf61ef3b": "确定提交吗",
	"i18nn_0174ec83eeb25914": "确定撤销吗",
	"i18nn_a68426dce989b497": "仓库号",
	"i18nn_5ebc284d0e1e5cf4": "请选择要运送的货物",
	"i18nn_ce1923a0c6c05200": "数据字典",
	"i18nn_f217cfe6c6291a89": "请选择出库仓库",
	"i18nn_5184f7a4033b4969": "请选择客户",
	"i18nn_79d40c126a46dd61": "请",
	"i18nn_56aa8ea768a4c6bd": "鼠标右键",
	"i18nn_17700f3f4a7ee5f6": "单元格",
	"i18nn_78897452dfd5c501": "如需更多操作",
	"i18nn_f1b23358832dfa49": "查看上方",
	"i18nn_12f42056f558e6f6": "如果是私人地址",
	"i18nn_05a8d5e71aabbcdd": "请按格式填写",
	"i18nn_bfcb068b25558f57": "预约派送是",
	"i18nn_96b7fef9ab4cc937": "仓库时",
	"i18nn_af757a29a5046ebe": "未知的索引数据",
	"i18nn_fc089dc894530f12": "请先选择数据",
	"i18nn_f6f129c36d0c471e": "不能超过",
	"i18nn_cc6963cfe06fc064": "个批次",
	"i18nn_e19edf6996055dc5": "确定删除该条吗",
	"i18nn_771752e23324e1c4": "更新地址失败",
	"i18nn_a5ed1c98a5b6763f": "等待签收",
	"i18nn_976cf9b5668838a0": "州简称二字码",
	"i18nn_8f5ce149c71f00d3": "快递简称",
	"i18nn_8ac049aa3fac8e9f": "保险",
	"i18nn_9d3382d5a74e6e34": "自供面单时必填",
	"i18nn_43cebc877265943a": "外箱规格单位",
	"i18nn_4843108e7e424145": "外箱毛重",
	"i18nn_e6e818680d77e014": "条码箱唛",
	"i18nn_3acca92babc7df1c": "确定废弃吗",
	"i18nn_f66fc986e2ae222f": "废弃成功",
	"i18nn_1118edcaeb4c3679": "请选择仓库",
	"i18nn_407c59bcef554efa": "请先勾选数据",
	"i18nn_0e8a3154ae62784f": "添加成功",
	"i18nn_91d7402a8bcd9fad": "添加失败",
	"i18nn_5c9f3500bc7fc4c2": "确认入账",
	"i18nn_b8ace7701a8138f9": "确定该账户已充值",
	"i18nn_ebe414c3e59312d7": "确定充值成功",
	"i18nn_012297a66930e480": "确定更新密码吗",
	"i18nn_9a8a031ac6ac8473": "直接打印",
	"i18nn_3412dc405baa4620": "单个重新打印失败",
	"i18nn_0fe4835b19dfd823": "正在停止",
	"i18nn_c82aace5dd1ac3dd": "或图片可以打印",
	"i18nn_080a48f195815c62": "第",
	"i18nn_9193c7e8137a5879": "打印失败",
	"i18nn_5660b714ee474402": "拣货单号不能为空",
	"i18nn_ba6389ba0f891b7d": "起始序列号不能为空",
	"i18nn_899f4315df1ea9f6": "结束序列号不能为空",
	"i18nn_31706d698e478551": "序列号不能为空",
	"i18nn_23d809d3bce6b99a": "查询信息失败",
	"i18nn_aaa9fd271d84c6e1": "信息失败",
	"i18nn_6cb81137e76a6a18": "并预览",
	"i18nn_d33d0afe39b5b1a2": "仓租按日汇总",
	"i18nn_65b02ebff64ee1a6": "仓租明细",
	"i18nn_36ed3657e60217a8": "数据已准备",
	"i18nn_37f3ec4a54bf2dbc": "汇总数据已准备",
	"i18nn_4d7265155f2745f2": "汇总数据有误",
	"i18nn_f562dbcbf20073b8": "请选择目的客户",
	"i18nn_411fdc693cb46984": "未审核",
	"i18nn_2b6045f2709c84bb": "未完善",
	"i18nn_03402c5b9fe7cab3": "完善通过",
	"i18nn_5dcc73d5e4f71512": "完善审核中",
	"i18nn_1ee1a0e366f2adba": "完善未通过",
	"i18nn_601218d4d572e98a": "已认证",
	"i18nn_177b191d8e0fa84d": "未认证",
	"i18nn_26d29891d178b990": "认证不通过",
	"i18nn_ff1b4c048301d7e6": "星",
	"i18nn_f41de3a1fa69135a": "手机号码",
	"i18nn_dff1423b58a24b84": "请选择省",
	"i18nn_ebe921ebb02d5f68": "请选择市",
	"i18nn_ca736b6e83d86d2c": "请上传营业执照",
	"i18nn_e796ecfa5ab2a057": "地址省市县数据必填",
	"i18nn_d47f3e0b7855dd5f": "提交有误",
	"i18nn_c9946fd976ed060f": "请检查提交数据",
	"i18nn_35da7aebbfd476d0": "详情数据有误",
	"i18nn_cef747656c086bca": "未知操作状态",
	"i18nn_d0e490d0c3ca7eb0": "请联系管理员",
	"i18nn_c4189210d2599fd7": "请扫描或者输入拣货单号",
	"i18nn_85a02086f987be2b": "请扫描或者输入",
	"i18nn_efdf63caa2c3f0e7": "查询该批次",
	"i18nn_42e8610163aaeda1": "已打印过此面单",
	"i18nn_e395c2d3d12126a5": "如继续打印",
	"i18nn_5fdb4151c7c11503": "请点击",
	"i18nn_a255b29f4ec1fe1a": "生成成功",
	"i18nn_8d80c9b1e3453063": "请先选择入库时间",
	"i18nn_c78d0436fda4c875": "您选择的仓库和客户所选仓库不一致",
	"i18nn_c2baf39462aa98d2": "部分签收",
	"i18nn_4d4f1d7366ae2f12": "全部签收",
	"i18nn_3c6d680fd4ace9c5": "确定全部签收吗",
	"i18nn_004a25fe67f297c3": "余额",
	"i18nn_74b62cbb161a84a4": "余额查询失败",
	"i18nn_7fa1fea309b0fd8d": "取消进行中",
	"i18nn_c136c2ffbc49c6e7": "未知的快递渠道和单号",
	"i18nn_778d871227234f32": "撤销打单成功",
	"i18nn_854a071f3cbf2761": "取消面单成功",
	"i18nn_c26d5c523b0b69d3": "没有面单可以下载",
	"i18nn_378153e9411327ea": "个面单",
	"i18nn_1e2279a00c4336a4": "已发货",
	"i18nn_3ece0cc00f1da8de": "已收货",
	"i18nn_820a3a60bc317776": "格式化数据失败",
	"i18nn_b0e16646a7aa97eb": "您选择了",
	"i18nn_b2330ac1b925cbcd": "请选择单条查询",
	"i18nn_34b9e521d99ba4c5": "当前选择的",
	"i18nn_33736cef5230e85b": "为不可用状态",
	"i18nn_b9221c0e13214d9c": "请检查输入的数据",
	"i18nn_a72f4a9e1170c63b": "已校验",
	"i18nn_7e1de493fb204260": "格式化异常",
	"i18nn_e9970bf947cf72e9": "请输入快递号",
	"i18nn_8f6d68423b465690": "待确认",
	"i18nn_68737d3d24002eed": "作废",
	"i18nn_e83442620acf37fb": "查询参数缺失",
	"i18nn_d9145c2cbe66f3dd": "请主动切换至账单明细查询查看",
	"i18nn_2c98a37551a345ca": "全部汇总数据查询失败",
	"i18nn_1b210b98ae740911": "按仓库汇总数据查询失败",
	"i18nn_0c72f3afd482899d": "按客户汇总数据查询失败",
	"i18nn_44c439345b1a2d97": "按仓库的客户汇总数据查询失败",
	"i18nn_cf698047bdf5efae": "按客户的仓库汇总数据查询失败",
	"i18nn_9c4659d873c8b417": "请先选择日期",
	"i18nn_7f995eb43e1fc92b": "按体积计算",
	"i18nn_02ad74c45e9271a0": "按重量计算",
	"i18nn_bc118f4faee1209f": "合计查询失败",
	"i18nn_615e611d715a5732": "账单汇总数据查询失败",
	"i18nn_802437747d78fc60": "仓库货量数据查询失败",
	"i18nn_affc498c59833669": "仓库操作",
	"i18nn_14c882fc42e6d75f": "入库收货",
	"i18nn_4721598dff939cbd": "财务费用",
	"i18nn_0bdd0558754bbc24": "请选择收货方式",
	"i18nn_2ab7ec407b634f9c": "请选择收货时间",
	"i18nn_598c56c4dda298bd": "请选择附件",
	"i18nn_82e92ac6f89d595f": "待上架",
	"i18nn_d4606176f0203870": "已上架",
	"i18nn_d460085129ba5569": "批量上架模板",
	"i18nn_6fb720f73efb6f5f": "下载成功",
	"i18nn_85d564c86ace60d8": "请选择显示",
	"i18nn_5d2e2702224d280d": "请输入正整数",
	"i18nn_a9d1938b369aff5b": "最多三级菜单",
	"i18nn_7e1a754534215f02": "字符串",
	"i18nn_b7099a09c1dd4c0d": "请先删除下级菜单",
	"i18nn_97954de4658077a8": "请重复输入密码",
	"i18nn_8623ea11efc1953d": "请输入账号",
	"i18nn_4f661d272dfc6cd3": "请选择权限组",
	"i18nn_2c344876df66771f": "确认密码不一致",
	"i18nn_0cb95e589cc2a0fe": "查询权限组接口失败",
	"i18nn_3f92cfa1112482ad": "请输入权限组",
	"i18nn_5d6fde2581708559": "查询菜单接口失败",
	"i18nn_d0f820f74ea352b4": "权限组详情",
	"i18nn_77fcb3c1f9df52dd": "查询权限组详情失败",
	"i18nn_91e192ea5925de92": "请先选择时间",
	"i18nn_2b65059530ec3ed5": "受理中",
	"i18nn_1fb4422ebabb847a": "正常结束",
	"i18nn_0eb9b2e0d01ad12b": "异常结束",
	"i18nn_d6ab1c29b36f35ae": "请选择提交状态",
	"i18nn_efbfa769d113b9fb": "请输入留言信息",
	"i18nn_b781fc794861218f": "拣货方式",
	"i18nn_ee57468801a2964d": "已撤销",
	"i18nn_ca037ca9e5a6af4c": "请选择时间",
	"i18nn_f1c38661e72ae864": "请选择出库方式",
	"i18nn_e9942ae46d0c7434": "请输入托盘数",
	"i18nn_1597574a935b8fca": "未上传图片",
	"i18nn_6b645127e97ecdf1": "请扫描或者输入发货编号",
	"i18nn_49356bf96d04d558": "未查到数据",
	"i18nn_40a6f7ebeefeb8f9": "请输入发货编号",
	"i18nn_46ee1d8e6fc11183": "未查询到此库位",
	"i18nn_c0c353c17e6f5792": "未知的客户编号",
	"i18nn_6a800c36981ed32f": "无值",
	"i18nn_6e972d4773497eb2": "查询该",
	"i18nn_c8805f665bfdec60": "确定回退吗",
	"i18nn_295e890bd68b5817": "确定开始处理吗",
	"i18nn_5f0cbc011b37740b": "该仓库暂无数据",
	"i18nn_89524efe3f5bdf69": "变化趋势",
	"i18nn_52b4f481aab3e343": "订单按月统计",
	"i18nn_6e5d728ebf9703a8": "订单按日统计",
	"i18nn_f762ff6f05589bdf": "收货",
	"i18nn_e36deb57ab8f614f": "自提出库",
	"i18nn_5d4c76c6431c06ef": "按年",
	"i18nn_43d7de9d82345073": "按天",
	"i18nn_2ce736eb73976890": "请输入频率表达式",
	"i18nn_cbfcb27fae20f059": "请输入任务描述",
	"i18nn_5cc8ea0e79e45cb0": "请选择任务类型",
	"i18nn_001b535c694bfabd": "请选执行类型",
	"i18nn_2142b1d4d10d8b5c": "请输入类型",
	"i18nn_a412adde8aa61969": "请输入选项描述",
	"i18nn_90e2f13098c0eab4": "请输入选项",
	"i18nn_9a85d5fea7339b0b": "请选择选项值",
	"i18nn_1c3b377187cb4bb2": "请选择排序",
	"i18nn_2a8b31eaadca2ae7": "请选择父类型",
	"i18nn_632499709fae44e4": "请输入选项值",
	"i18nn_9d14fa135072faa4": "刷新成功",
	"i18nn_14a06b4d26792590": "请选择国际区域代码",
	"i18nn_1da6b8c5aa8e9f2e": "确定要受理该预约吗",
	"i18nn_1af5867a26b5d7b2": "快速转运统计",
	"i18nn_d57278f56da4c035": "预约中",
	"i18nn_dc710cffe6313bb5": "查看详情",
	"i18nn_510163d3faaaec5d": "私人地址",
	"i18nn_0db20ba674893add": "入库装箱基础信息查询失败",
	"i18nn_d35eb46d39070ef8": "更新为拣货中",
	"i18nn_97ced22c0fc11907": "并打开转运清单",
	"i18nn_44d2a6208e2f9a20": "是否继续",
	"i18nn_d4a2f8ababb9fa73": "状态统计",
	"i18nn_be56d9786a28bfa8": "备货照片上传",
	"i18nn_6f8b61e167da9916": "请填写收货信息",
	"i18nn_71b59790ac5ed0e4": "子用户菜单配置",
	"i18nn_621f11a42a00ddb6": "查询菜单失败",
	"i18nn_1ca0e2fc087a178a": "未知的快递公司和单号",
	"i18nn_a39315c28eb857fc": "请选择发货地仓库",
	"i18nn_c7843e69972185e8": "单个批次不能超过",
	"i18nn_fbfaccc4de327695": "请分批操作",
	"i18nn_d7652614cc5e2d3e": "该批次共",
	"i18nn_2a5776c27559973b": "确定继续吗",
	"i18nn_9056478b3f520d62": "该批次存在",
	"i18nn_60321d753e930abd": "一票一件",
	"i18nn_57e5f6175bd644b5": "一票多件",
	"i18nn_632694245c6be4e7": "混合",
	"i18nn_37d83a937d60a027": "确定继续混合吗",
	"i18nn_44242c07a1f17e31": "只有状态为草稿",
	"i18nn_8f3b691fb1928c4a": "已审核才可以提交打单",
	"i18nn_04c1c1452cb3359f": "确定提交这",
	"i18nn_a544f42463e5840e": "确定拦截吗",
	"i18nn_d425224411bfdb2a": "确定出库吗",
	"i18nn_465d732d68ebdfbb": "操作成功",
	"i18nn_d1cba386aa21b1ff": "拣货单号为空",
	"i18nn_63f16a642738a266": "查询有误",
	"i18nn_68299543f3cc5f92": "确定移出该批次吗",
	"i18nn_82e48bab56339c40": "移出成功",
	"i18nn_1a77ac17dba4c85c": "确定合并下载面单",
	"i18nn_901bbc0509b3cd36": "确定合并下载附件",
	"i18nn_9da927fb6cb3c64f": "正在合并",
	"i18nn_16216f47baa947e3": "预计需要",
	"i18nn_7f7865ded377b432": "秒",
	"i18nn_7e33ace0b315758a": "请耐心等待",
	"i18nn_bed2a18430b51157": "预计每多",
	"i18nn_be3396cbdcf75fb2": "需要多",
	"i18nn_daeffa7c80c7dde2": "合并成功",
	"i18nn_769982d12808a912": "正在打开",
	"i18nn_0c86a93c46dcafaa": "下载失败",
	"i18nn_99f298e8a1f1faa4": "确定全部",
	"i18nn_6d0020fc9dc92ae7": "拦截吗",
	"i18nn_df7bb549078d49cd": "恢复吗",
	"i18nn_5d756d95aa048878": "请输入拣货单号",
	"i18nn_45ec4789fbc5c60f": "查询该批次信息失败",
	"i18nn_fffb62e1463bb144": "上架中",
	"i18nn_c35f68546e45071e": "是否确定收货",
	"i18nn_3bd582344601fa87": "且已核对无误",
	"i18nn_aa0c982bc4bca6c0": "请选择处理结果",
	"i18nn_167e06b5d98fca7a": "已受理",
	"i18nn_3e15930039e912d9": "确定退回吗",
	"i18nn_531256af4d92e0bb": "确定审核通过吗",
	"i18nn_31703955e64806b7": "中",
	"i18nn_5143f36f8f772d49": "文",
	"i18nn_4368252da5c6ce3f": "确定取消发货吗",
	"i18nn_9f9b1d4fbc735ff2": "只有状态为",
	"i18nn_428d860b48d9f9ea": "才可以提交打单",
	"i18nn_df6ccd168be94c45": "条数据发货吗",
	"i18nn_11b5e4d030395317": "确定恢复吗",
	"i18nn_022b0d00eed405f3": "状态统计查询失败",
	"i18nn_74ac2ab86b62b7ad": "标题一",
	"i18nn_fd8421fe2df8803b": "标题二",
	"i18nn_8d5c0114856bda15": "搜索数据失败",
	"i18nn_6ef8ab3a4523a2b2": "确定提交打单吗",
	"i18nn_33a114a8cba0232f": "查询该批次失败",
	"i18nn_066f783f19cb317e": "确定撤销打单吗",
	"i18nn_662e330f16cb98d8": "确定取消面单吗",
	"i18nn_caed6cfc34b968aa": "收件人名称一",
	"i18nn_f9c0ee1156f1e254": "收件人名称二",
	"i18nn_2dfd6348829d984f": "发件人名称一",
	"i18nn_e03f8082906c4de8": "发件人名称二",
	"i18nn_2fe24d33754468d6": "名称一",
	"i18nn_faef5bb72994f5cd": "名称二",
	"i18nn_2e49d456b5794910": "全部拣货完成吗",
	"i18nn_8ae560831457c982": "未上传图片附件",
	"i18nn_8e252a43b6085973": "库存为零的不能移库",
	"i18nn_f1710cf28f5c1b2a": "确定全部复核吗",
	"i18nn_7f550193f65e6c9b": "确定全部自动拣货吗",
	"i18nn_9e4dbfe29ddc0058": "拣货完成吗",
	"i18nn_a476975987294b07": "退货管理",
	"i18nn_d256e16919f5fceb": "预约数据",
	"i18nn_7f0e36c0c32152b9": "自提单号",
	"i18nn_c45d595e849aec6a": "添加预约单",
	"i18nn_a1c919aef2dec6d4": "新增明细",
	"i18nn_4f5f83fc6780a29c": "选择货物明细",
	"i18nn_7a29cab1a4b8f231": "是否优先操作",
	"i18nn_7f5fdc820bcd173e": "管理员修改",
	"i18nn_9e3f5a2cc07b627f": "只能为数字",
	"i18nn_f8da18cb19a9fa8c": "公司编码",
	"i18nn_9a716c393c296ea8": "尾程卡派",
	"i18nn_facd05c043886557": "合并部分行",
	"i18nn_6fd4956e676cec49": "合并",
	"i18nn_1861b71eeabd88b9": "不合并",
	"i18nn_d642ae850eca6b40": "提柜费用",
	"i18nn_f883ded0c750dce2": "货柜类型",
	"i18nn_3215d0a40f17773d": "新增费用",
	"i18nn_e47e0c74c02bf395": "已有费用",
	"i18nn_edb1b11e6c5ceb01": "最小十位",
	"i18nn_600c6fad90d0d7b1": "账单状态",
	"i18nn_15a021996904c423": "确认状态",
	"i18nn_98ea9b63e41e8543": "长宽高",
	"i18nn_8468ac93161085d6": "优先操作",
	"i18nn_10b4e989a2c42281": "装箱托数",
	"i18nn_d2084ce00c778575": "单箱尺寸单位",
	"i18nn_7e8bb92f75c45e74": "收货总箱数",
	"i18nn_20f5cf576e4034ee": "收货总件数",
	"i18nn_5a223c2b1a13ec41": "入库总托数",
	"i18nn_28a07becf88aa5f5": "换标",
	"i18nn_8fa2f6bbb0d5b697": "标识",
	"i18nn_0529f4753fb3c5a0": "大小",
	"i18nn_61ebcde89251413e": "确认合并",
	"i18nn_c5378920e015b12f": "选择面单",
	"i18nn_b539914bf0af5314": "校验并匹配",
	"i18nn_470fa451f57e6fff": "识别运单号自动匹配",
	"i18nn_2428648cafd20f21": "上传匹配的面单",
	"i18nn_eb680d44b667d34e": "根据运单号识别",
	"i18nn_53f9c1e720d261f9": "可自动分割面单至各个订单",
	"i18nn_39df26e0051d7903": "确认保存",
	"i18nn_e11679a643689255": "选择面单文件",
	"i18nn_349ee56d6d895249": "文件最大",
	"i18nn_1af19e9a72a235ba": "提交并校验",
	"i18nn_379c907d12f5c9f5": "匹配成功",
	"i18nn_a5d008a482d2b005": "所属页数",
	"i18nn_61d278478046ceaf": "文件流",
	"i18nn_b14b20e17d756cb2": "匹配失败",
	"i18nn_cc3cb77aad104caa": "重新选择",
	"i18nn_7c9926ac60a726ac": "上传匹配成功的面单",
	"i18nn_8433ab19cdbad17e": "上传状态",
	"i18nn_933692e63faf4555": "查看匹配",
	"i18nn_f524f7dafdd60a7a": "保存已匹配的面单",
	"i18nn_b109726c562cc092": "无匹配的数据",
	"i18nn_247c878546702010": "根据运单号自动识别并保存",
	"i18nn_dfaa3ac89594cfe8": "上传完后",
	"i18nn_e7ce2fa6da443509": "请等待结果",
	"i18nn_8054e6a00f7fb9c7": "预计",
	"i18nn_ced2ef6d0ce80ac1": "秒内匹配完成",
	"i18nn_12fde6f4ac0bd2a7": "文件越大等待越长",
	"i18nn_926b52e41c45d835": "上传并匹配",
	"i18nn_8aad52f19406c72d": "历史匹配记录",
	"i18nn_ad810b5b659860fe": "文件地址",
	"i18nn_d426ae19e98559b5": "总页数",
	"i18nn_75247a5e05331f7f": "匹配详情",
	"i18nn_46d9c96bfde09a4a": "请选择文件",
	"i18nn_60c521726fa38f44": "最多显示",
	"i18nn_9e0c510eaed20bc7": "刷新匹配结果",
	"i18nn_e5e53c1aa28e6682": "文件名匹配订单号",
	"i18nn_127af9ed95c3c6be": "按文件名匹配订单号",
	"i18nn_2fe088ec1114c539": "按运单号自动匹配",
	"i18nn_3d189e6fa5541753": "确定同步导入",
	"i18nn_197e2039cf365afa": "仓库打托数",
	"i18nn_59d307f03af09634": "在途中",
	"i18nn_91c9a1974dd0bff6": "已到仓",
	"i18nn_83125000f5142ed1": "入仓前托盘数",
	"i18nn_b8b6bfc4485da611": "相关",
	"i18nn_8b66deca86e6d66d": "请输入二字码",
	"i18nn_fe2e4275fe979e60": "退货信息",
	"i18nn_e1214c4d8b4e9626": "请稍等",
	"i18nn_c121c5915b23c201": "然后筛选后",
	"i18nn_e1c83961e7a8f928": "分批下载",
	"i18nn_f5e108e4d43c2a4b": "只下载前",
	"i18nn_c11ab9cc6f2492e6": "请筛选后",
	"i18nn_5f7f4143c0564b54": "加载第",
	"i18nn_7215a6d646ce357e": "当前可下载第",
	"i18nn_942c689d54ec40a3": "启用关联",
	"i18nn_a211166857c0d3b2": "不启用关联",
	"i18nn_c468dcd6f350323a": "下架方式",
	"i18nn_e0954e26f258ff37": "手动生成批次",
	"i18nn_da5d60a9f5f9537d": "客户快递渠道绑定",
	"i18nn_179471a4a7edfc9e": "渠道名称",
	"i18nn_3790f8e04246b942": "费用计算类型",
	"i18nn_96c4f39b3e50e482": "数值",
	"i18nn_2c68e29ae9b0241b": "应收账单",
	"i18nn_fe980c886a088d1b": "根据筛选条件",
	"i18nn_edd3960d003f3ce5": "已回款金额",
	"i18nn_b95ea219433207f6": "待回款金额",
	"i18nn_8dcd5c029510d331": "折扣金额",
	"i18nn_c6c96a132870f231": "已回款",
	"i18nn_49bd434745f8aa22": "待回款",
	"i18nn_3d620a2ecad95500": "账单下载",
	"i18nn_e7a22fdeba7b703b": "更新费用",
	"i18nn_df112e830bd3fba2": "回款中",
	"i18nn_3b5e992be756a85b": "回款完成",
	"i18nn_44f2c051869ecc91": "将根据",
	"i18nn_412bafcd98e04d4d": "筛选条件",
	"i18nn_f11eda77aadd7f57": "自动创建",
	"i18nn_77c91da599d5b4b6": "创建成功",
	"i18nn_affd861b44969ee2": "确认完成吗",
	"i18nn_0adb9b09d2678da8": "应收账单汇总",
	"i18nn_9abe7bec4631a470": "应收账单明细",
	"i18nn_70b2d10e778a16dc": "总汇总",
	"i18nn_951f968ca8b9bbc1": "待确认金额",
	"i18nn_2d9e36db18cb2ee7": "下载任务",
	"i18nn_77a1b5e97c90a5c8": "生成下载任务",
	"i18nn_44858bef4ea92a98": "任务编号",
	"i18nn_ec50c48081e0543b": "参数",
	"i18nn_a2aff0c6fb977468": "重新生成",
	"i18nn_678b0ae6cf3a4399": "已生成",
	"i18nn_01368e1a80ce6f61": "生成失败",
	"i18nn_cb874a1cc07abc0f": "已重新生成",
	"i18nn_7195cb76b5db754a": "重新生成失败",
	"i18nn_960d7b5c5217102c": "地址距离查询",
	"i18nn_7bfefc21c6aba7c6": "地址距离",
	"i18nn_9461d15f7ba8ad99": "地址拼接",
	"i18nn_2f6634ea185d80a7": "确认到仓",
	"i18nn_27ce3cad1fb426b8": "批量拣货",
	"i18nn_afa707f9e9c8f26f": "批量拣货完成",
	"i18nn_5555f8dbcb4fdf4f": "批量打印拣货单",
	"i18nn_e33374f71ce9a748": "勾选数据",
	"i18nn_54beb4e4556f9cf1": "确认操作这",
	"i18nn_8c9a6964bdaa2289": "条吗",
	"i18nn_e2f6d6395e1b5a5b": "请勾选数据",
	"i18nn_40ac872c0aa6ff41": "条形码下载",
	"i18nn_4ea50b32216e5504": "码过长时",
	"i18nn_dc88ac4413b2bf37": "生成的",
	"i18nn_fb870f042cdc0b90": "码图片也会很长",
	"i18nn_7ce36744aeec211a": "会缺失",
	"i18nn_e4f56cecd0453bbf": "建议下载",
	"i18nn_375809e1d450d562": "或直接右键该码保存为图片",
	"i18nn_36668b7051dae10b": "卡派价格",
	"i18nn_5b16dd13f1bc0e1c": "多订单号搜索",
	"i18nn_8544666ef2803c20": "如需下载",
	"i18nn_ea80f4411a8408b8": "目标打印机",
	"i18nn_1cd45ccce46f619e": "另存为",
	"i18nn_6f9c5b165f3bc662": "还可以去掉",
	"i18nn_f54ebccbce73a3d6": "页眉页脚",
	"i18nn_5c4a773bafcfe7bd": "勾选",
	"i18nn_3f6074f3db84a466": "将文件拖到此处",
	"i18nn_59bcca694e60fcfd": "或点击",
	"i18nn_8c7713cae892514d": "匹配统计",
	"i18nn_b3c2b18eb57891e5": "智能匹配运单号并拆分",
	"i18nn_829a74d12dc6905b": "校验查询",
	"i18nn_4bf00570023be25f": "邀请人账号",
	"i18nn_91428b80aa9e8f9c": "客户绑定仓库",
	"i18nn_a210136d22f35cb3": "目前仅支持",
	"i18nn_d351d2e9d8882726": "系统请至",
	"i18nn_430d5b3397dcc780": "请按上方提示检查",
	"i18nn_784a9e148dbadc4a": "箱唛设计",
	"i18nn_a3ed58117fd81192": "显示客户",
	"i18nn_ee7bdbe4f27e38df": "附加信息",
	"i18nn_287b771e159a7dc2": "查看工单",
	"i18nn_de98440157f199c2": "无数据",
	"i18nn_44816d067c071c41": "去操作",
	"i18nn_34dd4ecc9411c9c6": "备货完成",
	"i18nn_b079d5c37e1d2120": "备货中",
	"i18nn_0bf0eea18efcd589": "备货信息",
	"i18nn_af2b08910f762203": "备货明细",
	"i18nn_ebbc74e1ca8164d3": "有效库存",
	"i18nn_f503a392256579c0": "开始变更",
	"i18nn_bcc2ec3f9bce8104": "变更数据",
	"i18nn_1065d66e4371316e": "修改数量",
	"i18nn_41e367f548b0778d": "确定变更",
	"i18nn_0331ad48d9b40904": "只能选择一条",
	"i18nn_47c222b26ca4ce78": "条码设计",
	"i18nn_110b67a13dd38b1e": "原",
	"i18nn_788d9bdcf8ff660e": "原数量",
	"i18nn_a43cedadf897a6eb": "分拆后",
	"i18nn_ab505f0b8db48c6c": "绑定客户",
	"i18nn_824e8fd03e8b1a5c": "权限仓库",
	"i18nn_8ec797c2bf2b42b4": "当前账号有权限的仓库",
	"i18nn_66ff6da6299a5967": "若无权限的账号即使绑定",
	"i18nn_57252efc30f531ea": "仓库绑定客户列表也不可见",
	"i18nn_02da0f2e22973014": "但实际客户端客户可见",
	"i18nn_73768c3db7d6b661": "当前仓库",
	"i18nn_bba12068e25729f4": "仓库绑定客户列表",
	"i18nn_bcad6e616b8de5af": "第三方",
	"i18nn_3a1e63b67d26386a": "同步信息",
	"i18nn_9ac9d5c058d1465d": "互易单号",
	"i18nn_e91b219bcbc1d684": "配置编辑",
	"i18nn_7a6f218c034d0ecd": "三方仓库编号",
	"i18nn_81c10442a4bd4247": "三方编码",
	"i18nn_72d35d210669abb2": "预报数量",
	"i18nn_4567a206c45dc3f4": "实收数量",
	"i18nn_4b7fc42831b9db53": "取消收货",
	"i18nn_6c837fa18c154f3e": "箱唛下载",
	"i18nn_a8d53003236f396b": "确定取消收货吗",
	"i18nn_b32aa7a649eeb046": "库位变更",
	"i18nn_fc1ddf7dce134538": "变更",
	"i18nn_18f412662f2b18ba": "工单明细",
	"i18nn_f4c3e91a86d23afe": "服务",
	"i18nn_c0d09a392d8acf54": "更新成功",
	"i18nn_6724e693fadf9a09": "是否去重新登录",
	"i18nn_1bd796959e536718": "主题颜色",
	"i18nn_420e047cec52beba": "蓝",
	"i18nn_2a6c32718bd62ea0": "白",
	"i18nn_35681dbc90d33898": "绿",
	"i18nn_e0f905492520aeda": "青",
	"i18nn_80ddb314e4807b49": "红",
	"i18nn_bf77a9b5e485df33": "紫",
	"i18nn_9b62de99e77c2111": "黑",
	"i18nn_43f9e647d7e7ace0": "黄",
	"i18nn_d32b04432f78f1db": "生成图片",
	"i18nn_4497f9b307bf73ee": "长图预览",
	"i18nn_ede4b38a45b72520": "鼠标右键图片可以复制或保存",
	"i18nn_740df211dc3d7543": "显示增值服务",
	"i18nn_78226fb265027602": "显示明细",
	"i18nn_414a2cb9270b7231": "如果有值",
	"i18nn_8b3dbf4f5793930b": "目的地",
	"i18nn_7f791bff1c8abb4d": "合并下载全部面单",
	"i18nn_cc546e1ccd13cf0b": "请扫描或输入出库编号",
	"i18nn_084d695b245685c3": "移至异常",
	"i18nn_735df2dca58c4f9b": "重新提交",
	"i18nn_69018b3ce7961de3": "检验中",
	"i18nn_c7e739a5ba70db03": "确定移至异常吗",
	"i18nn_412f179fdcd6871c": "确定重新提交吗",
	"i18nn_17e7c7b16731a7e5": "请选择账号和仓库",
	"i18nn_e34c8a118634bf64": "绑定数据超过",
	"i18nn_daf04488c05b648b": "请减少绑定数据",
	"i18nn_e1a3fd479469bc3b": "预报仓",
	"i18nn_79ce1fe82ce3e424": "预报仓为空",
	"i18nn_7179e7e6f1c6607f": "加载成功",
	"i18nn_ae9788548d6b369d": "设置仓库",
	"i18nn_8e693617facf34a8": "理货完成",
	"i18nn_84b54464d1e28163": "仓库菜单",
	"i18nn_b23bdb36899ae7f4": "库区",
	"i18nn_0f4a445be54ed8ae": "锁定状态",
	"i18nn_ebddf036087d3618": "锁定类型",
	"i18nn_c301b22bd36255c8": "处理状态",
	"i18nn_28bc604691209657": "盘库信息",
	"i18nn_cc9fd9b44e3429ed": "盘点账号",
	"i18nn_a0030be92f6b1c1a": "盘库任务",
	"i18nn_5c5140f8913c5819": "任务名称",
	"i18nn_1bbf0464d7c1ed75": "创建账号",
	"i18nn_51521d7e394e5b44": "查询盘库信息",
	"i18nn_17a50282124ea21b": "完成任务",
	"i18nn_68624892c0eb7fb8": "同步至系统库存",
	"i18nn_53efa98019d8ee90": "确定完成本次盘点任务吗",
	"i18nn_ccb235dec92863c0": "请慎重操作",
	"i18nn_0ead89fbcc657f0c": "确定同步至系统库存",
	"i18nn_22f6e6aa14279e30": "库存汇总",
	"i18nn_d764dccdd0a96427": "已锁定库存明细",
	"i18nn_d8d0c7c9b9ee194d": "收货附件",
	"i18nn_c333de0c7954980d": "上传收货附件",
	"i18nn_3d3679bf88a9390c": "设置库区",
	"i18nn_1e05aead0e13d830": "确定收货吗",
	"i18nn_5efbe6b41f343c21": "理货记录",
	"i18nn_ffb9d72aca40b1fa": "维度",
	"i18nn_49bbd60a1c291f9c": "已理货数量",
	"i18nn_63396b17b57e1a49": "绑定用户",
	"i18nn_4aa83e7663f8d9ec": "绑定时间"
};