import Vue from 'vue'

// import AxiosPlugin from '../../axios/axios.js'
// Vue.use(AxiosPlugin, '$axios');
//不需要401，错误提示等过滤
import axios from 'axios'

import HYConfig from '../../config/config.js'
Vue.use(HYConfig)

const state = {
	logoInfo: {
		code: "",
		logo: "",
		owner: "",
	}
}

//存Storage
function setStorage(name, value) {
	sessionStorage.setItem(name, value);
}

//取Storage
function getStorage(name) {
	return sessionStorage.getItem(name);
}

//删除Storage
function delStorage(name) {
	sessionStorage.removeItem(name);
}

function getShowLogoData() {
	//异步Promise
	return new Promise(function(resolve, reject) {
		let domain = window.location.hostname;
		const Axios = axios.create({
			// baseURL: 'https://some-domain.com/api/',
			timeout: 60000,
			// headers: {'X-Custom-Header': 'foobar'}
			// 和后端 Content-Type 统一，跨域有option请求
			// headers:{'Content-Type':'application/x-www-form-urlencoded'}
			// headers: {'Content-Type':'application/json'}
			// headers: {'Content-Type':'application/json; charset=utf-8'} //默认
		});
		// if (!state.logoInfo || !state.logoInfo.logo) {
		Axios.get(Vue.prototype.$urlConfig.WhSysShowConfig + "?domain=" + domain)
			.then(({
				data
			}) => {
				// console.log("省份信息请求成功vuex");
				// console.log(data);
				if (200 == data.code && data.rows && data.rows[0]) {
					resolve(data.rows[0]);
					// commit("UPDATE_SHOWCONF", data.rows[0])
				} else {
					reject(data);
					// Vue.prototype.$message.warning('获取省份信息失败vuex');
				}
			})
			.catch((error) => {
				console.log(error);
				reject(error);
				// console.log("省份信息请求失败vuex");
				// Vue.prototype.$message.warning('省份信息请求失败vuex');
			});
		// } else {
		//   // console.log("省份信息从vuex缓存读取成功");
		//   resolve(state.logoInfo);
		// }
	}).catch(function(reason) {
		console.log('Promise Failed vuex: ', reason);
	});
}

const mutations = {
	//更新菜单菜单权限信息
	UPDATE_SHOWCONF(state, parm) {
		let logoInfo = {
			code: parm.code,
			logo: parm.logoUrl,
			owner: parm.owner,
		}

		try {
			setStorage('logoInfo', JSON.stringify(logoInfo));
		} catch (err) {
			console.log(err);
		}
		state.logoInfo = logoInfo;
	},

}

const getters = {
	//获得信息
	getLogoInfo(state) {
		let logoInfo = getStorage('logoInfo');
		state.logoInfo = logoInfo ? JSON.parse(logoInfo) : {};
		return state.logoInfo;
	},

}

const actions = {
	//更新
	// UPDATE_SHOWCONF: ({
	// 	commit
	// }, parm) => {
	// 	commit('UPDATE_SHOWCONF', parm);
	// },
	//更新
	UPDATE_SHOWCONF: ({
		commit
	}) => {
		//异步Promise
		if (!state.logoInfo || !state.logoInfo.logo) {
			getShowLogoData().then((res) => {
				commit('UPDATE_SHOWCONF', res);
			})
		}
		// return new Promise(function(resolve, reject) {
		// let domain = window.location.hostname;
		//   if (!state.logoInfo || !state.logoInfo.logo) {
		//     Vue.prototype.$http.get(Vue.prototype.$urlConfig.WhSysShowConfig + "?domain=" + domain)
		//       .then(({ data }) => {
		//         // console.log("省份信息请求成功vuex");
		//         // console.log(data);
		//         if (200 == data.code && data.rows && data.rows[0]) {
		//           resolve(data.rows[0]);
		//           commit("UPDATE_SHOWCONF", data.rows[0])
		//         } else {
		//           reject(data);
		//           // Vue.prototype.$message.warning('获取省份信息失败vuex');
		//         }
		//       })
		//       .catch((error) => {
		//         console.log(error);
		//         reject(error);
		//         // console.log("省份信息请求失败vuex");
		//         // Vue.prototype.$message.warning('省份信息请求失败vuex');
		//       });
		//   } else {
		//     // console.log("省份信息从vuex缓存读取成功");
		//     resolve(state.logoInfo);
		//   }
		// }).catch(function(reason) {
		//   console.log('Promise Failed vuex: ', reason);
		// });
	},
}

export default {
	state,
	mutations,
	getters,
	actions
}