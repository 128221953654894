module.exports = {
	"64fdf49f89960ea7": "do something with response data,",
	"2937b66448b05dab": "login failure response.data.code ",
	"69f9ba0349edc6b2": "login failed, please login again",
	"a679f255dfd94a56": "doing something when responding to an error,",
	"e8bcc6cb3bf6c21a": "login permission is invalid, please login again",
	"c02c393ccc6cbb32": "customer service hotline: 400-800-5550,",
	"cc62f4bf31d661e3": "prompt information",
	"71827d3678b69b71": "ten thousand",
	"9ccf3ec5dedd0911": "(not required) storage area for stock out",
	"3233d4e01025464e": "drag the SKU to the corresponding bracket and click '",
	"1ea9d0b7f11f0733": "'drag '",
	"e66c561f70bd9f8b": "arrival warehouse",
	"99ed5f00d1ae48d1": "warehouse address",
	"d6e21ff0cb0941c3": "click below",
	"54b11a743e904e2f": "'drag the SKU to the corresponding bracket",
	"93f5ca01b006206c": "operation",
	"1787524a3924f9b0": "FBA application No.",
	"c032f6b78808e7f3": "total",
	"c7653c0832527b93": "remainder",
	"bf0a1c4ff90aadcb": "allocated",
	"01b9ace71d0db12e": "add a new tray",
	"ad102ed83ed76332": "(will try to add the first SKU and the remaining data is not 0)",
	"de7090439acfce3f": "Tuo",
	"73bbf51487ec69e9": "total",
	"922c8542b7d698fb": "SKUs,",
	"7431e76029678ec7": "title,",
	"3775920d44088b96": "number of pieces",
	"4f5bb4ff8b3d804b": "confirm",
	"4e9fc68608c60999": "close",
	"36dd4d8e37f4a446": "SKU remaining quantity is not 0, please allocate accurately ~",
	"204ffab8a6e01870": "OK",
	"2dc8528200b25ad7": "please delete the bracket with SKU 0",
	"4c6841ce9043c9fe": "HYTX Storage",
	"57ef7062be4bcd5e": "language switching",
	"ed9ffec2e58ddb70": "simplified Chinese",
	"8be68daadde8fd16": "language switching succeeded ~",
	"b4bced00356095dc": "exit",
	"0df90bded79afc49": "products,",
	"408668e7ebee1050": "SKU inventory,",
	"7981e073e6b198a9": "SKU information",
	"4a45b6d8b823236e": "SKU pipeline",
	"fb44baec4150b31f": "forecast",
	"4b038d5cbc643471": "delivery",
	"83c817669b913ebf": "my packing list",
	"f52a39ff3d95336f": "my shipping order",
	"684a2afb069b6016": "Dropshipping",
	"23529ffcabeca8ea": "report",
	"64d6ec8ba3450dd8": "my storage fee",
	"7b1c2b1adc920d9c": "No.",
	"15b3627faddccb1d": "remarks",
	"b47c7cf948eb1d37": "adding SKUs,",
	"1dbc1e4ed1522f17": "importing SKUs,",
	"e720d3f78642a853": "SKU details",
	"89d50ef8fe430e48": "select or search product category,",
	"039b1175cdb03703": "support keyword fuzzy search",
	"ea5e5a3a3c176681": "it is recommended to upload pictures with size not less than 100 * 100,",
	"c1c4f12e8d35166c": "support PNG, JPG and other formats, the size is recommended not to exceed 500K;",
	"7de4da2660845ed2": "only numeric and alphabetic underscores, no more than 30 characters",
	"dbb039ef07c68443": "product classification",
	"539728f5dd9cb065": "SKU title",
	"bb0e2b6a0053154d": "SKU Chinese title",
	"2cb3d5af63361796": "SKU English title,",
	"9fcdcb3a067903d8": "Chinese",
	"3d1856da86a06def": "English",
	"efb573c2cb944147": "SKU pictures,",
	"9db48ed0e8ec64a6": "value",
	"96f1a4b17e75892d": "weight",
	"b6bf0a07fe26f74f": "unit",
	"0e60303b30d5d4b4": "long",
	"6cd31871f8528dd5": "wide",
	"93c9ea4a0e52c641": "high",
	"551b0a9e48d684f9": "length * width * height",
	"004617dc47191cb8": "safety stock",
	"594abd886182b662": "product information",
	"d48bb83a82237f60": "specification information",
	"0640e3814d107002": "inventory of existing locations",
	"68b3bb1ef6931076": "current locked inventory",
	"d91fd63094e82992": "current active inventory",
	"d8644e8d60c8bd8a": "unit price",
	"2594059036dfede5": "time",
	"f9db93b87e08763b": "quantity",
	"ae61193590bd513d": "Order No.",
	"184333c81babf2f1": "type,",
	"f925d9b48d8c1d45": "description",
	"6cdece641436d7ab": "status",
	"b7a10fe4e9528362": "No.",
	"844086cd62972687": "creating packing list",
	"411428188ecb0be8": "import packing list",
	"ed47d0c198c61d01": "port of departure",
	"d3d95bf498ae6cdb": "port of arrival",
	"04e92fdefcddec84": "departure date,",
	"b5c03c6f1b9b1e28": "arrival time",
	"b799f516e3940e3f": "packing list type",
	"b9d4d3f6183bf3c1": "the number of packages",
	"c9fbcb0263c9b1f2": "number of rechecked pieces",
	"f5d43732e3f6cf4d": "attachments",
	"ae5d09d5eca25a2b": "add",
	"8ed435ced73dc9bf": "basic information",
	"e6bdd31025bb4f9f": "details",
	"dc0eb5f461534508": "packing information",
	"1108b3e1ecd1fdce": "freight forwarder customer",
	"97e49d00bcc4c17c": "others",
	"16853bda54120bf1": "all",
	"fdd8426d7b8869c5": "not received",
	"1e2279a00c4336a4": "Not In",
	"3ece0cc00f1da8de": "Been In",
	"5508f750107a3649": "are you sure to ship?",
	"a2df50399ce1bf10": "delivery No.",
	"2548824ee5f19b0f": "creating a shipping order,",
	"5acbec83efb27445": "warehouse No.",
	"5165f3ee9377af83": "driving mode",
	"99b88de52e382ca4": "estimated arrival date",
	"0c78dcec39ac44fc": "delivery warehouse No.",
	"56384dec1b2a9aa8": "submit instruction,",
	"79784145b384f8da": "download bill of lading",
	"dc911ba1ba820fa8": "importing SKU details,",
	"0f859b9b45732564": "arriving at the warehouse,",
	"e0a4ad52c08d9bdb": "FBA warehousing application No.",
	"df7602042d7847f9": "arrival area",
	"59f93d534a71e55e": "detailed address of arrival",
	"9fc252e5a4f5345f": "volume (L * w * h)",
	"b4f10fe0216dec66": "add SKU manually",
	"915541755261249f": "draft",
	"7e14d2cd3996dcd2": "submitted",
	"1ff1fd8ddb3e0e8d": "picking",
	"5cec023a4b7d779b": "picking complete",
	"4841a132787c72d8": "palletizing",
	"dcc830098ae4c9fd": "to be delivered from the warehouse",
	"a342c84e359f0d02": "issued",
	"a3bd26a97ca5f275": "are you sure to submit delivery order?",
	"8730be85c2f45b7c": "import consignment note",
	"fb16a4833ec342cc": "batch submission",
	"fc7e87b5dfa6e4b8": "number of agents issued on behalf of",
	"7e1de5cb2215dfea": "no SKU,",
	"43a3586339251494": "date",
	"431d0d251ff90f3f": "place of delivery,",
	"80b704ca1789ae16": "place of receipt",
	"e44e9a291d80c593": "recipient name,",
	"c3755bab459a2cbc": "company",
	"c2d9cc88561f8cdc": "contact information",
	"497e4906e7963cfc": "address 1,",
	"9d92f7e2f89961c8": "address 2,",
	"e05b5d049b64b040": "city",
	"5abbc9dfd6289796": "state / Province",
	"c4913ab43009b365": "postcode",
	"b166e4e8fe9513fa": "state",
	"ad4345dbaabe1479": "submit",
	"0c3e84f9f6aa0e52": "audited",
	"7b80e66b535a3732": "please select the data to operate first",
	"8fdeb3c5d820598d": "all selected items can be submitted only when they are in draft status ~",
	"f96b62dfa31cde45": "OK to submit,",
	"cade329ca6989721": "data?",
	"b4ea1320f05a2958": "parsing failed, too much data",
	"209fdd8f47e20fbf": "customer No.",
	"46c3f6e0f657e7a3": "fee type",
	"d7315fb8114eb446": "fees",
	"4b107048a1f3cfd0": "cost description",
	"96e86c7f83dd7561": "charging method",
	"9bfcf061ccdd5c1c": "bill date",
	"47df8be257c59dde": "loading...",
	"44bd089554515c4d": "submitting..",
	"daaaeb1b7b22b126": "tips",
	"d655cebf64ac8df4": "sorry, the request data is incorrect,",
	"c67e2e19659d381d": "sorry, the request data is wrong, please try again",
	"d3613276a72a5ef7": "there is information in the data that is not filled in or filled in incorrectly, please check. ",
	"9f30371831a98237": "submitted successfully! ",
	"dbe331ab679cd67f": "sorry, submission failed, please try again,",
	"33bae59bb784a109": "sorry, submission failed, please try again! ",
	"81d48fdc2158d55d": "sorry, failed to query details,",
	"bf360c235e89e2eb": "sorry, query details failed! ",
	"04470afdd839db6e": "successfully joined the shopping cart,",
	"f991921edde2f136": "add to cart failed, please try again,",
	"e6c6120b5a8acbb8": "add to cart, request failed!",
	"67a39834582ad99a": "no data at the moment",
	"d0d544fad4507868": "not yet available",
	"a3af82cfacb6447f": "you must log in to operate. Do you want to log in now?",
	"2c19ac613a4b6a8a": "this operation can only be carried out by a manufacturer. Is it certified as a manufacturer?",
	"6bc4d7c40f408273": "you must be a manufacturer to perform this operation. Do you want to log in?",
	"191a1d1f11bbfb63": "merchant authentication can only be carried out if the manufacturer has passed the authentication",
	"8a23e83d049d4217": "authentication information has changed, please login again.",
	"c8840b2ed17fc9ee": "the world of reciprocity",
	"bdaf0b4164ce179e": "Avatar",
	"278ba7ca16ed0689": "commodity type",
	"9f0490bf3edeef88": "steel",
	"5a90bf08ed58d528": "wood",
	"66d7424398d24f52": "cotton",
	"fe08b328b3f821ce": "cotton yarn",
	"b4b5b263d22f7793": "cotton / yarn",
	"95c5b962979af124": "car",
	"bf8ba61af81f99bf": "wine",
	"f190f9795d4d3fa6": "tea",
	"693c7b35bdf3e3a3": "finance",
	"90354e648ee81925": "warehousing",
	"ff8e3022b2b9bdb9": "the commodity title can support up to 60 Chinese characters, and the content beyond the limit will no longer be displayed.",
	"4b3340f7872b411f": "Cancel",
	"4ce9979bfb6576d9": "save",
	"ca5a01a5adf20fe7": "return",
	"c09a4ec2fe473b0a": "modification",
	"556c0672dcecba13": "Settings",
	"70fdf36bdc2b0f58": "on",
	"a9e1d1c2c5eb6525": "Refresh",
	"1e7246dd6ccc5539": "search",
	"9eefb7335988c499": "query",
	"e33c9b93c36fd250": "delete",
	"c0246c55b9cac963": "add",
	"6267f3aedf895209": "editing,",
	"7fb6ada66a1fccca": "select",
	"de6344a0a81cf18e": "are you sure you want to delete? ",
	"2674282277c3714e": "pictures,",
	"04e722a308964860": "Preview",
	"4c0e3495d9042ebf": "view details",
	"228e58fddadbff82": "upload attachment",
	"e82c9fff83ec2d91": "mobile phone number,",
	"d49a93bb265a311d": "verification code",
	"f1ee1d97e869e447": "please input verification code,",
	"ace46c7b30cb9a6e": "obtaining captcha,",
	"4733de26e1c2e985": "captcha sent,",
	"b367a94ea38173fb": "the captcha can be retrieved in seconds",
	"4afe69876103f780": "the password must be 6-18 digits and contain at least one letter and one digit",
	"ed9ff5f7d8beaa43": "SMS verification code has been sent to your mobile phone, valid within 5 minutes, please check! ",
	"0f00eaea829fd543": "obtaining verification code failed",
	"ec04cac591a1850c": "error in captcha",
	"1ae7a6f6675d7d09": "password inconsistency",
	"5a9aefbc03c778f7": "please input",
	"2ad108ab2c560530": "please select",
	"e88a18bb2c03bb64": "please enter or select",
	"024e0fe5f03408ea": "please upload",
	"d23ea3ece931057a": "please input the second generation ID card,",
	"057d4e3f1ee2d9bc": "please enter a number,",
	"1a6a270dab462dfe": "please enter an integer,",
	"e69d4402bd9bdefc": "please keep up to three decimal places",
	"0cc9c1aa48812e61": "please input mobile phone number,",
	"b1b26a8c797b6806": "please select the province or city of receiving goods",
	"68efabd029c860a9": "select date,",
	"7519a687c0125f7b": "select Start Date",
	"0e1880b21fa14752": "select end date,",
	"0200bd47bb4bb83d": "today",
	"84b73bfc6dada445": "yesterday",
	"e3f3138cf5ba3f6d": "a week ago",
	"58169349e34744e8": "select date time,",
	"870c336dcb486d96": "common countries / regions",
	"6b0ab3e65c3be5f6": "mobile phone area code request failed",
	"f04d208f84e6c343": "province",
	"cbea4e1393d9e448": "district / county",
	"c944a6686d996ab3": "warehouse",
	"538606c127a812d7": "failed to obtain province information,",
	"ab46b83080b1bef8": "failed to obtain city information,",
	"599c4ec0106e9ec1": "failed to obtain district and county information,",
	"084ee4095468a454": "failed to obtain warehouse information,",
	"42a17cc85f956d55": "select file,",
	"a67037f1a5e99c8d": "upload to server",
	"2f75d79503294e52": "upload file size cannot exceed",
	"8ca9d59119d46247": "upload failed,",
	"3ceea1e67d757758": "please upload a file in an appropriate format,",
	"b180cd998ccbe52e": "the number of files exceeds the limit,",
	"9cdac116e11d2942": "please select file first",
	"4356672f48943143": "unknown picture address,",
	"66261f0ce9d34a49": "unknown file,",
	"2e9f903ca3d96308": "uploading",
	"1aa9b398f37906a4": "file name,",
	"1ecc8be9ba1c940a": "click Upload",
	"ea5174c2d4777af3": "error opening upload file",
	"934df8eb0fce18e9": "the browser version is too low to support file method, please upgrade the browser or contact customer service",
	"4ea587366b85f899": "component loading error,",
	"73dd0d43f580bc68": "import succeeded",
	"23ffd33f78e6f41f": "delete picture,",
	"2d692b31fafc6424": "are you sure you want to delete this picture? The deletion will not be restored. Are you sure? ",
	"fed4e5b5e96767cb": "please upload pictures in. PNG,. JPEG,. JPG,. GIF format,",
	"4c9af5f1aee9bee2": "select excel",
	"1d8178c8a95ccb3b": "upload and preview,",
	"e49d33421617c0da": "download template,",
	"bda76d3849a766ce": "ton",
	"06b5d5b29cf6518c": "cubic",
	"65bba0d13269d127": "vehicle",
	"74afbba17eeff513": "bottle",
	"815cf59b03075baa": "Yuan",
	"01d515661b01a0b3": "home page of reciprocity",
	"af52d9dcd723be61": "welcome to Huyi",
	"98b8be246c7d75f4": "please login",
	"e2d6d5e2822a673a": "free registration,",
	"024adf67cbe459cf": "user information",
	"2124c0d1d6d06b07": "account security",
	"59e36d01a5abbc90": "account management",
	"ca1c2845b3c08111": "authentication",
	"a4513a1a48f97338": "my order",
	"fb949604ce64a27d": "buyer center",
	"3a4e0063434e0aa7": "buyer's order",
	"2a6e768f44ff1335": "my purchase",
	"8e2405ce89d11c64": "my inquiry",
	"6e7d05a7f3e2fe77": "manufacturer center",
	"6d6faf9c75056074": "manufacturer's order",
	"c4b724022c1c6849": "commodity management",
	"9a954c4251c670df": "product release",
	"a0d9a8bda2c5e1ca": "my offer",
	"30746594c861e3f9": "partner center",
	"cf03031ed0359ac6": "my recommendation",
	"c68a2435378ac15f": "invite registration,",
	"471a3636b5d91f39": "customer service",
	"080e622e397bedee": "help notes",
	"f855997fac114554": "help center",
	"08cc7d24752ec42c": "online",
	"74f1dfbcbf21ef4f": "contact online customer service",
	"a97cd564413414f8": "customer service online",
	"3e6018b486a4f0b6": "picking code query",
	"ace012504bc529e0": "message,",
	"bbeda9da4cbc4745": "mobile client",
	"39e76151a6a7fbaf": "app download,",
	"6f63b667b53d4ab0": "wechat mall",
	"7c990867fe4740ee": "Android download,",
	"85cd7163c73f3541": "IOS download",
	"f08eba7edcb8b649": "welcome to register",
	"158eb06e6609b0b7": "user registration,",
	"29799f539ee6b906": "please input mobile phone number,",
	"534c2aade93b99db": "Chinese mainland",
	"604a0b11e916f387": "password",
	"acc6253786d62502": "duplicate password",
	"53f8f60579ce17a4": "partner's mobile number,",
	"ae21e9b65f895153": "read and agree,",
	"1dfd24199f850f69": "reciprocity world registration agreement",
	"1bc005fe7464ed3f": "registration",
	"d146dcc24e824603": "do you have an account number of reciprocity world? ",
	"0fff44c827a4f3b6": "login",
	"4041c67ffdcc3210": "please contact customer service if you have any questions",
	"2d13b6f878f30be3": "telephone",
	"bd2ffae5d1ea1dcd": "please select the international area code of mobile phone,",
	"f5a9e0830d476523": "must be a mobile phone number,",
	"b978bd71e959b620": "please keep the password consistent,",
	"47cfeb9a4965a9c3": "registration successful! ",
	"8efc7ede2b82d0d2": "welcome to login",
	"dffe304884c9a6ad": "password login",
	"be677facb85e372e": "remember password,",
	"c7609e554782bec4": "login",
	"41ad17fdbe906b08": "forget password?",
	"0f12dbc1cd5f49e2": "new user registration",
	"b5af3886627252eb": "mobile phone code scanning, secure login",
	"50f017673319c5db": "open",
	"17a79a4f51540f5b": "reciprocity app",
	"07532a765aa30fa5": "scan code login",
	"c673a194ace6c266": "please enter the correct mobile phone number and password,",
	"bf8f960ea394c351": "login succeeded! ",
	"bbe03be5df1c223f": "login failed! ",
	"1d89b3f39386d872": "password retrieval",
	"0f59ee02efe0d401": "authentication,",
	"b09a63f0d59e0df2": "reset password",
	"25e03120b83ee4ed": "finish",
	"4639f3a6e07c00b3": "next step",
	"02678cace7540fa0": "new password",
	"9cb1c9c51e7503d6": "confirm password",
	"c5208cc6dccef8a4": "Congratulations, your password is recovered successfully! ",
	"d1d7b80ea41c31bd": "please remember your new password,",
	"08806c68bf7fcf6a": "jump to login page in seconds",
	"ced8f3a726d82f7b": "log in",
	"6c241c83319c35d6": "failed to retrieve, please return and try again! ",
	"a49bf97bd35892ed": "global navigation hook",
	"d89b1f347399ba38": "not found",
	"d009ef7b75a30a3e": "deep copy failed",
	"c4e89cc1ae76e3ae": "province information request successful vuex,",
	"e97dfb2e53284adf": "province information request failed vuex,",
	"20fadea0ce9b979a": "province information read from vuex cache successfully",
	"8f762cb5b5c3b4eb": "bottom help center successfully requested vuex,",
	"4acbfc125c976cec": "bottom help center request failed vuex,",
	"8e8aa8b241e4c2e1": "bottom help center successfully read from vuex cache,",
	"33a2918595ed9930": "bottom about our successful request vuex,",
	"9818091176ebc075": "bottom about our successful reading from the vuex cache,",
	"18eb8d07e0e8256e": "product category navigation request successful vuex,",
	"6e1b8e5a32c67f8c": "product category navigation request failed vuex,",
	"f7f543cde1cd974c": "commodity classification navigation successfully read from vuex cache",
	"f7c9556928fe4ec9": "mobile phone area code request succeeded",
	"2caa2cb5696d6a37": "common",
	"a4dd3b2f9d3a0b22": "if you can't find your home location, please call the customer service hotline 4008005550 to solve it.",
	"1fc977dfed198654": "mobile phone area code request failed, vuex,",
	"8cc3916e6e3bd181": "mobile phone area code read from vuex cache successfully",
	"5b42b49a689d1bef": "need to log in, log in now?",
	"be99aad78a1ac1f0": "partner certification required, go to certification immediately?",
	"b9bc1fee3d9bf118": "manufacturer certification is required, go to certification immediately?",
	"0951ed7e701d0327": "merchant authentication required, go to authentication immediately?",
	"a3582c07278e2220": "please enter the partner Center - my recommendation module",
	"hytxs0000001": "please enter (exact search)",
	"hytxs0000002": "close",
	"hytxs0000003": "print receipt list",
	"hytxs0000004": "de checking",
	"hytxs0000005": "receiving",
	"hytxs0000006": "warehousing time",
	"hytxs0000007": "number of arrivals,",
	"hytxs0000008": "the number of incoming pieces",
	"hytxs0000009": "total weight,",
	"hytxs0000010": "total volume,",
	"hytxs0000011": "view SKU details,",
	"hytxs0000012": "warehousing",
	"hytxs0000013": "warehousing information",
	"hytxs0000014": "warehousing time",
	"hytxs0000015": "charging method",
	"hytxs0000016": "charging price",
	"hytxs0000017": "warehousing location and quantity",
	"hytxs0000018": "new warehousing location",
	"hytxs0000019": "warehouse information",
	"hytxs0000020": "select location",
	"hytxs0000021": "warehouse location",
	"hytxs0000022": "quantity",
	"hytxs0000023": "delete",
	"hytxs0000025": "SKU details,",
	"hytxs0000026": "select location",
	"hytxs0000027": "in storage",
	"hytxs0000028": "entered",
	"hytxs0000029": "list data error",
	"hytxs0000030": "list data, request failed! ",
	"hytxs0000031": "error in submission, please check the submitted data! ",
	"hytxs0000032": "query data dictionary failed, please try again,",
	"hytxs0000033": "query data dictionary interface failed, please try again!",
	"hytxs0000034": "regional standard",
	"hytxs0000035": "location",
	"hytxs0000036": "location type",
	"hytxs0000037": "current number of pieces",
	"hytxs0000038": "please enter (fuzzy search)",
	"hytxs0000039": "keyword search",
	"hytxs0000040": "print receipt list",
	"hytxs0000041": "de review",
	"hytxs0000042": "confirmation of receipt",
	"hytxs0000043": "are you sure to submit?",
	"hytxs0000044": "review all",
	"hytxs0000045": "review status",
	"hytxs0000046": "single review",
	"hytxs0000047": "number of rechecked pieces",
	"hytxs0000048": "the number of packages",
	"hytxs0000049": "confirmation review",
	"hytxs0000050": "work order processing",
	"hytxs0000051": "packing list",
	"hytxs0000052": "packing list details",
	"hytxs0000053": "receiving and warehousing",
	"hytxs0000054": "shipping order",
	"hytxs0000055": "one item distribution",
	"hytxs0000056": "financial records",
	"hytxs0000057": "location management",
	"hytxs0000058": "customer information",
	"hytxs0000059": "warehouse rent",
	"hytxs0000060": "customer name,",
	"hytxs0000061": "express order No.",
	"hytxs0000062": "fees",
	"hytxs0000063": "city",
	"hytxs0000064": "cancel shipment",
	"hytxs0000100": "Please select a printer (default if not selected)",
	"hytxs0000101": "You are advised to combine digits, letters, hyphens (-), and underscores (_). The value contains a maximum of 20 characters and must be unique",
	"hytxs0000102": "When exporting all, SKU CODE one-dimensional code image is not included; To export one-dimensional code image, please export the current page;",
	i18nn_38d84b35d4836a92:"When created",
	i18nn_246e8486702689b8:"Initialize wechat sharing",
	i18nn_c074db443e169104:"Monitor route changes",
	i18nn_5f5d2d5861866f89:"Avoid not getting it for the first time",
	i18nn_70e6e0b7ca198366:"If you use",
	i18nn_58d2c1bf5829535c:"Rewriting of existing page",
	i18nn_7060ca2fc8279505:"So I suggest that you",
	i18nn_4b0727f8db77be1d:"Of",
	i18nn_373222bafdc59d8c:"Call before executing statistical code",
	i18nn_f4b9a92ed6eba312:"The page will be automatically",
	i18nn_7f60c280ca3cf89e:"Statistics off",
	i18nn_f47ba4ab8f8737c3:"Prevent page traffic from being counted",
	i18nn_1e6fc9d258352566:"Used to send a",
	i18nn_ad66ec7a4a1379a4:"Statistics request",
	i18nn_7f8cc5c86bdabe1c:"Execute the first time you enter the page",
	i18nn_6b3c6618251b6db5:"One dimensional code generation failed",
	i18nn_470bbdcc42b395ec:"Select commodity category",
	i18nn_dd2d9da989e73605:"Does the selection box show all levels of display",
	i18nn_f0306a16ca426bbd:"Optional levels",
	i18nn_005085930f991fb0:"Level of drop down display",
	i18nn_2eccef01f0c2b163:"Before compiling and mounting",
	i18nn_d6f79d965aae9814:"Value change",
	i18nn_741a6252538afafe:"Returns all three-level category data",
	i18nn_a83b42e33f2b1173:"Tree classification information",
	i18nn_758239e08104dfb8:"Product category request failed",
	i18nn_81502b2761fec335:"Building",
	i18nn_c31a431d8ed71e3a:"Partner certificate",
	i18nn_a5e2444030dc17a8:"Preview the generated image",
	i18nn_d7a226d4df7bee09:"Preview error",
	i18nn_541218c422851c20:"Refresh and try again or contact the administrator",
	i18nn_be59529ed5139a67:"Select the displayed column pop-up window",
	i18nn_eeeadd6f7ea81f5d:"Selected fields",
	i18nn_af74800096e892cd:"All fields to be selected",
	i18nn_5083305255d191f7:"Can I drag it",
	i18nn_29f3a276f59714e3:"Watermark",
	i18nn_245883c80f181c4a:"download",
	i18nn_a0fde10c3eb9355a:"Trading partners in the world",
	i18nn_23d5dd4ea17213bc:"The number of packages that need screenshots",
	i18nn_9ef87cb36010d25e:"Native",
	i18nn_7c805dc66bdc9ed8:"object",
	i18nn_97e0de817d74acab:"obtain",
	i18nn_9e24e0e4745f4948:"width",
	i18nn_c5fa0857bc0df2d6:"height",
	i18nn_208be425bbda6438:"Create a",
	i18nn_d631d93aee55e4e4:"node",
	i18nn_e52d10aff3b6a667:"Define arbitrary magnification",
	i18nn_a1e786ef2dada8de:"Support for decimals",
	i18nn_5a1aa4d777de0438:"definition",
	i18nn_eb108fc5434cdb1a:"zoom",
	i18nn_74aed471f7aeebf8:"Here I'm putting",
	i18nn_30cab4bc8c8a1570:"It's magnified",
	i18nn_4b601bc837a3703c:"times",
	i18nn_bc342cf2cf912e4d:"important",
	i18nn_be0b3f3b2a98fc6b:"Turn off anti aliasing",
	i18nn_cd951a589ae6afd4:"Into",
	i18nn_b379748a6ce5496b:"format",
	i18nn_f66595f0720a11d1:"You can learn about this format online",
	i18nn_6133c5b3bf59043f:"Because it's magnified on top",
	i18nn_40a5987092f371b7:"generate",
	i18nn_23fadc97e516d373:"After that",
	i18nn_d5d4c82cdb5d9d10:"This can be adjusted automatically according to the print size",
	i18nn_138ce5945e0e273a:"paging",
	i18nn_f8c296ea4bc5dd45:"One page height",
	i18nn_2b51275d0b3669da:"Unprinted content height",
	i18nn_a0e80bb860526889:"Page offset",
	i18nn_747f93bdf1f4b8e6:"No paging is required",
	i18nn_181be7ba092f8b80:"Page height",
	i18nn_c966d4f92a75e8ef:"Need Pagination",
	i18nn_d9fedb2a9479ca84:"Avoid adding blank pages",
	i18nn_fdb8ef2b61f6ca70:"Add blank page",
	i18nn_662e6711e07501da:"Save as",
	i18nn_f156a9536049f461:"file",
	i18nn_811095e72ac19eba:"The background is usually set to white",
	i18nn_9fca1077909d3d2b:"Otherwise, there will be black in the place where there is no content outside the picture",
	i18nn_0bd75225078f343c:"Sometimes it's necessary to",
	i18nn_86274430e1c003db:"Set in style",
	i18nn_a8405b6dd99d594b:"White background",
	i18nn_6773ddd95bc0757b:"area",
	i18nn_92de017242c28a56:"county",
	i18nn_543a597c8b5342e7:"Province data",
	i18nn_826e79803d1bc2fe:"Option box size",
	i18nn_f0584015c235d1c1:"Layout size",
	i18nn_a544f827b30a69f4:"Layout interval",
	i18nn_43dc54253f7448e0:"Initial assignment",
	i18nn_9075502784cc4b3e:"Select Province",
	i18nn_c7fee137468893c7:"Empty the city",
	i18nn_59c682bd0cf21bca:"Qingkong District",
	i18nn_998f2f4329ec0971:"Empty the warehouse",
	i18nn_94b44280fa1d5493:"Empty operation",
	i18nn_00384cd4bb9a2292:"Trigger parent component method",
	i18nn_e6b1c2a1fc07e7d3:"Choose a city",
	i18nn_0897e206b059fa49:"Display district and county",
	i18nn_f8ca169fd7bcd7b8:"Display warehouse",
	i18nn_82808948d26efcf1:"Select districts and counties",
	i18nn_c6467851c112980c:"Select warehouse",
	i18nn_f74a3a59a7d5364e:"Province Information",
	i18nn_7557b5b6a660da92:"Province information read successfully",
	i18nn_d9b86f5c1d349e5c:"Province information request failed",
	i18nn_5ec78bef9d15434f:"Province information request successful",
	i18nn_36354660b7fc15b1:"City Information",
	i18nn_c08547dbd9309a2e:"empty",
	i18nn_ccf8300d69affc7a:"City information request successful",
	i18nn_271b7085dfabfab6:"City information request failed",
	i18nn_ce6f99466b1a54f0:"District and County Information",
	i18nn_205964e5f8c20982:"District and county information request successful",
	i18nn_996c5bb9df173125:"District / county information request failed",
	i18nn_862c7231ea467e38:"Warehouse information request successful",
	i18nn_de8931d48511795c:"Warehouse information request failed",
	i18nn_dfd0cad4cb8eb442:"support",
	i18nn_3a74667815adc8bf:"maximum",
	i18nn_09049355527ef6a2:"Zhang",
	i18nn_f5bcb37f7eeeb184:"Picture upload to server",
	i18nn_6ae6dd62d24bcb8a:"file type",
	i18nn_229b11e2f431750a:"Alicloud",
	i18nn_7dcf1197e1d2108c:"File list",
	i18nn_cc8603c183589ab1:"Listen for change initializable",
	i18nn_ad4f1f9c62389f04:"Limited size per file",
	i18nn_31439cad15cc246e:"Number of files that can be uploaded",
	i18nn_42d2e83f1c79aa4d:"Container width",
	i18nn_7499c732802bf588:"Picture pop up display",
	i18nn_46ddcffae70a5c3b:"Empty picture",
	i18nn_6f579f3a8a1d652f:"Roll slowly",
	i18nn_a3851d8f06926278:"Scroll to the top",
	i18nn_7b108cdca9ee7832:"variable",
	i18nn_25a0d7bacb0096bc:"roll",
	i18nn_46312513d60a4d12:"Override default upload behavior",
	i18nn_f815e09651e471f8:"Implementation of custom upload",
	i18nn_dffbf7d48cf860a2:"Upload options",
	i18nn_7d35d659ad32a051:"No return",
	i18nn_874b22782fe58a33:"configuration information",
	i18nn_b3742c8e82f10c3e:"Parameter failed",
	i18nn_904b1edd118e1b35:"Upload files in pieces",
	i18nn_02a03d22b029f499:"Call here",
	i18nn_2e4e32c5dd570bfa:"Twice",
	i18nn_7f2f052a5da435ca:"upload",
	i18nn_5fba7f16556a9197:"Hook when file status changes",
	i18nn_2a50e3872522d075:"Add file",
	i18nn_8389ed823b47a9c9:"Both successful and failed uploads will be called",
	i18nn_18ebda1756cafbe9:"File size cannot be larger than",
	i18nn_d9d6266f40f67534:"Unknown file",
	i18nn_21508344d0cf56ec:"Appears when uploading repeatedly",
	i18nn_486db80b86de2c1a:"Function of successful file upload",
	i18nn_d34e97bbd9523da6:"by",
	i18nn_99d6111748eae069:"The elements of",
	i18nn_b15f1d04b9c614ee:"Is the subscript",
	i18nn_dac6d2e1b7f9595e:"Original array",
	i18nn_f5797a71c8dc42f6:"Number of files and return",
	i18nn_b1e0fb9c9e07cee6:"When the numbers are equal",
	i18nn_5106bcad470f79d3:"Multiple images will call the successful method repeatedly",
	i18nn_b9143f93c45fee7f:"Remove duplicate",
	i18nn_669600b917b45f04:"Method implementation code is quite cool",
	i18nn_563f7ec62931b0d7:"Realization idea",
	i18nn_a1792e462520f7a7:"Get the rightmost value without repetition and put it into a new array",
	i18nn_5e981dea2122a654:"When duplicate values are detected, the current loop is terminated and the next round of judgment of the top loop is entered",
	i18nn_58179f501fd44ca0:"Hook before uploading file",
	i18nn_63bc87384ee1bdc5:"The parameter is the uploaded file",
	i18nn_a7c0a534a79af36b:"If returned",
	i18nn_410278c6a1528e67:"Or go back",
	i18nn_9f7ace149cfcb06d:"And was",
	i18nn_48fbbc37d0b71cb7:"Then stop uploading",
	i18nn_d60a3f24e8329e20:"less than",
	i18nn_027eacedab3f8d19:"Picture size cannot be larger than",
	i18nn_987cf2f4c71691ee:"Hook when the number of files exceeds the limit",
	i18nn_10a3c6e1c105c148:"File deletion",
	i18nn_e1ac5c1843d0185e:"Define scroll bar tracks",
	i18nn_73f96ab3340fe950:"Inner shadow",
	i18nn_3dca70270d1a74e9:"fillet",
	i18nn_8a8f72a194eeb1f9:"Define slider",
	i18nn_f6e62f9642018cbb:"home page",
	i18nn_e56ddb7ad8d6586e:"Last page",
	i18nn_8e2be23dd3b81a31:"information",
	i18nn_6a82fdcc3fecddc2:"use",
	i18nn_be0f3b211080d0b2:"Commodity sharing platform",
	i18nn_1dc4a69b5a4078eb:"Shopping hall",
	i18nn_8421526d274c0341:"cotton",
	i18nn_5354b8b40c686fc4:"Bound value",
	i18nn_b742c82472e2ef35:"Tabular data",
	i18nn_8ccadbd008827b22:"Paging data",
	i18nn_42abc1e524ae69dd:"sort order",
	i18nn_41f871978d079858:"sort field",
	i18nn_229f6b78d63e1bac:"Descending order",
	i18nn_2536c7b6fb36b80c:"Ascending order",
	i18nn_bbc49a6941f59913:"classification",
	i18nn_b8d1610b4726176f:"Product Name",
	i18nn_b3853278ad6d5611:"Picking type",
	i18nn_05e29fb623110c69:"Batch number",
	i18nn_ec834692ec6c8e38:"colour",
	i18nn_8d82ee4ab711fb75:"Better than",
	i18nn_ff8a4206aa17e719:"Micronaire value",
	i18nn_6cc2563839443db8:"Resurgence",
	i18nn_73ecfc7be38d4bcf:"Heterogenous",
	i18nn_0bffd1282cb3456e:"Place of delivery",
	i18nn_266d5c5e4cd4a9f2:"Minimum price range",
	i18nn_b0301d3bab660115:"The largest price range",
	i18nn_0d76cd7a958441df:"Changes in the number of displays per page",
	i18nn_dd3b072647434206:"each page",
	i18nn_117aa3f27e5e430c:"strip",
	i18nn_8dfb22636adecc2a:"Number of pages",
	i18nn_b18897c10c4eae3f:"Request data",
	i18nn_abc7a3d149718367:"It's triggered manually here",
	i18nn_6509d9c0082bc83c:"Pagination update",
	i18nn_b5a8e089049df396:"event",
	i18nn_c904eca04ed8d05c:"And pass the value in",
	i18nn_9214ca0902929e8e:"Page number change",
	i18nn_c936d3b74e41b901:"Current page",
	i18nn_8d369ba038658da9:"Request paging data",
	i18nn_2f5702841ed342ab:"Number of current page displays",
	i18nn_168c2364555142eb:"Commodity status",
	i18nn_5780e6a092c399c3:"Off the shelf",
	i18nn_e0199d6ecc3ee81a:"normal",
	i18nn_38e8c4d45d6ca3c1:"In stock for sale",
	i18nn_ed2b2c9b6ff98b31:"Violation",
	i18nn_31e8ec91f4c1e966:"forbid the sale",
	i18nn_303124d23b20ec0a:"Commodity audit",
	i18nn_91cfca219e1b41ce:"No",
	i18nn_00b3af4c32634497:"adopt",
	i18nn_f2d95c318721642c:"Under review",
	i18nn_2e3ee00867931849:"Market type",
	i18nn_4cf78c5cac4a485f:"Trade in bulk",
	i18nn_25e25543c26526d5:"Matchmaker Market",
	i18nn_674e5faed21eb561:"Cotton sorting",
	i18nn_776496ec446f21f9:"Request successful",
	i18nn_4217bc1acbacd2ba:"Table display data",
	i18nn_bc4bced036fdb495:"Total number of current data",
	i18nn_2854e5bf29a64ed3:"Current pages",
	i18nn_d34cf82368ba6d89:"Current number of pages",
	i18nn_323ee425eca7208c:"request was aborted",
	i18nn_2bbc6b12fbdf9019:"Parameter configuration",
	i18nn_ff6fd823ffab7e6b:"export",
	i18nn_a5d230a7299da11c:"Set monitoring",
	i18nn_b862f639d95fb8cf:"Update if you change",
	i18nn_ee5a0b69f9323d4a:"Bold",
	i18nn_d656c6a9c3cbd795:"Italics",
	i18nn_15881fe9a4aa9352:"Underline",
	i18nn_412470089bec1dfd:"Delete line",
	i18nn_c812fd61ee56d868:"quote",
	i18nn_21414c06d3e4ff2c:"code",
	i18nn_57a7de477b2ebe97:"Ordered list",
	i18nn_49132db903eec70f:"Unordered list",
	i18nn_4b9484089a14fff2:"format paragraph",
	i18nn_653d92fa8f1011d3:"paragraph",
	i18nn_32badce0902c46a7:"font size",
	i18nn_d48da5af833f1c63:"typeface",
	i18nn_dbd7312ad2825e55:"Font color",
	i18nn_03e7724664b15858:"background color ",
	i18nn_2c6b89b19200de97:"alignment",
	i18nn_5ed646cd1d97c310:"Hyperlinks",
	i18nn_f5817042904f4a12:"video",
	i18nn_407a5bd47a532136:"reduction",
	i18nn_27ae8de28d3c3821:"View the source code",
	i18nn_fae4e00e66853b23:"Currently entered",
	i18nn_4ebc25f9925a902b:"Characters",
	i18nn_513129bb8856e39f:"Yes",
	i18nn_138162c29671c3cd:"set",
	i18nn_fbf379655e68a5a2:"Article source code",
	i18nn_f86a8104e2fb8111:"The picture is shrinkable",
	i18nn_736b9d8693e29999:"Custom font size",
	i18nn_c53d0b17c0399897:"Custom font type",
	i18nn_481e4318f3bb1637:"Add font to whitelist",
	i18nn_5b27f3993d58438d:"Please input the content",
	i18nn_4ad7644b0a045896:"It is recommended to copy and paste the content",
	i18nn_2c39ccf2839171bb:"After selecting all the contents, click Restore on the far right to edit again",
	i18nn_06f0c71344e7183c:"Add picture",
	i18nn_b79cb734afe08e40:"Lose focus",
	i18nn_2d0a131edd6feb69:"Returned",
	i18nn_67acefa1d6a78e23:"Get focus",
	i18nn_5a3cd7da611f6553:"It can be entered",
	i18nn_2c94eb948dd388f4:"Input text change event",
	i18nn_5d2f8ccdc5abf8e7:"Returned edit object",
	i18nn_c5329b923a1a6213:"Upload pictures",
	i18nn_9b1a4a3b2513a12d:"Upload video",
	i18nn_b83b775c30e763e6:"Add video",
	i18nn_6c47edbd9e030ff5:"Submit upload file",
	i18nn_fd7d693ca5ce068b:"Upload picture callback",
	i18nn_cd47fe86827ac5fc:"Upload file succeeded",
	i18nn_6f1f00781c566d8f:"Upload file returned",
	i18nn_34d2cb61b69affe1:"Set the cursor at the end",
	i18nn_4c871e84a0564f9c:"This method can get the cursor position",
	i18nn_7d0005a132605a5b:"Select file",
	i18nn_222caea401e3439a:"Upload progress",
	i18nn_5fb9c387643c5737:"Contract address",
	i18nn_ee97f044c1f043b9:"stay",
	i18nn_d1c90aa33249c258:"In the middle",
	i18nn_2918e54ff488ec32:"Tag addition",
	i18nn_c4395ffd696b7a01:"And listen for the event",
	i18nn_a85fa7dbdc657d7a:"Open upload file error, please contact customer service",
	i18nn_adbf16ce2dbbe92b:"The browser version is too low to support file method",
	i18nn_75013b3b70e9a3c5:"Please upgrade your browser or contact customer service",
	i18nn_166108de2d7c2027:"Orders, contracts, etc",
	i18nn_fde1f90f1930c21b:"It is empty",
	i18nn_4a4497edde0d7206:"Please contact customer service",
	i18nn_b2fd3b9c18f697dd:"File size settings",
	i18nn_0be4d3c7384011b5:"The picture is unique",
	i18nn_a3159ee97638c70b:"Image processing",
	i18nn_2f2b1c77f45d1a43:"Rotate automatically according to picture information",
	i18nn_f17d5c1c032e68c3:"Automatic image clipping",
	i18nn_6dc79342e50cc171:"Are you sure you want to delete this picture",
	i18nn_92aaf4e1f0afae4c:"Deletion will not be restored",
	i18nn_3e4aebc665e89cae:"Are you sure",
	i18nn_97cb71abaca91a13:"Please contact customer service if there is error in opening the uploaded picture",
	i18nn_81589c78219b1bb5:"Images in different formats",
	i18nn_af98bda0cb94f7f5:"Non Avatar",
	i18nn_1b499d5c744131c7:"Manufacturer sharing platform",
	i18nn_ae4b2d07e3367db4:"reference resources",
	i18nn_d30b41ab51955fc4:"Tailoring",
	i18nn_156c6fd2ea701c2d:"No cutting",
	i18nn_c378a7bc81d60366:"Proportional scaling",
	i18nn_263c9f297c574aff:"Fill transparent",
	i18nn_1ec3705be58c7df8:"Error opening upload picture",
	i18nn_2eb3cd460fd45d17:"Browser version too low",
	i18nn_df5b81201f81955b:"File method is not supported",
	i18nn_739e5a9ed0c91f9e:"Please upgrade your browser",
	i18nn_389498309e60868f:"Upload error",
	i18nn_7fb9848bb94f4881:"Click to view",
	i18nn_a889656035b929cd:"nothing",
	i18nn_d73c73d13129ee77:"Click download",
	i18nn_377fa66b0b28ce31:"see",
	i18nn_34bf5c39dd2578eb:"Modify pop up window",
	i18nn_235e127fd6efba7d:"Switch display",
	i18nn_41413cddfa61538c:"Operation tips",
	i18nn_dc14da6ede09b7f1:"Table display configuration",
	i18nn_90b7f7d6846dfdce:"Export table",
	i18nn_6bf807d0c7a842a5:"Table configuration and export",
	i18nn_85b1942b3831e5fc:"Right container",
	i18nn_07d2a2430ac50e15:"draggable ",
	i18nn_5968ed3c7c608542:"Do not drag",
	i18nn_08d4a6af7c96969c:"Value display",
	i18nn_68b65535b51faedd:"pictures displaying",
	i18nn_91e471ccefc4766d:"File display",
	i18nn_33fc0ec9f5b6203e:"Multiple pictures",
	i18nn_c53675110af9fb34:"Show enabled or not enabled",
	i18nn_889b3ef0590d9f54:"Enable",
	i18nn_11419332713360d2:"Disable",
	i18nn_ac36125b19be0d99:"Format display",
	i18nn_d81d50608932d481:"Other format conversion",
	i18nn_7ea846c3c2d278b0:"Picture display",
	i18nn_1b956381e0763d05:"field",
	i18nn_03dba9f3d9a93a89:"Cell Click",
	i18nn_f80ee7aca6ceb210:"Select header display column",
	i18nn_b4d980ca4788fa0d:"Select display column",
	i18nn_3a7c1bda93906fe9:"Select all",
	i18nn_adbec0a43bad1521:"view picture",
	i18nn_62ba1be0ea549540:"Can columns be dragged",
	i18nn_01af227b74d043eb:"You can't use this drag and drop when you have a table header picture",
	i18nn_f0cf7313369cc153:"Multiple choice",
	i18nn_ce6bc235e66bd4b2:"check box",
	i18nn_1c08595226658d1a:"Violence reappears",
	i18nn_95ce5520e9a9ea05:"Copy header",
	i18nn_cc1eef3b5ac02fff:"Solve the form dislocation",
	i18nn_cd94bbe0a2222fa6:"element",
	i18nn_ae9c49432e02be76:"Display list fields",
	i18nn_03f79798b2ae58b0:"Bullets shown in the list",
	i18nn_e73c9c5607f97963:"Select at least one",
	i18nn_538da37c74898b01:"Click the cell",
	i18nn_c695c8f0e68e65a3:"Select table row",
	i18nn_6ebe6ec4eb502f95:"Multiple choice",
	i18nn_0679c6f94f9fba00:"Multiple row trigger",
	i18nn_71c0553cad2e65de:"Background sort",
	i18nn_726c51702f70c486:"Creation time",
	i18nn_fdc34fd7121f9c48:"Update time",
	i18nn_8bdefb0ca2cdbd9c:"System update time",
	i18nn_ded8f8808ac75c6d:"User registration time",
	i18nn_a2c069022c779043:"default",
	i18nn_f285c0fd3a4c6c07:"Line drag",
	i18nn_fb9424465770cad0:"Column drag",
	i18nn_5777d5244019bd22:"Don't drag here",
	i18nn_48837fdb8d5d6505:"It should not be draggable",
	i18nn_ec5311a7fb2e1420:"Temporarily write here as reset",
	i18nn_c9415e71fa62ceb6:"Delete the mobile node first",
	i18nn_6a4574d58d1a1854:"Then insert the moving node to the original node",
	i18nn_06e7de86935fa403:"Restored the operation of the move",
	i18nn_e1911e9360047dcb:"to update",
	i18nn_e351b00f4bea9840:"array",
	i18nn_b1d828aea8040adb:"next",
	i18nn_e49d6eec201085c0:"I will go",
	i18nn_5b388deb4a4cbdab:"head",
	i18nn_0f0de129ce37f7a9:"Content area",
	i18nn_00b0cc21e5cccf84:"Routing content area",
	i18nn_ed1a05a71adbe5ee:"Yongfei",
	i18nn_9e8d6372f23ed0ae:"copyright",
	i18nn_5b6f28d3282af680:"switch language",
	i18nn_9f5f783b6d4689f8:"Set top menu",
	i18nn_fc21b3a79577d149:"Get submenu data",
	i18nn_89aa0b0fe18cba30:"Menu assignment",
	i18nn_effa44d2cbefe3b3:"Does the route exist",
	i18nn_b1177ef53417dc7a:"Read if it exists",
	i18nn_d62e90c3a1a3dc77:"If it doesn't exist, add it",
	i18nn_3c8f950d7eddf344:"Please confirm whether to exit",
	i18nn_74708f3a8699305a:"Log out",
	i18nn_13ac9fa4999a28f3:"Get menu permission",
	i18nn_c524cff2488f2ebd:"success",
	i18nn_e5368dfd7865a5f1:"Login successful",
	i18nn_cb1872e68cb6c717:"Get menu permission successfully",
	i18nn_2e4051588cdc7268:"Failed to get menu permission",
	i18nn_797fd79ded8f47a0:"Visit history",
	i18nn_5920542fc20b3263:"back-stage management",
	i18nn_0d93ee2eb17156c9:"WMS Client",
	i18nn_bb801fa91df5d1c2:"Are you sure you want to log out",
	i18nn_1237bcd5e3a8f579:"History tab",
	i18nn_952c01d2653fd0ec:"Is there any login information",
	i18nn_a2e9c78c71a78745:"There is no sign out",
	i18nn_528f3ff5da537927:"Get user rights menu",
	i18nn_48fea1870aa235fd:"Get user permission menu successfully",
	i18nn_725d750832726cc3:"Failed to get user permission menu",
	i18nn_b125fa6fe5308e6a:"Get user menu successfully",
	i18nn_8f0b9b3e6ac16f1b:"Failed to set user rights menu",
	i18nn_14d8615847c3bb28:"Login request failed",
	i18nn_e92f5def0a0b73b1:"Processing menu display",
	i18nn_30191ae543992f85:"call",
	i18nn_9f400fb4cef5c50f:"Add route",
	i18nn_67a710b383c8b449:"Background management system",
	i18nn_bc63cb564276221b:"Signing in",
	i18nn_cc1f108900897a83:"please wait a moment",
	i18nn_a1805b0f0117f109:"user name",
	i18nn_04152d1213c1a758:"enter one user name",
	i18nn_e93cb6cc8d5978ed:"Please input a password",
	i18nn_a6ab8f96b90f8df1:"Login type",
	i18nn_eae360b6837f15e9:"Please select login type",
	i18nn_5cabbc13e4bee21a:"administrators",
	i18nn_db9cb45c970a316e:"Super administrator",
	i18nn_a2dce77193d8d064:"Board",
	i18nn_f0b2eacbbd5eafa9:"record",
	i18nn_4c4e546aa3683ff5:"empty",
	i18nn_61bd9a8fcc2ea788:"reminder",
	i18nn_2376ef707384772d:"Please login with account and password",
	i18nn_7cded08b7116a70d:"Length in",
	i18nn_a1f1ba9fb3797498:"reach",
	i18nn_b698f53c03aa9dc7:"Querying permissions",
	i18nn_5fe6cc0473e0d313:"Login failed",
	i18nn_747b33bfa850b075:"Failed to get user permission menu request",
	i18nn_807a814ed58a93bc:"Clear cache",
	i18nn_fb45aad612581e75:"Warehouse distribution management center",
	i18nn_85f24646cf45f5ee:"Failed to get the user menu",
	i18nn_71bc43a0fa1cbad0:"Please try again or contact the administrator",
	i18nn_1d18c926cdb19606:"Reset",
	i18nn_b20485f2a6e11119:"Second assignment",
	i18nn_d0382156496d94ef:"Please try again and contact the administrator",
	i18nn_9a67dbf9d75ede6d:"format",
	i18nn_62295d661d690460:"And add routes",
	i18nn_5dc4c734e351f7c1:"Is there a sub route",
	i18nn_26c3c98ef539ae99:"Judge the validity of routing",
	i18nn_dc0497912f640aee:"Validity of routing path and parameters",
	i18nn_0f03037ecaf1990d:"Page not found",
	i18nn_d25a9b29d0bb390b:"Log in again",
	i18nn_e097bb9b1216bfd8:"Contact customer service",
	i18nn_0f014c5453716e4b:"Parameter backfill",
	i18nn_c0117e8321811bb4:"eliminate",
	i18nn_a363e5f23b1040fe:"check",
	i18nn_6576ce14f2a92b83:"Remember to click after sorting",
	i18nn_a6dcecbe146242e2:"Confirm sort",
	i18nn_a0cf2fef18330d96:"How to add more than one picture at a time",
	i18nn_cc504b75f45b6c8e:"system",
	i18nn_f9f65e4fadee5183:"mode",
	i18nn_92988957fcbb8456:"key",
	i18nn_ed635542b3fb8fba:"Select multiple pictures",
	i18nn_754c78f5c569030e:"Mouse drag",
	i18nn_5f612e85ebd5642e:"Select picture and drag to",
	i18nn_38f3e4e3906f4cde:"Office No",
	i18nn_b458f03c43a90ee9:"Picking Order No",
	i18nn_31f887c1ada79a99:"Product code",
	i18nn_9eeccc6d6f1ff32f:"Product name",
	i18nn_f27f07c66a08b754:"Applied delivery quantity",
	i18nn_1fa934a0815960b6:"Inventory quantity",
	i18nn_f340f0ba85ea230d:"volume",
	i18nn_877c5a0e44a0eb07:"Product picture",
	i18nn_a332a38ed3832d9d:"Warehousing number",
	i18nn_0e0f0fa9793ae89d:"Shelf Number",
	i18nn_3479171cb6e901db:"error message",
	i18nn_b56fdc27a5fdd70a:"Required",
	i18nn_da7c594e2f707ed1:"Picking location",
	i18nn_d83a23d20001121a:"Applied volume",
	i18nn_858e31654677a536:"Applied delivery weight",
	i18nn_f7f39a6e6d9d26cb:"read-only",
	i18nn_1b60ee08c82b5d9d:"Error message",
	i18nn_7e2929024089363d:"Column width",
	i18nn_60ba17b93050a4ab:"The value is",
	i18nn_08dae0e0a7266acb:"Time",
	i18nn_ac3d810b75671d57:"Rows can be dragged to the specified row",
	i18nn_8be7f943d9509bc0:"Columns can be dragged and moved to the specified column",
	i18nn_3919f3d48a7e1c48:"On duty",
	i18nn_d4980b91d5c8daef:"Enable right click menu",
	i18nn_2b90bfff8b7e3856:"Allow keyboard copy",
	i18nn_f292e1f7ff4f86d6:"Allow drag copy",
	i18nn_fd3258ae9f2db54e:"You can only drag vertically to copy",
	i18nn_20c39ac8a6aeb87d:"Right click the cell to display the menu",
	i18nn_8b456c5396713d9a:"Insert row above",
	i18nn_382c5bafd600d2bd:"Insert row below",
	i18nn_77054b0e5ea4d30d:"Insert column left",
	i18nn_1b2106ded89310ba:"Insert column to the right",
	i18nn_f04cf5507ecc5774:"Remove the row",
	i18nn_327aa3849b3d5c68:"Remove the column",
	i18nn_b4e5993f97fe04ee:"revoke",
	i18nn_1a42987a0df962e9:"recovery",
	i18nn_0607472de4525358:"Alignment",
	i18nn_ea37e4e88aada736:"frame",
	i18nn_29f67f0644a78bf3:"copy",
	i18nn_6b7fbcd658f0ccdb:"shear",
	i18nn_c420ac9915ca288d:"Clear all cell data",
	i18nn_98de889dff3208a6:"Specifications",
	i18nn_ceebc30416e4b421:"Do generate",
	i18nn_637e19a84d9ab6e2:"Backfill formatting",
	i18nn_dd3b9a53a418f0e4:"Data backfill",
	i18nn_a9c5de692b5d3d8f:"Multi image upload",
	i18nn_24c8f46012a25c89:"newly added",
	i18nn_760f75dc397a9cb8:"OK to add",
	i18nn_598ade86cbdf4868:"Place the cursor behind",
	i18nn_217d41539ee86758:"Otherwise, it will move forward automatically",
	i18nn_4f62f8d07bb4054f:"Batch close",
	i18nn_de57dcdfc3e01860:"Finish receiving",
	i18nn_cabc5dc617eef30e:"Statistical time",
	i18nn_5e7740c52e22eefc:"number",
	i18nn_18d7892498938435:"customer",
	i18nn_1df68c34677c2aae:"corporate name",
	i18nn_00b8608bd21b2160:"introducer",
	i18nn_8bdf7efaeff69d77:"Partner information",
	i18nn_dc1706be2ffb6973:"Delete",
	i18nn_7b88d9549cff842d:"Warehouse rental",
	i18nn_619a3387e6517f01:"Cancel editing",
	i18nn_8bafd5202547e50d:"journal",
	i18nn_6ae7ca889532ec40:"cache",
	i18nn_ead881df31939a6e:"Original data",
	i18nn_62a3b0f685e9962c:"Table selected data",
	i18nn_96fb4a4cdd7de04b:"Jump to the first page",
	i18nn_7bf4dc759d2719bc:"Select row data as a component",
	i18nn_3a671c04d1dc0d96:"Select row",
	i18nn_c0a0adbd5c04ac55:"Click to select",
	i18nn_5ad16932d8972191:"page",
	i18nn_bf9e5fb79957d959:"New contact information",
	i18nn_e30e4c1103362d8c:"Delete contact information",
	i18nn_b4cd5c00f153455d:"Please delete or save the first new data first",
	i18nn_b2d4c7fffe79258e:"mailbox",
	i18nn_8758fd50c87d6c9c:"address",
	i18nn_eac88e4b6549c80e:"Delete new",
	i18nn_4d894c107a78fffe:"Restore data",
	i18nn_9376c9a16c52a4a4:"Deep copy",
	i18nn_649d9798f7590713:"Shallow copy",
	i18nn_d1c1b3313ba0a3a1:"Receive new array",
	i18nn_f57f809cbf01b621:"Do not refresh data",
	i18nn_4424d5414a823632:"Change edit state only",
	i18nn_328771b9159af957:"congratulations",
	i18nn_994cb374e601fab3:"Edited successfully",
	i18nn_4ab989bd6abd3ac7:"Added successfully",
	i18nn_84adf48acbd6ad24:"list",
	i18nn_2ee4bebcd386a807:"Called on cancel edit",
	i18nn_a1761c0e88af75ff:"The data is wrong",
	i18nn_9e5e4dfbc3ec6aa9:"Submit information",
	i18nn_bc868e024b80d2e3:"Submitted successfully",
	i18nn_a7d2e953195a5588:"Failed to submit",
	i18nn_4eb3309d6692dbd4:"Please try again",
	i18nn_3bd1ac1378a9cf27:"Delete operation",
	i18nn_18a47a2171c01d1c:"confirm deletion",
	i18nn_7495b1a692c585b5:"Is that right",
	i18nn_e7f7c8278ceafe72:"Delete specific data",
	i18nn_de017dafc266aa03:"Successfully deleted",
	i18nn_54dc58d9d0377bb5:"Deletion failed",
	i18nn_785c25ad46aa1a92:"Confirm completion",
	i18nn_6bfc5f83ed58481a:"Bill of lading No",
	i18nn_e0c16ac094cc2bce:"Product code",
	i18nn_64fed2851f79d875:"Customer code",
	i18nn_7b27c6b2a3f93412:"Package number",
	i18nn_8e01866868930d09:"weight",
	i18nn_ad4b0047e16d89ae:"Unit weight",
	i18nn_57936a4767ded7f5:"Volume unit",
	i18nn_3eb79cfe71bbae0b:"Warehousing code",
	i18nn_bad53577db0da2d3:"Total ",
	i18nn_0bd31bb65ba068e8:"Expected number of goods picked",
	i18nn_06c0936eb86ae31b:"Actual picking number",
	i18nn_ae34bf9aa8b77864:"Number of shelves",
	i18nn_ed482b9aef60900b:"Product image upload",
	i18nn_218ff32b6139e98b:"Picture upload",
	i18nn_90322478b34cd272:"Etc",
	i18nn_aa326abf563f1185:"Size not more than",
	i18nn_ef6eeb5bf7c4a821:"Select warehousing data",
	i18nn_306b9a84a664e731:"Enlarge the picture",
	i18nn_bb8f8c1e0b6e7000:"Open the stock in selection box",
	i18nn_87619aa3fd09e8c2:"Put it on the shelf",
	i18nn_dcf1675624490b2c:"Sector data",
	i18nn_34f5f82ff9c257b5:"Open selection data",
	i18nn_55a803f40c4b3790:"Call back after selecting data",
	i18nn_1dacc6a4a7e86357:"Open picture upload",
	i18nn_5d4a393cc159da1e:"Clear picture",
	i18nn_dbe10ab99c80eb65:"Picture change",
	i18nn_a236026c65338284:"This is the value passed by the subcomponent",
	i18nn_b071e67922639ea1:"Actual number of shelves",
	i18nn_bb0b485a98f17aea:"Total volume on shelves",
	i18nn_4157ea5a8c62064b:"Total weight on shelf",
	i18nn_5d43cbeb679e9830:"Single",
	i18nn_1df24953a2a13e57:"Multiple",
	i18nn_6858c7b5e8d82691:"Data to be edited",
	i18nn_8a2312876de02fd7:"Direct assignment to shallow copy",
	i18nn_2de08c29da81f5f3:"It's also a light copy",
	i18nn_9e36893d5736abb4:"Order list",
	i18nn_2bfebf0495b9cdcb:"Switch list on this page",
	i18nn_e8564657bbe9ca53:"establish",
	i18nn_a51a59d19387d567:"Operation number",
	i18nn_862bfc135547f78e:"Picking list name",
	i18nn_b9cf6fa157d27973:"Commodity category",
	i18nn_0ae3aed4e3b5423d:"In process",
	i18nn_a0acfaee54f06458:"Finished",
	i18nn_ab6ea90b9164b20a:"describe",
	i18nn_ef122ce66f8b5dcf:"Start picking",
	i18nn_02f57850a3029222:"Switch on this page",
	i18nn_57d71ea1aa426464:"View, etc",
	i18nn_b83597729ab29694:"Select customers",
	i18nn_96617ace367556e8:"Please select fee type",
	i18nn_6afbe4c6dcf87a6c:"Expense details",
	i18nn_16aa273f2941c9b5:"data",
	i18nn_a4eca6ff2588d791:"Fee name",
	i18nn_c5a57cce12401a00:"Picking product information",
	i18nn_9a42179fb9922dc1:"Product location",
	i18nn_16658a855f07d171:"amount of money",
	i18nn_442a9ac138407a28:"Beneficiary",
	i18nn_bb5ac0c49150f3b0:"Partner type",
	i18nn_f7e0da8748d5c821:"Distribution mode",
	i18nn_ed085b86f07e3e99:"value",
	i18nn_819a0e214d58c0c3:"representative",
	i18nn_966f3d0952773d84:"Please enter your comments",
	i18nn_d552aa9f1c14363f:"Save edit",
	i18nn_0ddb67d8d6d01ad4:"Save new",
	i18nn_86eac36cf4283081:"Logout pop up",
	i18nn_dad95a93a594366e:"cancellation",
	i18nn_ba0732214679e13b:"Configuration name",
	i18nn_2345771b0cea8487:"entry-into-force time",
	i18nn_0f1834c450f21a65:"Logout Date ",
	i18nn_b45b4338ee939ad0:"Details pop up",
	i18nn_3d76412ed771dbe2:"Secondary configuration details",
	i18nn_2afd7f6f1166341d:"Settlement subject",
	i18nn_c0adeddd1792480d:"Table display",
	i18nn_0d6140ad7af4ed36:"Picking list No",
	i18nn_0bd7d983fe70ea56:"Number of goods picked",
	i18nn_9cee201b82dbc9cb:"Not started",
	i18nn_821531c6e29bd318:"All done",
	i18nn_cadfbfa511d00eb1:"Force completion",
	i18nn_4b95be19dadf6f0a:"Partially completed",
	i18nn_5599155c3578c611:"Abandoned",
	i18nn_a8f9e2b4dcb1c4a4:"effective date",
	i18nn_517d6a5d35a1989c:"Date options",
	i18nn_62a1adfe6207b3c5:"Detailed data",
	i18nn_fecdd1b2ab21bef3:"When new",
	i18nn_3193004ef6dd6bbf:"Must be passed on when editing",
	i18nn_9df79d7120a0a741:"Assigned value",
	i18nn_2aeed2ab99c446d7:"Expiration date",
	i18nn_10f91e600df6414f:"Display only",
	i18nn_f5822cb6006a60f0:"Category selection",
	i18nn_4c6bd84cb427086e:"Open the page directly",
	i18nn_22105cf6781b0f09:"Open new edit",
	i18nn_c91d861ccbaddcd5:"Query data",
	i18nn_75fb4d6c9f31a6e0:"Current page display number",
	i18nn_a13684cbb78b8e81:"Business bill list",
	i18nn_09f074ad0a9bfb6e:"Open the secondary distribution pop-up window",
	i18nn_9c0ace2b5d32f74c:"Request data error",
	i18nn_2ba96cbfad324580:"Pop up update",
	i18nn_dc2f299dc3aeffb3:"Reset",
	i18nn_fa91b99043676081:"Open edit",
	i18nn_3bd29fdff03f23e3:"Split into templates",
	i18nn_e16831d9f37ce74d:"Please select the data to be deleted",
	i18nn_ee32decd972008a6:"Please select the data to log off",
	i18nn_31ce64e52ed71628:"Not required",
	i18nn_373dcbacd938fcf3:"When editing",
	i18nn_6e06968321e13384:"Verify Province, city and county must input",
	i18nn_e386c58d13efe5af:"Merging object properties",
	i18nn_b93f7c8c4e2346f8:"Saved successfully",
	i18nn_9fb513abc029d610:"Drop down list data request failed",
	i18nn_db210f50b5fde38d:"Update specific data",
	i18nn_d96afd0c0de3686c:"Are you sure to delete",
	i18nn_2e58cb9b52e2a214:"Deletion canceled",
	i18nn_70678b883f1517f5:"Submit data",
	i18nn_25593efaf75ce5af:"Details of financial records",
	i18nn_dceb9f39f0d789cc:"currency",
	i18nn_1d5fb99b98e26365:"bar code",
	i18nn_dd8b4f0bf9ce1e74:"Query data dictionary",
	i18nn_3c144f7fa49aeaf8:"Failed to query data dictionary",
	i18nn_9742b74b5a4c77d4:"Query data dictionary interface",
	i18nn_104709397ba68e7e:"form",
	i18nn_33d4cfe5cfd81678:"Compatibility issues",
	i18nn_c517fbaec10de990:"Rich text editor style",
	i18nn_399a045892a8f9a0:"Drag the image to",
	i18nn_d79b54a98b79b8c1:"position",
	i18nn_ce6e0b43e891250d:"Back end not supported",
	i18nn_cc446c9868b9bda0:"Image transfer and upload",
	i18nn_e24642aea868fc42:"Front end upload converted to",
	i18nn_8bf1de8dc482bdf1:"Label is OK",
	i18nn_3547ab0eda19fe64:"There's a problem adding pictures",
	i18nn_ed1dbfbdb704fc24:"Add form",
	i18nn_3a09b6f334222d7c:"Drag save",
	i18nn_e2ef45e045968e11:"Import",
	i18nn_2a2e2fbcc567efc6:"Attachment upload",
	i18nn_b95858c63542921f:"List of attachments",
	i18nn_d54012286fece209:"Download address",
	i18nn_e127ec3da1353c98:"Receipt bill",
	i18nn_79fd9ff9de54ef02:"Initialize upload control",
	i18nn_17a941b7c4c108fe:"Print preview",
	i18nn_886072e393710563:"Printing",
	i18nn_6839940dac08c794:"register",
	i18nn_fb666618a592dd15:"Customer inventory information",
	i18nn_e66a14f7b414a00c:"Please enter the store name",
	i18nn_1af050f1d11dee5b:"Please input keywords and select",
	i18nn_a28112c8cca3514f:"Historical total inventory",
	i18nn_e6a250894a04db4c:"details",
	i18nn_c5820dce4b05019f:"Total stock price",
	i18nn_7c99ec8184b2b9d1:"Location details",
	i18nn_3def3d09b6b0b42a:"Location information",
	i18nn_de53ab10322cd38a:"stock",
	i18nn_16d01744a65f4531:"Store default optional data",
	i18nn_07e0f8f59293e78d:"Please select the customer first",
	i18nn_4045c7be6edea082:"Customer fuzzy search",
	i18nn_9ab1e759ed215b97:"Customer fuzzy request",
	i18nn_398644c781d3f039:"Name search",
	i18nn_7aecdd9e65ae5c7c:"Wrong store name search data",
	i18nn_a575e56eb92b451c:"Store name search data",
	i18nn_0bf3695809741cae:"Unknown",
	i18nn_988794ecaef531dd:"or",
	i18nn_993c4e2a71557fe7:"Approved",
	i18nn_a048bb76469dd141:"Automatic picking",
	i18nn_1e2ddc6db3db8200:"Print picking list",
	i18nn_7f12d7a3ac88b55e:"Modify waybill",
	i18nn_19ec51d5bd5fc832:"No,",
	i18nn_c301de1e444a3e90:"To be delivered",
	i18nn_fcf5923a042705f3:"To be recalled",
	i18nn_190dca403b6f540a:"Cancelled",
	i18nn_7f18cb0ceb569eb0:"Attachments",
	i18nn_acea67a875570f7f:"Go to pick up the goods",
	i18nn_55b8714a870ee316:"View picking",
	i18nn_76cd201aeb72dba2:"Details of Datuo",
	i18nn_26130eea27bcb952:"Go to daycare",
	i18nn_112b0c5486af47fd:"To start again",
	i18nn_a395bd8229786778:"Complete the task",
	i18nn_360398d06d6845c8:"Set delivery location",
	i18nn_104421e8ad4f7c4f:"Delivery completed",
	i18nn_d2041ca7c242cd64:"picking ",
	i18nn_87d3b81a6d6723d8:"Batch review",
	i18nn_83f8f6bdfb9fc3db:"Put on the shelves",
	i18nn_fd61d6c2cefddb41:"Datuo information",
	i18nn_4531476fa35570f0:"Waybill No",
	i18nn_f7045d8fb5d91e53:"Batch entry of Waybill",
	i18nn_268e50d838264ca8:"Category",
	i18nn_878e6c82032b80d5:"rate ",
	i18nn_e2e9f6fe81c1a369:"Picking pop up window",
	i18nn_0e240c5802d17fea:"Unit volume",
	i18nn_11e41b2ec3d872ed:"Unit weight",
	i18nn_76dcdaaea25414c6:"Picking date",
	i18nn_f069714744defaba:"Picking details",
	i18nn_1fb1693779a2d16c:"New picking location",
	i18nn_a26a1c375abb4285:"Number of picking pieces",
	i18nn_16c5afb8e869abda:"Attachment view",
	i18nn_97ec3bb319c68844:"Interface",
	i18nn_8854b9baff9494a5:"Picking data",
	i18nn_6d8cbf8c49d6a7e6:"Total picking volume",
	i18nn_3e1652df125209de:"Total picking weight",
	i18nn_4647ba3fc60f0151:"Storage date",
	i18nn_3cce40fc2b7d333c:"Click row to expand the table",
	i18nn_7414f31517552941:"in",
	i18nn_cbc1a1fd9e6c9d70:"We've set it up",
	i18nn_663d98f9fed21ec3:"Value to each row of data",
	i18nn_9e22ab6de55df607:"Open attachment upload",
	i18nn_7b23bac6ffe353b9:"All selected items can be approved only when they are in submitted status",
	i18nn_3e48b039f79c39a0:"Data",
	i18nn_97939133a6d30da8:"Cancel successfully",
	i18nn_ea760847f7cf2e57:"Batch approval",
	i18nn_383c2d8909230165:"Is it approved",
	i18nn_0b96be3d99dc3206:"All selected items can be picked only when they are in approved status",
	i18nn_6ea7bcc065a76da5:"Automatic picking",
	i18nn_493d12b09fa54b95:"All selected items can be delivered only when they are in the status of to be delivered",
	i18nn_5c6f06e49c5e6188:"Does it ship",
	i18nn_5cbd41b354689581:"Only when all selected items are in picking status can picking be completed",
	i18nn_7c5912ab3762973a:"Batch entry",
	i18nn_c3acacf493ad6a41:"Waybill",
	i18nn_5965027c91e708d0:"Submit the entered waybill",
	i18nn_f7e93d88810feb36:"Determine shipment number",
	i18nn_8ae9ba38aa4182dd:"All picking completed",
	i18nn_6a70ed428cf73159:"View details",
	i18nn_6495b78f59be9d4a:"All of them are completed",
	i18nn_502658ab7e58b6af:"Setting issue unknown",
	i18nn_ab87ac4b5567a2dd:"Calculate the total weight and volume",
	i18nn_e2b44fd7a8f1aacc:"Calculate the total weight",
	i18nn_4b3684d07411df81:"Calculate the total volume",
	i18nn_fa4b8c97858f967d:"calculation",
	i18nn_e6560efaa437bead:"Request details list",
	i18nn_a3b3a614f0e2b584:"Open the import pop-up window",
	i18nn_f880656430c365f3:"File cannot exceed",
	i18nn_4dadf13ebce2167d:"Unknown file format",
	i18nn_c8e99e36cefd743a:"File read failed",
	i18nn_15da46e90a2c3e3b:"Exception handling",
	i18nn_abf9f4b8c93d641e:"order number",
	i18nn_e5569530cce59efe:"Drag test",
	i18nn_92c54ea7edec1dce:"Sign for",
	i18nn_d1e7cc74b33a490e:"Abnormal parts warehousing",
	i18nn_cc09153fb6e7d447:"Return waybill entry",
	i18nn_3f27967056329300:"Return entry",
	i18nn_851e87b9fe311af7:"Open the selection box",
	i18nn_c5cdbe2717d0885c:"Open location selection",
	i18nn_885c3cdb72cb2e0b:"Signed in",
	i18nn_abe96316be45d385:"Are you sure to sign for it",
	i18nn_6921bc1023f16466:"Abnormal parts receiving and warehousing",
	i18nn_0b29495c591994a9:"Warehousing name",
	i18nn_a6412f1996e4a96c:"Completion time",
	i18nn_1bbe44c20effdd20:"detail printing ",
	i18nn_87912cccd1e9b132:"Packing list printing",
	i18nn_a6c2cf7ad91292ac:"Number of original stock in pieces",
	i18nn_66880274fe53fdb2:"Recommended upload size not less than",
	i18nn_38bae5210404815f:"Picture of",
	i18nn_2bbd3825f5d84377:"Equal format",
	i18nn_c9494357a4632b3f:"The recommended size is no more than",
	i18nn_a058372131d1eb0d:"to review",
	i18nn_e4cd341c63cb9f12:"Not reviewed",
	i18nn_d7d633d548c8d2ea:"Reviewed",
	i18nn_6e20769a5a1dcf67:"Single check",
	i18nn_d1ccda856600ae3a:"Reset input box",
	i18nn_66bfec97a0d9bbae:"Sub trust information",
	i18nn_226086d3a02580ba:"Check out Datuo",
	i18nn_07cac86ffb75046b:"Invoice No",
	i18nn_e0c689d09fab9404:"Destination warehouse",
	i18nn_f22715c4a2b7630b:"Bill of lading status",
	i18nn_42a92582f098713f:"Index of trust number",
	i18nn_466acbdbf0d357de:"Placement area",
	i18nn_a5046acba2ced5e0:"Details of sub entrustment",
	i18nn_facbddd33662a8c2:"Divided trust",
	i18nn_bb40488af04ed09a:"Designated trust",
	i18nn_85d7199b591e553c:"Invoice all",
	i18nn_35fb6b462cf31828:"Fentuo",
	i18nn_6d4774e43b0e2a8f:"Submit delivery information",
	i18nn_deb1cce28643a520:"Warehouse address",
	i18nn_66d65a315a762976:"Operation button",
	i18nn_28ab2b0d85fa756f:"Start sharing",
	i18nn_e41f20e73d172bbe:"Personal trust",
	i18nn_fe3466680d9d8d20:"Please enter the delivery location",
	i18nn_2e3d588c04ef832e:"Delivery location",
	i18nn_ae1d0428288eeec8:"Trust number",
	i18nn_2ec0f364e8bbaa4c:"Number of deliveries",
	i18nn_94d68bad9f60445f:"Tatuo",
	i18nn_907d5dd39a2bdfd8:"Drag and drop",
	i18nn_8eaa1e53e8fbe252:"Sub trust completed",
	i18nn_174a161a6ab78443:"Please send",
	i18nn_0d4d7e978c7d8f51:"All sub consignments completed",
	i18nn_223662eee6115fc3:"Submit again",
	i18nn_8b240c7ceece2079:"Set default issue location",
	i18nn_6a3f752e6c5923a0:"If there is one",
	i18nn_0772a37dc4042001:"Delivery details",
	i18nn_89413403ccaade4a:"Arrival country",
	i18nn_0607c7a5960734f1:"Arrival Province",
	i18nn_7cab79716236ebb0:"State",
	i18nn_ba8ce6a27a2ce7ef:"Get to the city",
	i18nn_30ca47ff019be06f:"Arrival address",
	i18nn_daa23bc02afc02a7:"Arrival postcode",
	i18nn_2a89532e280a805d:"Manual picking",
	i18nn_bcc14b0ce9338094:"Picking details",
	i18nn_e2c4b9b00d105e39:"Picking details pop up window",
	i18nn_b77298f9f158fb58:"Automatic picking pop up window",
	i18nn_ec2da13066ef0351:"Auto picking callback succeeded",
	i18nn_794cc17f5d24dbe0:"Popup",
	i18nn_55043e046c4de8b4:"Auto picking details",
	i18nn_b29bf013aaec9ce6:"Recipient number",
	i18nn_f9514183570a10fe:"province",
	i18nn_8db45b615b72b7a8:"Address one",
	i18nn_fe0e2bcbae565a93:"Print picking list",
	i18nn_2f66aa86e2aa1c1e:"label",
	i18nn_93bf7c9249938506:"Submit the information of daycare",
	i18nn_1272ee58ec6ee1ad:"Application number",
	i18nn_d4263545654ce610:"use",
	i18nn_37035e9562ec6bcf:"assembly",
	i18nn_8ad21658621a64bc:"drag ",
	i18nn_b430b32f6e542047:"To the corresponding tray",
	i18nn_bb346b646af55bd8:"click",
	i18nn_c08f45e8d7723648:"Click below",
	i18nn_f4b368568f664eae:"We'll try to add new ones",
	i18nn_840cf164b0b46099:"The first and the rest are not",
	i18nn_4155c83b6d3bc02b:"Data for",
	i18nn_0a8c7b53565de4f4:"individual",
	i18nn_b4918924ef5887be:"Delivery area",
	i18nn_eb47d78018466a06:"Defines an array of objects to be dragged",
	i18nn_cda8720e92408ff6:"No docking allowed",
	i18nn_f61fff93d0819b58:"Wok, wok, wok",
	i18nn_a379abcf1154098a:"Bowl bowl bowl",
	i18nn_3a6ea07d88deba69:"Piao Piao Piao",
	i18nn_69cca7059011676d:"Basin basin basin",
	i18nn_70180b416ca792e5:"Dragging is not allowed",
	i18nn_2913f1ad38625b27:"Rice, rice, rice",
	i18nn_ca1a84683c9bf71b:"Common menu",
	i18nn_216f463b7ce4215a:"This is the most critical step",
	i18nn_bc31df4de59f616f:"I didn't handle it",
	i18nn_f5fab291e2bdfcb0:"There will be data confusion later",
	i18nn_7f49b11e275b090c:"By converting to a string",
	i18nn_1977cc34417d9160:"Make him a new object",
	i18nn_e7631d389bd59d26:"Otherwise, dragging as like as two peas will be the same as the first second components.",
	i18nn_441bbac4c41b5ed8:"Change the first component and the second",
	i18nn_3478ca879ecefbae:"The third one will change as well",
	i18nn_a3502344f06f5b38:"according to",
	i18nn_9b05290064fa6b92:"All calculations",
	i18nn_e096accc09ed9b55:"It's recommended to play Tuo",
	i18nn_b238120036242f81:"Delete trust",
	i18nn_4e512ae4de36d74f:"Events when dragging left to right",
	i18nn_7c085826fd4fb451:"If on the left",
	i18nn_760c7a887a6afc3e:"Events when dragging right to left",
	i18nn_392002493ad6f113:"Callback method",
	i18nn_bdb19cd21161c810:"No repetition",
	i18nn_92aff4ef421db044:"The remaining quantity is not",
	i18nn_4087c7aebcdd2979:"Please allocate accurately",
	i18nn_289a4232e2595995:"Please delete",
	i18nn_bc6ac9ce32252fb9:"My trust",
	i18nn_20e9d386d07026ca:"Total quantity",
	i18nn_f566f50ef680920c:"Work order type",
	i18nn_1ee5a245fd386e54:"assignment",
	i18nn_8039faf6d63be278:"return",
	i18nn_b402009cd5e58e1a:"Processing accounts",
	i18nn_647ebbeff5de7017:"Distribution method",
	i18nn_d8669386ff40ccf1:"Assignment value",
	i18nn_2f09651c43c2a375:"Processed",
	i18nn_34e22b977de24408:"Open the secondary assignment pop-up window",
	i18nn_8ccf7ec6c5c949de:"Successfully assigned",
	i18nn_e5462a1be765de05:"Work order details",
	i18nn_3e358d140504ab46:"Work order content",
	i18nn_8200a25ab641ba97:"Attachment information",
	i18nn_10f9cf44daa70871:"Work order log",
	i18nn_1f4c28db7c80a5f5:"Rich text",
	i18nn_dd0bec40af232754:"Chinese title",
	i18nn_209a1dc79c180718:"English title",
	i18nn_a7e730af52ab52b1:"Receiving list",
	i18nn_643e08ba53f72f39:"Packing type",
	i18nn_336569a8af640d81:"Picking list",
	i18nn_cba303e79da77222:"Picking quantity",
	i18nn_c152190d960a3ff7:"Consignment list",
  i18nn_16abbd0c63637be3:"Wrong customer search data",
  i18nn_1cf15e3e5f830da1:"Customer search data",
  i18nn_35c31bde1e2c8b2c:"be careful",
  i18nn_c0e3a584888c5ea6:"Up to upload",
  i18nn_c70b6428abb8c93d:"piece",
  i18nn_24799f4c6ce60dbd:"Please select the file and click upload to the server",
  i18nn_71229f26cb88e30e:"Check format",
  i18nn_a974598ef531812b:"Do not check format",
  i18nn_a05ac587acc6763a:"Verify file size",
  i18nn_b23004db5349dfd2:"Chinese name of product",
  i18nn_05141ac0139eaf01:"English name of product",
  i18nn_791e36963e37617a:"Custom encoding",
  i18nn_198738759379a3c0:"Customs code",
  i18nn_73d237ea940222ce:"Chinese name of declaration",
  i18nn_9894786aad05f849:"English name of declaration",
  i18nn_fe28507421c4c3b3:"Declared value",
  i18nn_2cba96917484569a:"length",
  i18nn_619d7e563f879811:"Receiving goods",
  i18nn_1dfb3a065d3ffe39:"Receiving completed",
  i18nn_c24587738eb25651:"Jump to page",
  i18nn_5cbf7e07f157572f:"Are you sure you want to confirm the bill",
  i18nn_ed492a621bbf4ecf:"Click the pop-up window in the status of non accounting",
  i18nn_e741d17b1fd891c2:"only",
  i18nn_ec54decc8e274bbc:"Out of account",
  i18nn_d6f109b7bee2f33f:"Status data",
  i18nn_002e3156942b6047:"Please check",
  i18nn_e8ec92802315a287:"Are you sure you want to",
  i18nn_e969f83400f78bc5:"Through the bill",
  i18nn_aefbfa41c779d472:"Specific data",
  i18nn_7a4aff235c9a01dd:"fail",
  i18nn_6e1b866b2add32bb:"Upload callback",
  i18nn_3144aad544eb9ac4:"Upload succeeded",
  i18nn_d63854ffd4e0f2df:"Total upload",
  i18nn_4195755cd53e871a:"query was successful",
  i18nn_d74d0bd89431d6d5:"Query failed",
  i18nn_e12ec0fbad09bac1:"Keyword search data error",
  i18nn_61d304ff72c5c155:"Keyword search data",
  i18nn_785ee8efb6d09b2a:"Billing details",
  i18nn_36edafcc55cf50f6:"Label query",
  i18nn_4ca88dd2e7071844:"Cancellation of billing",
  i18nn_06dd7cb65641390b:"Delivery status",
  i18nn_90429ac39f4f4634:"Billing progress",
  i18nn_48ebfc4319cbac59:"Order No",
  i18nn_b0c50b6ff9674173:"Courier Services Company",
  i18nn_1f90c9eb506ddf15:"Cancel waybill",
  i18nn_3c2605e54bb69e38:"Billing rate",
  i18nn_3d6502c491aca17a:"Submit and print",
  i18nn_d4d1e037938ae65e:"Do you submit the form",
  i18nn_1cb9e763fc0324fa:"Submitted and printed successfully",
  i18nn_f0cb028847831431:"Do you want to check the Label",
  i18nn_d0bb84f55c074e32:"Do you want to cancel the billing",
  i18nn_327144b46bd2854b:"Query label to label list",
  i18nn_e6d7aa8c4f2fc0c7:"Querying",
  i18nn_32aff432f169204e:"Query Label information",
  i18nn_34e1e57771d4bd81:"Refreshed",
  i18nn_ab719d5d91ae7b5b:"Failed to query Label",
  i18nn_0da14d270576fc82:"Query Label interface",
  i18nn_0b3917ac0797b5b3:"Default optional data",
  i18nn_762ffdac60685e7e:"Dropshipping details",
  i18nn_1324a1d91ae3de53:"Label address",
  i18nn_d99d790ec4383bfb:"Operation time",
  i18nn_e02e5da233bd816b:"intimidate",
  i18nn_db48f5ae63408e0f:"This is a passage",
  i18nn_7ce351f5edbbf8c0:"activation",
  i18nn_f69f323dc7e9ec22:"Los Angeles departure",
  i18nn_65deb1358842a262:"Billing rate query",
  i18nn_a33831a969cda728:"Order selection",
  i18nn_77cf8937f28bff9b:"Get selected data",
  i18nn_6cf7f1b329fdc67f:"Query billing rate information",
  i18nn_ea8cd49587f7e54a:"Query billing rate information",
  i18nn_0bd52db433d771f1:"Failed to query billing rate information",
  i18nn_0cdf81c9c7f64805:"Interface for querying billing rate information",
  i18nn_d1df2dbe81a6de76:"Summary of Dropshipping",
  i18nn_c3fa539dca493ed4:"Delay for more than two days",
  i18nn_490aceb0639604e5:"One day's delay",
  i18nn_9e10475eb3e1d075:"Number of new additions in the day",
  i18nn_b22be862e5bc215c:"total",
  i18nn_e4fce3e8821ac4e3:"Management background",
  i18nn_7cbe47cdcaa28905:"List data",
  i18nn_ebe6f6c25130b09a:"Type of warehouse rent charge",
  i18nn_c8239d27c661e123:"Warehouse rental unit",
  i18nn_1b4525c800280581:"Submitting",
  i18nn_10229dd4c7509cdb:"If not, the system will generate it automatically",
  i18nn_4ff968abd6e2fc12:"This field cannot be edited when editing",
  i18nn_b954f8829728e9d2:"Loading",
  i18nn_f4a19c201427a0b1:"Packing number",
  i18nn_e513671dd2474a29:"Arrival date",
  i18nn_38bc71c0dc55904b:"Dimension unit",
  i18nn_367f1e0c18ec661a:"Packing quantity",
  i18nn_b713a5cc2e38eb89:"Successful callback of attachment upload",
  i18nn_634b4be09ed29f37:"Are you sure to ship",
  i18nn_5beb6ed7e8fdff3e:"This is the input of the warehouse",
  i18nn_e0f97fb9747905dc:"code",
  i18nn_b9aa51e503858bf1:"It is recommended to upload pictures with size not less than",
  i18nn_c9b828ca934ba6c6:"Only numbers and letters can be underlined",
  i18nn_d5b3460b4b868e7b:"No more than",
  i18nn_0c9acfecfc4ec2eb:"character",
  i18nn_ad8c1e33ebc7b51c:"No more than",
  i18nn_a8f12f1452cf72c8:"Changes in commodity categories",
  i18nn_462f2e7b92066e43:"The basic attributes of commodities in this category",
  "i18nn_f353b1ebbdc25be4": "fuzzy search",
  "custom_enterfuzzysearch": "please enter(fuzzy search)",
	i18nn_851c121d787993a2:"Inventory statistics details",
	i18nn_d42355e6aef72227:"time frame",
	i18nn_4d3765c415bb4462:"Storage age",
	i18nn_cffd7c9783c11047:"Please select a date",
	i18nn_350662278f221eee:"Inventory statistics summary",
	i18nn_ef71790e2ba5798c:"To be extracted",
	i18nn_61204dd04df0ad13:"Extracted",
	i18nn_81821301b16c8a7f:"return goods",
	i18nn_57495c18e2baebdc:"transport",
	i18nn_34ffc91fe81fa585:"Customer pick up",
	i18nn_5eb29146a398224e:"Kappa",
	i18nn_6373cb1d1204d580:"BOL/FBA",
	i18nn_87bef133f8b3638c:"Warehouse expense statistics",
	i18nn_3c4a40675f59cd1c:"Total expenses",
	i18nn_375acecdfac5c204:"Fee date",
	i18nn_25761be67f072851:"Operation record",
	i18nn_9e33ad4200cd1405:"Batch confirmation",
	i18nn_a4c9e42169129983:"Add all to the bill under the current query criteria",
	i18nn_7ab9966b8fbbf3bf:"Select an operation record to add to an existing bill",
	i18nn_3f8f460475a0854f:"Operation status",
	i18nn_4b5536ea9955714b:"Record number",
	i18nn_6235565b185f0725:"Work order No",
	i18nn_69220152f0875d9a:"Operation name",
	i18nn_37ddb899b42e6e6a:"Add selected record to existing bill",
	i18nn_0cc30e8af9f94edb:"Selected action",
	i18nn_06058087984d7f57:"Existing bills",
	i18nn_e9669e4a9661c203:"Select an existing bill",
	i18nn_e35175fcc579b438:"Bill No",
	i18nn_daf169d1f7dceda0:"Work order No",
	i18nn_9f44ef2f7b7d8178:"Add all to the bill by query criteria",
	i18nn_c4fc947a88b7ef75:"Add operation record to existing bill",
	i18nn_abdf300f02a919dc:"Open bill selection",
	i18nn_3b43d8f1ba1c2287:"Add an operation record to an existing bill",
	i18nn_cd39e1ad92c5a24f:"bill",
	i18nn_c44b77fdd0a0914d:"Query warehouse",
	i18nn_a0caf27419f2effd:"Failed to query warehouse",
	i18nn_4e55f242c71cef9a:"Location barcode",
	i18nn_ce391aeb2469ee01:"Location barcode Download",
	i18nn_582f1ff343f74d13:"Download up to the first 5000 data",
	i18nn_26e732981f29709a:"Open pop-up window",
	i18nn_e5b0d7939b714f1a:"Download location QR code",
	i18nn_6ac98f81bd978520:"Please select a warehouse first",
	i18nn_1c9746bd61ebaa8c:"Account binding warehouse",
	i18nn_b00576fed9b3a1f2:"account number",
	i18nn_b17342e22700eb32:"Select account",
	i18nn_f1e02877d27204c8:"Open the account selection pop-up window",
	i18nn_8f574078dfe6e56c:"request",
	i18nn_a36eda2f87d65f7b:"role",
	i18nn_0f864f84a5e810d6:"Account information",
	i18nn_831ab31568a78285:"Recharge",
	i18nn_cefda9657773f55f:"Query all customers",
	i18nn_a9320232a40b10dc:"Generate prepayment account",
	i18nn_49504f74aad443ce:"title of account",
	i18nn_10d660564497918d:"Query all",
	i18nn_169a0780408f1cce:"account",
	i18nn_4a0cacddeb2e38b1:"Account type",
	i18nn_a06e84801d6582be:"balance of account",
	i18nn_c38333badc226309:"user",
	i18nn_01571203859d6b8c:"Description text",
	i18nn_f7549b7e760a39cc:"Prepaid account generated successfully",
	i18nn_cd79f3c6084173d1:"Recharge succeeded",
	i18nn_54082523ac9409ca:"account statement",
	i18nn_80d0adba3c341069:"Record type",
	i18nn_cd173f918d077f02:"Record name",
	i18nn_5706df6392c2b9ef:"Record amount",
	i18nn_b1813b05de28548d:"Total number of torches",
	i18nn_e7b838a996147e21:"Total boxes",
	i18nn_85a10d3e5ea0e103:"Pieces per box",
	i18nn_ae802858dbd29efa:"Outer box size",
	i18nn_89d99a1a68608f3b:"Full container weight",
	i18nn_736c4f14b7c228e8:"Download page",
	i18nn_8d565f4ef805f787:"Please install not less than",
	i18nn_a902ce087be9d96c:"Version of",
	i18nn_869467a93c7dd4a1:"Installation instructions",
	i18nn_8abb75d7c36c9668:"test page",
	i18nn_83178fcd6fe3c803:"If the test page prints abnormally",
	i18nn_7c496b7f90c2fbb1:"Print control",
	i18nn_aa5bf45727be9c66:"May be abnormal",
	i18nn_cf417fd16cf53e8b:"Please check according to the prompt below",
	i18nn_5e016c05078fb5a4:"Perform installation or upgrade",
	i18nn_7968855735d75a74:"Please refresh the page or re-enter after installation",
	i18nn_2597d782dc405773:"After downloading",
	i18nn_ff21cd07e7a01504:"install",
	i18nn_26dcfdda4a82bb24:"Click next all the time during installation",
	i18nn_23cd29704e4461cd:"After successful installation",
	i18nn_4913ccaad8c4cae5:"Refresh browser",
	i18nn_4df69796238d2be7:"If blocked by the browser",
	i18nn_b9112cb81e44855e:"Please check the far right side of the URL bar",
	i18nn_110656956d1d0165:"Click allow to load and refresh the browser",
	i18nn_3e73f05539beaf1b:"As shown in the figure below",
	i18nn_d2edb127f71c2998:"For other situations, please contact customer service or administrator",
	i18nn_9b6fc3dc177f566c:"Other instructions",
	i18nn_a030f529d8c03632:"Install update method",
	i18nn_c5810f3ceadf7f0c:"If previously normal",
	i18nn_ec0d6a9a594f7bcd:"Problems only due to browser upgrade or reinstallation",
	i18nn_cabc624b3ab6ec1e:"The above installation needs to be performed again",
	i18nn_98ba86c55fc76cd4:"If installed",
	i18nn_e5bc41ba848585dd:"Legacy attachments",
	i18nn_bd42088d06715f22:"Please in",
	i18nn_fa909a5cd6252661:"tool",
	i18nn_d172f2b3474629f9:"Add ons",
	i18nn_b8033d17c41b08f3:"extend",
	i18nn_3137c37384c02ae4:"Remove it first",
	i18nn_630a9924533f6653:"If previously installed",
	i18nn_1c6d906aac2fc13f:"can",
	i18nn_07672c898e0f679f:"Click here to start again",
	i18nn_d88d2c774b992fff:"Official website",
	i18nn_bcafd1d256ec2117:"Local preview configuration page",
	i18nn_6b1a265012492058:"Print debug design",
	i18nn_a4346358e98aea80:"Print control not installed",
	i18nn_db4f2068895975f9:"click here ",
	i18nn_953f4232ebdf7eb2:"Perform installation",
	i18nn_87aa0a1ae8a43312:"The print control needs to be upgraded",
	i18nn_7fd5cb82192c32c2:"Perform upgrade",
	i18nn_4d9c8b1271b74f74:"Please re-enter after upgrading",
	i18nn_f7dc31d13b03f431:"Print service",
	i18nn_6e7e50639b462aa4:"Boot not installed",
	i18nn_e4772dc8568564ed:"Download to perform installation",
	i18nn_c3285ca681070371:"Please refresh this page after success",
	i18nn_7667eacd2bbcd3cf:"Upgrade required",
	i18nn_08cbc831c7629d39:"Please refresh the page after upgrading",
	i18nn_9cad0c89a033c539:"initialization",
	i18nn_05d718c95a72fa48:"Please upgrade or download first",
	i18nn_086a73016c53d692:"abnormal",
	i18nn_89df29c83fd68a9b:"Parse into",
	i18nn_797bcc6c4af7fa88:"Parsed data",
	i18nn_13647b4031487392:"As shown below",
	i18nn_fe735bc696bccb15:"Files",
	i18nn_105937855b43aef9:"Preparing",
	i18nn_db2a83c04d0d85ee:"one",
	i18nn_4820b88f87f4332e:"Basic service charge",
	i18nn_669de9a6d032bb1f:"Pay Service",
	i18nn_6867f16a2ac5e825:"Price",
	i18nn_aec87e7239d5a6a6:"discount",
	i18nn_482bbc1edfb4212b:"Namely",
	i18nn_6fda2cab86ef5cc3:"fracture",
	i18nn_c9e78713e31e5c0c:"two",
	i18nn_f80dedf7c601059f:"Value added services",
	i18nn_7e95458d2a2eef81:"Unloading fee",
	i18nn_4b98d0a614266407:"Order processing fee",
	i18nn_5ef418dc89a48756:"Toll",
	i18nn_8766a8425c9fc7da:"Ex warehouse fee",
	i18nn_4b1a6ff16878595b:"Packaging material cost",
	i18nn_ecbdeee4451a9621:"Repackaging",
	i18nn_4fdda8fb0985d084:"Labeling fee",
	i18nn_b43b2c2848adc46b:"Inspection fee",
	i18nn_95fbe2b6d26e92bc:"List fee",
	i18nn_3fdd0d4d9a7310c2:"Counting fee",
	i18nn_ec4432df79807d32:"Module coding",
	i18nn_a6c64785365eba23:"Service fee type",
	i18nn_04082505d6c655d4:"Service charge code",
	i18nn_881c8c98311f4ef1:"Tab type",
	i18nn_9dc7842043356291:"transfer",
	i18nn_b4b553687e5ffaeb:"Basics",
	i18nn_3cf44bfd6bba96c0:"increment",
	i18nn_2c1e8bfea2b4a66d:"Extract configured",
	i18nn_262120d7cf7f97c3:"Assign value to array dictionary",
	i18nn_780302aa0482685b:"Distinguish between basic and value-added groups",
	i18nn_e4b7d3708af73a94:"Set defaults",
	i18nn_5b177a1b00c63503:"Container handling at port",
	i18nn_e2116489db0ae58b:"Composition of transshipment expenses",
	i18nn_55c4ce60e1e2a8f6:"Outbound operation fee",
	i18nn_7e5c4599cfa54cca:"freight",
	i18nn_9454732776dd845a:"if need",
	i18nn_592ba2f32d800195:"Storage fee",
	i18nn_09552ac5725aa0de:"Ocean import operation cost",
	i18nn_d85ba9ab4e6427c4:"Customs clearance",
	i18nn_f609585cedb25279:"Carry cabinet",
	i18nn_bef6d3f3719bac17:"Outer state",
	i18nn_f9e9644219d3f75f:"Quotation configuration",
	i18nn_5c9f84e4f267dd19:"Transfer configuration",
	i18nn_87e4d2a2afee5f6b:"Transit service",
	i18nn_199f5bded0b23976:"Return and bid exchange",
	i18nn_d7af7ac5cfab3cf4:"Return labeling fee composition",
	i18nn_926ff3559ae0f7e1:"Unpack",
	i18nn_ff85bdffd9d663cc:"Count",
	i18nn_58b12a522f8a4505:"Label replacement and labeling fee",
	i18nn_4451ef6efa66781b:"Packing fee",
	i18nn_3155418abb05309e:"Ex warehouse",
	i18nn_4181240879167208:"Unpacking fee",
	i18nn_24a08f5b0d5b5b7b:"Sorting, counting and putting on the shelf",
	i18nn_224de294b46466af:"Sort sort",
	i18nn_eb8383f1ad65f63a:"Repackaging fee",
	i18nn_3f126633b4bfea56:"Combined packaging",
	i18nn_6b09c7002805bd30:"Take out the attachments",
	i18nn_61f33b788a44c915:"Photo fee",
	i18nn_570125be2fff2e6d:"Destruction and disposal fee",
	i18nn_d60632d7f06399fa:"Cost composition",
	i18nn_6b521743986aaec3:"Warehousing fee",
	i18nn_16ad5a8bda3fbe6c:"Delivery fee",
	i18nn_4d02523e5d95b88b:"Review fee",
	i18nn_b74a51743a2a06e3:"Return parts to shelves again",
	i18nn_73195b8b5e85d58a:"configuration management",
	i18nn_41d67b94402699ed:"Role management",
	i18nn_41f35b4ad3af9df5:"Timing task compensation",
	i18nn_62d1f13b854eb1f7:"Bill management",
	i18nn_fc708500e3251bac:"Unconfirmed",
	i18nn_5534c1866fe37029:"Confirmed",
	i18nn_1164fc3766b98658:"Adjustment amount",
	i18nn_18f2e59ad31254be:"Amount adjustment",
	i18nn_c6b8047d8c2eff05:"Adjusted amount",
	i18nn_b44a4a27cac7433e:"Not issued",
	i18nn_27372599992ff988:"Issued order",
	i18nn_cce168301447d1ba:"have in hand",
	i18nn_bcef26d98d690aba:"Cancel Label",
	i18nn_53ff6abaec297551:"Type of goods",
	i18nn_d9b62506e8e01149:"Transfer work order",
	i18nn_32f88191102e9648:"Shipment order details",
	i18nn_7edc8f4b9d8f2435:"Delivery method",
	i18nn_15e0074dc8713a02:"Must pass",
	i18nn_ca5862cb3b0eff3e:"Work order No. is blank",
	i18nn_1bea7a5b9ac9b8df:"Open pop-up window after delivery",
	i18nn_ca5180fb4e706f0c:"Charging standard configuration",
	i18nn_fd416cd66f25eaa2:"Add configuration",
	i18nn_336f0c0952c94654:"Charge type",
	i18nn_5b3e07a3dae222c4:"Fold namely",
	i18nn_a861c2514faa683b:"to configure",
	i18nn_c8d60a88161de6da:"General configuration",
	i18nn_294ea7a0c08d79ad:"new",
	i18nn_fcba4b5591a04cbe:"Please save the new data first",
	i18nn_74ed28f373ea14da:"Item number",
	i18nn_fc72e5de884434f5:"The charging method is dead",
	i18nn_7079c6a7861b3dca:"local",
	i18nn_df6f9110d032d8f9:"Batch print label",
	i18nn_1cbfbf4afdd75282:"Batch download attachments",
	i18nn_adea92839a4c22b0:"Generate Label",
	i18nn_2b52b35b48065b9b:"to",
	i18nn_7d2ffc27a09ff792:"Start date",
	i18nn_46f7be1133b3e2c6:"End date",
	i18nn_49bff759e9b3498f:"Local batch printing",
	i18nn_c688937c592c594f:"Last week",
	i18nn_a2db995132295e05:"Last month",
	i18nn_502cb8bd057f1605:"Last three months",
	i18nn_a1c05de3b3c1d3af:"Change in current route",
	i18nn_2d75009010a4b35d:"But called when the component is reused",
	i18nn_245df9bea6e8ad3d:"for instance",
	i18nn_bf52d6fc866035de:"For a path with dynamic parameters",
	i18nn_f302ed3011ec4e3a:"and",
	i18nn_6246cc4b005a2f15:"When jumping between",
	i18nn_c2b47bdf46331312:"Because it renders the same",
	i18nn_4f04df1634a24e1c:"Therefore, component instances are reused",
	i18nn_36bfbdf25a9d4c23:"The hook will be called in this case",
	i18nn_863a97c7dbcf6a49:"Can access component instances",
	i18nn_5871ca463c7c5038:"Local printing",
	i18nn_2de6c4eb2e40cf1f:"Please use Google browser",
	i18nn_73845584be23a298:"Files can be printed",
	i18nn_0759f172945714a6:"Are you sure to print",
	i18nn_5542a647676b34b2:"There are no attachments to download",
	i18nn_b7819b9d931008b0:"Compressing",
	i18nn_9c63dfa3ec2d8024:"Batch submission and printing",
	i18nn_fdbfdd6b62e582b1:"The service level supported by batch printing is",
	i18nn_157f5b25af1adf94:"If billing fails",
	i18nn_89d5f61bf8d059a1:"You can print a single order",
	i18nn_52def4f590ebd639:"Select the applicable service level",
	i18nn_422d8aa4b5756fa4:"Confirm to submit and print",
	i18nn_9404be77588b0259:"Data",
	i18nn_3cc534923cd57f47:"Request label list",
	i18nn_ec2bb3dbcfef94ad:"Receipt requisition work order",
	i18nn_e7b4fae7cec06141:"Receipt completion time",
	i18nn_bdc361ba04506136:"Number of boxes",
	i18nn_18c1c1a3ea76e9d3:"Number of Total pallets",
	i18nn_f8fdea45f59d1627:"Outer box weight",
	i18nn_eb8af1b7e16e2069:"Outer box length",
	i18nn_64e6eb209a8a2cff:"Outer box width",
	i18nn_2a02dbcab12c9ee8:"Outer box Height",
	i18nn_d11aca919045a1bc:"Outer box review",
	i18nn_a3b1e5efc70b46eb:"Location query",
	i18nn_f2727d808fbeb34d:"View location details",
	i18nn_036240f3e205cce2:"Location details",
	i18nn_7f6fb8c8bbd8d300:"Start library migration",
	i18nn_c5fcbc314ea993e1:"Destination location",
	i18nn_50838d78708562c0:"Select destination location",
	i18nn_f3178ce0fdbae662:"Please select destination location",
	i18nn_8cc424a5448c923b:"Original location",
	i18nn_5ed60ec54dbdc1d8:"Batch add",
	i18nn_20c5097839a5654c:"Original warehouse",
	i18nn_a02853ebb27ea44f:"Quantity removed",
	i18nn_a2af45b9be0761c3:"Confirm library transfer",
	i18nn_7837b1dec1a2825d:"Transfer Library",
	i18nn_0dc7e221eb87de4c:"Move out of warehouse",
	i18nn_f4e7721d7bffa60c:"Move out of location",
	i18nn_33aa8f4f99900ddf:"Remove",
	i18nn_7a6a82c1525f7b14:"Callback for library transfer selection",
	i18nn_86403b2da007f2c8:"Display migration",
	i18nn_9bbc838d781b8399:"Single add original location",
	i18nn_2a02e80d0a30ad77:"Inventory is",
	i18nn_9b483a6110e422f1:"The database cannot be moved",
	i18nn_f530fe18b6c7eaf6:"add all",
	i18nn_275c1a1b9cdfc981:"Select destination warehouse",
	i18nn_4460b377f745fdd9:"Delete database data to be moved",
	i18nn_000785781fb38c21:"Submit library transfer operation",
	i18nn_2e5176b9dc8f4449:"Default warehouse for location selection",
	i18nn_977ed6286b514ff6:"Container size",
	i18nn_a0f2924544ff3ad8:"Operation warehouse",
	i18nn_b41186fa3480c6dc:"Selected warehouse for import",
	i18nn_cab185a8f0e5b0c2:"Transshipment document",
	i18nn_079e4ad054aecdb4:"One consignment work order",
	i18nn_98b9372724332102:"Batch printing test",
	i18nn_1810ae5cb9c84f46:"statistical information ",
	i18nn_9144ddc68d05d528:"start time",
	i18nn_8b5d709462c8e71e:"End time",
	i18nn_8bbeb2b4db9f282e:"Creation date",
	i18nn_2e4ec5fa375c846a:"Start processing",
	i18nn_5715d8eb76147f98:"handle",
	i18nn_f5ad3484ae46cdb1:"To process the page",
	i18nn_a9aa576fa6cc8db0:"A consignment work order page",
	i18nn_64534a523dc0e8fc:"Transfer work order page",
	i18nn_17051c76a9c7c424:"No order address found",
	i18nn_e7e014b9b622da06:"Determine batch",
	i18nn_4800bde602c5979c:"Request all data",
	i18nn_e80826b3be85d896:"Error in query parameters",
	"i18nn_3ee4c9b76289e93a": "Please enter keyword",
	"i18nn_c944a6686d996ab3":"warehouse",
	"i18nn_ad4345dbaabe1479":"Save",
	i18nn_6b1945319eb18d5f:"Bill of Lading",
	i18nn_66f0e38b9285894b:"Departure mode",
	i18nn_c2e3d79e5e3050f0:"Create transfer order",
	i18nn_51177b2d7ce0d883:"Transfer order details",
	i18nn_bdc361ba04506136:"Number of boxes",
	i18nn_4b2b4820019ef897:"Quantity of single box",
	i18nn_b19f7906dab47f9c:"Delivery method",
	i18nn_eb8af1b7e16e2069:"Outer box length",
	i18nn_64e6eb209a8a2cff:"Outer box width",
	i18nn_2a02dbcab12c9ee8:"Outer box Height",
	i18nn_55ed30102ca735c1:"Gross weight of container",
	i18nn_b5b5d2437dca1e76:"Warehouse code",
	i18nn_fa6ae0aebfee7efa:"Shipping barcode",
	i18nn_32df9cb3d1681477:"Shipping mark",
	i18nn_5fb84776e29f5b72:"Operation notes",
	i18nn_3374a2514ef7dc5d:"Transfer doc editing",
	i18nn_f6283c46ba925ab4:"Delivery warehouse",
	i18nn_e44e9a291d80c593:"Recipient name",
	i18nn_5acbec83efb27445:"Warehouse number",
	i18nn_b29bf013aaec9ce6:"Recipient number",
	i18nn_c4913ab43009b365:"Postcode",
	i18nn_b166e4e8fe9513fa:"country",
	i18nn_f9514183570a10fe:"province",
	i18nn_7cab79716236ebb0:"State",
	i18nn_8db45b615b72b7a8:"Address one",
	i18nn_5165f3ee9377af83:"Driving mode",
	i18nn_5d77e5949de8eef9:"Transfer doc import",
	i18nn_e05b5d049b64b040:"city",
	i18nn_2d13b6f878f30be3:"Telephone",
	i18nn_2ad108ab2c560530:"Please select",
	i18nn_e6bdd31025bb4f9f:"detailed",
	i18nn_ebd257df8f7efc53:"Polygon import",
	i18nn_bd6d1ded173dc477:"Upload self label",
	i18nn_a363e5f23b1040fe:"check",
	i18nn_e712a380cef4e31e:"The sheet number and file name must be the same before matching",
	i18nn_6c3dd9349932c839:"Label file",
	i18nn_5fefbf45c9ca6464:"Upload and verify",
	i18nn_ac71ea3f789a778c:"Check surLabel",
	i18nn_582446272516f554:"Matching status",
	i18nn_cf0c41b879762cdf:"Label No",
	i18nn_70e058bc23d52c1e:"Previous step",
	i18nn_aaf7f7f3df61a6a5:"Re upload",
	i18nn_a93e095e532cd424:"Save the matching Label",
	i18nn_f9104346c336651d:"Label saved successfully",
	i18nn_e5664b79012a5cc1:"You can go",
	i18nn_df43e4fc4ab96cfb:"Module list view",
	i18nn_826ff6f5d853fee5:"Label",
	i18nn_658d7f0cc43ab866:"I'll send one on behalf",
	i18nn_b172f8fd2a3c5ce4:"I got it!",
	i18nn_abdbf97e2d5bdb25:"Sunday",
	i18nn_c2acf27a87ce160a:"Monday",
	i18nn_48ecd1e7be525c75:"Tuesday",
	i18nn_62551a706d64f9c9:"Wednesday",
	i18nn_7c08f11a440bfb1c:"Thursday",
	i18nn_38b82f33d30ed560:"Friday",
	i18nn_54904ecee1cf2823:"Saturday",
	i18nn_aa3b760147bd92e6:"subscript",
	i18nn_a0ba0a35323754ae:"Superscript",
	i18nn_9765f9d440d9715c:"indent",
	i18nn_ef31da179c71e165:"reduce",
	i18nn_b8c98f42caa15ca5:"direction",
	i18nn_ffc679c4b1ea3d2a:"Left menu",
	i18nn_fcb613b8ba83ced9:"routine",
	i18nn_ac089483cf2b4a0c:"Columns",
	i18nn_89304b37afa2f1b0:"current",
	i18nn_578842e17fea3b6a:"Line 1",
	i18nn_cb143acd6c929826:"China",
	i18nn_4fd9ca7a902c7edb:"Line 2",
	i18nn_09b2a7a32b3c8db1:"United States",
	i18nn_c84e312946608267:"Co disassembly and separation",
	i18nn_34051a0fa4c8b001:"And file name",
	i18nn_d11719409666a169:"Display Test",
	i18nn_bb77a02f2157ee4b:"Download and compress",
	i18nn_e61eff8fdd759f9d:"List of order numbers",
	i18nn_267cd7e59f3b0549:"Line by line",
	i18nn_ec9fcd75e3d2b843:"And press",
	i18nn_fe072dfa8e77ac15:"Sequential matching",
	i18nn_cb48ac138393dec7:"One line, one order number",
	i18nn_037ffd217472355a:"according to",
	i18nn_b6bdc839bdc262f3:"Each file",
	i18nn_57998aee70aea45f:"About seconds",
	i18nn_a8980879d3a4c82a:"Please enter and select",
	i18nn_671663add1f18f05:"If the order has completed offline express delivery surface order generation",
	i18nn_8e258808e2a13395:"Please upload the corresponding waybill here",
	i18nn_b6f0167ad2fd3cee:"The order number and file name must be the same to match",
	i18nn_8f2e8ec212959586:"good",
	i18nn_90db8de2b4a16c9d:"Easy Warehouse Platform Order Pull",
	i18nn_3f9e9a6fe6f4f971:"Easy Warehouse Order Status",
	i18nn_590686fdff40723b:"Reference number",
	i18nn_380912455c6e2f06:"Can be queried multiple times",
	i18nn_cd18d57f3bd7779f:"Enter one per line",
	i18nn_68a6337017ca7d67:"Sales order number",
	i18nn_a3b6f85b4a9530ff:"Warehouse code",
	i18nn_a456e74e3c8a95f2:"Pulling",
	i18nn_e99e48ab45c5e800:"Update shipping warehouse",
	i18nn_4f26196e75e6eb1a:"Third party orders",
	i18nn_ab29edcb476d7186:"Generate shipment order",
	i18nn_e9d55d41fee9a273:"Revoke shipment order",
	i18nn_09124c6c5acf6c8a:"Yicang Platform",
	i18nn_79205a47cd5d529c:"Pull the latest order",
	i18nn_b30717f74d1d91b3:"Third party order number",
	i18nn_737061cefdb6d0af:"E-commerce platform tracking number",
	i18nn_b6b08a7daa1de1ab:"Store Name",
	i18nn_d2dd21a61c559695:"Third party platforms",
	i18nn_bdf9dc871938ad08:"Synchronize or not",
	i18nn_e691da78ee2e02f5:"region",
	i18nn_b7f95c7fddc0d602:"city",
	i18nn_ea4756bc1642e0f1:"name",
	i18nn_5b954c149f0b1bdf:"Name of third-party platform",
	i18nn_0f85a73e30bce3c5:"Express delivery channels",
	i18nn_bb74b80c21dcc1a5:"Push date",
	i18nn_2d78a37c0efc2723:"Easy warehouse order retrieval",
	i18nn_b83011b3bfea8797:"split",
	i18nn_90faee419c9a32ae:"Yi Cang",
	i18nn_a6abf1dd9e065e27:"New Configuration",
	i18nn_0e3b6b0b53dd94e8:"Yichang username",
	i18nn_8af36bb95bcac474:"Yi Cang Password",
	i18nn_b306361a5aeda4e8:"Account Notes",
	i18nn_62519f438e8f64b5:"Whether to return the tracking number",
	i18nn_296b2c6a46764af7:"Automatically synchronize or not",
	i18nn_d165fe5a9a65fb8c:"Synchronization method",
	i18nn_ecb75613330d89fa:"Service provider",
	i18nn_27aaf0520109d72e:"Service provider code",
	i18nn_44b27e0a8476a256:"Update warehouse",
	i18nn_3a6fdc59b1cd84c1:"To be split",
	i18nn_02ccd2cf723f9272:"yes",
	i18nn_e76d885ae1c74d4f:"no",
	i18nn_60f5b585fd34c2bd:"Export Sort",
	i18nn_0b2cd5cfcfebe08e:"Easy warehouse inventory synchronization",
	i18nn_c7948588841d75cc:"Inventory synchronization",
	i18nn_dfd9cde6fbd425c7:"Is it possible to synchronize",
	i18nn_3bae5212138a7193:"Number of differences",
	i18nn_ddecfa4a8d6bb743:"Available quantity of easy warehouse",
	i18nn_73e14fb8e120ce61:"Inventory source",
	i18nn_07048291fdb26b6e:"synchronization",
	i18nn_54e4d834b7e2c26a:"Inventory loss",
	i18nn_38624a6aa0667143:"Inventory surplus",
	i18nn_21d58958f280d153:"Rapid transit",
	i18nn_6b42f3e7ab6e4c7e:"More filtering",
	i18nn_c617dfa4c0b5ecb4:"System number",
	i18nn_3d3bc466f13154d4:"Cabinet number",
	i18nn_a31d32ac4015d196:"Tracking number",
	i18nn_6aa78c268e87527b:"Cargo tracking number",
	i18nn_611f87335433e0c3:"Arrival method",
	i18nn_17768c25d95d04ca:"Transport type",
	i18nn_f56c111456a351be:"Arrival date",
	i18nn_e69b8beb7706725b:"Date of receipt",
	i18nn_42274ccae6a91180:"Appointment outbound number",
	i18nn_0bbce74881c893df:"Address Type",
	i18nn_61c33362d88eb0ba:"source",
	i18nn_ea881f28aae703af:"Appointment Type",
	i18nn_b7a786a287eb00e4:"Whether to make an appointment",
	i18nn_fb6105f4b294a9d0:"Pickup time",
	i18nn_c8773d8d74202801:"Number of pallets",
	i18nn_54f17bfe03d79b8a:"Box label",
	i18nn_9168144190f66f5d:"Association number",
	i18nn_ed74196efa90cded:"Edit Shipping Order",
	i18nn_004bc5eedbcc75e0:"Add a waybill",
	i18nn_2f19f86ff8be446f:"After sales",
	i18nn_43f2b49eef6bda66:"Appointment Details",
	i18nn_515bc077445e40dc:"Appointment status",
	i18nn_cc335ba8f4b2cc0d:"Appointment number",
	i18nn_e1e2b21c7b4b951f:"Transfer method",
	i18nn_fa02547543cff0f7:"Whether to change the label",
	i18nn_886ea69af099215d:"Appointment of pallets",
	i18nn_08e5062e3affb676:"Number of labels",
	i18nn_b7f9db36db186f72:"Appointment operation",
	i18nn_da1ca7369f4bde15:"Appointment Form",
	i18nn_68fd8440c5a9dd35:"Delivery completed",
	i18nn_2bcdd4344979655f:"Appointment completed",
	i18nn_e90a876d882d595a:"Expand Appointment Details",
	i18nn_db9b1bd30ea188f8:"Print container dismantling list",
	i18nn_e99e3c2ab95512c3:"Cabinet disassembly completed",
	i18nn_1f1bd51a9a045025:"Transfer to warehouse",
	i18nn_786593149c0bf9f8:"New",
	i18nn_c4ca802cf2230b0b:"screen",
	i18nn_559fed47b0e95407:"Transfer note number",
	i18nn_8af1fd820046d3c1:"Warehousing warehouse",
	i18nn_8221d63993501b1c:"Delivery method",
	i18nn_9be246e60da2a0a2:"Arrival date",
	i18nn_33a85575edeaacaa:"Number of inbound pallets",
	i18nn_daa60c502de1462f:"Logistics tracking number",
	i18nn_696eca4149742942:"Whether to display the products inside the box",
	i18nn_46b944e39511234b:"Additional Services",
	i18nn_0ae081c3b8c4d4a1:"Submission date",
	i18nn_e137950d1897817e:"Whether to unload on site",
	i18nn_9e9277ad9980fd7d:"Expected arrival at warehouse",
	i18nn_f904142c2f2c8106:"Create transfer outbound",
	i18nn_6c1ebb1581a6828e:"Select the goods to be shipped",
	i18nn_35ec768075eb8f4e:"Warehouse entry number",
	i18nn_73fc38e67381ae73:"Outer box identification",
	i18nn_5caaadc380029e3f:"Number of boxes transferred this time",
	i18nn_3f0ecebbd9d2f23b:"available",
	i18nn_d4b6bcb26128130e:"Received details",
	i18nn_e1363c8a04ba532e:"Number of inventory in stock",
	i18nn_678824ae3065f35b:"Number of locks",
	i18nn_94fb3830c62f2c1c:"Available quantity",
	i18nn_51c7caf81e915eb4:"Available boxes",
	i18nn_48bc6e8b56cba243:"Number of boxes in stock",
	i18nn_0ddc3b0b28d12b29:"Number of locked boxes",
	i18nn_53f9f6f694a5b0a8:"Single box size",
	i18nn_9e168db87b716866:"Single box weight",
	i18nn_c5ef0b068e92e23d:"Inside the box",
	i18nn_fd6620afef3fab05:"Outbound warehouse",
	i18nn_e1d76a34da86da92:"whether",
	i18nn_7110aa855f2767a1:"Logistics number",
	i18nn_e98a6f2582037336:"shipment types ",
	i18nn_c301b10948889cb5:"Planned delivery date",
	i18nn_e520453b3f22ad66:"Address selection",
	i18nn_59764ba919e3d70d:"Address Name",
	i18nn_5a9fb0915ecea987:"Contact number",
	i18nn_fa4d50bc26e39b26:"Outer box label",
	i18nn_4de0a112bb772da8:"Warehousing details",
	i18nn_7fba76c83eec6d09:"option",
	i18nn_e186babc2e9cadf4:"save as draft ",
	i18nn_6e33a5e062b119ff:"Whether to display the items inside the box",
	i18nn_3dc377fecc5acdb1:"Receipt List",
	i18nn_202e232a73f0abe1:"View outbound details",
	i18nn_b1d3482f4406f513:"Outbound details",
	i18nn_885206600df4fb3d:"Edit Transfer Receipt",
	i18nn_f6272b352b3f3061:"Add transfer and warehousing",
	i18nn_1f6b9a150926ba64:"Arrival time",
	i18nn_8a43886ff356ed78:"Box identification",
	i18nn_53fa7ff1ea383b2f:"Number of boxes",
	i18nn_70b5c883b227851b:"Box length",
	i18nn_0a389c3a5e3938d3:"Box width",
	i18nn_5c31dfdb8f4781ec:"Box height",
	i18nn_91263f8fa1a94da9:"Box weight",
	i18nn_3107835382623271:"Please separate multiple logistics tracking numbers with English commas",
	i18nn_ffe3865fea625e35:"Search address",
	i18nn_266578312b060df9:"Transit cargo information",
	i18nn_1baf81dc415067f6:"Can be copied in bulk",
	i18nn_925eaf37d939c1f4:"Fill in data in",
	i18nn_a449b9611b8c347c:"Table editing with right mouse button",
	i18nn_b05fb36dbc990e3b:"Please delete blank lines when saving",
	i18nn_bc1e00ff29953589:"Same operation",
	i18nn_4eaadbbbdcab5fe5:"equally",
	i18nn_84858479e2b2e839:"Can be copied and pasted",
	i18nn_7b2dbb677a1ccc93:"When inputting individual data",
	i18nn_ea777e88d4a6de0f:"Click to enter information directly",
	i18nn_069d3b178e94b830:"If the field is a drop-down option",
	i18nn_a81fdd0b73709368:"Double click on the field to display the option value",
	i18nn_2063a37475d39272:"Add Box",
	i18nn_514377f02a8481e6:"Save and submit",
	i18nn_65fe75ea422bdc50:"Transfer outbound",
	i18nn_b6cb663839a53fd9:"Transfer note number",
	i18nn_b1131a0cc3945b5f:"Delivery note number",
	i18nn_579f43c8c657bbbc:"Destination warehouse number",
	i18nn_f2896032570c4d41:"Address information",
	i18nn_eed89121cdfb33c3:"Stocking completion date",
	i18nn_54bfc3e9958727d8:"Delivery completion date",
	i18nn_f4af8c42d7478c6f:"Update Destination",
	i18nn_71a647a484c3f5c2:"Shipping List",
	i18nn_f88b93f4fd02974c:"Number of tray Total labels",
	i18nn_95fb447f1661affd:"Edit outbound",
	i18nn_bab35eeb688f9eae:"Add outbound",
	i18nn_8e23aeb472e03f34:"List to be shipped out",
	i18nn_6feb2a7c40070fa2:"Ordering Information",
	i18nn_80755d56db88fca2:"Please fill in the number of outbound boxes",
	i18nn_4118399786a5251a:"Inventory List",
	i18nn_0950e42c6423c2a7:"Transit inventory",
	i18nn_f608e17fc712987a:"Inventory in stock",
	i18nn_53976ac09b57e51e:"Transferred and stored data",
	i18nn_92958dfd9227a641:"Final process information",
	i18nn_7836f25126b0e111:"If there are multiple logistics tracking numbers",
	i18nn_d9081425db9f2c5f:"Please separate the logistics tracking numbers with English commas",
	i18nn_d420ca5d51e2fc22:"Goods to be transported",
	i18nn_4672c6113ffede32:"Appointment for outbound delivery",
	i18nn_5cd591b578350972:"Address Number",
	i18nn_13247dc8e601ddef:"Transfer and warehousing container number",
	i18nn_934695e82afbe756:"Has the outbound instruction been issued",
	i18nn_1d9d50fca9ffeb39:"Is the appointment completed",
	i18nn_e00cd157c0ac4d1f:"Add Address",
	i18nn_2d34f0565cfc4473:"Select existing inventory",
	i18nn_b2e9a0ce00229898:"Clear operational instructions",
	i18nn_b195f5d99d869b3f:"such as",
	i18nn_43cef0e6de330163:"Labeling requirements",
	i18nn_137d90eaafe33693:"Labeling quantity",
	i18nn_0f03549000bc5f2d:"Labeling method",
	i18nn_b95b66c11ead8aac:"mark",
	i18nn_1d5ba91957adcff9:"Pallet label",
	i18nn_c8c17c7e3fbf62f1:"etc.",
	i18nn_34cc4b37bb2d93a7:"Supporting requirements",
	i18nn_4721a0daf4241a40:"Do you need to take photos",
	i18nn_6c1e4bd2907c0a3d:"Volume measurement",
	i18nn_10abe7527d9d0f26:"Weight measurement and other matters that require warehouse operations",
	i18nn_59550e3d8c8088f9:"make an appointment",
	i18nn_a29209bd396e4bdd:"Appointment method",
	i18nn_d6cecadea664b088:"Display data for reference only",
	i18nn_58830b2f3e6b548e:"Suggest uploading the template again",
	i18nn_7f6c721b57ed0e7b:"Outbound number",
	i18nn_af48c37c06629ec5:"Number of outbound boxes",
	i18nn_ee62c49d9dae1b96:"Transfer and warehousing details",
	i18nn_7c27cb7b39aa1d82:"Transferred and stored goods",
	i18nn_158db9252e21d1a6:"Associated order number",
	i18nn_dae2f81921b3b3c0:"Edit Appointment",
	i18nn_d992d16df3748947:"Add Appointment",
	i18nn_c6bf739e696c2bfc:"Next selected name",
	i18nn_c996cb376f253cb5:"Batch Import",
	i18nn_844521f361060167:"Single entry",
	i18nn_67e91d786f25ae66:"Proxy Operation Panel",
	i18nn_8e3e8fb05382614e:"Editing and other operations",
	i18nn_c39fd3d48389bea7:"Warehousing forecast",
	i18nn_74a5def3116ecd89:"segmentation",
	i18nn_45855d1e066e1764:"rotate",
	i18nn_f82bf2cacfa6042f:"Work Order List",
	i18nn_a38569d545d8e238:"Create a work order",
	i18nn_2a714652d0b17e0d:"Create value-added services",
	i18nn_c508489f1b628c1c:"Work order agent",
	i18nn_14b5f4f572736efe:"Work Order Date",
	i18nn_9b73b93c1607cd97:"Submit Work Order",
	i18nn_f37cb25b8f6a5486:"After downloading the template",
	i18nn_a6278bd3526e47e6:"Direct Import",
	i18nn_23b9e07b17dfefc2:"You can also copy and paste",
	i18nn_705fc0e58821d525:"Medium data",
	i18nn_6172b2d3ca888226:"Right click to add",
	i18nn_898fe40df4262eea:"Edit Table",
	i18nn_6aada61bec5df499:"Please delete blank lines when saving",
	i18nn_2a52107e50015b89:"Double click to select or select data",
	i18nn_3644a90f9f13f0b8:"Work Order Information",
	i18nn_52a8c38184d84581:"System order number",
	i18nn_32b164c53fa35e6d:"Module Name",
	i18nn_61fc9e07fff97930:"Service Type",
	i18nn_68493357463cb368:"Multiple order number search",
	i18nn_05e6db2372e82816:"Order Number Search",
	i18nn_d5031b2bd42d2229:"Order number input box",
	i18nn_33bb5e57aba192e1:"Order number input",
	i18nn_b92065474c13bf38:"Enter one order number per line",
	i18nn_b5511889be42a3da:"Confirm and search",
	i18nn_c3525eb110db58aa:"more",
	i18nn_dc628291530d00b9:"Sign for attachments",
	i18nn_108c7dc6debca017:"Cabinet type",
	i18nn_e4c6b981fdd27703:"Departure time",
	i18nn_76e82afd9c2b6ca1:"Submission time",
	i18nn_31519f8b1453a53e:"Outbound type",
	i18nn_716dcf4d38910c35:"Delivery time",
	i18nn_7e4fc5ad9e8a3d62:"Expected arrival time",
	i18nn_da7eef74bb8e0f6d:"Warehouse completion time",
	i18nn_b4296642bcc9f428:"Return Warehouse",
	i18nn_d7aef993e73ebf60:"Shipping Information ",
	i18nn_3601b5a98803f4d7:"Order issuing method",
	i18nn_4851293ecdd539ed:"Recipient Information",
	i18nn_8b9b30a5bc0eab3a:"mail",
	i18nn_3e429a7c7cf3cab2:"Address 2",
	i18nn_10c015e3241e842e:"State abbreviation",
	i18nn_388b46f93fbfbf4d:"Tracking information",
	i18nn_245a46426329f070:"Tracking number",
	i18nn_b2b1ac4f554665b1:"Insurance and signature services",
	i18nn_0ffca7433738453f:"Only for platform issuance",
	i18nn_7b16a04efec1728b:"Insurance amount",
	i18nn_488e817afcd3b95e:"Sign or not",
	i18nn_3e8b33480ff7e0f7:"Outer box information",
	i18nn_e366dc49c78c8e3d:"Shipping address",
	i18nn_fc554f12d8734681:"Enable or not",
	i18nn_f085596674018f3e:"correlation",
	i18nn_08b0ac0e59879bb8:"When editing and saving",
	i18nn_9cdb5c847968eb64:"Can be directly imported",
	i18nn_53b13e07568ca635:"commodity",
	i18nn_3e764f7ae7f5e850:"Return logistics tracking number",
	i18nn_35b7a8c5deb39056:"Outer box specifications",
	i18nn_1697895cfac3573c:"When one box comes in one piece",
	i18nn_cc492a06237b3169:"The specifications of the outer box can be left blank",
	i18nn_f51d697e212aaa0c:"Selected",
	i18nn_4c72d6edbaf60363:"Warehouse Receipt",
	i18nn_a1ef1454c217a90d:"Manual entry",
	i18nn_f1d6776242105f03:"Value added type",
	i18nn_ad36057ff6a7ce6b:"Add attachments",
	i18nn_f4c19062aa935c68:"customer management",
	i18nn_4438ee6b6104e469:"Sub account",
	i18nn_3e98a7801f9d8a6d:"Belonging to warehouse",
	i18nn_f4e9f3da4fc75304:"Login account not supported",
	i18nn_decbca7186d5d34a:"Ambiguous phone number",
	i18nn_cdd4901a665110a9:"Is it a sub account",
	i18nn_e4842b8507dd415c:"Company abbreviation",
	i18nn_bf5a6019eaff1a94:"Login account",
	i18nn_dae8d92a749abc4e:"Change password",
	i18nn_1afc53810c8d999b:"Customer account",
	i18nn_fbad90bef41ba586:"Select a user account",
	i18nn_010e0573f7c0a8cd:"Example",
	i18nn_45313d26d1c74281:"Enter email",
	i18nn_b906982cc26ae11d:"Telephone, etc",
	i18nn_39128cd2a6819be3:"Contact information content",
	i18nn_4580fd803dfe44d5:"Enter specific content",
	i18nn_6a78da7fb21a7380:"Recalculation of warehouse costs",
	i18nn_158ecb8cbcabc069:"Expense business",
	i18nn_748bca3d68c277f5:"billing cycle ",
	i18nn_3258bbe488af3e79:"day",
	i18nn_aa0fc2e0cf8a9331:"sign",
	i18nn_eb6d28d29b20a456:"Cost recalculation",
	i18nn_ab7c1e41cdae24c5:"Recalculate",
	i18nn_82b5bd967d494be3:"Operation type",
	i18nn_c6e98c83801b5198:"Product name",
	i18nn_599710d641d92957:"Container number",
	i18nn_ed6ece9e43eef321:"type",
	i18nn_656e30fd838de0c2:"Additional Operations",
	i18nn_b0a8a31be81514ba:"charge",
	i18nn_33e215cab320c32f:"View expense details",
	i18nn_600a0535e21fdb62:"Place of residence",
	i18nn_6ddb75919426c9e8:"Suzhou City",
	i18nn_61f6a5148fa3704c:"school",
	i18nn_4cf3d3e9cdca9259:"Contact address",
	i18nn_16e95bf041f1a325:"Wuzhong Avenue, Wuzhong District, Suzhou City, Jiangsu Province",
	i18nn_4335d39df11a6971:"Number",
	i18nn_90194c3c809141d4:"Expense Details Attachment",
	i18nn_f14c1e5fe58824bc:"Surcharge Record",
	i18nn_eebc23576f87cbb2:"Confirm Selection",
	i18nn_6c2519a4169a3bd4:"Download prompt",
	i18nn_9618edd493c9d6ea:"Current total",
	i18nn_83130aa2fa9470ae:"When there is a large amount of data",
	i18nn_f57015be50941d22:"Browser will get stuck",
	i18nn_98feeaa7602d644a:"Please wait a moment",
	i18nn_1c4cee3493d17175:"When the waiting time is too long",
	i18nn_35c67777896ab842:"Suggest closing the page and re-entering",
	i18nn_88acc688b8e99581:"Then download in batches",
	i18nn_5f12f9d48f1551af:"Before downloading at most",
	i18nn_8dd194f4fe9000f0:"exceed",
	i18nn_034351a2ccf9a040:"Suggest downloading in batches after filtering",
	i18nn_9ecdcf15b4299bb8:"Warehouse location import",
	i18nn_e8deb376cbd8558f:"Customer warehouse binding",
	i18nn_9d8eca19feef6cb6:"Warehouse name",
	i18nn_4926f0dc61317402:"Change amount",
	i18nn_93e87666aa87fa25:"Amount that needs to be increased or decreased",
	i18nn_6ab4144b540a8a6a:"Change type",
	i18nn_1466b1690d2ae404:"Cost time",
	i18nn_41d2148209b5edc8:"Recharge Record",
	i18nn_df8b6d3f1ea2753f:"Last update date",
	i18nn_7b7058faea31b99f:"Add recharge record",
	i18nn_89221ad15d2ec113:"File Name",
	i18nn_8c49a4bfd3a78dc2:"Recharge remittance materials",
	i18nn_fa642f1b3c8159cd:"current version",
	i18nn_6a6a43ebb138860a:"Suggested version",
	i18nn_7f7ce15a429dbf34:"Please confirm the password",
	i18nn_3ba51d48e0c6865b:"Print size",
	i18nn_1e24cf708a14ce81:"test",
	i18nn_e373ed3890866bf8:"Print Test",
	i18nn_6648c86d7226f1e2:"Please select a printer",
	i18nn_bf0c35e9445b35ee:"If not selected, default",
	i18nn_a3bda85154d251b6:"Paper size",
	i18nn_777032b851831702:"Preview or not",
	i18nn_08cfa554ac74bc3f:"Label Preview",
	i18nn_54672b9140a3afe0:"printer",
	i18nn_17702b3b5666ee6b:"stop printing",
	i18nn_a85af4ba66084d28:"size",
	i18nn_fdc1d71066710361:"Print Label",
	i18nn_6052fe73b8339cd3:"Printing",
	i18nn_6e57f4deb066020b:"Print Preview ",
	i18nn_9a888b8bf3ba1d24:"Stop and turn off printing",
	i18nn_e3de3b77067f6a24:"singular",
	i18nn_ed6f76dee1d4e5be:"Number of surface orders",
	i18nn_a7daecd641c95b4d:"Single printing",
	i18nn_1a493bfd083dfde4:"Single preview printing",
	i18nn_c21d10868d2cfbc2:"Printing failed waybills",
	i18nn_69b1b60d3a29bb0f:"Reprint",
	i18nn_27e57d9202ec74fc:"Preview printing again",
	i18nn_61b790003a44b0f2:"Original serial number",
	i18nn_23bfb1f8c0bfa5c8:"Single printed sheet",
	i18nn_bdac055c1651b9f6:"print now",
	i18nn_07480238e18d3b89:"Or image",
	i18nn_52a808e5eb9cc79d:"Order serial number query",
	i18nn_9643a3d4e7104ad3:"Query Method",
	i18nn_6eeebe9748c133d4:"Range query",
	i18nn_a5f8ba14adddd7f0:"Fine Query",
	i18nn_0b0a9b957660d0ea:"origin",
	i18nn_3a687c8baff0dddb:"Starting sequence",
	i18nn_38af6326c94fd116:"finish",
	i18nn_f135e419f43a6c8b:"End sequence",
	i18nn_27e24ff9191e254d:"have",
	i18nn_66e4e9fd7415a919:"Order serial number",
	i18nn_65e32ce7238e46e7:"Merge Download Labels",
	i18nn_c4d4d7d025f1d47d:"serial number",
	i18nn_e63fc078a7baf7c9:"Express delivery information",
	i18nn_e83443dd6c718cf9:"Logistics trajectory status",
	i18nn_41d020489ecacbf0:"Logistics update date",
	i18nn_198d8fd115bfc3d9:"Self supplied menu",
	i18nn_15bc1e82df3428d8:"System Issue Order",
	i18nn_ba5c84ce26d97fb2:"Reprint the waybill",
	i18nn_8eb6e92d013f9555:"suspend",
	i18nn_7a9b4f96510fb0e1:"Submitted and can be paused and resumed",
	i18nn_a9eaee9d97fb6c43:"Paused and can be resumed",
	i18nn_9f88e3e6b1e719bd:"Choose a channel",
	i18nn_ab5902ee5c645e01:"Unavailable",
	i18nn_ecc3d56be2ae0baf:"Other parameters",
	i18nn_7e80cfa637ec486b:"Signature Services",
	i18nn_e716ca411b2142e3:"Channel Code",
	i18nn_2ed454931e1c1f73:"Please enter and select",
	i18nn_a87302a8cd63eae8:"Template updated",
	i18nn_daef27ebf42d4adb:"Select the columns to export",
	i18nn_055a45ea5fef0d11:"Filter Item Data",
	i18nn_26ad8f1f07292f65:"Before exporting various bills",
	i18nn_21bae2bd339406af:"More data, slower",
	i18nn_83a93e396379a993:"Export All",
	i18nn_5734a059a4622f20:"ago",
	i18nn_5d6f695ebedef0a4:"Batch Export All Pages",
	i18nn_45605a8587b5efab:"Batch export",
	i18nn_f71701ed55a2e712:"Each batch",
	i18nn_8d8c79fcfaa246f5:"Before exporting",
	i18nn_018de66d9d708dc7:"Including total",
	i18nn_be331b9c430a360b:"Export section",
	i18nn_0d9a272b3124998c:"Export Current Page",
	i18nn_c432271c995f31a5:"Confirm export",
	i18nn_dd3438b8ee62f146:"Export All Pages",
	i18nn_fc8ab6ca436ce57e:"Preview of all attachments",
	i18nn_e8c8c5ee90b04380:"Uploaded",
	i18nn_c1bdca8d2544fee9:"Uploaded attachment",
	i18nn_2bea3f02bd4a8c8c:"Attachment Preview",
	i18nn_520e48e008363ef7:"Select after searching for keywords",
	i18nn_7c3fc1953013745c:"Logistics trajectory query",
	i18nn_f32034adcbc2c3e5:"Business fee items",
	i18nn_76dc341edac65885:"Business type",
	i18nn_c5400c966a9be32d:"Operation module",
	i18nn_2e53a931fe17b287:"Off season calculation parameters",
	i18nn_fa3279450bc594f7:"off-season rates ",
	i18nn_aeb910ebbdad440f:"Peak season calculation parameters",
	i18nn_19e3ed244a2eb1c3:"Peak season prices",
	i18nn_0e914402e9c804dd:"Enable Regularization",
	i18nn_a89500c5feb306cd:"regular",
	i18nn_b85bec86fa809471:"business",
	i18nn_67709252ea4322d2:"Select Business",
	i18nn_6ffc5b58796093bb:"Calculate parameters",
	i18nn_d2b3d0f99eca4fb1:"specify",
	i18nn_6f140c7fab44b6d2:"Enable formula",
	i18nn_2694c4443e3fe806:"Not enabled",
	i18nn_d766c2e817285f0a:"formula",
	i18nn_54d26313aeff4235:"Price configuration",
	i18nn_37d1e22dd3233494:"New Price Time",
	i18nn_ac6555f08d4fd109:"When greater than or equal to",
	i18nn_274045338eb039af:"Chronology",
	i18nn_3514db4364576458:"When",
	i18nn_4e5d40d707d90d7d:"Off Season Charge Price",
	i18nn_d5beb7f89f0e6b61:"Off Season Discount",
	i18nn_b40390257aa6c985:"Peak season charging price",
	i18nn_30e41d362a584108:"Peak season discounts",
	i18nn_397c4e137c9c7c81:"Add price configuration",
	i18nn_7c554958c96fab58:"Choose to copy to the specified customer",
	i18nn_61bd7405863443de:"Copy to specified customer",
	i18nn_27e3e67c62eaa392:"Designated customers, etc",
	i18nn_a407a2fe067a20e6:"Original customer name",
	i18nn_7a806e59f1e812b7:"Expense Type Configuration",
	i18nn_94a6bbd4c69bda08:"Expense Type Code",
	i18nn_90656db9cda8e9e5:"Operating fee",
	i18nn_f9abbe1d9c185407:"Express billing rate",
	i18nn_81f6eaf23b9c56eb:"express",
	i18nn_f7c6fd5cfe8ef5e5:"Institution bound users",
	i18nn_9ce5ada346da7c39:"Enterprise Name",
	i18nn_21a9ea9e8872f02e:"enterprise",
	i18nn_4c9736c24ed29b16:"User account",
	i18nn_1145acd2ac058642:"Institutional enterprises",
	i18nn_3469860ee3533708:"Institution bound warehouse",
	i18nn_b3766d43261ef72d:"Institutional Warehouse Price",
	i18nn_d5942292f805c023:"Institutional warehouse addition",
	i18nn_5ddc19a148f5e5c1:"Return to warehouse",
	i18nn_5151fd7875058ddf:"Warehouse rent",
	i18nn_fc9eb3ba06096cbe:"condition",
	i18nn_8f7ac0044d3ded94:"Peak season",
	i18nn_7b97262a9d0258b3:"Off-season",
	i18nn_8104a3554e1a3034:"Fee items",
	i18nn_4584f1f5c7cc6e72:"Fee details",
	i18nn_a88502dfaa1c0f1e:"business management",
	i18nn_85da697a473926e3:"thawing",
	i18nn_8c919e34ddb841f3:"freeze",
	i18nn_50c0cc32890919fa:"Application enterprise",
	i18nn_10fcff51464fe101:"certificate",
	i18nn_7fe92f41627d9a70:"Review failed",
	i18nn_60456fdef41cac4c:"Frozen state",
	i18nn_18a470eeff7a2bed:"Audit status",
	i18nn_f6152345aa67d0e5:"Perfect state",
	i18nn_8845c7472bacf9fd:"Registration time",
	i18nn_0a442b86842d8f5a:"Enterprise Star Rating",
	i18nn_2ccd11027b844bf2:"Partner's mobile phone",
	i18nn_1aa424e1fb1f2313:"Partner's mobile phone",
	i18nn_7c4924f55ce55589:"User's mobile phone",
	i18nn_901d401d431913a0:"Please enter the user's phone number",
	i18nn_0aef54c2f76d42dd:"Identity tags",
	i18nn_08c2e44bd2e49e57:"Please select an identity tag",
	i18nn_cc68ee555944f23d:"Manufacturer",
	i18nn_9793ebd267f312aa:"agent",
	i18nn_20bce8cda928de93:"Please select the enterprise star rating",
	i18nn_4622f53565591dbd:"Please enter the company name",
	i18nn_97cb2e8579a289d8:"Country of Domicile",
	i18nn_090e2ea2d973d9ec:"Detailed address",
	i18nn_7d823020b0799877:"Please enter the company's detailed address",
	i18nn_689d5dfe51037e00:"Company Office Number",
	i18nn_a0dbea5c5ee32b72:"Please enter the company phone number",
	i18nn_20650eaf339e1fe6:"contacts",
	i18nn_8935a7931669cfde:"Please enter a contact person",
	i18nn_fd722075b445907a:"Please enter the partner's phone number",
	i18nn_2d8b2a9b74b67dd4:"Unified Social Credit Code",
	i18nn_b5fab46e6e28ca45:"Please enter the social unified credit code",
	i18nn_f76ad974fe64cbc9:"Enterprise email",
	i18nn_0da2b8e83a58c95c:"Please enter your company email address",
	i18nn_4442771eba7574b1:"Upload Business License Certificate",
	i18nn_d181d689ca3e0fc3:"Upload account opening certificate",
	i18nn_c732c477b91864bf:"Upload Enterprise Certification Authorization Letter",
	i18nn_27ae0ed997c04daa:"Inventory proof",
	i18nn_8b2af441b1291795:"Tax certificate",
	i18nn_f352f204c0d4ec65:"Enterprise Institutional Management",
	i18nn_a71629b70679f124:"Enterprise label",
	i18nn_558389543b97cb9b:"Enterprise Phone",
	i18nn_699a5577be729dde:"Enterprise Contact Person",
	i18nn_8c3434f7035f8d40:"Partner Name",
	i18nn_dcc52d1264a9722b:"Partner's phone number",
	i18nn_b11cc38d93b92f1c:"Merchant CA ",
	i18nn_cef78ab0fe758fbd:"Star rating",
	i18nn_3d5ce01c5f6e820a:"Business License",
	i18nn_0ee39403e4a88684:"Account Opening Certificate",
	i18nn_5acf4b4f6e330dad:"Enterprise Certification Authorization Letter",
	i18nn_ff2df2d80b2fdce5:"Unified Social Credit Code",
	i18nn_20ba48c609b26cfa:"E-mail",
	i18nn_49f50229125a8a51:"History Popup",
	i18nn_c67f820bdcd91a9d:"Log management",
	i18nn_1cedf24d2e4fd2a0:"application",
	i18nn_d4cfcb57dd2bad40:"Enterprise",
	i18nn_98465bf8d5a398d7:"trade",
	i18nn_e9444828251ef5cd:"Corporate phone number",
	i18nn_6b476b8f0e7c5e8f:"Organizational Code",
	i18nn_1ba21db0a2e4a389:"Enterprise freeze status",
	i18nn_d3a2d4f5a3174de0:"Enterprise Freeze Status Operation",
	i18nn_a7d4eccb211cb6d6:"Registration Phone",
	i18nn_c0a404d1f7558e6d:"current state",
	i18nn_15585d51ec229bda:"Permanently frozen",
	i18nn_4e1c0d9b7f56ed80:"Unknown state",
	i18nn_29fa673774a28f1b:"Ban",
	i18nn_4b832774a18f4806:"General freezing",
	i18nn_70868662be221c2e:"Partner bound warehouse",
	i18nn_3f621a26b8218f81:"partner",
	i18nn_650ac628d61a9cc4:"Partner Name",
	i18nn_c764976f063a67f2:"Please scan or input",
	i18nn_b42006e76f7ab948:"Current scanning history",
	i18nn_098f32f54c38955e:"Current scanned information",
	i18nn_2e885d00d595e429:"Number of scanned items",
	i18nn_8858352f1fa85f37:"Only then will the order be automatically placed",
	i18nn_a2984348f5b674aa:"Scan Status",
	i18nn_eb8407fcf599c7e9:"Incomplete",
	i18nn_9b61f70b3098b686:"Query exception",
	i18nn_92fce53540b740f8:"Abnormal printing",
	i18nn_6692e48729688461:"Is it currently completed",
	i18nn_13f180f311a15217:"Number of hits made",
	i18nn_f84d899216c242b6:"Analysis Results of Label",
	i18nn_74cb497a2bc3955f:"Order number information",
	i18nn_9ccb286534db9538:"Scanned",
	i18nn_4a06d4093d28db10:"Currently scanned",
	i18nn_2b0a6beffb55e690:"Historical scanning",
	i18nn_58213be577a36611:"Ordering results",
	i18nn_db2c3e5fbf7e8716:"Printed",
	i18nn_8b7f3722921430ec:"Printing Times",
	i18nn_4930700ae08b8ce9:"Unprinted",
	i18nn_8e889f5d61f01af9:"Automatically generate batches",
	i18nn_8c1398ec3f931421:"By current search criteria",
	i18nn_1a48aa3ac5484ce5:"Automatic generation",
	i18nn_c3f5d0b14db8e5f0:"Only one shipment per item",
	i18nn_dbea1a6d85f337e5:"Maximum number of shipping orders per batch",
	i18nn_9c83fb25e5b52f0f:"greater than",
	i18nn_5ec7e78c96d85677:"And less than",
	i18nn_d8acaa9f7fa3780f:"Modify shipping address",
	i18nn_1bab2e4f2c65c40f:"Number of inbound pallets",
	i18nn_3f587beffe3d37f1:"Return Processing Details",
	i18nn_62acd8019074883b:"Return and listing",
	i18nn_aa70fc026e3878ab:"Listing time",
	i18nn_48e4efd421dad5b7:"Return order number",
	i18nn_bbb236c847f5d948:"Processed pieces",
	i18nn_99bdc541f0c8e85c:"Number of pieces to be processed",
	i18nn_338b82cfb2144745:"Processing progress",
	i18nn_330d028642043f28:"Number of destroyed pieces",
	i18nn_4f0e045b2f905acc:"Total number of packages packed",
	i18nn_0e96a24e353951fa:"Processing Details",
	i18nn_c6a187c1e4cb202e:"Number of shelved items",
	i18nn_674e806b27aeb130:"Number of items to be put on shelves",
	i18nn_49ed1205788ae14e:"Return and label exchange information",
	i18nn_1e3bd079ae689aba:"Listing information",
	i18nn_35c6a24828ffaf57:"Processing type",
	i18nn_80c8ebbab22bee8f:"Destroy Information",
	i18nn_196943f1dd80dcc6:"Destruction quantity",
	i18nn_9bf67649c36061b1:"Listing location and quantity",
	i18nn_449b0dd4808c9211:"Add shelving location",
	i18nn_d3d6315eb562cea4:"Number of original storage locations",
	i18nn_69783641ed87b618:"New warehouse",
	i18nn_f7c17f0f7ad9ba90:"Return List",
	i18nn_b685200d9e29e2aa:"Go sign for it",
	i18nn_ef712dc1dd7c6dac:"Sign for photos",
	i18nn_df8e7d6b41623197:"Value-added/after-sales Service",
	i18nn_64f869f8b24510bc:"Return and label replacement details",
	i18nn_c0245eb64fff2671:"All received normally",
	i18nn_cddbe40f9813ab94:"Signing time",
	i18nn_b1467e06606a8471:"Actual Received Quantity",
	i18nn_e4342b5989a0c5ac:"Warehousing status",
	i18nn_a47468d856f5ab14:"Single Receipt",
	i18nn_96441274d7db7020:"Signing type",
	i18nn_6a538a850c37009a:"Normal receipt",
	i18nn_efbb5d5eaaddf3e5:"Abnormal Receipt",
	i18nn_5e0724c62e0a56d1:"Number of signed in pieces",
	i18nn_51cfacfe7320fd10:"Confirm receipt",
	i18nn_e4713d4209db1f88:"Express waybill account",
	i18nn_a9dc5000637ff647:"Is it available",
	i18nn_1cacee9f09138a3d:"Tripartite channels",
	i18nn_ade48dee835e25c0:"autograph",
	i18nn_dbcffdf31e86d2d8:"sort",
	i18nn_8d337267addff5d5:"Balance query",
	i18nn_eb763bfab5d5a930:"Express Manifest Details",
	i18nn_21b22a8018f3cba2:"Batch download of Labels",
	i18nn_5b7f75f3443354a2:"Submission and printing time",
	i18nn_afe446fb3a8e3e40:"Clear order number",
	i18nn_50dcfc837d43b873:"Accurate search",
	i18nn_8004917a89ed807b:"heavy",
	i18nn_3d6d2d670fa63b7c:"channel",
	i18nn_316e16926411681a:"Channel provider",
	i18nn_3ad90a648c6bfa99:"Service level",
	i18nn_310c8b0f4df16e08:"Place an order or not",
	i18nn_82b1c8e36ef45bb8:"Whether to issue an account",
	i18nn_728bf6a36a68e566:"Printing progress status",
	i18nn_c6b76f2746d7dc6f:"Printing serial number",
	i18nn_e5c3fd867ea4c8e6:"Account code",
	i18nn_ab6994d16b9b4366:"Waybill fee",
	i18nn_673767ab6ddb37cd:"Waybill fee description",
	i18nn_d353bf2887ca0603:"service charge",
	i18nn_0deaa0a62ac136b6:"Sales Partner Service Fee",
	i18nn_5f6b842a6ff748ab:"Order issuing time",
	i18nn_e4f53ced9388c487:"Shipment Track Query",
	i18nn_526831ff87464f45:"Express delivery waybill",
	i18nn_75087cd54a97247c:"Freight inquiry",
	i18nn_79de6d14b424271b:"Import waybill",
	i18nn_7cdf6a7f9798699b:"Add waybill",
	i18nn_e020fbe62b0c9833:"Channel Code",
	i18nn_1f4c2f1c8fcb66b7:"Order date",
	i18nn_0a84f4853ab4d1ed:"Waybill cost",
	i18nn_037f5845b7a88b16:"Fee details",
	i18nn_b98aefdd52a12eee:"Package Information",
	i18nn_b7367a5ccf9facf3:"From",
	i18nn_ab3ce045a4241cb0:"recipients",
	i18nn_7b8ed340400a6e2b:"name",
	i18nn_549a44bae725f4a2:"surname",
	i18nn_ab653780491c0d4b:"Company Name",
	i18nn_8b90c4cfdf339af8:"Two character code",
	i18nn_6b57a5faf337b87b:"unknown",
	i18nn_a9cbcfc68126f75a:"Business address",
	i18nn_af200a78a2a44559:"Residential address",
	i18nn_6a4dc4a49612041e:"Verify address",
	i18nn_479ea0356ec72989:"Sender Information",
	i18nn_2bfaf322a81c4e8c:"Select Address",
	i18nn_f1e789e4700e15e3:"Select sender address",
	i18nn_79dc0cb30753ec26:"Sender Name",
	i18nn_19252a10a7c4755e:"Label size",
	i18nn_45b0c5bc56871f57:"Query rate results",
	i18nn_ff89821d70424147:"Select this channel and save it",
	i18nn_ea74b89efa9dbdb8:"Query rate",
	i18nn_1ffc6ecc6d0685b6:"When confirming receipt",
	i18nn_107dd8e36bc7c397:"Whether to receive goods on site",
	i18nn_7e06b928579ba3cd:"Add Edit",
	i18nn_ab546359d111c7f4:"Import waybill template",
	i18nn_48e17cdf3460fb7f:"minimum",
	i18nn_aa7237f43c313974:"consult",
	i18nn_b6a8725d78c586be:"Delivery waybill not online",
	i18nn_8f89632c68d3b9a2:"Delay Days",
	i18nn_8b9618de2e85645e:"Express number",
	i18nn_04ea6d4ff8e5c4bb:"most",
	i18nn_d88844033912c1fc:"Error message",
	i18nn_2678054e53eeefdd:"Error code",
	i18nn_094903a0e1dd5849:"Logistics trajectory",
	i18nn_032abee95f72da18:"Other information",
	i18nn_eca5c29ad1a845c1:"Destination Address",
	i18nn_93ba6023a4128d75:"Shipper Information",
	i18nn_e29ea9e7cb029d6c:"Service Information",
	i18nn_847f063538ae47c2:"for short",
	i18nn_ca9b892a79dfa7ee:"Service Command Information",
	i18nn_ceffcc237c2608b9:"total",
	i18nn_50a95a5f20c112cf:"Package identification",
	i18nn_38482e62a277f304:"Latest status details",
	i18nn_5360545085fcff1f:"Other details",
	i18nn_a3a09445e8b04a4f:"Authorization testing",
	i18nn_fc7197a70838eeeb:"authorization",
	i18nn_06ef5de26edd9e88:"Delivery information",
	i18nn_d721bca263c00924:"Date node",
	i18nn_19ce8e68aa3bde0c:"Accounts Payable Management",
	i18nn_70e8592f684aeb7d:"Cost items",
	i18nn_1d4b6488790ad72c:"Payer ",
	i18nn_2306f9f2f9197dd4:"Bill number",
	i18nn_9f105a36ff4efa98:"Payee",
	i18nn_589bc0f7e4769a3e:"Inventory trend statistics",
	i18nn_5c98d407627f383d:"Bill Summary",
	i18nn_5203265398bc2ae1:"Bill Details",
	i18nn_0c306145232cff32:"Summary Statistical Chart",
	i18nn_e3a9b63372854968:"Number of customers",
	i18nn_da35456de5403862:"Total amount",
	i18nn_726d411f60e6631d:"Total number of customers",
	i18nn_0d35fa57b3aab0f7:"Summary by Customer",
	i18nn_14b9b51812811e15:"Total cost",
	i18nn_194d733acdedb737:"One piece shipping operation",
	i18nn_9695030363addb97:"One shipment for outbound delivery",
	i18nn_56b48b3b6de43bde:"Batch transfer and warehousing",
	i18nn_e69eafd9946571ed:"Batch transfer and outbound",
	i18nn_636d7192b222277c:"Surcharge",
	i18nn_e19ca5d2e0c83887:"View Summary Details",
	i18nn_ac53fd8c761abf7e:"Summary Details",
	i18nn_2f281726defd00db:"View billing details",
	i18nn_cf84be5a2f8c584b:"Summarize by Warehouse",
	i18nn_07d4648dfc7810d5:"Warehouse bill",
	i18nn_45eae388d108d4fe:"Operation record status",
	i18nn_c2c4e3a31c61dc7f:"Number of records",
	i18nn_6e9c4e33131b18a7:"Number of applications",
	i18nn_723f3d8f11c640b1:"Maximum load capacity of the pallet",
	i18nn_c65deeaa92c96268:"Total amount",
	i18nn_5ffda681afa71861:"singleton ",
	i18nn_621091be3bb350a9:"Unit price volume",
	i18nn_c726626501fc8bc1:"Export Details",
	i18nn_c11109ccca2c19cf:"Billing statistics",
	i18nn_48c855b33061cbb9:"By type",
	i18nn_d099c84ccfd8a4bb:"By customer",
	i18nn_428b0a18700aa88e:"Summarize by type",
	i18nn_87f891127ffff1f8:"Warehouse cargo volume statistics",
	i18nn_051e757f5c7e8026:"Warehouse cargo volume",
	i18nn_37181c74559db182:"order form",
	i18nn_9954ce6437e66f41:"month",
	i18nn_bb3d670df5390f32:"Months",
	i18nn_ac2c5e612fb71dbb:"day",
	i18nn_c232abd55b5719fb:"monthly",
	i18nn_fadfaed787cc4acf:"Daily",
	i18nn_52b2ff9675f77b3f:"Workbench",
	i18nn_a967bc023b5157c4:"Order statistics of this month",
	i18nn_de44e8c1830a426c:"Quantity of goods",
	i18nn_ee110e3c7304b62d:"Other services",
	i18nn_72917c44e787864a:"offer",
	i18nn_55806c9c59b87e47:"inquiry",
	i18nn_3b4e0cc90e264c6f:"Picking up goods",
	i18nn_b5f81f005ec90ee8:"Overseas warehouse quotation",
	i18nn_d8f295864833fc57:"Inquiry for Truck Trail",
	i18nn_19918f72bcdca413:"Empty container reservation",
	i18nn_c0eb5cb77d8024b6:"document",
	i18nn_fdb7b865dfa87747:"Resource Library",
	i18nn_54153ccc0c756e9f:"Help Documents",
	i18nn_8861e53cbc04161f:"Overseas warehouse",
	i18nn_b5e2fe92fe54ace2:"Headquarters Phone",
	i18nn_ab0778ba44a8f3c1:"Truck Department Phone",
	i18nn_e0a806b3165119e6:"WeChat official account",
	i18nn_0f63cc67b9017a81:"Scan and follow WeChat",
	i18nn_c12ab070fe3cbeac:"Scan WeChat to add",
	i18nn_f600a6d1dbcfce47:"WeChat signal",
	i18nn_b33f004fe453ef3b:"Customer service phone",
	i18nn_8292010ff4193c50:"WeChat",
	i18nn_a4365f7edaab7453:"System optimization feedback",
	i18nn_f9f51c08f4fadac8:"Order control",
	i18nn_e65143a1620ea3df:"Other Links",
	i18nn_411e2be4b5bc7080:"Reciprocal logistics",
	i18nn_d59bf3e617c83b91:"Exchange warehouse management end",
	i18nn_f5924c3c9ec8fb0b:"line",
	i18nn_6d2294fdbfbb6b85:"end",
	i18nn_7d6f758a93cb33b5:"announcement",
	i18nn_1595bdab4d7bf7c2:"Today status",
	i18nn_396cb19960e15651:"Total order quantity",
	i18nn_16d3ec8d8ecbac75:"Time out",
	i18nn_7d814d9a1e592965:"Not processed",
	i18nn_a22056d71c960f0e:"Intercept",
	i18nn_fd445581cff561bf:"Value added service work order",
	i18nn_dd697b8fa767da8b:"pending for customer service",
	i18nn_42d98013e99e7380:"pending for warehouse processing",
	i18nn_4f5a6083d34bdccd:"Shared Files",
	i18nn_af1a2cc986597e90:"Request for inbound",
	i18nn_15aeae6cfcd5725a:"Received quantity",
	i18nn_7689a837889ed035:"Express waybill",
	i18nn_28c0ca2da0ad71dc:"Orders not online",
	i18nn_221fcaddc4cb040a:"Quick Link",
	i18nn_ca43d9909e9fcd5c:"Confirm receipt list",
	i18nn_3d2dd59c0c3efda2:"Receiving information",
	i18nn_eced406a05a38b33:"Off site unloading",
	i18nn_e8e55e3612f95a85:"On site unloading",
	i18nn_70620f04c8129aa1:"Receiving time",
	i18nn_b22e04c70b19a9eb:"Uploaded attachments",
	i18nn_d6e8213d96d6018d:"Save receipt list",
	i18nn_40859a22ab42e313:"Date of arrival at warehouse",
	i18nn_e8ee69190e3c6ddf:"Batch listing",
	i18nn_132d087e57aebb7d:"Step 1",
	i18nn_160393458e0a8b07:"Query pending data",
	i18nn_9c17e9aea46e0c8a:"Step 2",
	i18nn_d2acd7e99625d37f:"Submit listing data",
	i18nn_42b17a0bf7d4ad0b:"Date of listing",
	i18nn_db745f83cbb0a3f6:"Shelving location",
	i18nn_deedfcad4cbb3a00:"Number of listings",
	i18nn_6e961a14e09d9784:"Message notification list",
	i18nn_b40debd910bb74f2:"Notification object",
	i18nn_2528332bfcac14b5:"Message Type",
	i18nn_db1f861ffd10f028:"Notification Title",
	i18nn_bd0db0ad240cc83b:"content",
	i18nn_7df9df0664f60cd8:"Content viewing",
	i18nn_741bf985d2d42039:"Message Notification Management",
	i18nn_0cc0c5206402c130:"Cooperation methods",
	i18nn_ea519c59cd7bec7d:"print pallet label",
	i18nn_55793d80c64c2dc7:"system configuration",
	i18nn_fcd37bf760bc15c3:"Menu management",
	i18nn_6218b3fea806918d:"Main interface",
	i18nn_577ba81af19408f2:"button",
	i18nn_01f47f2bd0c79f92:"menu",
	i18nn_fe054fb92fbd6690:"menu name",
	i18nn_f3e476303b47045b:"First level",
	i18nn_8e5aa3f56700e4b0:"second level",
	i18nn_f49f0068c43d8f20:"Level 3",
	i18nn_af76f74cc6174a6d:"English name",
	i18nn_a5c7073db104f92e:"Whether to hide",
	i18nn_d79e2ed6a2fc66fd:"display",
	i18nn_b978a8259ecfca8f:"hide",
	i18nn_ba14fba14998cbd6:"Menu Information",
	i18nn_f18bbc3b19a8d345:"Parent Menu",
	i18nn_f241d2c8737ad7ba:"Please select the parent menu",
	i18nn_a6356aab65f8178c:"Menu Name",
	i18nn_32fc9710fbb0291b:"Please enter a menu name",
	i18nn_b7004d634b60c827:"Please enter the English name of the menu",
	i18nn_4af676d69de62f2b:"Top level menu",
	i18nn_6eda3e16989e48c7:"Must contain",
	i18nn_973a0a2f334d7703:"Easy to distinguish whether it is a warehouse menu",
	i18nn_61e631acdbe6900b:"Please enter sorting",
	i18nn_ec1a4c45b0b20622:"Permission settings",
	i18nn_e40dab1482ecbe86:"Permission Group",
	i18nn_76c9af09324c78c7:"Login Name",
	i18nn_55e9328f2e63cea3:"Last login",
	i18nn_6c4ee02a7ce0e0be:"Accumulated login",
	i18nn_c4418aa4798740f5:"Permission configuration",
	i18nn_7b82edfeee93b9f7:"Edit administrator",
	i18nn_67cfb454fd187462:"Form prompt",
	i18nn_476a108ec1e1ddc6:"Support adding a backend administrator",
	i18nn_0b271bc5f9a73661:"Set their backend login username and password",
	i18nn_faeeaa921e4fcbf8:"But you cannot log in to the front desk of the website",
	i18nn_288fab15c7304b09:"Administrators must report to a permission group",
	i18nn_15b2d542d7f1043a:"If you do not have permission to select a group, please complete it first",
	i18nn_43c65725d5d64ee9:"Add permission group",
	i18nn_f88c31ed3971ca4d:"step",
	i18nn_db7aa54d2e90e5b8:"Please enter a user account",
	i18nn_cf9cf589607ad707:"Please enter your real name",
	i18nn_719aa883348659cb:"User Password",
	i18nn_ecf43f86317f199f:"Please enter the user password",
	i18nn_6a76fe48d020d59b:"Please enter a confirmation password",
	i18nn_f7043fa3372747f8:"Permission Group Belonging to",
	i18nn_f23e001dbc74dcba:"Please select the permission group you belong to",
	i18nn_e325beec788727cd:"Gender",
	i18nn_557796858a347c06:"male",
	i18nn_b1749374a7524a59:"female",
	i18nn_b9ff7fc2f7dfa2c7:"Please enter your email address",
	i18nn_2a27e61375e7fea8:"Date of birth",
	i18nn_e22d367ea23d6fae:"Detailed address",
	i18nn_15f4f186d9700760:"Please enter detailed address",
	i18nn_dddf8b41201d952c:"Affiliated department",
	i18nn_c6703669fc78ffe6:"Please enter the department under which you belong",
	i18nn_d6a54f83ce3cb04a:"position",
	i18nn_6bbdffcf9d86218c:"Please enter the position",
	i18nn_c14037ef764cfeae:"Remarks Description",
	i18nn_4eed7667ef911a72:"Please enter a note description",
	i18nn_5f01d91d44f46ffb:"Department",
	i18nn_0b6ece8a034c7796:"Can add a permission group",
	i18nn_df812a0da6bcd476:"And name it",
	i18nn_7a9be273020481b8:"Easy to use when adding administrators",
	i18nn_d4573844836678d9:"You can select all functions in the title or select operation permissions one by one based on the functional modules",
	i18nn_124961995dbe464b:"Effective after submission and saving",
	i18nn_886cc0a2040c65a4:"Please enter the name of the permission group",
	i18nn_f13b5a3276612c1e:"Shipping Address ",
	i18nn_ed66f1feaedb8f00:"Other expenses",
	i18nn_4bc20b08caf342f6:"In progress and unsuccessful order issuance",
	i18nn_9e724924dd8b83dc:"Edit Service Type",
	i18nn_22f9df183cbbca11:"After sales records",
	i18nn_4dc6f04018e0f73b:"Problem Type",
	i18nn_9666c59aedbfa70e:"Module Type",
	i18nn_3af42243b3f54e9b:"Created by",
	i18nn_05682dbef5eeb0a8:"Customer service",
	i18nn_f4c5829c71fcd8bf:"Number of progress nodes",
	i18nn_627df7b14df32cdb:"applicant",
	i18nn_dee186b860d4cb4f:"Tracking account",
	i18nn_10a6a2a6124bbb9a:"View progress",
	i18nn_8f8b0799ae9827e0:"Convenient for customer service connection",
	i18nn_c62590f388b2ce71:"Service Description",
	i18nn_466677324278a9a1:"summary",
	i18nn_805c8f776e461e39:"Detailed description",
	i18nn_ef8d08b45c898979:"Update tracking account",
	i18nn_2fc1c1e30d69c8c7:"Service progress",
	i18nn_3c7beea052b8230c:"New progress",
	i18nn_354482714c59064a:"More content",
	i18nn_0f6916ca53218662:"New Service Progress",
	i18nn_b95047968d1358d4:"Progress status",
	i18nn_786280a019b8a1b7:"Detailed Service Description",
	i18nn_caf2eff6e258a06d:"Service Record",
	i18nn_1ba6fbd520bc16df:"Value added service records",
	i18nn_6c6c882d02a35cb3:"Added value-added services",
	i18nn_ec153a16f143e276:"agent",
	i18nn_0512b13b7e3eee11:"Print Flowchart",
	i18nn_b8906d9824b52a7b:"Message board",
	i18nn_ac3f40a45db7c05a:"Number of labels",
	i18nn_2f9954db5ba06447:"Picking type",
	i18nn_7b1927e9f5c2b1a1:"Picking time",
	i18nn_6fba545981478392:"Picture of Stocking completed and outbound completion",
	i18nn_6c1a30d60ca12a5f:"Picture of Stocking completed ",
	i18nn_8139c1fa7c3b121f:"Image of completed outbound delivery",
	i18nn_3b9fcbeb6011f7eb:"All automatic picking",
	i18nn_d6a5d5c63900e166:"From Bill of Lading Information",
	i18nn_4429a015eeb36653:"Number of pallets",
	i18nn_4f1444e2d3ca67a2:"Scan and print attachments",
	i18nn_0b7094d813283593:"From Bill of Lading Details Search",
	i18nn_2cd83801592ec2f1:"Time to be shipped out",
	i18nn_5188115ad64c0874:"Operation attachment",
	i18nn_b3cc9e7df0c87798:"Stocking completed attachments",
	i18nn_82dd2957184fa298:"Outbound attachments",
	i18nn_3fae3719c2e02ccf:"Query self bill of lading details",
	i18nn_55a13021a8604d26:"Leave a message",
	i18nn_305b4f079b790079:"Upload pictures or attachments of completed outbound delivery",
	i18nn_03a135502cffa9fb:"Total number of labels",
	i18nn_d3a9b09bd2f020d8:"Upload Stocking completed images",
	i18nn_cc7fcae614d9722f:"Uploaded Stocking completed images",
	i18nn_1fe8e8eece311626:"Supporting pictures",
	i18nn_5239f60a9c2145f7:"Uploaded Stocking completed image",
	i18nn_33ba2cabce60048d:"Please scan or enter the shipping number",
	i18nn_53f8f97f65af0a5e:"Self pickup type",
	i18nn_63b637700eeaf5a3:"Print Only",
	i18nn_9e8e8134d43fee59:"And pictures",
	i18nn_fd13de5896760ebc:"Check whether it has been typed or not",
	i18nn_71955b9b4416485f:"Lock inventory",
	i18nn_2fc069db0549ff40:"Inventory details",
	i18nn_46ed817ece2a5540:"Locked inventory",
	i18nn_f1c73718e1c8fcb7:"Warehousing source",
	i18nn_1829e02aab962bd5:"Receipt number",
	i18nn_c62b0d9279f5e7cd:"Third party inventory information",
	i18nn_27f0ca34ddd11deb:"Original location data",
	i18nn_028e4de6cb4de767:"New storage location",
	i18nn_11e354526dca680c:"Number of stock transfers",
	i18nn_f3828f9b73412e7d:"custom",
	i18nn_d5d972e6063a3180:"Length calculation",
	i18nn_6d8f9b0c398319ff:"Does it contain batteries",
	i18nn_824815ceb3f6c6db:"Selected",
	i18nn_1ff0f0c0745be010:"Displayed",
	i18nn_f7fac56017a0ca84:"More please",
	i18nn_650abf9b436446ba:"Select Inventory",
	i18nn_2b2633c44fbf02b8:"Outbound quantity",
	i18nn_67d0b6c398583e40:"Lock quantity",
	i18nn_6c86c295863f5218:"Receipt number",
	i18nn_2140e9438c2d1633:"Unlock",
	i18nn_b6ff7f85ee235684:"Inventory unlocking",
	i18nn_467c8054005f46e1:"Unlocking quantity",
	i18nn_97d0138b2632e9c0:"Increasing",
	i18nn_7366500a28c55788:"Negative is a deduction",
	i18nn_6187084ee2cbb89b:"Scan the associated number for a new one",
	i18nn_911974004d5d7e10:"Order number type",
	i18nn_0d17574a2e68d656:"Scan code exchange",
	i18nn_9700e172aec17e89:"Split Number",
	i18nn_bf64e6a6859f2692:"Split completion time",
	i18nn_87daab1c4e709403:"View split",
	i18nn_baccbf2a2e9b5938:"Split completed",
	i18nn_9a2003ebc60baac4:"Fallback",
	i18nn_6726a8914c9083a3:"Split",
	i18nn_2a1d7a54b0818a4b:"Actual number of split pieces",
	i18nn_e3ad85bef8be1a4c:"Split and put on shelves",
	i18nn_2832cf1e501780d2:"Listing progress",
	i18nn_a6b5087ca10391ab:"Split Information",
	i18nn_588fde1c3de565ab:"Statistical chart",
	i18nn_c1b676e21af630d2:"Total inventory",
	i18nn_2c5ae1686e1c4aa2:"Trend statistics",
	i18nn_bced63832451e050:"Real time inventory query",
	i18nn_2f47c3d499a6e740:"Order statistics",
	i18nn_9c7e841fa06de079:"Outbound report",
	i18nn_63ee7265156e13ac:"Statistical methods",
	i18nn_f551589093f4bbfb:"Product keywords",
	i18nn_bb78423a37ae875d:"Single volume",
	i18nn_30d971603621c78d:"Individual weight",
	i18nn_a7162d05268c0fe9:"Hide all inventory locations",
	i18nn_22905878d9b15acc:"Show all inventory locations",
	i18nn_28b7dbfbaf281deb:"Display number of pieces",
	i18nn_4415614666ad24cb:"Display location values",
	i18nn_62f1c7e4748e6c28:"region",
	i18nn_8f9184cef1644784:"Total number of storage positions",
	i18nn_a5606fce0b131a1b:"Inventory location",
	i18nn_b4c5f9946aeeaf70:"Total quantity of goods",
	i18nn_4e0d087d88156795:"Shelves",
	i18nn_750722ca673dfa57:"Supporting position",
	i18nn_52e1fa389a9ced75:"Control Configuration",
	i18nn_762cf41ecbb3ee58:"implement",
	i18nn_243a6ecd4e231c9c:"start-up",
	i18nn_cbf03affa6d6ca3d:"Dictionary Configuration",
	i18nn_2fb94604c67cb05c:"Scheduling Configuration",
	i18nn_b0b291800a22292a:"SMS configuration",
	i18nn_8ed9142b991183f8:"Affiliated system",
	i18nn_70b9676c2dd63579:"Execute Code",
	i18nn_05ee04f297da360d:"frequency",
	i18nn_043d67a736a50795:"Run time",
	i18nn_eacf3e797aa9647b:"Last Run",
	i18nn_06c0f8db93cf78c4:"blocking",
	i18nn_714305346b0dbfb6:"Reminder to",
	i18nn_b8621f081ba09c7c:"Please enter the system you belong to",
	i18nn_75dcca76e9759f0f:"target of execution",
	i18nn_c15ee49569aa67a3:"Please enter the execution object",
	i18nn_b8f5a409163c99eb:"Execution method",
	i18nn_fcd432862f71ff63:"Please enter the execution method",
	i18nn_3b1b41a5d269d87f:"Execution type",
	i18nn_df9911ca9c694630:"Single row",
	i18nn_cde07561d01ba5d5:"block",
	i18nn_5c720579cbdce8e3:"parallel",
	i18nn_75bff8ec707c8591:"Non blocking",
	i18nn_f0bb599bc05cd23e:"Type of actuator",
	i18nn_92388e2b578b2316:"remote",
	i18nn_c82f4615f444bde6:"Execution frequency",
	i18nn_8441f1f7f6d6954d:"expression",
	i18nn_7aae192c0ef52155:"Notify personnel",
	i18nn_fc2a95852c846bae:"Please enter the subordinate personnel",
	i18nn_01e460e0c10324aa:"Select Users",
	i18nn_43753ad5329348d6:"keyword",
	i18nn_24b9c5e694c18344:"nickname",
	i18nn_bf55171bd3928132:"Main account",
	i18nn_7f28764b786320f9:"Enterprise certification",
	i18nn_09737906ddb3e95e:"Partner certification",
	i18nn_16e71584163b9bf7:"Partner Name",
	i18nn_daa52e38a6df288d:"Affiliated Business Unit",
	i18nn_475412859bb2048e:"User Details",
	i18nn_5841c2904805147a:"Chinese name",
	i18nn_b123d7a0a2181bb2:"Region",
	i18nn_5f24dcf49cbc6b69:"Warehouse management",
	i18nn_f7db73b2f3f1f0bf:"Name of affiliated company",
	i18nn_c77a882ff6fa16b9:"area",
	i18nn_bd16e84498ab145f:"square foot",
	i18nn_4c14f792298e4e94:"Dictionary Management",
	i18nn_2f8d01a5e86ae412:"Refresh Cache",
	i18nn_907e5f417e2967fd:"Please select a type",
	i18nn_6e7a6111b87ee282:"Option Description",
	i18nn_eca4e9acff89221b:"Option value",
	i18nn_d0acc6aa5293bb22:"According to type",
	i18nn_1482a8297c1c085e:"check",
	i18nn_15d0f5dfea526482:"Inquiry",
	i18nn_596f184984001117:"Parent type",
	i18nn_ca93d1765ac4dd1d:"Parent option value",
	i18nn_b5ff6d3c4d78b76b:"Modification time",
	i18nn_89beeeac1bb28e32:"Set as default address",
	i18nn_dba7512a76fd42d7:"user management ",
	i18nn_b3a5f4352ef652b5:"Obfuscable",
	i18nn_9423dd99b6cd497d:"Modify phone number",
	i18nn_ebacdd8daa50b7c3:"Import Popup",
	i18nn_2f647e039073d7d4:"Please select the international area code",
	i18nn_ea411a18dd6aface:"Business Unit",
	i18nn_66ed6b56e3438d24:"Add pop-up window",
	i18nn_ca8b02c7ab9cc5c5:"New users",
	i18nn_4d20e95ad039b9b3:"User Information",
	i18nn_94a8da065245abe4:"account type",
	i18nn_aef488a45f17e7cd:"Please enter your login account",
	i18nn_9ee02e0fc3a8784e:"Suggested email",
	i18nn_7ef9e1dfbf42bba6:"New user and enterprise merchant information",
	i18nn_ff6570b63cd14f9d:"Only add user information",
	i18nn_f8c2fff93a98c5d9:"Can add enterprise merchant information",
	i18nn_4b401066ab552393:"Enterprise Information",
	i18nn_b164d8e831bc84df:"Please enter the company name",
	i18nn_f58f4664e290c476:"Please enter a unified social credit code",
	i18nn_304864e12f05f734:"Updated by",
	i18nn_395edce59b8bf304:"Warehouse management",
	i18nn_1ed7cdc1233483b5:"Unique and not modifiable",
	i18nn_9426df1162e7b5fa:"Cephalogram",
	i18nn_1ddfdcf5b21699d5:"Warehouse addition",
	i18nn_526f935030526185:"Display images",
	i18nn_f3f39bae61e74a8c:"Visualization of storage locations",
	i18nn_f1720b14c8b2293a:"Number of storage pallets",
	i18nn_7ec061870e40cbf0:"Appointment modification",
	i18nn_922e8b4c9ee71753:"Appointment acceptance",
	i18nn_1c99f4e064cb3b85:"Upload receipt list",
	i18nn_4e4bec1fd140fcf7:"Number of inbound boxes",
	i18nn_9d3249981866af60:"Source of goods",
	i18nn_43f1b30ab1c1efe6:"Print receipt",
	i18nn_1c50833bf647e4ef:"Set storage location",
	i18nn_4d4a22cd70f7c145:"Review receipt",
	i18nn_16a4f2646be08433:"To set up a storage location",
	i18nn_a99f3bb60a3c008e:"Preview of tray label printing",
	i18nn_1699795038a5e623:"And receiving list",
	i18nn_018cea07957567ef:"Review and Receipt",
	i18nn_65ff76c08629a2c8:"Review box count",
	i18nn_913e044950ff558b:"Outer box volume",
	i18nn_da669f87b133d0ae:"Separate by commas",
	i18nn_32e717822a28001b:"Print shipping list",
	i18nn_5f20abb4ee2efb33:"Upload picking photos",
	i18nn_885b56265946eacf:"Upload pickup documents",
	i18nn_a2b0e3d5bbc2591e:"Stock up",
	i18nn_f8e4e5693c8edc82:"Operation completed",
	i18nn_7fe010a57f969108:"Upload delivery documents",
	i18nn_6026969aae4ce7ce:"File upload",
	i18nn_baa5d4309421d040:"Picking completion date",
	i18nn_1ddf8fca05634d4f:"Please provide information on the actual consumables used during the picking of goods during transit",
	i18nn_5bbf6928eab32e30:"Is it a second picking",
	i18nn_8ed10bfaa8885638:"Photo upload",
	i18nn_7d9664098f76ed4f:"Details View",
	i18nn_1e262c44c1504e10:"Pickup date",
	i18nn_f7e99b8c203e2b07:"Order details",
	i18nn_b2d95e887d5425b7:"Appointment Details",
	i18nn_92f2ebbe14794f38:"Details of one shipment",
	i18nn_148c3451c7861e8f:"batch",
	i18nn_ce764b74be1b9a90:"Work Order",
	i18nn_83b1b50eb00a9fb6:"Third order number",
	i18nn_994551e2ae3176ca:"Detailed List",
	i18nn_74c2b69060be9bfe:"Receipt List",
	i18nn_859fd2c643fde5a3:"Client Menu Management",
	i18nn_7803ff939c276311:"Menu name in English",
	i18nn_893f64784c70bd2a:"Display or not",
	i18nn_d8917510efdc82c9:"Sub account management",
	i18nn_4b2d7b585777fa99:"Main account",
	i18nn_2489ac08975334e4:"Customer or company name",
	i18nn_f5765bd7956321f2:"View password",
	i18nn_8a2be54a2e609dcc:"Sub account account",
	i18nn_9ea9185268cf2519:"Whether to automatically account",
	i18nn_e78b4579ca11c47f:"Sub account abbreviation",
	i18nn_8664edb3936db8b0:"Automatically generate sub accounts",
	i18nn_d02053ea2ba9ff5d:"Customer menu configuration",
	i18nn_7732c57a7fb0fe19:"Sub users",
	i18nn_f34439ef133ee53d:"Inventory modification",
	i18nn_888c8eeffccf0077:"Display zero inventory",
	i18nn_9db83859e4338779:"Inventory editing",
	i18nn_3d2f43b3ef9fbac6:"running water",
	i18nn_e782953406e15421:"Account template",
	i18nn_13def55da64470b3:"Generate shipment batch",
	i18nn_8088a92b8b5f8f4d:"Receiving warehouse",
	i18nn_3a1ffab7c81c5d22:"Shipping Order Type",
	i18nn_ce30b55b8325b8a2:"Enter order number",
	i18nn_6fb0b3e58872e910:"Multiple order numbers",
	i18nn_9abd60b54d001c53:"Channel Company",
	i18nn_68ab250470bd3e4f:"Select Batch Printing",
	i18nn_b74df69000311063:"Batch order printing",
	i18nn_0a56058bd549a476:"Modify shipping address",
	i18nn_ba0dd389f81225fa:"Batch printing of Labels",
	i18nn_5cce32d9f8fcc8c9:"Single printing of Labels",
	i18nn_e1384ed0c924041b:"Flow query",
	i18nn_d2ad76d380f6aaf1:"One shipment batch",
	i18nn_d50e79aeea569249:"Warehouse at the place of shipment",
	i18nn_8bf7c69409f84506:"Receiving location warehouse",
	i18nn_924c113ef86babef:"Printed Label",
	i18nn_8afe1671ff878bf1:"Audit quantity",
	i18nn_46c4526201c8f1cb:"Quantity to be shipped out",
	i18nn_e45682a295cc8d27:"Extracted quantity",
	i18nn_fde6698b89672ed0:"Return quantity",
	i18nn_637b82edf600c14d:"Suspended quantity",
	i18nn_33104bbb28852199:"Cancel quantity",
	i18nn_6b55cfb713e28ca0:"Picking list",
	i18nn_743322012e31b427:"Summary",
	i18nn_2263ad3ad61335cd:"Summary and Details",
	i18nn_739d2c266c165132:"printing labels",
	i18nn_1ac7d281d8ceaa24:"delivery",
	i18nn_40e32dfaf7721550:"Picking list details",
	i18nn_1432b6fd3fd542c5:"scan and print shipping labels",
	i18nn_254b1f2eaf7007a1:"Merge Download Attachments",
	i18nn_fe5694a607ddfbd3:"batch",
	i18nn_3928eb88a75a5cd9:"front end",
	i18nn_bac349e62b3dcc5e:"Print Local",
	i18nn_4da5b63ad4c676d2:"Print the waybill and automatically pick the goods",
	i18nn_4cca3d88465a08a1:"Enter order number search",
	i18nn_f0a2e1e1c93ee971:"Viewing Print Records",
	i18nn_cc94f80dfacda656:"Remove this batch",
	i18nn_1fc7dfdc5251f81e:"Current display",
	i18nn_7a832466d6f61c60:"Delivery type",
	i18nn_156e7ed12f5d4b35:"Choose a courier company",
	i18nn_851730a3963df484:"Print Summary Only",
	i18nn_74225b7a0edee665:"Print Summary and Details",
	i18nn_f9f381e5b5ff1f88:"Merge and Download Label",
	i18nn_1bd7adf3f641e2d5:"Do you want to download the original file",
	i18nn_a73f09a050db50e5:"Please scan or enter the picking list number",
	i18nn_b0929817d371ed27:"Total singular number",
	i18nn_89ecaa622b09b1a0:"Scanable Singles",
	i18nn_dd05e02f1d454c5c:"Number of scanned orders",
	i18nn_3f907fa53cbe61e3:"View Printed",
	i18nn_494ffc9644d87900:"Untitled",
	i18nn_7327bf9113497eb5:"Single mode",
	i18nn_e4300f029469a8c2:"Multiple modes",
	i18nn_aae671b49dde2bba:"Basic information",
	i18nn_2bc1d5d63b619188:"Inbound container number",
	i18nn_dfd180c9fc784ac6:"Recipient Address",
	i18nn_d7786305cfd1b23d:"Warehouse Receipt",
	i18nn_9c3b3985298f22f1:"Association number",
	i18nn_0b2370aa8d088368:"Unusual parts on shelves",
	i18nn_eb366645fe3f7a20:"Abnormal part information",
	i18nn_80702accb94bb27d:"port",
	i18nn_10bc8a2b45aa5636:"Service feedback",
	i18nn_871a4f15148cc1b3:"Save Attachment",
	i18nn_b799f516e3940e3f:"Packing list type",
	i18nn_695d318c3b1ae5ea:"Confirm All",
	i18nn_43c20b6ea93b4b20:"Is it a new product",
	i18nn_3aea187873780514:"Single confirmation",
	i18nn_b46afeb645c05b06:"Only after all reviews are completed can the receipt be confirmed",
	i18nn_ce985bcb5f28b6de:"Receiving and shelving",
	i18nn_560595129574513f:"Processing logs",
	i18nn_7d05cde2e8464496:"Value added service list",
	i18nn_b4c1d75c7228b765:"Change to direct submission",
	i18nn_34026f16e115b40a:"Processing results",
	i18nn_a2d0e11a2b9f07d5:"Assign to Warehouse",
	i18nn_b9ee0408e39cb451:"Suggest coding combinations based on numbers and letters",
	i18nn_e729e61f9c82621b:"And remain unique",
	i18nn_93425399dd0a7b54:"Suggest following",
	i18nn_5d819cd0462cbe89:"number",
	i18nn_dfa40fe9cb22797d:"letter",
	i18nn_1786e81d2ba2694a:"combination",
	i18nn_d9df5070c410e81d:"The system will be based on",
	i18nn_418709fadc1e6c54:"Generate Standards",
	i18nn_cef61d13aefc83cf:"The warehouse will follow the standard",
	i18nn_ebcd402850731325:"When exporting all",
	i18nn_9b303c33a756ca45:"Excluding",
	i18nn_a8e47905b55a7fd5:"One-dimensional code image",
	i18nn_083b4e410bb4174e:"To export a one-dimensional code image",
	i18nn_8888f6735d4b5717:"Please export the current page",
	i18nn_45d1826d9048ca94:"Menu Search",
	i18nn_bc9dde3526b4dcd0:"Search and select",
	i18nn_e81d5203b18447dc:"Theme configuration",
	i18nn_e6c086cadd8732c6:"Activity Name",
	i18nn_9c5a41d10078e21e:"layout",
	i18nn_2a51888e02ba42a5:"float",
	i18nn_abdfd58e3b842a11:"Menu width",
	i18nn_43f72026b0c0699b:"Menu expansion",
	i18nn_7a22cefca1bca21d:"single",
	i18nn_c3a6ecdfb0f15d2a:"Unlimited",
	i18nn_cf47a8351ab58361:"Activity time",
	i18nn_00473f5ef0955636:"Select time",
	i18nn_612dc996c02cae5d:"Instant delivery",
	i18nn_593242ce3c67a074:"Activity nature",
	i18nn_fd0f2ba821918f4e:"Food",
	i18nn_543782932b959d8c:"Restaurant online activities",
	i18nn_8cb171a4912f9ae5:"Ground promotion activities",
	i18nn_3e2af40ab8939ecd:"Offline themed activities",
	i18nn_c4c6d0906f0299fa:"Pure brand exposure",
	i18nn_03b50caa388efd40:"Special Resources",
	i18nn_888a17c3151ea785:"Online brand sponsorship",
	i18nn_478e6c7637d0f1a6:"Free offline venue",
	i18nn_9d5fa522fb8a6c37:"Activity format",
	i18nn_9527abe40244bd22:"additional fees",
	i18nn_468267e2f309f146:"theme",
	i18nn_6b8d659c9bd2f861:"Template",
	i18nn_e8c4c868d3115139:"Menu search box",
	i18nn_6f8f60e16e726ccc:"category",
	i18nn_09012b6c20617148:"Transfer and warehousing number",
	i18nn_7cebbad6657bdbf4:"Expected delivery date",
	i18nn_b97a417f08e79456:"Application for outbound date",
	i18nn_18eac2972dd87064:"Failed to obtain user permission menu",
	i18nn_0c901139f9896f14:"Deleted",
	i18nn_7178a20888e962fc:"No matching Label",
	i18nn_b49ec02534e4f9b9:"Not placed an order",
	i18nn_139eee335633c377:"Ordered",
	i18nn_b12f88738181c217:"Are you sure to generate a shipment order",
	i18nn_adfbc9ddacfd7f42:"Are you sure to cancel the shipment order",
	i18nn_35f54f309c93f9e8:"Received",
	i18nn_4413cff8f8a5be70:"Partial transfer",
	i18nn_d58a804e17a620ae:"Transferred",
	i18nn_ab894a37bf61ef3b:"Are you sure to submit",
	i18nn_0174ec83eeb25914:"Are you sure to cancel",
	i18nn_a68426dce989b497:"Warehouse number",
	i18nn_5ebc284d0e1e5cf4:"Please select the goods to be shipped",
	i18nn_ce1923a0c6c05200:"data dictionary",
	i18nn_f217cfe6c6291a89:"Please select the outbound warehouse",
	i18nn_5184f7a4033b4969:"Please select a customer",
	i18nn_79d40c126a46dd61:"please",
	i18nn_56aa8ea768a4c6bd:"Right mouse button",
	i18nn_17700f3f4a7ee5f6:"Cells",
	i18nn_78897452dfd5c501:"If more actions are needed",
	i18nn_f1b23358832dfa49:"View Above",
	i18nn_12f42056f558e6f6:"If it is a private address",
	i18nn_05a8d5e71aabbcdd:"Please fill in the format",
	i18nn_bfcb068b25558f57:"Appointment for delivery is",
	i18nn_96b7fef9ab4cc937:"At warehouse",
	i18nn_af757a29a5046ebe:"Unknown index data",
	i18nn_fc089dc894530f12:"Please select data first",
	i18nn_f6f129c36d0c471e:"Cannot exceed",
	i18nn_cc6963cfe06fc064:"Batches",
	i18nn_e19edf6996055dc5:"Are you sure to delete this item",
	i18nn_771752e23324e1c4:"Failed to update address",
	i18nn_a5ed1c98a5b6763f:"Waiting for signature",
	i18nn_976cf9b5668838a0:"State abbreviation two character code",
	i18nn_8f5ce149c71f00d3:"Express abbreviation",
	i18nn_8ac049aa3fac8e9f:"insurance",
	i18nn_9d3382d5a74e6e34:"Mandatory for self supplied noodles",
	i18nn_43cebc877265943a:"Outer box specification unit",
	i18nn_4843108e7e424145:"Gross weight of outer box",
	i18nn_e6e818680d77e014:"Barcode box label",
	i18nn_3acca92babc7df1c:"Are you sure to discard it",
	i18nn_f66fc986e2ae222f:"Abandoned successfully",
	i18nn_1118edcaeb4c3679:"Please select a warehouse",
	i18nn_407c59bcef554efa:"Please check the data first",
	i18nn_0e8a3154ae62784f:"Successfully added",
	i18nn_91d7402a8bcd9fad:"Add failed",
	i18nn_5c9f3500bc7fc4c2:"Confirm posting",
	i18nn_b8ace7701a8138f9:"Confirm that the account has been recharged",
	i18nn_ebe414c3e59312d7:"Confirm successful recharge",
	i18nn_012297a66930e480:"Are you sure to update the password",
	i18nn_9a8a031ac6ac8473:"Direct printing",
	i18nn_3412dc405baa4620:"Single reprint failed",
	i18nn_0fe4835b19dfd823:"Stopping",
	i18nn_c82aace5dd1ac3dd:"Or images can be printed",
	i18nn_080a48f195815c62:"Section",
	i18nn_9193c7e8137a5879:"Printing failed",
	i18nn_5660b714ee474402:"Picking list number cannot be empty",
	i18nn_ba6389ba0f891b7d:"The starting serial number cannot be empty",
	i18nn_899f4315df1ea9f6:"The ending serial number cannot be empty",
	i18nn_31706d698e478551:"The serial number cannot be empty",
	i18nn_23d809d3bce6b99a:"Failed to query information",
	i18nn_aaa9fd271d84c6e1:"Information failure",
	i18nn_6cb81137e76a6a18:"And preview",
	i18nn_d33d0afe39b5b1a2:"Warehouse rent summary by day",
	i18nn_65b02ebff64ee1a6:"Warehouse rental details",
	i18nn_36ed3657e60217a8:"Data prepared",
	i18nn_37f3ec4a54bf2dbc:"Summary data prepared",
	i18nn_4d7265155f2745f2:"Incorrect summary data",
	i18nn_f562dbcbf20073b8:"Please select the target customer",
	i18nn_411fdc693cb46984:"Unaudited",
	i18nn_2b6045f2709c84bb:"Incomplete",
	i18nn_03402c5b9fe7cab3:"Improve through",
	i18nn_5dcc73d5e4f71512:"Under improvement review",
	i18nn_1ee1a0e366f2adba:"Improvement failed",
	i18nn_601218d4d572e98a:"Certified",
	i18nn_177b191d8e0fa84d:"Not certified",
	i18nn_26d29891d178b990:"Certification failed",
	i18nn_ff1b4c048301d7e6:"Star",
	i18nn_f41de3a1fa69135a:"Mobile phone number",
	i18nn_dff1423b58a24b84:"Please select a province",
	i18nn_ebe921ebb02d5f68:"Please select a city",
	i18nn_ca736b6e83d86d2c:"Please upload your business license",
	i18nn_e796ecfa5ab2a057:"Address Province City/County Data Required",
	i18nn_d47f3e0b7855dd5f:"Submission error",
	i18nn_c9946fd976ed060f:"Please check the submitted data",
	i18nn_35da7aebbfd476d0:"Incorrect detailed data",
	i18nn_cef747656c086bca:"Unknown operation status",
	i18nn_d0e490d0c3ca7eb0:"Please contact the administrator",
	i18nn_c4189210d2599fd7:"Please scan or enter the picking list number",
	i18nn_85a02086f987be2b:"Please scan or input",
	i18nn_efdf63caa2c3f0e7:"Query this batch",
	i18nn_42e8610163aaeda1:"This waybill has already been printed",
	i18nn_e395c2d3d12126a5:"If printing continues",
	i18nn_5fdb4151c7c11503:"Please click on",
	i18nn_a255b29f4ec1fe1a:"Successfully generated",
	i18nn_8d80c9b1e3453063:"Please select the storage time first",
	i18nn_c78d0436fda4c875:"The warehouse you have selected does not match the warehouse selected by the customer",
	i18nn_c2baf39462aa98d2:"Partial Receipt",
	i18nn_4d4f1d7366ae2f12:"Sign for all",
	i18nn_3c6d680fd4ace9c5:"Are you sure to sign for all",
	i18nn_004a25fe67f297c3:"balance",
	i18nn_74b62cbb161a84a4:"Balance query failed",
	i18nn_7fa1fea309b0fd8d:"Cancellation in progress",
	i18nn_c136c2ffbc49c6e7:"Unknown delivery channel and tracking number",
	i18nn_778d871227234f32:"Successfully cancelled the order printing",
	i18nn_854a071f3cbf2761:"Successfully cancelled the label order",
	i18nn_c26d5c523b0b69d3:"No menu available for download",
	i18nn_378153e9411327ea:"Individual Label",
	i18nn_1e2279a00c4336a4:"Shipped",
	i18nn_3ece0cc00f1da8de:"Received goods",
	i18nn_820a3a60bc317776:"Format data failed",
	i18nn_b0e16646a7aa97eb:"You have chosen",
	i18nn_b2330ac1b925cbcd:"Please select a single query",
	i18nn_34b9e521d99ba4c5:"Currently selected",
	i18nn_33736cef5230e85b:"Is in an unavailable state",
	i18nn_b9221c0e13214d9c:"Please check the input data",
	i18nn_a72f4a9e1170c63b:"Verified",
	i18nn_7e1de493fb204260:"Format exception",
	i18nn_e9970bf947cf72e9:"Please enter the courier number",
	i18nn_8f6d68423b465690:"To be confirmed",
	i18nn_68737d3d24002eed:"Void",
	i18nn_e83442620acf37fb:"Missing query parameters",
	i18nn_d9145c2cbe66f3dd:"Please proactively switch to the billing details query view",
	i18nn_2c98a37551a345ca:"Failed to query all summarized data",
	i18nn_1b210b98ae740911:"Failed to query summary data by warehouse",
	i18nn_0c72f3afd482899d:"Failed to query summary data by customer",
	i18nn_44c439345b1a2d97:"Failed to query customer summary data by warehouse",
	i18nn_cf698047bdf5efae:"Failed to query summary data by customer's warehouse",
	i18nn_9c4659d873c8b417:"Please select a date first",
	i18nn_7f995eb43e1fc92b:"Calculated by volume",
	i18nn_02ad74c45e9271a0:"Calculated by weight",
	i18nn_bc118f4faee1209f:"Total query failed",
	i18nn_615e611d715a5732:"Query of bill summary data failed",
	i18nn_802437747d78fc60:"Warehouse inventory data query failed",
	i18nn_affc498c59833669:"Warehouse Operations",
	i18nn_14c882fc42e6d75f:"warehouse receiving",
	i18nn_4721598dff939cbd:"Financial expenses",
	i18nn_0bdd0558754bbc24:"Please select the delivery method",
	i18nn_2ab7ec407b634f9c:"Please select the delivery time",
	i18nn_598c56c4dda298bd:"Please select an attachment",
	i18nn_82e92ac6f89d595f:"Pending listing",
	i18nn_d4606176f0203870:"Listed",
	i18nn_d460085129ba5569:"Batch listing templates",
	i18nn_6fb720f73efb6f5f:"Download successful",
	i18nn_85d564c86ace60d8:"Please select display",
	i18nn_5d2e2702224d280d:"Please enter a positive integer",
	i18nn_a9d1938b369aff5b:"Up to three levels of menu",
	i18nn_7e1a754534215f02:"character string",
	i18nn_b7099a09c1dd4c0d:"Please delete the lower level menu first",
	i18nn_97954de4658077a8:"Please re-enter the password",
	i18nn_8623ea11efc1953d:"Please enter an account",
	i18nn_4f661d272dfc6cd3:"Please select a permission group",
	i18nn_2c344876df66771f:"Confirm password inconsistency",
	i18nn_0cb95e589cc2a0fe:"Failed to query permission group interface",
	i18nn_3f92cfa1112482ad:"Please enter permission group",
	i18nn_5d6fde2581708559:"Failed to query menu interface",
	i18nn_d0f820f74ea352b4:"Permission Group Details",
	i18nn_77fcb3c1f9df52dd:"Failed to query permission group details",
	i18nn_91e192ea5925de92:"Please select a time first",
	i18nn_2b65059530ec3ed5:"Accepting",
	i18nn_1fb4422ebabb847a:"Normal end",
	i18nn_0eb9b2e0d01ad12b:"Abnormal end",
	i18nn_d6ab1c29b36f35ae:"Please select the submission status",
	i18nn_efbfa769d113b9fb:"Please enter the message information",
	i18nn_b781fc794861218f:"Picking method",
	i18nn_ee57468801a2964d:"rescinded",
	i18nn_ca037ca9e5a6af4c:"Please select a time",
	i18nn_f1c38661e72ae864:"Please select the outbound method",
	i18nn_e9942ae46d0c7434:"Please enter the number of pallets",
	i18nn_1597574a935b8fca:"No pictures uploaded",
	i18nn_6b645127e97ecdf1:"Please scan or enter the shipping number",
	i18nn_49356bf96d04d558:"No data found",
	i18nn_40a6f7ebeefeb8f9:"Please enter the shipping number",
	i18nn_46ee1d8e6fc11183:"This storage location has not been found",
	i18nn_c0c353c17e6f5792:"Unknown customer number",
	i18nn_6a800c36981ed32f:"No value",
	i18nn_6e972d4773497eb2:"Query this",
	i18nn_c8805f665bfdec60:"Are you sure to roll back",
	i18nn_295e890bd68b5817:"Are you sure to start processing",
	i18nn_5f0cbc011b37740b:"There is currently no data available in this warehouse",
	i18nn_89524efe3f5bdf69:"Changing trends",
	i18nn_52b4f481aab3e343:"Order statistics by month",
	i18nn_6e5d728ebf9703a8:"Order statistics by day",
	i18nn_f762ff6f05589bdf:"Receipt",
	i18nn_e36deb57ab8f614f:"Self pickup outbound",
	i18nn_5d4c76c6431c06ef:"Annually",
	i18nn_43d7de9d82345073:"By day",
	i18nn_2ce736eb73976890:"Please enter a frequency expression",
	i18nn_cbfcb27fae20f059:"Please enter a task description",
	i18nn_5cc8ea0e79e45cb0:"Please select a task type",
	i18nn_001b535c694bfabd:"Please select the execution type",
	i18nn_2142b1d4d10d8b5c:"Please enter the type",
	i18nn_a412adde8aa61969:"Please enter an option description",
	i18nn_90e2f13098c0eab4:"Please enter an option",
	i18nn_9a85d5fea7339b0b:"Please select an option value",
	i18nn_1c3b377187cb4bb2:"Please select sorting",
	i18nn_2a8b31eaadca2ae7:"Please select a parent type",
	i18nn_632499709fae44e4:"Please enter an option value",
	i18nn_9d14fa135072faa4:"Refresh successful",
	i18nn_14a06b4d26792590:"Please select an international region code",
	i18nn_1da6b8c5aa8e9f2e:"Are you sure you want to accept this appointment",
	i18nn_1af5867a26b5d7b2:"Rapid transit statistics",
	i18nn_d57278f56da4c035:"Appointment in progress",
	i18nn_dc710cffe6313bb5:"View Details",
	i18nn_510163d3faaaec5d:"Private address",
	i18nn_0db20ba674893add:"Failed to query the basic information of inbound packing",
	i18nn_d35eb46d39070ef8:"Update to Picking",
	i18nn_97ced22c0fc11907:"And open the shipping list",
	i18nn_44d2a6208e2f9a20:"Do you want to continue",
	i18nn_d4a2f8ababb9fa73:"Status statistics",
	i18nn_be56d9786a28bfa8:"Upload stock photos",
	i18nn_6f8b61e167da9916:"Please fill in the receiving information",
	i18nn_71b59790ac5ed0e4:"Sub user menu configuration",
	i18nn_621f11a42a00ddb6:"Failed to query menu",
	i18nn_1ca0e2fc087a178a:"Unknown courier company and tracking number",
	i18nn_a39315c28eb857fc:"Please select the warehouse of the shipping location",
	i18nn_c7843e69972185e8:"A single batch cannot exceed",
	i18nn_fbfaccc4de327695:"Please operate in batches",
	i18nn_d7652614cc5e2d3e:"This batch has a total of",
	i18nn_2a5776c27559973b:"Are you sure to continue",
	i18nn_9056478b3f520d62:"This batch exists",
	i18nn_60321d753e930abd:"One ticket, one piece",
	i18nn_57e5f6175bd644b5:"One ticket with multiple items",
	i18nn_632694245c6be4e7:"blend",
	i18nn_37d83a937d60a027:"Are you sure to continue mixing",
	i18nn_44242c07a1f17e31:"Only with status as draft",
	i18nn_8f3b691fb1928c4a:"Approved before submitting for printing",
	i18nn_04c1c1452cb3359f:"Confirm submitting this",
	i18nn_a544f42463e5840e:"Are you sure to intercept",
	i18nn_d425224411bfdb2a:"Are you sure to ship out",
	i18nn_465d732d68ebdfbb:"Operation successful",
	i18nn_d1cba386aa21b1ff:"The picking list number is empty",
	i18nn_63f16a642738a266:"Query error",
	i18nn_68299543f3cc5f92:"Are you sure to remove this batch",
	i18nn_82e48bab56339c40:"Successfully removed",
	i18nn_1a77ac17dba4c85c:"Confirm to merge and download the menu",
	i18nn_901bbc0509b3cd36:"Confirm merging and downloading attachments",
	i18nn_9da927fb6cb3c64f:"Merging",
	i18nn_16216f47baa947e3:"Expected needs",
	i18nn_7f7865ded377b432:"second",
	i18nn_7e33ace0b315758a:"Please be patient and wait",
	i18nn_bed2a18430b51157:"Expected every extra",
	i18nn_be3396cbdcf75fb2:"Need more",
	i18nn_daeffa7c80c7dde2:"Merge successful",
	i18nn_769982d12808a912:"Opening",
	i18nn_0c86a93c46dcafaa:"Download failed",
	i18nn_99f298e8a1f1faa4:"Confirm all",
	i18nn_6d0020fc9dc92ae7:"Intercept it",
	i18nn_df7bb549078d49cd:"Restore it",
	i18nn_5d756d95aa048878:"Please enter the picking list number",
	i18nn_45ec4789fbc5c60f:"Failed to query the batch information",
	i18nn_fffb62e1463bb144:"On shelves",
	i18nn_c35f68546e45071e:"Are you sure to receive the goods",
	i18nn_3bd582344601fa87:"And it has been verified to be correct",
	i18nn_aa0c982bc4bca6c0:"Please select the processing result",
	i18nn_167e06b5d98fca7a:"Accepted",
	i18nn_3e15930039e912d9:"Are you sure to return it",
	i18nn_531256af4d92e0bb:"Are you sure the review has passed",
	i18nn_31703955e64806b7:"in",
	i18nn_5143f36f8f772d49:"writing",
	i18nn_4368252da5c6ce3f:"Are you sure to cancel the shipment",
	i18nn_9f9b1d4fbc735ff2:"Only with a status of",
	i18nn_428d860b48d9f9ea:"Only then can I submit the order",
	i18nn_df6ccd168be94c45:"Are data items shipped",
	i18nn_11b5e4d030395317:"Are you sure to restore",
	i18nn_022b0d00eed405f3:"Status statistics query failed",
	i18nn_74ac2ab86b62b7ad:"Title One",
	i18nn_fd8421fe2df8803b:"Title 2",
	i18nn_8d5c0114856bda15:"Search data failed",
	i18nn_6ef8ab3a4523a2b2:"Are you sure to submit the order",
	i18nn_33a114a8cba0232f:"Failed to query the batch",
	i18nn_066f783f19cb317e:"Are you sure to cancel the order",
	i18nn_662e330f16cb98d8:"Are you sure to cancel the label order",
	i18nn_caed6cfc34b968aa:"Recipient Name One",
	i18nn_f9c0ee1156f1e254:"Recipient Name Two",
	i18nn_2dfd6348829d984f:"Sender Name One",
	i18nn_e03f8082906c4de8:"Sender Name Two",
	i18nn_2fe24d33754468d6:"Name One",
	i18nn_faef5bb72994f5cd:"Name 2",
	i18nn_2e49d456b5794910:"Are all picking completed",
	i18nn_8ae560831457c982:"Image attachment not uploaded",
	i18nn_8e252a43b6085973:"Inventory with zero cannot be moved",
	i18nn_f1710cf28f5c1b2a:"Are you sure to review all of them",
	i18nn_7f550193f65e6c9b:"Are you sure to automatically pick all items",
	i18nn_9e4dbfe29ddc0058:"Is the picking completed",
	"i18nn_a476975987294b07": "return management",
	i18nn_d256e16919f5fceb:"Appointment data",
	i18nn_7f0e36c0c32152b9:"Self pickup number",
	i18nn_c45d595e849aec6a:"Add Appointment Form",
	i18nn_a1c919aef2dec6d4:"Add details",
	i18nn_4f5f83fc6780a29c:"Select goods details",
	i18nn_7a29cab1a4b8f231:"Priority operation",
	i18nn_7f5fdc820bcd173e:"Administrator modification",
	i18nn_9e3f5a2cc07b627f:"Can only be numbers",
	i18nn_f8da18cb19a9fa8c:"Company Code",
	i18nn_9a716c393c296ea8:"Final Kapai",
	i18nn_facd05c043886557:"Merge Partial Rows",
	i18nn_6fd4956e676cec49:"merge",
	i18nn_1861b71eeabd88b9:"Do not merge",
	i18nn_d642ae850eca6b40:"Container pick-up fee",
	i18nn_f883ded0c750dce2:"Container type ",
	i18nn_3215d0a40f17773d:"New expenses",
	i18nn_e47e0c74c02bf395:"Existing expenses",
	i18nn_edb1b11e6c5ceb01:"Min Ten Digits",
	i18nn_600c6fad90d0d7b1:"Billing Status",
	i18nn_15a021996904c423:"Confirm Status",
	i18nn_98ea9b63e41e8543:"Length, width, and height",
	i18nn_8468ac93161085d6:"Priority operation",
	i18nn_10b4e989a2c42281:"Number of packing pallets",
	i18nn_d2084ce00c778575:"Single box size unit",
	i18nn_7e8bb92f75c45e74:"Total number of boxes received",
	i18nn_20f5cf576e4034ee:"Total number of received items",
	i18nn_5a223c2b1a13ec41:"Total number of stored pallets",
	i18nn_28a07becf88aa5f5:"Label change",
	i18nn_8fa2f6bbb0d5b697:"identification",
	i18nn_0529f4753fb3c5a0:"size",
	i18nn_61ebcde89251413e:"Confirm merge",
	i18nn_c5378920e015b12f:"Select waybill",
	i18nn_b539914bf0af5314:"Verify and match",
	i18nn_470fa451f57e6fff:"Automatic matching of identification waybill number",
	i18nn_2428648cafd20f21:"Upload matching waybills",
	i18nn_eb680d44b667d34e:"Identify based on waybill number",
	i18nn_53f9c1e720d261f9:"Can automatically split the waybill to various orders",
	i18nn_39df26e0051d7903:"Confirm Save",
	i18nn_e11679a643689255:"Select waybill file",
	i18nn_349ee56d6d895249:"Maximum file size",
	i18nn_1af19e9a72a235ba:"Submit and verify",
	i18nn_379c907d12f5c9f5:"Matching successful",
	i18nn_a5d008a482d2b005:"Number of pages",
	i18nn_61d278478046ceaf:"File stream",
	i18nn_b14b20e17d756cb2:"Matching failed",
	i18nn_cc3cb77aad104caa:"Re select",
	i18nn_7c9926ac60a726ac:"Upload successfully matched waybills",
	i18nn_8433ab19cdbad17e:"Upload status",
	i18nn_933692e63faf4555:"View matches",
	i18nn_f524f7dafdd60a7a:"Save matched waybills",
	i18nn_b109726c562cc092:"No matching data",
	i18nn_247c878546702010:"Automatically identify and save based on waybill number",
	i18nn_dfaa3ac89594cfe8:"After uploading",
	i18nn_e7ce2fa6da443509:"Please wait for the result",
	i18nn_8054e6a00f7fb9c7:"expect",
	i18nn_ced2ef6d0ce80ac1:"Matching completed within seconds",
	i18nn_12fde6f4ac0bd2a7:"The larger the file, the longer the wait",
	i18nn_926b52e41c45d835:"Upload and match",
	i18nn_8aad52f19406c72d:"Historical matching records",
	i18nn_ad810b5b659860fe:"File address",
	i18nn_d426ae19e98559b5:"PageCount",
	i18nn_75247a5e05331f7f:"Matching details",
	i18nn_46d9c96bfde09a4a:"Please select a file",
	i18nn_60c521726fa38f44:"Maximum Display",
	i18nn_9e0c510eaed20bc7:"Refresh matching results",
	i18nn_e5e53c1aa28e6682:"File name matches order number",
	i18nn_127af9ed95c3c6be:"Match order number by file name",
	i18nn_2fe088ec1114c539:"Automatically match by waybill number",
	i18nn_3d189e6fa5541753:"Confirm synchronous import",
	i18nn_197e2039cf365afa:"Number of pallets in the warehouse",
	i18nn_59d307f03af09634:"On the way",
	i18nn_91c9a1974dd0bff6:"Arrived at warehouse",
	i18nn_83125000f5142ed1:"Number of pallets before warehousing",
	i18nn_b8b6bfc4485da611:"correlation",
	i18nn_8b66deca86e6d66d:"Please enter a two character code",
	i18nn_fe2e4275fe979e60:"Return Information",
	i18nn_e1214c4d8b4e9626:"Please wait a moment",
	i18nn_c121c5915b23c201:"After filtering",
	i18nn_e1c83961e7a8f928:"Batch download",
	i18nn_f5e108e4d43c2a4b:"Only before downloading",
	i18nn_c11ab9cc6f2492e6:"After filtering, please",
	i18nn_5f7f4143c0564b54:"Load page",
	i18nn_7215a6d646ce357e:"Currently available for download",
	i18nn_942c689d54ec40a3:"Enable association",
	i18nn_a211166857c0d3b2:"Do not enable association",
	i18nn_c468dcd6f350323a:"Removal method",
	i18nn_e0954e26f258ff37:"Manually generate batches",
	i18nn_da5d60a9f5f9537d:"Customer express delivery channel binding",
	i18nn_179471a4a7edfc9e:"Channel name",
	i18nn_3790f8e04246b942:"Cost calculation type",
	i18nn_96c4f39b3e50e482:"numerical value",
	i18nn_2c68e29ae9b0241b:"Accounts receivable",
	i18nn_fe980c886a088d1b:"Based on filtering criteria",
	i18nn_edd3960d003f3ce5:"Received amount",
	i18nn_b95ea219433207f6:"Pending payment amount",
	i18nn_8dcd5c029510d331:"Discount amount",
	i18nn_c6c96a132870f231:"Received payment",
	i18nn_49bd434745f8aa22:"Pending payment",
	i18nn_3d620a2ecad95500:"Bill Download",
	i18nn_e7a22fdeba7b703b:"Update fees",
	i18nn_df112e830bd3fba2:"In payment collection",
	i18nn_3b5e992be756a85b:"Payment completed",
	i18nn_44f2c051869ecc91:"Will be based on",
	i18nn_412bafcd98e04d4d:"Filter criteria",
	i18nn_f11eda77aadd7f57:"Automatic creation",
	i18nn_77c91da599d5b4b6:"Created successfully",
	i18nn_affd861b44969ee2:"Are you sure it's completed",
	i18nn_0adb9b09d2678da8:"Summary of accounts receivable",
	i18nn_9abe7bec4631a470:"Accounts receivable details",
	i18nn_70b2d10e778a16dc:"Total Summary",
	i18nn_951f968ca8b9bbc1:"Amount to be confirmed",
	i18nn_2d9e36db18cb2ee7:"Download task",
	i18nn_77a1b5e97c90a5c8:"Generate download task",
	i18nn_44858bef4ea92a98:"Task number",
	i18nn_ec50c48081e0543b:"parameter",
	i18nn_a2aff0c6fb977468:"Regenerate",
	i18nn_678b0ae6cf3a4399:"Generated",
	i18nn_01368e1a80ce6f61:"Generation failed",
	i18nn_cb874a1cc07abc0f:"Regenerated",
	i18nn_7195cb76b5db754a:"Regenerate failed",
	i18nn_960d7b5c5217102c:"Address distance query",
	i18nn_7bfefc21c6aba7c6:"Address distance",
	i18nn_9461d15f7ba8ad99:"Address concatenation",
	i18nn_2f6634ea185d80a7:"Confirm arrival at warehouse",
	i18nn_27ce3cad1fb426b8:"Batch picking",
	i18nn_afa707f9e9c8f26f:"Batch picking completed",
	i18nn_5555f8dbcb4fdf4f:"Batch printing of picking lists",
	i18nn_e33374f71ce9a748:"Check Data",
	i18nn_54beb4e4556f9cf1:"Confirm the operation",
	i18nn_8c9a6964bdaa2289:"Is it a bar",
	i18nn_e2f6d6395e1b5a5b:"Please check the data box",
	i18nn_40ac872c0aa6ff41:"Barcode download",
	i18nn_4ea50b32216e5504:"When the code is too long",
	i18nn_dc88ac4413b2bf37:"Generated",
	i18nn_fb870f042cdc0b90:"The code image will also be very long",
	i18nn_7ce36744aeec211a:"Will be missing",
	i18nn_e4f56cecd0453bbf:"Suggest downloading",
	i18nn_375809e1d450d562:"Or right click on the code and save it as an image directly",
	i18nn_36668b7051dae10b:"Kappa price",
	i18nn_5b16dd13f1bc0e1c:"Multiple order number search",
	i18nn_8544666ef2803c20:"To download",
	i18nn_ea80f4411a8408b8:"Target printer",
	i18nn_1cd45ccce46f619e:"Save as",
	i18nn_6f9c5b165f3bc662:"It can also be removed",
	i18nn_f54ebccbce73a3d6:"header footer",
	i18nn_5c4a773bafcfe7bd:"Check the box",
	i18nn_3f6074f3db84a466:"Drag files here",
	i18nn_59bcca694e60fcfd:"Or click on",
	i18nn_8c7713cae892514d:"Matching statistics",
	i18nn_b3c2b18eb57891e5:"Intelligent matching of waybill numbers and splitting",
	i18nn_829a74d12dc6905b:"Verify Query",
	i18nn_4bf00570023be25f:"Inviter account",
	i18nn_91428b80aa9e8f9c:"Customer bound warehouse",
	i18nn_a210136d22f35cb3:"Currently only supported",
	i18nn_d351d2e9d8882726:"Please go to the system",
	i18nn_430d5b3397dcc780:"Please follow the prompts above to check",
	i18nn_784a9e148dbadc4a:"Box Mark Design",
	i18nn_a3ed58117fd81192:"Display customers",
	i18nn_ee7bdbe4f27e38df:"Additional information",
	i18nn_287b771e159a7dc2:"View work orders",
	i18nn_de98440157f199c2:"No data available",
	i18nn_44816d067c071c41:"Go ahead and operate",
	i18nn_34dd4ecc9411c9c6:"Stock up completed",
	i18nn_b079d5c37e1d2120:"In stock",
	i18nn_0bf0eea18efcd589:"Stock information",
	i18nn_af2b08910f762203:"Stock details",
	i18nn_ebbc74e1ca8164d3:"Effective inventory",
	i18nn_f503a392256579c0:"Start change",
	i18nn_bcc2ec3f9bce8104:"Change data",
	i18nn_1065d66e4371316e:"Modify quantity",
	i18nn_41e367f548b0778d:"Determine changes",
	i18nn_0331ad48d9b40904:"Only one can be selected",
	i18nn_47c222b26ca4ce78:"Barcode design",
	i18nn_110b67a13dd38b1e:"primary",
	i18nn_788d9bdcf8ff660e:"Original quantity",
	i18nn_a43cedadf897a6eb:"After splitting",
	i18nn_ab505f0b8db48c6c:"Bind customers",
	i18nn_824e8fd03e8b1a5c:"Permission Warehouse",
	i18nn_8ec797c2bf2b42b4:"The warehouse for which the current account has permission",
	i18nn_66ff6da6299a5967:"If an account without permission is bound",
	i18nn_57252efc30f531ea:"The customer list bound to the warehouse is also not visible",
	i18nn_02da0f2e22973014:"But the actual client customers are visible",
	i18nn_73768c3db7d6b661:"Current warehouse",
	i18nn_bba12068e25729f4:"Warehouse bound customer list",
	i18nn_bcad6e616b8de5af:"Third party",
	i18nn_3a1e63b67d26386a:"Synchronize information",
	i18nn_9ac9d5c058d1465d:"Exchange number",
	i18nn_e91b219bcbc1d684:"Configuration editing",
	i18nn_7a6f218c034d0ecd:"Third party warehouse number",
	i18nn_81c10442a4bd4247:"Tripartite coding",
	i18nn_72d35d210669abb2:"Forecast quantity",
	i18nn_4567a206c45dc3f4:"Actual received quantity",
	i18nn_4b7fc42831b9db53:"Cancel receipt",
	i18nn_6c837fa18c154f3e:"download carton label",
	i18nn_a8d53003236f396b:"Are you sure to cancel the receipt",
	i18nn_b32aa7a649eeb046:"Location change",
	i18nn_fc1ddf7dce134538:"change",
	i18nn_18f412662f2b18ba:"Work Order Details",
	i18nn_f4c3e91a86d23afe:"service",
	i18nn_c0d09a392d8acf54:"Update successful",
	i18nn_6724e693fadf9a09:"Do you want to log in again",
	i18nn_1bd796959e536718:"Theme colors",
	i18nn_420e047cec52beba:"blue",
	i18nn_2a6c32718bd62ea0:"white",
	i18nn_35681dbc90d33898:"green",
	i18nn_e0f905492520aeda:"young",
	i18nn_80ddb314e4807b49:"red",
	i18nn_bf77a9b5e485df33:"purple",
	i18nn_9b62de99e77c2111:"black",
	i18nn_43f9e647d7e7ace0:"yellow",
	i18nn_d32b04432f78f1db:"Generate images",
	i18nn_4497f9b307bf73ee:"Long image preview",
	i18nn_ede4b38a45b72520:"Right click on the mouse to copy or save the image",
	i18nn_740df211dc3d7543:"Display value-added services",
	i18nn_78226fb265027602:"show details ",
	i18nn_414a2cb9270b7231:"If there is a value",
	i18nn_8b3dbf4f5793930b:"Destination",
	i18nn_7f791bff1c8abb4d:"Merge and download all waybills",
	i18nn_cc546e1ccd13cf0b:"Please scan or enter the outbound number",
	i18nn_084d695b245685c3:"Move to Exception",
	i18nn_735df2dca58c4f9b:"Resubmit",
	i18nn_69018b3ce7961de3:"Under inspection",
	i18nn_c7e739a5ba70db03:"Are you sure you want to move to the exception",
	i18nn_412f179fdcd6871c:"Are you sure to resubmit",
	i18nn_17e7c7b16731a7e5:"Please select an account and warehouse",
	i18nn_e34c8a118634bf64:"Binding data exceeds",
	i18nn_daf04488c05b648b:"Please reduce the binding data",
	i18nn_e1a3fd479469bc3b:"Forecast warehouse",
	i18nn_79ce1fe82ce3e424:"The forecast warehouse is empty",
	i18nn_7179e7e6f1c6607f:"Successfully loaded",
	i18nn_ae9788548d6b369d:"Set up warehouse",
	i18nn_8e693617facf34a8:"Tallying completed",
	i18nn_84b54464d1e28163:"Warehouse menu",
	i18nn_b23bdb36899ae7f4:"Warehouse area",
	i18nn_0f4a445be54ed8ae:"Locked state",
	i18nn_ebddf036087d3618:"Lock type",
	i18nn_c301b22bd36255c8:"Processing status",
	i18nn_28bc604691209657:"Inventory information",
	i18nn_cc9fd9b44e3429ed:"Inventory account",
	i18nn_a0030be92f6b1c1a:"Inventory task",
	i18nn_5c5140f8913c5819:"Task Name",
	i18nn_1bbf0464d7c1ed75:"Create an account",
	i18nn_51521d7e394e5b44:"Query inventory information",
	i18nn_17a50282124ea21b:"Complete the task",
	i18nn_68624892c0eb7fb8:"Synchronize to system inventory",
	i18nn_53efa98019d8ee90:"Are you sure to complete this inventory task",
	i18nn_ccb235dec92863c0:"Please operate with caution",
	i18nn_0ead89fbcc657f0c:"Confirm synchronization to system inventory",
	i18nn_22f6e6aa14279e30:"Inventory Summary",
	i18nn_d764dccdd0a96427:"Locked inventory details",
	i18nn_d8d0c7c9b9ee194d:"Receiving attachments",
	i18nn_c333de0c7954980d:"Upload receiving attachment",
	i18nn_3d3679bf88a9390c:"Set up storage area",
	i18nn_1e05aead0e13d830:"Are you sure you want to receive the goods",
	i18nn_5efbe6b41f343c21:"Tally records",
	i18nn_ffb9d72aca40b1fa:"dimension",
	i18nn_49bbd60a1c291f9c:"Quantity of goods sorted",
	i18nn_63396b17b57e1a49:"Bind Users",
	i18nn_4aa83e7663f8d9ec:"Binding time",

};