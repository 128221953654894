import enLocale from 'element-ui/lib/locale/lang/en'
// import enUserCenter from './UserCenter/en.js'
// import enStorage from './Storage/en.js'
// import locale_en from '../../locale/en.js'
import locale_en from '../../../i18n_locales/en.js'
import locale_en_client from '../../../i18n_locales_client/en.js'

const en = {
	// meta: {
	// 	互易仓: "HYTX",
	// },
	topBar: {

		// Chinese: "中文",
		// English: "英文",
		language: "Language",
		Chinese: "中文简体",
		English: "English",
		// switchLangSuccess:"Switch language successfully! Refresh this page immediately?",
		switchLangSuccess: "Switch Success",
	},
	meta: {
		main: "HYTX-Storage-WMS",
	},
	tips: {
		loading: 'loading...',
		Submitting: "Submitting..",
		tipsTitle: 'Tips',
	
		errorData: "Sorry, the request data is incorrect.",
		submitSuccess: "Successful submission!",
		successfullyDelete: "successfully delete",
	
		requestErrorData: "Sorry, the request data is wrong. Please try again.",
	
		checkSubmitData: "Please check if there is any unfilled or incorrect information in the data.",
	
		submitError: "Submission failed, please try again",
		submitRequestError: "Submission failed, please try again!",
	
		queryErrorFailed: "Sorry, the inquiry failed",
		queryRequestFailed: "Sorry, the inquiry failed！",
	
		// successAddShopCart:"Added to shopping cart successfully",
		// ErrorAddShopCart:"Failed to add to shopping cart, please try again",
		// RequestFailedAddShopCart:"Failed to add to shopping cart, please try again!",
	
		no_data: "no data",
		no_null: "not available",
	
		noLoginUserMsg: "Must be logged in to operate. Do you log in immediately?",
		changeLangMsg: "Click OK to refresh the page!",
		// noEnMsg: "Must be an enterprise to perform this operation. Is it certified as an enterprise?",
		// noLoginMsg: "You must be an enterprise to do this. Are you logged in?",
		// noShopMsg:"Business authentication can only be carried out after passing the enterprise authentication",
		// authChangeLogin:"The authentication information has changed, please log in again",
	},
	common: {
		HYTX: 'HYTX',
		HeadPortrait: 'Head portrait',
	
		Commodity_types: "Commodity Types",
		All: "All",
		// Steel: 'Steel',
		// Wood: 'Wood',
		// Cotton: 'Cotton',
		// Cotton_Yarn: 'Cotton Yarn',
		// CottonAndCottonYarn: 'Cotton',
		// VehicleHY: 'Vehicle',
		// Vehicle: 'Vehicle',
		// Alcohol: 'Liquor',
		// Tea: 'Tea',
	
		// Finance: 'Finance',
		Storage: 'Storage',
		Other: 'Other',
	},
	FormMsg: {
		placeholder_commodity_name: "Up to 60 Chinese characters are supported for the title of the product. The content beyond the limit will no longer be displayed",
		Cancel: "Cancel",
		Sure: "OK",
	
		Save: "Save",
		Submit: "Submit",
	
		back: "Back",
	
		Modify: "Modify",
		Setting: "Setting",
	
		Open: "Open",
		Close: "Close",
		Refresh: "Refresh",
	
		Operation: "Operation", //操作
		Delete: "Delete", //删除
		Add: "Add", //添加
		Edit: "Edit", //编辑
		Select: "Select",
		// Modify:"Modify",//修改
	
		confirm_Delete: "Are you sure to delete it?",
	
		picture: "picture", //图片
		preview: "preview", //预览
	
		details_View: "details View", //查看明细
		upload_attachment: "upload attachment", //上传附件
	
		Mobile_phone: "Mobile phone",
		Verification_Code: "Verification Code",
		placeholder_Code: "Please enter the verification code.",
		Get_Code: "Get auth code",
		Sent: "Sent",
		seconds: "seconds",
		placeholder_must_pwd: "The password must be 6-18 bits and contain at least one letter and one digit.",
		placeholder_phone_msg: "Mobile phone verification code has been sent, please check!",
	
		Failed_captcha: "Failed to obtain captcha", //"获得验证码失败"
		error_captcha: "There was an error in the captcha", //验证码存在错误
		Password_inconsistency: "Password inconsistency", //密码不一致
	
		Please_Enter: "Please Enter",
		Please_select: "Please Select",
		Please_Enter_Sel: "Please enter or select",
		Please_upload: "Please Upload",
		Search: "Search",
		Query: "Query",
	
		ver_generation_card: "Please enter second generation id card",
		ver_number: "please enter number",
		ver_integer: "Please enter integer",
		ver_3_decimal: "Please keep up to three decimal places",
		ver_phone_number: "Please enter your mobile phone number",
		ver_place: "Please select the receiving city",
	
		Select_date: "Select date", //,"选择日期"
		Select_start_date: "Select start date", //,"选择开始日期"
		Select_end_date: "Select end date", //,"选择结束日期"
		Now: "Now", //今天
		yesterday: "Yesterday", //昨天
		A_Week_Ago: "A Week Ago", //一周前
	
		Select_date_time: "Select date time", //,"选择日期"
	
		Select_data_msg: "Select the data to operate on first", //请先选择要操作的数据
	},
	module: {
		HyAreaCodeSel: {
			"DefaMsg": 'Common Countries/Areas',
			"errorMsg": 'Mobile area code request failed',
		},
	
		HyCityCountyLinkage: {
			province: "province", //省份
			city: "city", //城市
			district_county: "district/county", //区/县
			warehouse: "warehouse", //仓库
			Failed_province_information: "Failed to obtain province information", //'获取省份信息失败'
			Failed_city_information: "Failed to obtain city information", //'获取城市信息失败'
			Failed_district_information: "Failed to obtain district information", //'获取区县信息失败'
			Failed_warehouse_information: "Failed to retrieve warehouse information", //'获取仓库信息失败'
		},
		upload: {
			Choose_File: "Choose File", //选取文件
			Upload_server: "Upload to server", //上传到服务器
			uploaded_over: "The size of the uploaded file cannot exceed", //上传文件大小不能超过 2M
			fail_upload: "fail to upload", //上传失败
			Please_upload_format: "Please upload files in appropriate format", //'请上传合适格式的文件'
			files_exceeded: "The number of files exceeded the limit", //'文件超出个数限制'
			Please_sel_file: "Please select the file first", //Please select the file first// 请先选择文件
			// Failed_district_information:"The size of the uploaded file cannot exceed",//'文件尺寸不能大于'
			Unknown_image: "Unknown image address", //'未知的图片地址'
			Unknown_file: "Unknown file", //this.$t('module.upload.Unknown_file')
	
			uploading: "uploading", //'正在上传'
			filename: "filename", //'文件名'
			Click_upload: "Click on the upload", //''点击上传
			open_error_img: "There is an error in opening the uploaded file. ", //''打开上传文件有误，
			open_browser_error_img: "The browser version is too low to support the file method, please upgrade the browser or contact customer service", //''浏览器版本过低不支持文件方法，请升级浏览器或联系客服
			comp_Failed: "Component loading error", //''组件加载有误，
	
			Import_success: 'Import success', //Import successthis.$t('module.upload.Import_success')
	
			Unknown_image: "Unknown image address", //''未知的图片地址
			Delete_picture: "Delete the picture", //删除图片
			sure_del_img: "Are you sure to delete this picture? Deletion will not be restored. Are you sure?", //确定删除此图片吗，删除将无法恢复。是否确定?
			Please_upload_format_img: "Please upload pictures in.png,.jpeg,.jpg,.gif format", //请上传 .png,.jpeg,.jpg,.gif格式的图片
			
			Select_Upload_Excel: "Upload Excel",
			Select_Excel: "Select Excel", //选择Excel
			Upload_preview: "Upload preview", //上传并预览
			Download_template: "Download template", //下载模板
			
			upload_el_wh_1: "notice",
			upload_el_wh_2: "up to upload",
			upload_el_wh_3: "file",
			upload_el_wh_4: "maximum",
			upload_el_wh_5: "file",
		},
		// RecommendShopList:{
		//   main:"Main",//"主营"
		//   not_available:"Not Available",//"暂无"
		//   view_details:"View Details",//"查看详情"
		// }
	},
	Storage: {
		common: {
			title: 'HYTX Storage',
			Logout: "Logout"
		},
		nav: {
			product: "product", //
			SKU_inventories: "SKU inventories", //
			SKU_information: "SKU information", //
			SKU_water: "SKU water", //
			prediction: "prediction", //
			shipments: "shipments", //
			My_packing_list: "My packing list", //
			My_invoice: "My invoice list", //
			A_undertakes_to: "Drop shipping", //
			report: "report", //
			My_storage_fee: "My storage fee", //
		},
		tableColumn: {
			no: "No.", // 序号
			remark: "remark", // 备注
			operation: "operation", // 操作
		},
		skuInfo: {
			Add_SKU: "Add SKU", // 添加SKU
			Import_SKU: "Import SKU", // 导入SKU
	
			SKU_detail: "SKU detail", //SKU详情
	
			SKU_information: "SKU information", // SKU信息
	
			cate_placeholder: "Select or search for product categories", // 选择或搜索商品类目
			cate_tips: "Supports keyword fuzzy search", // 支持关键字模糊搜索
			pic_size_tips: "It is recommended to upload an image with size no less than 100*100.", // 建议上传尺寸不小于100*100的图，
			pic_size_type: "Support PNG, JPG and other formats, the size of the recommended not more than 500K;", // 支持png、jpg等格式，大小建议不超过500k;
			sku_tips: "Only alphanumeric underscores, no more than 30 characters", // 只能是数字字母下划线，不超过30字符
	
			category: "category", // 产品分类
			SKU_title: "Name", // SKU名称
			Chinese_title: "Chinese Name", // SKU中文标题
			English_title: "English Name", // SKU英文标题
			Chinese: "Chinese",
			English: "English",
			SKU_pictures: "SKU pictures", // SKU图片
			price: "price", // 价值
			weight: "weight", // 重量
			unit: "unit", // 重量/体积单位
			length: "length", // 长度
			width: "width", // 宽度
			height: "height", // 高度
			length_width_height: "length*width*height", //长*宽*高
			safety_stock: "safety stock", // 安全库存
	
			warehouse: "warehouse", // 仓库
	
			product_information: "Product information", //产品信息
			Specification_information: "Specification information", //规格信息
	
			on_hand_inventory: "on-hand inventory", // 现有库位库存
			Current_locked_inventory: "Current locked inventory", // 当前锁定库存
			Current_available_stock: "Current available stock", // 当前有效库存
			unit_price: "unit price", // 单价
	
			date: "date", // 时间
			quantity: "quantity", // 数量
			number: "number", // 单号
			type: "type", // 类型
			explain: "explain", // 说明
		},
		packing_list: {
			status: "status", //状态
			_no: "No.", // 货柜号
			Create: "Create", // 创建装箱单
			Import: "Import", // 导入装箱单
			Departure_port: "Departure port", //出发港口
			arrive_port: "arrive port", //到达港口
			Departure_Date: "Departure Date", // 出发日期
			arrive_Date: "arrive Date", // 到达时间
			Type: "Type", // 装箱单类型
			quantity: "quantity", // 装箱件数
			Check_number: "Check number", // 复核件数
			attachment: "attachment", // 附件
	
			shipments: "shipments", //发货
	
			add: "Add", //增加
	
			basic_information: "basic information", // 基本信息
			detail: "detail", // 明细
			packing_information: "packing information", // 装箱信息
			Forwarder_guests: "Forwarder guests", // 货代客人
	
			Other: "Other", // 其他
	
			All: "All", // 全部
			not_receive: "not receive", // 未收货
			shipped: "shipped", // 已发货
			received: "received", // 已收货
	
			sure_shipment: "Are you sure of shipment?", // 确定发货吗?
		},
		invoice_list: {
			delivery_number: "delivery number", //:发货编号
			Create_invoice: "Create invoice", //创建发货单
			warehouse_umber: "warehouse number", // 仓库编号
			out_car_Type: "out car Type", // 出车方式
			Expected_delivery_date: "Expected delivery date", // 预计到车日期
			Delivery_Warehouse_Number: "Delivery Warehouse Number", //发货仓库编号
			Submit_instructions: "Submit instructions", //提交指令
			Download_lading_bill: "Download lading bill", //下载提货单
			Import_SKU_details: "Import SKU details", // 导入SKU明细
			arrive_storehouse: "arrive storehouse", // 到达仓库
			FBA_number: "FBA number", // FBA入库申请号
			Arrive_address: "Arrive address", // 到达区域
			Arrive_detailed_address: "Arrive detailed address", // 到达详细地址
			Volume: "Volume (length * width * height)", // 体积(长*宽*高)
			Add_SKU_manually: "Add SKU manually", // 手动新增SKU
	
			All: "All", //全部
			manuscript: "manuscript", // 草稿
			submitted: "submitted", // 已提交
			Picking: "Picking", // 拣货中
			Picking_complete: "Picking complete", // 拣货完成
			play_pack: "play pack", // 打托中
			For_outbound: "For outbound", // 待出库
			Have_outbound: "Have outbound", // 已出库
	
			submit_sure: "Are you sure to submit the shipping order?", //'确定 提交发货指令 吗?'
		},
		DropShipping: {
			Numbers: "Numbers", //Numbers单号
			Import: "Import the invoice", // 导入代发单
			Batch_submission: "Batch submission", // 批量提交
			quantity: "quantity", // 代发数量
	
			no_SKU: "no SKU", // 没有SKU
			date: "date", // 日期
			place_dispatch: "place of dispatch", // 发货地
			place_receipt: "place of receipt", // 收货地
			Addressee_name: "Addressee name", // 收件人名称
			company: "company", //公司
			contact_way: "contact way", //联系方式
			Address_1: "Address 1", // 地址1
			Address_2: "Address 2", // 地址2
			city: "city", // 城市
			State: "State", // 州/省
			postal_code: "postal code", // 邮编
			country: "country", // 国家
			submit: "submit", // 提交
	
			All: "All", // 全部
			manuscript: "manuscript", // 草稿
			submitted: "submitted", //已提交
			checked: "checked", // 已审核
			Picking: "Picking", // 拣货中
			shipped: "shipped", //已发货
	
			sel_tips_1: "Select the data you want to operate on first", // 请先选择要操作的数据
			sel_tips_2: "All selected drafts can be submitted", // 所有选中为草稿状态才可以提交~
			sure_submit_1: "Are you sure to submit", //Are you sure to submit 12 pieces of data?//确定提交
			sure_submit_2: "pieces of data?", //pieces of data//条数据吗?
			Parsing_failed: "Parsing failed, data volume is too large", // 解析失败，数据量过大
		},
		whFee: {
			Customer_No: "Customer No", //客户编号
			Fee_Type: "Fee Type", //费用类型
			Fee: "Fee", //费用
			Fee_description: "Fee description", //费用描述
			quantity: "quantity", //件数
			toll_collection_manner: "toll collection manner", //收费方式
			billing_date: "billing date", //账单日期
		}
	
	},
	// ...enStorage,
	...locale_en,
	...locale_en_client,
	...enLocale
}

export default en
