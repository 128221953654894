//浏览器 localStorage

//国际化，语言
// export const key_lang = "lang";

//默认选择的打印机
export const hy_wms_prt_name = "hy_wms_prt_name";

//页面标签历史
export const history_Tab_Data = "history_Tab_Data";

//样式模版
export const theme_config = "theme_config";
export const menuCollapse = "menuCollapse";

//自动生成批次-数量
export const DS_AUTO_C_BAT_NUM = "DS_AUTO_C_BAT_NUM";

//存Storage
export const setStorage = (name, value) => {
	localStorage.setItem(name, value);
}

//取Storage
export const getStorage = (name) => {
	return localStorage.getItem(name) || null;
}

//删除Storage
export const delStorage = (name) => {
	localStorage.removeItem(name);
}
