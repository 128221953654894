
/*请求地址配置*/

/*请求地址配置*/
let HY_BASEURL = "";
if (process.env.NODE_ENV === 'development') {
    // dev 开发 run dev
    HY_BASEURL = "/baseUrl";
} else {
    // build 线上和测试 run build
    HY_BASEURL = "";
}

const urlConfig = {

	//登录
	HyLogin: HY_BASEURL+"/bck/login",
	//登出
	HyLoginOut: HY_BASEURL+"/logout",
	//用户权限菜单
	HyPromission: HY_BASEURL+"/user/read/promission",
	//角色页
	// HyRolePage: HY_BASEURL+"/role/read/page",
	//用户信息 HyUserInfo key重复
	// HyUserInfo: HY_BASEURL+"/bizUserInfo/read/list",


	//手机号区域代码
	HyPhoneAreaCode: HY_BASEURL+"/dic/getAreaCode",
	// /bizUserInfo/read/list

	//用户信息分页数据
	//  HyUserInfo key重复
	// HyUserInfo: HY_BASEURL+"/user/read/list",
	//用户信息详情
	HyUserInfoDet: HY_BASEURL+"/user/read/detail",
	//用户信息新增
	HyUserInfoAdd: HY_BASEURL+"/user",
	//用户信息删除
	HyUserInfoDel: HY_BASEURL+"/user",
	//用户信息修改
	HyUserInfoModify: HY_BASEURL+"/user",
	
	//管理员 
	HyAdminUserInfo: HY_BASEURL+"/user/read/list",

	//部门分页数据
	// HyDeptInfo: HY_BASEURL+"/dept/read/list",
	// //部门新增
	// HyDeptAdd: HY_BASEURL+"/dept",
	// //部门删除
	// HyDeptDel: HY_BASEURL+"/dept",
	// //部门修改
	// HyDeptModify: HY_BASEURL+"/dept",
	// //部门信息详情
	// HyDeptDet: HY_BASEURL+"/dept/read/detail",

	//角色分页
	// HyRolePage: HY_BASEURL+"/role/read/page",
	// //角色新增
	// HyRoleAdd: HY_BASEURL+"/role",
	// //角色删除
	// HyRoleDel: HY_BASEURL+"/role",
	// //角色修改
	// HyRoleModify: HY_BASEURL+"/role",
	// //角色信息详情
	// HyRoleDet: HY_BASEURL+"/role/read/detail",

	//菜单集合
	HyMenuPage: HY_BASEURL+"/menu/read/list",
	//菜单所有集合
	HyMenuAll: HY_BASEURL+"/menu/read/all",
	//菜单新增
	HyMenuAdd: HY_BASEURL+"/menu",
	//菜单删除
	HyMenuDel: HY_BASEURL+"/menu",
	//菜单修改
	HyMenuModify: HY_BASEURL+"/menu",
	//菜单信息详情
	HyMenuDet: HY_BASEURL+"/menu/read/detail",
	//新菜单
	HyBckMenuAdd: HY_BASEURL+"/bckMenu",
	HyBckMenuDel: HY_BASEURL+"/bckMenu/deleteMenu",
	HyBckMenuModify: HY_BASEURL+"/bckMenu",

	//用户角色
	HyUserByRole: HY_BASEURL+"/user/read/role",
	//更新用户角色
	HyUserByRoleUpdate: HY_BASEURL+"/user/update/role",
	//同步缓存
	HySynchroCache: HY_BASEURL+"/cache/update",

	//角色菜单
	HyRoleByMeun: HY_BASEURL+"/role/read/menu",
	//更新角色菜单
	HyRoleByMeunUpdate: HY_BASEURL+"/role/update/menu",

	//权限菜单
	HyRoleByAuth: HY_BASEURL+"/menu/read/tree",
	//角色权限菜单
	HyRoleByPermiss: HY_BASEURL+"/role/read/permission",

	//字典分页
	HyDicPage: HY_BASEURL+"/dic/read/page",
	//字典新增
	HyDicAdd: HY_BASEURL+"/dic",
	//字典删除
	HyDicDel: HY_BASEURL+"/dic",
	//字典修改
	HyDicModify: HY_BASEURL+"/dic",
	//字典信息详情
	HyDicDet: HY_BASEURL+"/dic/read/detail",
	//字典集合，数据字典
	HyDicDitList: HY_BASEURL+"/dic/read/list",
	//字典类型
  HyDicType: HY_BASEURL+"/dic/query/types",
  //字典集合，数据字典，多类型查询
  HyDicQueryList: HY_BASEURL+"/dic/query/list",
	//参数分页
	HyParmPage: HY_BASEURL+"/sys/sysParam/read/list",
	//参数新增
	// HyParmAdd: HY_BASEURL+"/sys/sysParam/insertEntity",
	// //参数删除
	// HyParmDel: HY_BASEURL+"/sys/sysParam/deleteEntity",
	// //参数修改
	// HyParmModify: HY_BASEURL+"/sys/sysParam/updateEntity",
	// //参数信息详情
	HyParmDet: HY_BASEURL+"/sys/sysParam/read/detail",
	
	//字典缓存 /dic/refresh/dic Get
	HyDicRefresh: HY_BASEURL+"/dic/refresh/dic",

	//调度配置分页
	// HyScheduledTasksPage: HY_BASEURL+"/scheduled/read/tasks",
	// HyScheduledTasks: HY_BASEURL+"/scheduled",
	// //调度配置删除
	// HyScheduledTasksDel: HY_BASEURL+"/scheduled",
	// //调度配置修改
	// HyScheduledTasksRun: HY_BASEURL+"/scheduled/run",
	// //暂停
	// HyScheduledTasksClose: HY_BASEURL+"/scheduled/close",
	// //启动
	// HyScheduledTasksOpen: HY_BASEURL+"/scheduled/open",

	//操作日志分页
	// HyEventPage: HY_BASEURL+"/event/read/list",
	//操作日志新增
	// HyEventAdd: HY_BASEURL+"/event",
	// //操作日志删除
	// HyEventDel: HY_BASEURL+"/event",
	// //操作日志修改
	// HyEventModify: HY_BASEURL+"/event",
	// //操作日志信息详情
	// HyEventDet: HY_BASEURL+"/event/read/detail",

	//调度日志分页
	// HyScheduledPage: HY_BASEURL+"/scheduled/read/log",
	//调度日志新增
	// HyScheduledAdd: HY_BASEURL+"/scheduled",
	// //调度日志删除
	HyScheduledDel: HY_BASEURL+"/scheduled",
	// //调度日志修改
	// HyScheduledModify: HY_BASEURL+"/scheduled",
	// //调度日志信息详情
	// HyScheduledDet: HY_BASEURL+"/scheduled/read/detail",
	// 暂停
	// HyScheduledClose: HY_BASEURL+"/scheduled/close",
	// //启动
	// HyScheduledOpen: HY_BASEURL+"/scheduled/open",
	// //立即执行
	// HyScheduledRun: HY_BASEURL+"/scheduled/run",

	//消息公告
	//列表
	// HyAdvertiseMessagePageList: HY_BASEURL+"/platform/msg/sys/query/page/ad",
	// //后台保存和更新接口
	// HyAdvertiseMessageSave: HY_BASEURL+"/platform/msg/sys/update",
	// //草稿保存
	// HyAdvertiseMessageDraft: HY_BASEURL+"/platform/msg/sys/draft/update",
	// //发送
	// HyAdvertiseMessageSend: HY_BASEURL+"/platform/msg/sys/send",

	//反馈管理
	// HyFeedBackInfo: HY_BASEURL+"/sysConsultation/queryConsultations",
	//业务管理
	//会员管理
	//用户信息分页数据
	HyBizUserInfo: HY_BASEURL+"/bizUserInfo/read/list",
	// //用户信息详情
	HyBizUserInfoDet: HY_BASEURL+"/bizUserInfo/read/detail",
	// //用户信息新增
	HyBizUserInfoAdd: HY_BASEURL+"/bizUserInfo",
	// //用户信息删除
	HyBizUserInfoDel: HY_BASEURL+"/bizUserInfo",
	// //用户信息修改
	HyBizUserInfoModify: HY_BASEURL+"/bizUserInfo",
	// // 增加web用户
	addBizUserBySys: HY_BASEURL+"/bizUserInfo/add/bizUser",
	// //用户信息导入，转化格式
	// HyinsertUserExcel: HY_BASEURL+"/bizUserInfo/insertUserExcel",
	// //用户信息导入
	// HyinsertUserList: HY_BASEURL+"/bizUserInfo/insertUserList",

	//用户手机号
	HyBizUserMobileModify: HY_BASEURL+"/bizUserInfo/update",
	//修改密码 /bizUserInfo/resetPassword/{userId}   POST {"password":"","confromPassword":""}
	HyBizUserPasswordModify: HY_BASEURL+"/bizUserInfo/resetPassword",
	
	//用户列表简要信息
	// /bizUserInfo/query/simple/page 
	HyBizUserSimplePage: HY_BASEURL+"/bizUserInfo/query/simple/page",
	//企业信息分页数据
	// HyBizEnterpriseList: HY_BASEURL+"/bizEnterpriseAudit/read/queryEnterpriseList",
	// // 企业详情
	// HyBizEnterpriseDet: HY_BASEURL+"/bizEnterpriseAudit/read/enterpriseDetail",
	// // 企业修改
	// HyBizEnterpriseUpdate: HY_BASEURL+"/bizEnterpriseAudit/update/enterprise",
	// //企业ca证书
	// HyBizEnterpriseCA: HY_BASEURL+"/bizContractTemplate/addCertificateAuthority",
	// // 企业审核
	// HyBizEnterpriseAudit: HY_BASEURL+"/bizEnterpriseAudit/auditing",
	// //企业，解冻冻结
	// HyBizEnterpriseFrozen: HY_BASEURL+"/bizEnterpriseAudit/updateEnterpriseState",

	// 商户信息分页数据
	// HyBizShopList: HY_BASEURL+"/bizEnterpriseAudit/read/queryShopList",
	// // 商户详细信息
	// HyBizShopDet: HY_BASEURL+"/bizUserInfo/read/shopDetail",
	// // 商户编辑
	// HyBizShopUpdate: HY_BASEURL+"/bizUserInfo/update/shop",
	// // 合伙人信息分页数据
	// HyBizPartnerList: HY_BASEURL+"/bizEnterpriseAudit/read/queryPartnerList",
	// // 合伙人详细信息
	// HyBizPartnerDet: HY_BASEURL+"/bizUserInfo/read/partnerDetail",
	// // 合伙人编辑
	// HyBizPartnerUpdate: HY_BASEURL+"/bizUserInfo/update/partner",
	// //合伙人，发放证书，查询
	// HyPartnerCertiGeneQuery: HY_BASEURL+"/bizPartnerCertificate/read/detail",
	// //合伙人，发放证书，生成证书编码
	// HyPartnerCertiGeneCode: HY_BASEURL+"/bizPartnerCertificate/getCertificateNo",
	// //合伙人，发放证书，保存证书
	// HyPartnerCertiGeneSave: HY_BASEURL+"/bizPartnerCertificate/insertCertificateNo",
	// //合伙人，作废销毁证书
	// HyPartnerCertiGeneCancel: HY_BASEURL+"/bizPartnerCertificate/updateCertificate",
	// // 商户主页分页
	// Hy_BizShopHomepage: HY_BASEURL+"/bizEnterpriseAudit/read/shopHomepagList",
	// // 商户主页详情
	// Hy_BizShopHomepageDet: HY_BASEURL+"/bizEnterpriseAudit/read/shopHomepageDetail",
	// // 商户主页修改
	// Hy_BizShopHomepageUpdate: HY_BASEURL+"/bizEnterpriseAudit/update/shopHomepageUpdate",
	// // 商户主页新增
	// ShopHomePageAdd: HY_BASEURL+"/bizEnterpriseAudit/add/shopHomepage",
	// //金融申请管理
	// HyFinanceManaList: HY_BASEURL+"/sys/bizFinanceEnterprise/read/list",
	// //修改金融企业用户认证审核状态和用户状态
	// HyFinanceUpdateStatus: HY_BASEURL+"/sys/bizFinanceEnterprise/updateStatus",
	// 棉花商品列表
	// HyBizGoodsCottonList: HY_BASEURL+"/bizGoodsList/read/queryCottonList",
	// 棉纱商品列表
	// HyBizGoodsCottonYarnList: HY_BASEURL+"/bizGoodsList/read/queryCottonYarnList",

	// 用户日志分页接口
	HyBizUserHistory: HY_BASEURL+"/bizUserHistory/read/list",
	// // 企业日志分页接口
	// HyBizEnterpriseHistory: HY_BASEURL+"/bizEnterpriseHistory/read/list",
	// // 合伙人日志分页接口
	// HyBizPartnerHistory: HY_BASEURL+"/bizPartnerHistory/read/list",
	// // 商户日志分页接口
	// HyBizShopHistory: HY_BASEURL+"/bizShopHistory/read/list",
	// // 棉 活动 分页
	// HyActiveMana: HY_BASEURL+"/tmpInpartEnterprise/get",
	// // 合伙人招募活动 分页
	// HyTmpRecuitPartnerList: HY_BASEURL+"/sysTmpRecruitPartner/read/list",
	// // 合伙人招募逻辑删除
	// HyTmpRecuitPartnerDel: HY_BASEURL+"/sysTmpRecruitPartner/delete",

	//新后端接口
	//管理员,用户
	HyAdminPage: HY_BASEURL+"/bckUser/showUser",
	HyAdminAdd: HY_BASEURL+"/bckUser/addUser",
	HyAdminUpdate: HY_BASEURL+"/bckUser/updateUser",
	HyAdminDel: HY_BASEURL+"/bckUser/deleteUser",
	HyAdminDetail: HY_BASEURL+"/bckUser/showUserDetail",
	//权限组
	HyPermissGropPage: HY_BASEURL+"/bckRole/showGroup",
	HyPermissGropAdd: HY_BASEURL+"/bckRole/addGroup",
	HyPermissGropUpdate: HY_BASEURL+"/bckRole/updateGroup",
	HyPermissGropDel: HY_BASEURL+"/bckRole/deleteGroup",
	HyPermissGropDetail: HY_BASEURL+"/bckRole/showGroupDetail",
	//全部菜单
	HyMenuAllPage: HY_BASEURL+"/bckMenu/read/all",
	//当前用户菜单信息
	HyAdminMenuAllPage: HY_BASEURL+"/bckUser/getCurrentUserInfo",
	// 开发用 当前用户菜单信息
	// HyAdminMenuAllPage: "/static/menu.json",
	//仓库管理
	// HyStoreManaPage: HY_BASEURL+"/sysSellerStore/read/list",
	// HyStoreManaAdd: HY_BASEURL+"/sysSellerStore/insert",
	// HyStoreManaUpdate: HY_BASEURL+"/sysSellerStore/update",
	// HyStoreManaDel: HY_BASEURL+"/sysSellerStore/delete",
	// HyStoreManaDetail: HY_BASEURL+"/sysSellerStore/querySellerStoreById",

	//厂家信息管理
	//分页
	// HyManufacturersPage: HY_BASEURL+"/factory/queryFactoryPage",
	// //新增
	// HyManufacturersAdd: HY_BASEURL+"/factory/insertFactory",
	// //编辑
	// HyManufacturersUpdate: HY_BASEURL+"/factory/updateFactory",
	// //删除
	// HyManufacturersDel: HY_BASEURL+"/factory/deleteFactory",
	// //查询
	// HyManufacturersQuery: HY_BASEURL+"/factory/queryFactory",

	//产能预售厂家
	//分页
	// HyFactoryCapacityPage: HY_BASEURL+"/sysPreSellCapacity/read/page",
	// //新增
	// HyFactoryCapacityAdd: HY_BASEURL+"/sysPreSellCapacity/insert",
	// //编辑
	// HyFactoryCapacityUpdate: HY_BASEURL+"/sysPreSellCapacity/update",
	// //删除
	// HyFactoryCapacityDel: HY_BASEURL+"/sysPreSellCapacity",
	// //查询
	// HyFactoryCapacityQuery: HY_BASEURL+"/sysPreSellCapacity/read/detail",
	// //明细，分页
	// HyFactoryCapacityDetList: HY_BASEURL+"/sysPreSellCapacityDetail/read/page",
	// //明细，审核
	// HyFactoryCapacityDetAuth: HY_BASEURL+"/sysPreSellCapacityDetail",

	//业务架构管理
	//查询顶层架构
	// BusinessArchitectureTopListData: HY_BASEURL+"/sysBranchGroup/queryTopGroup",
	// //根据上级组织id查找组织和成员信息
	// // BusinessArchitectureListData: HY_BASEURL+"/sysBranchGroup/queryBranchGroupByParentId",
	// BusinessArchitectureListData: HY_BASEURL+"/sysBranchGroup/queryBranchGroupByGroupId",
	// //批量新增组织
	// BusinessArchitectureListAdd: HY_BASEURL+"/sysBranchGroup/insertBranchGroupBatch",
	// //批量新增人员
	// BusinessArchitecturePersonListAdd: HY_BASEURL+"/sysBranchGroupUser/insertBranchGroupUserBatch",
	// //查询合伙人手机号
	// HySerParentPhoneList: HY_BASEURL+"/sysBranchGroupUser/getUsersByMobileChar",
	// //删除人员
	// BusinessArchitectureDelPer: HY_BASEURL+"/sysBranchGroupUser/deleteBranchGroupUser",
	// //删除组织
	// BusinessArchitectureDelOrg: HY_BASEURL+"/sysBranchGroup/deleteBranchGroup",
	// //编辑组织
	// BusinessArchitectureEditOrg: HY_BASEURL+"/sysBranchGroup/updateBranchGroup",
	// //编辑人员
	// BusinessArchitectureEditPerson: HY_BASEURL+"/sysBranchGroupUser/updateBranchGroupUser",
	// //组织树状查询
	// BusinessArchitectureOrgAll: HY_BASEURL+"/sysBranchGroup/queryGroupAll",

	//运营活动，插入不带模板的活动
	// OperInActivityNT: HY_BASEURL+"/sysActivityTemplate/insertActivityNT",
	// //运营活动，分页查询不带模板的活动
	// OperActivityNTPage: HY_BASEURL+"/sysActivityTemplate/getActivityNTPage",
	// //运营活动，删除不带模板的活动
	// DelOperActivityNT: HY_BASEURL+"/sysActivityTemplate/delActivityNT",
	// //运营活动，查询不带模板的活动集合
	// DetOperActivityNT: HY_BASEURL+"/sysActivityTemplate/getActivityNT",
	// //运营活动，编辑不带模板的活动
	// UpdateOperActivityNT: HY_BASEURL+"/sysActivityTemplate/updateActivityNT",

	//运营活动，插入带模板的活动
	// OperInActivityMT: HY_BASEURL+"/sysActivityTemplate/insertActivityWT",
	// //运营活动，分页查询带模板的活动
	// OperActivityMTPage: HY_BASEURL+"/sysActivityTemplate/getActivityWTPage",
	// //运营活动，删除带模板的活动
	// DelOperActivityMT: HY_BASEURL+"/sysActivityTemplate/delActivityWT",
	// //运营活动，查询带模板的活动集合
	// DetOperActivityMT: HY_BASEURL+"/sysActivityTemplate/getActivityWT",
	// //运营活动，编辑带模板的活动
	// UpdateOperActivityMT: HY_BASEURL+"/sysActivityTemplate/updateActivityWT",

	//更新不带模板的活动展示状态
	// UpdateActivityNTSS: HY_BASEURL+"/sysActivityTemplate/updateActivityNTSS",
	// //更新广告位活动展示状态
	// UpdateAdvSS: HY_BASEURL+"/sysActivityTemplate/updateAdvSS",
	// //更新带模板的活动展示状态
	// UpdateTemplateSS: HY_BASEURL+"/sysActivityTemplate/updateTemplateSS",

  //移动首页推荐位--新移动运营配置
  //分页
  // OperActivityNewPage: HY_BASEURL+"/sysActivityBaseConf/query/page",
  // //新增和更新
  // OperActivityNewUpdate: HY_BASEURL+"/sysActivityBaseConf/update",
  // //查询详情
  // // /sysActivityBaseConf/query/{id}/goods
  // OperActivityNewDet: HY_BASEURL+"/sysActivityBaseConf/query",
  // //删除
  // // /sysActivityBaseConf/delete/{id}
  // OperActivityNewDel: HY_BASEURL+"/sysActivityBaseConf/delete",

	//钢材商品管理
	// HySteelGoods: HY_BASEURL+"/bizGoodsList/read/querySteelList",
	//店铺名称模糊查询
	// HyShopNameLike: HY_BASEURL+"/bizUserInfo/queryByShopName",
	// //商品管理
	// HyGoods: HY_BASEURL+"/bizGoodsList/read/list",
	// //商品状态更新
	// HyUpdateGoodsStatus: HY_BASEURL+"/bizGoodsList/updateGoodsStatus",
	// // 商品属性分页
	// HyGoodsAttributePage: HY_BASEURL+"/sysSellerAttribute/read/list",
	// // 商品属性修改
	// HyGoodsAttributeUpdate:HY_BASEURL+"/sysSellerAttribute/update",

	//多类目商品
	// HyCateGoods: HY_BASEURL+"/sysShopGoods/bizshopGoods/queryByPage",
 //  //商品状态，上下架
	// HyCateGoodsUpdateStatus: HY_BASEURL+"/sysShopGoods/shopGoods/update/state",
 //  //商品审核
 //  HyCateGoodsAuditStatus: HY_BASEURL+"/sys/bizShopGoods/audite",
	// /sysShopGoods/shopGoods/update (POST) 修改
	// /sysShopGoods/shopGoods/delete(POST)删除
	// /sysShopGoods/shopGoods/update/state  (POST)上下加

	//木材商品管理
	// HyWoodGoods: HY_BASEURL+"/bizGoodsList/read/queryWoodList",
	//属性值管理
	// HyGoodsAttrPage: HY_BASEURL+"/sysSellerSub/read/specvalues",
	// HyGoodsAttrValAdd: HY_BASEURL+"/sysSellerSub/insert",
	// HyGoodsAttrUpdate: HY_BASEURL+"/sysSellerSub/update",
	// HyGoodsAttrDel: HY_BASEURL+"/sysSellerSub/delete",
	// HyGoodsAttrDetail: HY_BASEURL+"/sysSellerSub/read/querySpecValuesBySpId",
	// HySellerSubList: HY_BASEURL+"/sysSellerSub/read/querySubByParams",
	// //前台类目管理
	// HyHomeCategoryPage:  HY_BASEURL+"/sysHomeCategory/queryPage",
	// HyHomeCategoryList:  HY_BASEURL+"/sysHomeCategory/queryList",
	// HyHomeCategoryInsert: HY_BASEURL+"/sysHomeCategory/update",
	// HyHomeCategoryUpdate: HY_BASEURL+"/sysHomeCategory/update",
	// HyHomeCategoryDelete: HY_BASEURL+"/sysHomeCategory/delete",
	// //刷新前台类目缓存
	// HyHomeCategoryPageRefrence:  HY_BASEURL+"/sysHomeCategory/cache/refrence/categroy",

  //多类目关键词管理、
  //分页
 //  HyCategoriesKeysPage:  HY_BASEURL+"/sysSearchKeyWords/query/page",
 //  //新增
 //  // HyCategoriesKeysAdd:  HY_BASEURL+"/sysSearchKeyWords/update",
 //  //编辑
 //  HyCategoriesKeysUpdate:  HY_BASEURL+"/sysSearchKeyWords/update",

	// //求购大厅管理
	// HyPurchasePageList: HY_BASEURL+"/sys/bizEnquiry/read/list",
	// //求购单，审核状态，下架
	// HyPurchaseAudit: HY_BASEURL+"/sys/bizEnquiry/updateAuditStatus",

 //  //新-求购大厅管理
 //  HyPurchaseNewPageList: HY_BASEURL+"/bizEnquiryV2/query/page",
 //  //审核
 //  HyPurchaseNewAudit: HY_BASEURL+"/bizEnquiryV2/verifty",
 //  //编辑
 //  HyPurchaseNewUpdate: HY_BASEURL+"/bizEnquiryV2/opr/update",


	//民生广告，列表
	// HyLilihoodAdverPageList: HY_BASEURL+"/sysLivelihoodAdvertising/read/list",
	// //民生广告，新增
	// HyLilihoodAdverAdd: HY_BASEURL+"/sysLivelihoodAdvertising/insert",
	// //民生广告，编辑
	// HyLilihoodAdverUpdate: HY_BASEURL+"/sysLivelihoodAdvertising/update",
	// //民生广告，删除
	// HyLilihoodAdverDel: HY_BASEURL+"/sysLivelihoodAdvertising/delete",

	// //司机管理
	// HyDriverPageList: HY_BASEURL+"/driver/getDriverInfos",
	// //司机编辑更新
	// HyDriverUpdate: HY_BASEURL+"/driver/updateDriver",
	// //司机审核数据
	// HyDriverUpdateStatus: HY_BASEURL+"/driver/updateStatus",

	//开户管理
	// HyOpenAccountPageList: HY_BASEURL+"/bizOnlineAccount/query/account/proc",

	//省市县仓库
	// ProvinceListData:  HY_BASEURL+"/sys/sysProvince/queryAllInfo",
	// ProvinceCityData:  HY_BASEURL+"/sys/sysCity/queryCityByProvinceCode",
	// CityCountyData:  HY_BASEURL+"/sys/sysArea/queryAreaByCityCode",
	// CityStoryData:  HY_BASEURL+"/sys/sysProvince/queryAllInfo",

	//订单信息
	// HyOrderListPage:  HY_BASEURL+"/bizOrderInfos/read/queryOrderList",
	// //新订单列表
	// HyOrderPageListPage:  HY_BASEURL+"/order/qryOrderByPage",
	// HyOrderListDetail: HY_BASEURL+"/bizMarketSuborder/read/detail",
	// //企业联想输入控件
	// HyEnterpriseListLike: HY_BASEURL+"/associative/qryEnterpriseName",
	// //订单流程联想输入控件
	// HyOrderProcedureListLike: HY_BASEURL+"/associative/qryOrderProcedure",

	// //货的订单-运输单列表
	// HyHuoDeOrderPageListPage:  HY_BASEURL+"/bizCargoOrder/qryCargoOrderByPage",

	//民生订单管理
	// HyPeoLiveOrderPageListPage:  HY_BASEURL+"/bizLivelihoodOrder/queryOrderByPage",
	// //民生订单详情
	// HyPeoLiveOrderDet:  HY_BASEURL+"/bizLivelihoodOrder/queryOrderById",

 //  //取消订单
 //  HyPeoLiveOrderCancel:  HY_BASEURL+"/bizLivelihoodOrder/oprator/cancel",

 //  //后台管理-订单支付凭证审核
 //  HyPeoLiveOrderPayAuth:  HY_BASEURL+"/bizLivelihoodOrder/evidence/verfity",

 //  //修改订单备注
 //  HyModifyRemark:  HY_BASEURL+"/bizLivelihoodOrder/update/remark",

	//资讯管理
	// HyArticleManaPage: HY_BASEURL+"/sysMarketInfoValue/query/page",
	// HyArticleManaAdd: HY_BASEURL+"/sysMarketInfoValue/addNews",
	// HyArticleManaUpdate: HY_BASEURL+"/sysMarketInfoValue/addNews",
	// HyArticleManaDel: HY_BASEURL+"/sysMarketInfoValue/del",
	// HyArticleManaDetail: HY_BASEURL+"/sysMarketInfoValue/edit",
	// //分类查询
	// HyArticleDic: HY_BASEURL+"/sysMarketInfoValue/dicInfo",

	//省份
	// HyProvincePageData:  HY_BASEURL+"/sys/sysProvince/read/list",
	// HyProvinceListData:  HY_BASEURL+"/sys/sysProvince/queryList",
	// HyProvinceUpdate:  HY_BASEURL+"/sys/sysProvince/update",
	// HyProvinceDelete:  HY_BASEURL+"/sys/sysProvince/delete",
	// HyProvinceInsert:  HY_BASEURL+"/sys/sysProvince/insert",
	// //城市
	// HyCityPageData:  HY_BASEURL+"/sys/sysCity/read/list",
	// HyCityUpdate:  HY_BASEURL+"/sys/sysCity/update",
	// HyCityDelete:  HY_BASEURL+"/sys/sysCity/delete",
	// HyCityInsert:  HY_BASEURL+"/sys/sysCity/insert",
	// HyCityListData:  HY_BASEURL+"/sys/sysCity/queryList",
	// //区县
	// HyAreaPageData:  HY_BASEURL+"/sys/sysArea/read/list",
	// HyAreaUpdate:  HY_BASEURL+"/sys/sysArea/update",
	// HyAreaDelete:  HY_BASEURL+"/sys/sysArea/delete",
	// HyAreaInsert:  HY_BASEURL+"/sys/sysArea/insert",

	// //app更新控制
	// HyAppUpdateQueryPage:  HY_BASEURL+"/sysHomeAppUpdate/read/list",
	// HyAppUpdateInsert:  HY_BASEURL+"/sysHomeAppUpdate/insert",
	// HyAppUpdateModify:  HY_BASEURL+"/sysHomeAppUpdate/update",
	// HyAppUpdateDelete:  HY_BASEURL+"/sysHomeAppUpdate/delete",
	// HyAppUpdateQueryById:  HY_BASEURL+"/sysHomeAppUpdate/queryAppUpdateById",

	//app更新控制
	// HyAppLinkQueryPage:  HY_BASEURL+"/sysHomeAppLink/read/list",
	// HyAppLinkInsert:  HY_BASEURL+"/sysHomeAppLink/insert",
	// HyAppLinkModify:  HY_BASEURL+"/sysHomeAppLink/update",
	// HyAppLinkDelete:  HY_BASEURL+"/sysHomeAppLink/delete",
	// HyAppLinkQueryById:  HY_BASEURL+"/sysHomeAppLink/queryAppLinkedById",

	// //Banner更新控制
	// HyBannerQueryPage:  HY_BASEURL+"/sysBannerConfig/read/list",
	// HyBannerInsert:  HY_BASEURL+"/sysBannerConfig/insert",
	// HyBannerModify:  HY_BASEURL+"/sysBannerConfig/update",
	// HyBannerDelete:  HY_BASEURL+"/sysBannerConfig/delete",
	// HyBannerQueryById:  HY_BASEURL+"/sysBannerConfig/queryBannerById",

	// 属性列表查询
	HyshowAttribute: HY_BASEURL+"/sysAttribute/showAttribute",
	// 属性List查询
	HyshowAttributeList: HY_BASEURL+"/sysAttribute/showAttributeList",

	// 优质厂商管理
	// HyGoodShopMana: HY_BASEURL+"/sysGoodShop/queryPage",
	// // 优质厂商推荐
	// HyGoodShopRecommend:HY_BASEURL+"/sysGoodShop/goodShop",
	// // 优质厂商详情
	// HyGoodShopDel: HY_BASEURL+"/sysGoodShop/queryById",
	// // 优质商户删除
	// HyGoodShopDelete: HY_BASEURL+"/sysGoodShop/delete",
	// // 优质商户排序
	// HyGoodShopUpdateLevel:HY_BASEURL+"/sysGoodShop/update",

	//合同管理
	//合同管理列表
	// HyContractTemPageList:HY_BASEURL+"/bizContractTemplate/qryTemplateByPage",
	// //合同模板上传
	// HyContractTemAdd:HY_BASEURL+"/bizContractTemplate/addContractTemplate",
	// //合同预览
	// HyPreviewContractDet:HY_BASEURL+"/bizContractTemplate/viewTemplate",
	// //合同删除
	// HyDelContractTem:HY_BASEURL+"/bizContractTemplate/updateTemplateByDelete",
	// //浏览合同模板
	// HyViewRecContractTem: HY_BASEURL+"/bizContractTemplate/viewTemplate",


	//账单
	// HyQueryMatchUserMatchfeePage: HY_BASEURL+"/sysBill/query/userMatchfeePage",
	// HyQueryMatchUserMatchfeeDetail: HY_BASEURL+"/sysBill/query/userMatchfeeDetailPage",
	// HyQueryMatchUserModify: HY_BASEURL+"/sysBill/update/userMatchfee",

	// HyQueryMatchPartnerMatchfeePage: HY_BASEURL+"/sysBill/query/partnerMatchfeePage",
	// HyQueryMatchPartnerMatchfeeDetailPage: HY_BASEURL+"/sysBill/query/partnerMatchfeeDetailPage",
	// HyQueryMatchPartnerModify: HY_BASEURL+"/sysBill/update/partnerMatchfee",
	//货的提现
	// HyCarDeposListPage: HY_BASEURL+"/bizAccountCash/query/cash/page",
	// //货的审核、核销
	// HyCarDeposAutaData: HY_BASEURL+"/bizAccountCash/finVerify/cash",

	// //服务费明细管理
	// HyServiceFeeMana: HY_BASEURL+"/bizOnlineAccount/query/service/fee",
	// //服务费分账发送短信
	// HyAccountSendMsg: HY_BASEURL+"/bizOnlineAccount/send/msg",
	// //进行分账操作，短信验证码
	// HySeparateAccounts: HY_BASEURL+"/bizOnlineAccount/div/service/fee",

	// //服务费发票管理
	// HyServiceFeeReceiptMana: HY_BASEURL+"/sys/bizBuyInvoice/queryPageByEnterprise",

 //  //信用保证金
 //  HyDepositCreditorMana: HY_BASEURL+"/sys/bizAccount/query/ma/page",
 //  //信用保证金-明细
 //  HyDepositCreditorDetList: HY_BASEURL+"/sys/bizAccount/query/ma/detail",

	//商品类目管理 sku需要
  //树形类目
  HyCommodityCateTreeList: HY_BASEURL+"/sysShopGoods/shopGoodsSubject/tree",
	// //查询
	// HyCommodityCateList: HY_BASEURL+"/sysShopGoods/shopGoodsSubject/queryByParams",
	// //新增
	// HyCommodityCateAdd: HY_BASEURL+"/sysShopGoods/shopGoodsSubject/insert",
	// //删除
	// HyCommodityCateDel: HY_BASEURL+"/sysShopGoods/shopGoodsSubject/delete",
	// //修改
	// HyCommodityCateUpdate: HY_BASEURL+"/sysShopGoods/shopGoodsSubject/update",
	// //多类目商品属性
	// //查询
	// HyCateAttrsList: HY_BASEURL+"/sysShopGoods/shopGoodsAttribute/queryByPage",
	// //新增
	// HyCateAttrsAdd: HY_BASEURL+"/sysShopGoods/shopGoodsAttribute/insert",
	// //删除
	// HyCateAttrsDel: HY_BASEURL+"/sysShopGoods/shopGoodsAttribute/delete",
	// //修改
	// HyCateAttrsUpdate: HY_BASEURL+"/sysShopGoods/shopGoodsAttribute/update",

	//添加属性
	// HyAttributeAdd: HY_BASEURL+"/sysAttribute/insertAttribute",
	// //查询属性
	// HyAttributeList: HY_BASEURL+"/sysAttribute/showAttribute",
	// //删除属性
	// HyAttributeDelete: HY_BASEURL+"/sysAttribute/deleteAttribute",
	// //修改属性
	// HyAttributeUpdate: HY_BASEURL+"/sysAttribute/updateAttribute",

  //厂家类目-标签管理
  //分页列表
 //  HyFacTagsPageList: HY_BASEURL+"/bizEnterpriseLabel/query/page",
 //  //新增-修改
 //  HyFacTagsUpdate: HY_BASEURL+"/bizEnterpriseLabel/update",

	// //结算配置
	// HyMatchintFeeConfQueryPage: HY_BASEURL+"/sysMatchingFeeConf/query/Page",
	// HyMatchintFeeConfUpdateRecord: HY_BASEURL+"/sysMatchingFeeConf/update/record",
	// HyMatchintFeeConfInvalidDate: HY_BASEURL+"/sysMatchingFeeConf/invalid/date",
	// HyMatchintFeeConfQueryDic: HY_BASEURL+"/sysMatchingFeeConf/query/dic",
	// //结算配置详情
	// HyMatchintFeeConfQueryDet: HY_BASEURL+"/sysMatchingFeeConfDetail/read/list",

	// //结算配置--新
	// //结算配置--收取
	// HySettleConfCollectQueryPage: HY_BASEURL+"/sysMatchingFeeConfV2/query/page",
	// HySettleConfCollectAdd: HY_BASEURL+"/sysMatchingFeeConfV2/update",
	// HySettleConfCollectOff: HY_BASEURL+"/sysMatchingFeeConfV2/invalid",
 //  HySettleConfCollectDet: HY_BASEURL+"/sysMatchingFeeConfV2/query/list",
	// //结算配置--分成
	// HySettleConfAllocationQueryPage: HY_BASEURL+"/sysMatchingFeeDivConf/query/page",
	// HySettleConfAllocationAdd: HY_BASEURL+"/sysMatchingFeeDivConf/update",
	// HySettleConfAllocationOff: HY_BASEURL+"/sysMatchingFeeDivConf/delete/",
	// //结算配置--关系配置
	// HySettleConfRelationQueryPage: HY_BASEURL+"/sysMatchingSubjectConfig/query/page",
	// HySettleConfRelationAdd: HY_BASEURL+"/sysMatchingSubjectConfig/update",
 //  HySettleConfRelationDel: HY_BASEURL+"/sysMatchingSubjectConfig/deletes",
 //  //结算配置--关系配置--厂家
 //  HySettleConfRelationFacQueryPage: HY_BASEURL+"/sysMatchingEnterSubjectConfig/query/Page",
 //  HySettleConfRelationFacAdd: HY_BASEURL+"/sysMatchingEnterSubjectConfig/save",
 //  HySettleConfRelationFacDel: HY_BASEURL+"/sysMatchingEnterSubjectConfig/deletes",

	//活动中心
	//集采供销商品管理列表
	// HyCentralizedGoodsPageList: HY_BASEURL+"/sysSellerSpellGoods/read/list",
	// //集采供销商品新增
	// HyCentralizedGoodsAdd: HY_BASEURL+"/sysSellerSpellGoods/insert",
	// //集采供销商品删除
	// HyCentralizedGoodsDel: HY_BASEURL+"/sysSellerSpellGoods/delete",
	// //集采供销参团分页-活动跟踪
	// HyCentralizedGroupPageList: HY_BASEURL+"/bizBookingJoin/read/page",
	// //集采供销参团-活动跟踪-删除
	// HyCentralizedGroupDel: HY_BASEURL+"/bizBookingJoin/deleteEntity",
	// //现货拼团列表
	// HyGroupBuyingPageList: HY_BASEURL+"/bizBookingGoods/read/page",
	// //现货拼团列表-删除
	// HyGroupBuyingDel: HY_BASEURL+"/bizBookingGoods/deleteEntity",

  //报表中心
  //合伙人厂家商品统计
  // HyParFacGoodsPageList: HY_BASEURL+"/bizPartner/query/facAndGoods/statistics",
  // //合伙人厂家商品明细
  // HyParFacGoodsDetList: HY_BASEURL+"/bizPartner/query/facAndGoods/detail",
  // //每日新增厂家合伙人
  // HyParFacGoodsTodayList: HY_BASEURL+"/bizPartner/query/facAndGoods/addtotal",

  // //合伙人汇总报表统计
  // HyParReportCollect: HY_BASEURL+"/bizPartner/query/op/report",
  // //邀请合伙人明细
  // InvitePartnersList: HY_BASEURL+"/bizPartner/query/recommendPartnerDetail/report",
  // //签约厂家明细
  // SignEnterpriseList: HY_BASEURL+"/bizPartner/query/signEnterpriseDetail/report",
  // //厂家发布在售明细
  // enterGoodsInfoList: HY_BASEURL+"/bizPartner/query/enterGoodsInfoDetail/report",

  // //厂家关闭
  // closeEnterprise: HY_BASEURL+"/bizEnterpriseAudit/close",
  // //合伙人关闭
  // closePartner: HY_BASEURL+"/bizPartner/close",

  //进销存中心
  // //进-基本信息分页
  // PurchasingBasePageList: HY_BASEURL+"/bizPurchaseBase/query/page",
  // //进-采购信息提交
  // PurchasingBaseUpdate: HY_BASEURL+"/bizPurchaseBase/update",
  // //进-采购商品分页列表
  // PurchasingGoodsPageList: HY_BASEURL+"/bizPurchaseItem/query/page",
  // // 进-采购费用分页列表
  // PurchasingFeePageList: HY_BASEURL+"/bizPurchaseFee/query/page",

  //销售信息管理-分页
  // closeEnterprise: HY_BASEURL+"/bizEnterpriseAudit/close",
  // //销售SKU明细记录查询
  // PurchasingSellSkuPageList: HY_BASEURL+"/bizPurchaseOrderItem/query/page",
  // //销售信息分页查询
  // PurchasingSellPageList: HY_BASEURL+"/bizPurchaseOrder/query/page",
  // //销售信息提交
  // PurchasingSellAdd: HY_BASEURL+"/bizPurchaseOrder/update",

  // //物流货运管理-分页
  // PurchaseLogisticsPageList: HY_BASEURL+"/bizPurchaseLogistics/query/page",
  // //物流货运管理-新增
  // PurchaseLogisticsUpdate: HY_BASEURL+"/bizPurchaseLogistics/update",
	
	//出库地址保存新增
	// 地址分页 /wh/bizWhFc/query/my/page
	WhTransferAddressPageList:  HY_BASEURL + "/wh/bizWhFc/query/my/page",
	//地址删除 /wh/bizWhFc/delete/{id}
	WhTransferAddressDel:  HY_BASEURL + "/wh/bizWhFc/delete",
	//地址添加 /wh/bizWhFc/add/wh/fc  
	WhTransferAddressAdd:  HY_BASEURL + "/wh/bizWhFc/add/wh/fc",
	
	//转运出库-目的地更新
	// /wh/bizWhTransferOut/update/fba/address  PUT  {"id":"","outWhCode":""}
	WhTransferUpdataAddress:  HY_BASEURL + "/wh/bizWhTransferOut/update/fba/address",
	//自提-目的地更新
	// /wh/bizWhMySendGoods/update/fba/address  PUT {"id":"","toWhNo":""}  自提
	WhShipmentUpdataAddress:  HY_BASEURL + "/wh/bizWhMySendGoods/update/fba/address",
	
	//转运bol下载
	// /wh/bizWhTransferOut/download/bol/{id}  GET 
	WhTransferOutDownloadBol:  HY_BASEURL + "/wh/bizWhTransferOut/download/bol",
	//自提bol下载
	// /wh/bizWhMySendGoods/download/bol/{id}  GET 
	WhShipmentDownloadBol:  HY_BASEURL + "/wh/bizWhMySendGoods/download/bol",
	
	//预约出库
	// 分页 /wh/bizWhTransferOutPlanBatch/query/page
	WhTransferPlanOutWhPageList:  HY_BASEURL + "/wh/bizWhTransferOutPlanBatch/query/page",
	//新增 /wh/bizWhTransferOutPlanBatch/add/plans
	WhTransferPlanOutWhAdd:  HY_BASEURL + "/wh/bizWhTransferOutPlanBatch/add/plans",
	//删除 /wh/bizWhTransferOutPlanBatch/delete
	WhTransferPlanOutWhDel:  HY_BASEURL + "/wh/bizWhTransferOutPlanBatch/delete",
	//删除明细 /wh/bizWhTransferOutPlan/remove
	WhTransferPlanOutWhDetDel:  HY_BASEURL + "/wh/bizWhTransferOutPlan/remove",
	//更新 /wh/bizWhTransferOutPlanBatch/update/edit
	WhTransferPlanOutWhUpdate:  HY_BASEURL + "/wh/bizWhTransferOutPlanBatch/update/edit",
	//编辑查询 /wh/bizWhTransferOutPlanBatch/query/edit/{id}
	WhTransferPlanOutWhQuery:  HY_BASEURL + "/wh/bizWhTransferOutPlanBatch/query/edit",
	//预约完成 /wh/bizWhTransferOutPlanBatch/plan/complate
	WhTransferPlanComplate:  HY_BASEURL + "/wh/bizWhTransferOutPlanBatch/plan/complate",
	//提货单 /wh/bizWhTransferOutPlanBatch/print/bol
	WhTransferPlanBol:  HY_BASEURL + "/wh/bizWhTransferOutPlanBatch/print/bol",
	
	
	//仓库-附件上传
	//新增 /wh/bizWhFile/add/{relationId}
	WhFileUploadAdd:  HY_BASEURL + "/wh/bizWhFile/add",
	//删除 /wh/bizWhFile/delete/{id}
	WhFileUploadDel:  HY_BASEURL + "/wh/bizWhFile/delete",
	//查询 /wh/bizWhFile/query/{relationId}
	WhFileUploadList:  HY_BASEURL + "/wh/bizWhFile/query",
	//全部附件查询 /wh/bizWhFile/query/all/{relationId}
	WhFileUploadAllList:  HY_BASEURL + "/wh/bizWhFile/query/all",
	// 记录打印次数以及转换 /wh/bizWhFile/get/print/{id} GET
	WhFileGetPrtById:  HY_BASEURL + "/wh/bizWhFile/get/print",
  //仓配管理中心
  //产品列表-分页查询
  WhGoodsClassPageList: HY_BASEURL+"/warehouse/bizWhGoodsClass/query/page",
  //产品-编辑
  WhGoodsClassUpdate: HY_BASEURL+"/warehouse/bizWhGoodsClass/updateInsert",
  //产品-删除
  WhGoodsClassDel: HY_BASEURL+"/warehouse/bizWhGoodsClass/deletes",

  //客户列表-分页查询
  WhCustomerPageList: HY_BASEURL+"/warehouse/bizWhCustomer/query/page",
  //客户列表-新增编辑
  WhCustomerUpdate: HY_BASEURL+"/warehouse/bizWhCustomer/edit/update",
	
	//客户查询全部
	// /warehouse/bizWhCustomer/query/all?keyword=  GET
	WhCustomerAllList: HY_BASEURL+"/warehouse/bizWhCustomer/query/all",
	
	//查询各个模块费用，根据ID 
	// 入库预报   一件代发 自提  分批入库/出库 快速转运   退货
	// /wh/bizWhFeeBillOpRecord/query/commit/{id}/fees  GET
	WhFeeModleQueryById: HY_BASEURL+"/wh/bizWhFeeBillOpRecord/query/commit",
	//二级费用-预约出库 /wh/bizWhFeeBillOpRecord/query/relation/{id}/fees
	WhFeeRelModleQueryById: HY_BASEURL+"/wh/bizWhFeeBillOpRecord/query/relation",

  //仓租费用-仓租费用分页
  WhFeeRecordPageList: HY_BASEURL+"/warehouse/bizWhFeeRecord/query/page",
	//仓租费用重新计算
	// /warehouse/bizWhFeeRecord/reset/wh/fee/{userId}
	WhFeeRecordReset: HY_BASEURL+"/warehouse/bizWhFeeRecord/reset/wh/fee",
	
  //收货管理
  //收货-分页
  WhGoodsPageList: HY_BASEURL+"/warehouse/bizWhGoods/query/page",
  //收货-完成收货/warehouse/bizWhGoods/confirm/complete/{id}
  WhGoodsComplete: HY_BASEURL+"/warehouse/bizWhGoods/confirm/complete",
  //收货-新增编辑
  WhGoodsUpdate: HY_BASEURL+"/warehouse/bizWhGoods/edit/update",
  //收货-删除/warehouse/bizWhGoods/delete/{id} PUT
  WhGoodsDel: HY_BASEURL+"/warehouse/bizWhGoods/delete",
  //收货打托/warehouse/bizWhGoods/confirm/package/{id} PUT
  WhGoodsPackage: HY_BASEURL+"/warehouse/bizWhGoods/confirm/package",

  //收货-明细分页
  WhGoodsDetPageList: HY_BASEURL+"/warehouse/bizWhGoodsRecord/query/page",
  //收货-明细删除
  WhGoodsDetDel: HY_BASEURL+"/warehouse/bizWhGoodsRecord/delete",

  //收货打托
  //入库 /warehouse/bizWhPackage/in/wh
  WhPackageIn: HY_BASEURL+"/warehouse/bizWhPackage/in/wh",
  //出库 /warehouse/bizWhPackage/out/wh
  WhPackageOut: HY_BASEURL+"/warehouse/bizWhPackage/out/wh",
  //收货打托-分页
  WhPackagePageList: HY_BASEURL+"/warehouse/bizWhPackage/query/page",
  //收货打托-新增编辑
  WhPackageUpdate: HY_BASEURL+"/warehouse/bizWhPackage/edit/update",
  //收货打托-删除
  WhPackageDel: HY_BASEURL+"/warehouse/bizWhPackage/delete",
  //收货打托-明细分页
  WhPackageDetPageList: HY_BASEURL+"/warehouse/bizWhPackageRecord/query/page",

  //下架管理
  //下架-分页
  WhDownShelfPageList: HY_BASEURL+"/warehouse/bizWhDownShelf/query/page",
  //下架-完成
  WhDownShelfComplete: HY_BASEURL+"/warehouse/bizWhDownShelf/complete",
  //下架-新增编辑
  WhDownShelfUpdate: HY_BASEURL+"/warehouse/bizWhDownShelf/edit/update",
  //下架-删除/warehouse/bizWhDownShelf/delete (PUT) [11111111]
  WhDownShelfDel: HY_BASEURL+"/warehouse/bizWhDownShelf/delete",
  //下架-明细分页
  WhDownShelfDetPageList: HY_BASEURL+"/warehouse/bizWhDownShelfRecord/query/page",
  //导入 excel 检验
  WhDownShelfCreateExCheck: HY_BASEURL+"/warehouse/bizWhDownShelfRecord/check",

  //上架商品
  //分页
  WhShelvesPageList: HY_BASEURL+"/warehouse/bizWhShelves/query/page",
  //新增编辑
  WhShelvesUpdate: HY_BASEURL+"/warehouse/bizWhShelves/edit/update",

  //删除/warehouse/bizWhShelves/del PUT [1111]
  WhShelvesDel: HY_BASEURL+"/warehouse/bizWhShelves/del",
  //确认完成
  WhShelvesComplete: HY_BASEURL+"/warehouse/bizWhShelves/confirm/complete",
  //拣货管理
  //拣货列表-分页/warehouse/bizWhPl/query/page
  WhPlPageList: HY_BASEURL+"/warehouse/bizWhPl/query/page",
  //创建拣货单/warehouse/bizWhPl/create/plList
  WhPlCreate: HY_BASEURL+"/warehouse/bizWhPl/create/plList",
  // //导入 excel 检验
  // WhPlCreateExCheck: HY_BASEURL+"/warehouse/bizWhPlRecord/check",

  //拣货单-删除/warehouse/bizWhPl/delete PUT  [111111111]
  WhPlDel: HY_BASEURL+"/warehouse/bizWhPl/delete",
  //开始拣货/warehouse/bizWhPl/start/pl
  WhPlStart: HY_BASEURL+"/warehouse/bizWhPl/start/pl",
  //拣货完成/warehouse/bizWhPl/complete/pl
  WhPlComplete: HY_BASEURL+"/warehouse/bizWhPl/complete/pl",
  //拣货单明细-分页/warehouse/bizWhPlRecord/query/page
  WhPlRecPageList: HY_BASEURL+"/warehouse/bizWhPlRecord/query/page",
  //拣货单明细-删除/warehouse/bizWhPlRecord/del
  WhPlRecDel: HY_BASEURL+"/warehouse/bizWhPlRecord/del",
  //拣货单明细-更新/warehouse/bizWhPlRecord/update
  WhPlRecUpdate: HY_BASEURL+"/warehouse/bizWhPlRecord/update",
  //财务记录
  //财务记录-分页/warehouse/bizWhOpRecord/quer/page
  WhFinancePageList: HY_BASEURL+"/warehouse/bizWhOpRecord/quer/page",
  //创建财务记录/warehouse/bizWhOpRecord/create/op/record
  WhFinanceCreate: HY_BASEURL+"/warehouse/bizWhOpRecord/create/op/record",
  //财务记录-完成/warehouse/bizWhOpRecord/complete  PUT  [111111111]
  WhFinanceComplete: HY_BASEURL+"/warehouse/bizWhOpRecord/complete",
  //删除财务记录/warehouse/bizWhOpRecord/del/{id}
  WhFinanceDel: HY_BASEURL+"/warehouse/bizWhOpRecord/del",
  //财务记录明细-分页/warehouse/bizWhOpFinRecord/quer/page
  WhFinanceRecPageList: HY_BASEURL+"/warehouse/bizWhOpFinRecord/quer/page",
  //财务记录明细-删除/warehouse/bizWhPlRecord/del
  WhFinanceRecDel: HY_BASEURL+"/warehouse/bizWhOpFinRecord/dels",
	//财务记录-编辑查询 /warehouse/bizWhOpRecord/edit/query/{id}
	WhFinanceEditDet: HY_BASEURL+"/warehouse/bizWhOpRecord/edit/query",
	
	//操作记录-新
	// 操作记录:
	// 分页查询:/wh/bizWhFeeBillOpRecord/query/page  {"offset":"0","limit":"10"} PUT
	WhFeeBillOpRecord: HY_BASEURL+ "/wh/bizWhFeeBillOpRecord/query/page",
	// 重新计算: /wh/bizWhFeeBillOpRecord/repeat/clac  [11111111111] PUT
	WhFeeBillOpRecordClac: HY_BASEURL+ "/wh/bizWhFeeBillOpRecord/repeat/clac",
	//重算-按照筛选条件 /wh/bizWhFeeBillOpRecord/repeat/clac/condf PUT
	WhFeeBillOpRecordClacCondf: HY_BASEURL+ "/wh/bizWhFeeBillOpRecord/repeat/clac/condf",
	// 费用明细
	// /wh/bizWhFeeBillOpRecord/query/{id}/fees  GET  
	WhFeeBillOpRecordDetList: HY_BASEURL+ "/wh/bizWhFeeBillOpRecord/query",
	//废弃 /wh/bizWhFeeBillOpRecord/abandoned  PUT [1111111111]  废弃
	WhFeeBillOpRecordAbandoned: HY_BASEURL+ "/wh/bizWhFeeBillOpRecord/abandoned",
	
  //库位管理
  //库位管理-分页
  WhPlacePageList: HY_BASEURL+"/warehouse/bizWhPlace/query/page",
  //库位管理-新增
  WhPlaceAdd: HY_BASEURL+"/warehouse/bizWhPlace/edit/insert",
	//库位管理-编辑 /warehouse/bizWhPlace/edit/update
	WhPlaceEdit: HY_BASEURL+"/warehouse/bizWhPlace/edit/update",
	
  //库位管理-删除
  WhPlaceDel: HY_BASEURL+"/warehouse/bizWhPlace/delete",
	//库位导入
	WhPlaceExcelRead: HY_BASEURL+"/warehouse/bizWhPlace/reader/location/excel",
	
  //仓配-新-第二版
  //收货-分页
  WhInGoodsPageList: HY_BASEURL+"/wh/bizWhMyGoodsPacking/query/sys/page",
  //仓库收货-明细分页查询
  WhInGoodsDetPageList: HY_BASEURL+"/wh/bizWhMyGoodsPackingRecord/query/page",
  //收货-收货明细单条复核 /wh/bizWhMyGoodsPackingRecord/single/verify
  WhInGoodsDetAuth: HY_BASEURL+"/wh/bizWhMyGoodsPackingRecord/single/verify",
  //收货-收货明细批量复核 /wh/bizWhMyGoodsPackingRecord/verify/{packId}
  WhInGoodsAuth: HY_BASEURL+"/wh/bizWhMyGoodsPackingRecord/verify",
  //收货-确认收货 /wh/bizWhMyGoodsPacking/sys/received/{packId}
  WhInGoodsSureReceived: HY_BASEURL+"/wh/bizWhMyGoodsPacking/sys/received",
	//我的装箱单-撤销发货
	// /wh/bizWhMyGoodsPacking/cancel/command
	WhMyGoodsPackingCancel:  HY_BASEURL + "/wh/bizWhMyGoodsPacking/cancel/command",
	// /wh/bizWhMyGoodsPackingLpn/query/lpn/record/info/{packId} get
	WhMyGoodsPackingLpnRecord:  HY_BASEURL + "/wh/bizWhMyGoodsPackingLpn/query/lpn/record/info",
	//更新实收数 /wh/bizWhReceiveGoods/modify/recevice/qt    更新收货数  PUT  {"ids":[]}
	WhInGoodsModifyReceviceQt:  HY_BASEURL + "/wh/bizWhReceiveGoods/modify/recevice/qt",
	
	//确认到仓 /wh/bizWhMyGoodsPacking/arrived/wh  PUT 入库预报  {"id":"","arriveWhDate":"到仓日期"}
	WhMyGoodsArrivedInWh:  HY_BASEURL + "/wh/bizWhMyGoodsPacking/arrived/wh",
	//SKU补录 /wh/bizWhMyGoodsPackingRecord/add/records
	WhInWhRecAddSku:  HY_BASEURL + "/wh/bizWhMyGoodsPackingRecord/add/records",
	//

  //收货打印 /wh/bizWhMyGoodsPacking/print/{id}
  WhPrinterReceptionList: HY_BASEURL+"/wh/bizWhMyGoodsPacking/print",
	// /wh/bizWhMyGoodsPackingLpn/query/pack/lpn/list PUT 更具入库ID或者入库编号获取一个区间的LPN {"packId":"","receiptNo":"","seqNoStart":"","seqNoEnd":""}
	// lpn获取
	WhInGoodsLpnQuery: HY_BASEURL+"/wh/bizWhMyGoodsPackingLpn/query/pack/lpn/list",
	
	//收货-编辑
	// /wh/bizWhMyGoodsPacking/update/sys/modify
	WhInGoodEdit: HY_BASEURL+"/wh/bizWhMyGoodsPacking/update/sys/modify",

  // 仓库收货-入库记录分页
  WhInWhRecPageList: HY_BASEURL+"/wh/bizWhReceiveGoods/query/page",
	// 仓库收货-入库记录 导出-废弃
	WhReceiveGoodsExportBill: HY_BASEURL+"/wh/bizWhReceiveGoods/export/bill",
	// 仓库收货-入库记录 导出
	WhReceiveGoodsQueryExport: HY_BASEURL+"/wh/bizWhReceiveGoods/query/export",
	
  // 仓库收货-入库-上架
  WhInStock: HY_BASEURL+"/wh/bizWhReceiveGoods/in/stock",
	// 仓库-批量上架-读取 /wh/bizWhReceiveGoods/reader/excel POST 
	WhBatchInStockRead: HY_BASEURL+"/wh/bizWhReceiveGoods/reader/excel",
	// 仓库-批量上架-提交 /wh/bizWhReceiveGoods/save/batch
	WhBatchInStockSave: HY_BASEURL+"/wh/bizWhReceiveGoods/save/batch",
	
	//收货入库库位查询 /wh/bizWhMySkuStock/query/receive/place/{id}
	WhSkuInStockPlace: HY_BASEURL+"/wh/bizWhMySkuStock/query/receive/place",
	//取消收货 /wh/bizWhMyGoodsPacking/cancel/receive {"id":}
	WhInGoodCancelReceive: HY_BASEURL+"/wh/bizWhMyGoodsPacking/cancel/receive",
	
	//理货完成 /wh/bizWhMyGoodsPacking/sys/tallying/{packId}
	WhInGoodSysTallying: HY_BASEURL+"/wh/bizWhMyGoodsPacking/sys/tallying",
	//收入入库-移库操作
	// WhSkuMoveStockPlace: HY_BASEURL+"/app/wh/bizWhDashboard/move/stock",
	// /wh/bizWhMySkuStock/move/stock   PUT  {"id":"","moveList":[{"stock":"","whNo":"","place":""}]}
	//收入入库-移库操作-新
	WhSkuMoveStockPlace: HY_BASEURL+"/wh/bizWhMySkuStock/move/stock",
	
  // 仓库发货管理-发货指令-分页
  WhInShipmentsCommandPageList: HY_BASEURL+"/wh/bizWhMySendGoods/query/sys/page",
	
	//收入入库-移库操作-sku调整 /wh/bizWhMySkuStock/change/sku  {"moveList":[{"id":"","changeStock":"","goodsSku":"","place":""}]}
	WhSkuMoveStockChange: HY_BASEURL+"/wh/bizWhMySkuStock/change/sku",
	
	// 转运，fba-导出
	WhSendGoodsExport: HY_BASEURL+"/wh/bizWhMySendGoods/export/bill",
	//自提-导出 /wh/bizWhMySendGoods/export/{userId}
	WhInShipmentsExport: HY_BASEURL+"/wh/bizWhMySendGoods/export",
  // 发货管理-发货指令明细分页
  WhInShipmentsComDetList: HY_BASEURL+"/wh/bizWhMySendGoodsRecord/query/sys/page",
  // 发货管理-发货明细拣货商品SKU分页查询 /wh/bizWhMySendGoodsRecord/query/pl/stock/{id}/page
  WhInShipmentsComDetSkuList: HY_BASEURL+"/wh/bizWhMySendGoodsRecord/query/pl/stock",
  //拣货 /wh/bizWhMySendGoodsRecord/pl/record/{sendRecordId}
  WhInShipmentsPicking: HY_BASEURL+"/wh/bizWhMySendGoodsRecord/pl/record",
  //自动拣货 /wh/bizWhMySendGoodsRecord/autoPl/record/{sendRecordId}
  WhInShipmentsAutoPicking: HY_BASEURL+"/wh/bizWhMySendGoodsRecord/autoPl/record",
  //拣货完成/wh/bizWhMySendGoods/sys/pl/complete/{sendId}
  WhInShipmentsPickingComplete: HY_BASEURL+"/wh/bizWhMySendGoods/sys/pl/complete",
  //拣货明细 /wh/bizWhMySendGoodsPlRecord/query/sys/page  sendId sendRecordId
  WhInShipmentPickinDetList: HY_BASEURL+"/wh/bizWhMySendGoodsPlRecord/query/sys/page",
	//撤销-自提单明细撤销 /wh/bizWhMySendGoodsRecord/remove/{sendRecordId}   PUT {}
	WhInShipmentPickinDetRemove: HY_BASEURL+"/wh/bizWhMySendGoodsRecord/remove",
	//撤销-自提单 /wh/bizWhMySendGoodsRecord/send/remove/{sendId}  PUT {"place":""}
	WhInShipmentRemove: HY_BASEURL+"/wh/bizWhMySendGoodsRecord/send/remove",
	//更新数据-自提单：/wh/bizWhMySendGoods/sys/edit/update     PUT {"id":""}
	WhInShipmentEditUpdate: HY_BASEURL+"/wh/bizWhMySendGoods/sys/edit/update",
	//我的发货单-删除 /wh/bizWhMySendGoods/delete/{id}
	WhShipmentsListDel:  HY_BASEURL + "/wh/bizWhMySendGoods/delete",
	//全部自动拣货 /wh/bizWhMySendGoodsRecord/autoPl/record/all
	WhInShipmentsAllAutoPicking:  HY_BASEURL + "/wh/bizWhMySendGoodsRecord/autoPl/record/all",
	//批量拣货完成 /wh/bizWhMySendGoods/batch/sys/pl/complete PUT {"ids":[]}
	WhInShipmentsPickingCompleteAll:  HY_BASEURL + "/wh/bizWhMySendGoods/batch/sys/pl/complete",
	// 批量修改-出车方式 /wh/bizWhMySendGoods/update/cartmstype {"ids":[],"carTmsType":""}
	WhInShipmentsBatchEditTmsType:  HY_BASEURL + "/wh/bizWhMySendGoods/update/cartmstype",
	
	//批量拣货-新 /wh/bizWhMySendGoods/batch/off/shelf  {"ids":[],"downType":"","plGoodsType":"","placeList":[]}
	WhShipmentsBatchPicking:  HY_BASEURL + "/wh/bizWhMySendGoods/batch/off/shelf",
	//自提-批量拣货-查询库位 /wh/bizWhMySendGoods/query/order/sku/location  PUT {"ids":[],"userId":"","whNo":"","":""}
	WhShipmentsQuerySkuPlace:  HY_BASEURL + "/wh/bizWhMySendGoods/query/order/sku/location",
	
  //拣货打印 /wh/bizWhMySendGoods/print/{id}
  whPriterPickingList: HY_BASEURL+"/wh/bizWhMySendGoods/print",
	//自提-批量出库完成 /wh/bizWhMySendGoods/batch/out/completes   PUT {"ids":[]}
	whShipmentsBatchOutCompletes: HY_BASEURL+"/wh/bizWhMySendGoods/batch/out/completes",

  //发货-分托查询 /wh/bizWhMySendGoodsRecord/query/{sendId}/pack/group
  WhInShipmentPalletData: HY_BASEURL+"/wh/bizWhMySendGoodsRecord/query",
  //发货-分托提交 /wh/bizWhMySendPackage/package/updateInsert/{sendId}
  WhInShipmentSubmitPalletData: HY_BASEURL+"/wh/bizWhMySendPackage/package/updateInsert",
	
	//自提单明细搜索 /wh/bizWhMySendGoodsRecord/search/page  PUT 
	WhTransferOrderPageList: HY_BASEURL+"/wh/bizWhMySendGoodsRecord/search/page",
	//新-直接打托完成 /wh/bizWhMySendGoods/pallet/complete  PUT  {"id":"","sendOutType":"出库方式","palletCount":"托盘数","attachments":[]}
	WhTransferOrderPalletComplete: HY_BASEURL+"/wh/bizWhMySendGoods/pallet/complete",
	
	//自提-批量导入附件-匹配 /wh/bizWhMySendGoods/attachment/matching  PUT  {"matchAttachments":[{"type":"","fileName":"","url":""}],"ids":[]}
	WhShipmentsUploadFileMatch: HY_BASEURL+"/wh/bizWhMySendGoods/attachment/matching",
	//自提-批量导入附件-关联  /wh/bizWhMySendGoods/upload/matching/attachment 上传附件 [{"type":"","fileName":"","relationId":""}]
	WhShipmentsUploadFileMatchRel: HY_BASEURL+"/wh/bizWhMySendGoods/upload/matching/attachment",

  //分托数据
  //发货管理-分托类表分页
  WhInPalletPageList: HY_BASEURL+"/wh/bizWhMySendPackage/query/page",
  //发货管理-分托明细列表
  WhInPalletDetList: HY_BASEURL+"/wh/bizWhMySendPackageRecord/query/page",
  //发货管理-打托完成 /wh/bizWhMySendPackage/package/complete/{sendId}
  WhInPalletComplete: HY_BASEURL+"/wh/bizWhMySendPackage/package/complete",

  //出库数据
  //查询-设置出库数据--查询拖的信息-按目的仓库分组
  ///wh/bizWhMySendPackage/query/package/{sendId}
  WhInOutPlaceDetData: HY_BASEURL+"/wh/bizWhMySendPackage/query/package",
  //设置出库数据
  ///wh/bizWhMySendPackage/set/{sendId}/package/put/area
  // WhInSetOutPlace: HY_BASEURL+"/wh/bizWhMySendPackage/set",
  //出库完成 /wh/bizWhMySendGoods/out/complete/{sendId}
  WhOutComplate: HY_BASEURL+"/wh/bizWhMySendGoods/out/complete",

  //一件代发
  //分页
  WhDropShippingPageList: HY_BASEURL+"/wh/bizWhMySendExpress/query/sys/page",
  //取消发货 /wh/bizWhMySendExpress/cancel/{id}
  WhDropShippingCancel: HY_BASEURL+"/wh/bizWhMySendExpress/cancel",
	//一件代发-删除 /wh/bizWhMySendExpress/delete/{id}
	WhDropShippingDel:  HY_BASEURL + "/wh/bizWhMySendExpress/delete",
	
	///wh/bizWhMySendExpress/revoke  PUT   {"ids":[]}  一件代发撤销
	WhDropShippingRevoke:  HY_BASEURL + "/wh/bizWhMySendExpress/revoke",
	//一件代发-修改发货地址
	WhDSUpdateShipAddress:  HY_BASEURL + "/wh/bizWhMySendExpress/update/ship/addr ",
	//一件代发-异常件-重新提交 /wh/bizWhMySendExpress/resubmit/check/commit  {"ids":[],"zoneWhNo":""}
	WhDropShippingReSubmit:  HY_BASEURL + "/wh/bizWhMySendExpress/resubmit/check/commit",
	//一件代发-移至异常 /wh/bizWhMySendExpress/pending/to/fail PUT {"ids":[]}
	WhDropShippingMoveFail:  HY_BASEURL + "/wh/bizWhMySendExpress/pending/to/fail",
	
  //一件代发-统计看板
  WhDropShippingDashboard: HY_BASEURL+"/wh/bizWhMySendExpress/dashboard",
	 
	//一件代发-明细-状态统计 /wh/bizWhMySendExpress/query/statistics  PUT {}
	WhDropShippingStatusStatistics: HY_BASEURL+"/wh/bizWhMySendExpress/query/statistics",
  
  //审核-批量通过 /wh/bizWhMySendExpress/sys/verify
  WhDropShippingAuth: HY_BASEURL+"/wh/bizWhMySendExpress/sys/verify",
  //录入运单-单件 /wh/bizWhMySendExpress/sys/writ/tracking/{exprId}
  WhDropShippingWrTracking: HY_BASEURL+"/wh/bizWhMySendExpress/sys/writ/tracking",
  //拣货-自动 /wh/bizWhMySendExpress/sys/auto/pl
  WhDropShippingAutoPl: HY_BASEURL+"/wh/bizWhMySendExpress/sys/auto/pl",
	
	// 5: /wh/bizWhMyGoodsPackingLpn/query/out/lpn/list PUT {} 获取OUT LPN 
	WhDSOLpnDownQueryList: HY_BASEURL+"/wh/bizWhMyGoodsPackingLpn/query/out/lpn/list",
	
	// 4：/wh/bizWhMySendExpress/lpn/find/out/lpn/ship?outLpn= 查询OutLPN对应label
	WhDSOLpnQueryLpn: HY_BASEURL+"/wh/bizWhMySendExpress/lpn/find/out/lpn/ship",
  
  //打单费率查询 fedex /wh/bizWhLabelInfo/rates/{exprId}
  WhPriterOrderRate: HY_BASEURL+"/wh/bizWhLabelInfo/rates",
  //提交打单 fedex /wh/bizWhLabelInfo/commit/create/label
  WhPriterOrderCreateLabel: HY_BASEURL+"/wh/bizWhLabelInfo/commit/create/label",
	
	//批量提交打单
	WhPriterOrderBatchCreate: HY_BASEURL+"/wh/bizWhLabelInfo/commit/create/batch/label",
	
	//打单费率查询 ups /wh/bizWhLabelInfo/rates/{exprId}
	WhPriterOrderUpsRate: HY_BASEURL+"/wh/bizWhLabelUpsInfo/rates",
	//UPS批量、单个提交打单 ups
	WhPriterOrderUpsBatchCreate: HY_BASEURL+"/wh/bizWhLabelUpsInfo/commit/create/label",
	
	//打单流水查询 /wh/bizWhMySendExpress/query/print/seq/page  PUT    ['202202020001-00123']
	WhQueryPrtSeq: HY_BASEURL + "/wh/bizWhMySendExpress/query/print/seq/page",
	
	//UPS 渠道2，直接提交
	// /thirdLogistics/bizLgsThridLabelInfo/create/label  PUT  [1111111111111]
	WhPriterOrderUps2BatchCreate: HY_BASEURL+"/thirdLogistics/bizLgsThridLabelInfo/create/label",
	// //UPS 渠道2，费率查询
	// // /thirdLogistics/fuerthertry/bizLgsThridOrderInfo/wms/query/rate/{id} PUT 
	// WhPriterOrderUps2Rate: HY_BASEURL+"/thirdLogistics/fuerthertry/bizLgsThridOrderInfo/wms/query/rate",
	//UPS 渠道2，费率查询-新-去掉-fuerthertry
	// /thirdLogistics/fuerthertry/bizLgsThridOrderInfo/wms/query/rate/{id} PUT 
	WhPriterOrderUps2Rate: HY_BASEURL+"/thirdLogistics/bizLgsThridOrderInfo/wms/query/rate",
	//UPS 渠道2，lgs 列表 /thirdLogistics/bizLgsThridKey/query/list/vo  PUT {}
	WhPriterOrderUps2LgsList: HY_BASEURL+"/thirdLogistics/bizLgsThridKey/query/list/vo",
	//cope shipFrom /thirdLogistics/bizLgsThridOrderInfo/cope/shipfrom   Get
	WhPriterOrderCopeShipfrom: HY_BASEURL+"/thirdLogistics/bizLgsThridOrderInfo/cope/shipfrom",
	
	
  //发货 /wh/bizWhMySendExpress/pickup
  WhDropShippingPickup: HY_BASEURL+"/wh/bizWhMySendExpress/pickup",
  
  //完成拣货 /wh/bizWhMySendExpress/pl/complete
  WhDropShippingPlComplete: HY_BASEURL+"/wh/bizWhMySendExpress/pl/complete",
  
  //代发-拣货打印 /wh/bizWhMySendExpress/print
  whPriterDropShippingPickingList: HY_BASEURL+"/wh/bizWhMySendExpress/print",
	
	//一件代发-暂停 /wh/bizWhMySendExpress/stop
	whDropShippingStop: HY_BASEURL+"/wh/bizWhMySendExpress/stop",
	//一件代发-暂停恢复 /wh/bizWhMySendExpress/recovery/stop
	whDropShippingStopRecovery: HY_BASEURL+"/wh/bizWhMySendExpress/recovery/stop",

  //录入运单-批量导入-读取 /wh/bizWhMySendExpress/batch/read/tracking/excel
  WhWrTrackingExRead: HY_BASEURL+"/wh/bizWhMySendExpress/batch/read/tracking/excel",
  //录入运单-批量导入-录入 /wh/bizWhMySendExpress/sys/batch/writ/tracking
  WhWrTrackingExSubmit: HY_BASEURL+"/wh/bizWhMySendExpress/sys/batch/writ/tracking",
	
	//工单-一件代发操作
  //审核-批量通过 /wh/bizWhMySendExpress/sys/verify
  WhWorkDropShippingAuth: HY_BASEURL+"/wh/bizWhMySendExpress/sys/verify/byWorkNo",
	//批量提交打单
	WhWorkPriterOrderBatchCreate: HY_BASEURL+"/wh/bizWhLabelInfo/commit/create/batch/label/byWorkNo",
	//拣货-自动 /wh/bizWhMySendExpress/sys/auto/pl
	WhWorkDropShippingAutoPl: HY_BASEURL+"/wh/bizWhMySendExpress/sys/auto/pl/byWorkNo",
	//发货 /wh/bizWhMySendExpress/pickup
	WhWorkDropShippingPickup: HY_BASEURL+"/wh/bizWhMySendExpress/pickup/byWorkNo",
	//完成拣货 /wh/bizWhMySendExpress/pl/complete
	WhWorkDropShippingPlComplete: HY_BASEURL+"/wh/bizWhMySendExpress/pl/complete/byWorkNo",
	//代发-拣货打印 /wh/bizWhMySendExpress/print
	whWorkPriterDropShippingPickingList: HY_BASEURL+"/wh/bizWhMySendExpress/print/byWorkNo",
	
	//合并下载面单 /wh/download/pdf/merger/download
	WhDownloadMergeLabel: HY_BASEURL+"/wh/download/pdf/merger/download",
	//合并下载附件PDF /wh/download/appendix/pdf/merger
	WhDownloadMergeAttaPdf: HY_BASEURL+"/wh/download/appendix/pdf/merger",
	//一件代发-扫码打单 /wh/bizWhMySendExpress/v2/scan/print/label   {"batchNo":"","keyword":""}
	WhDsScanPrint: HY_BASEURL+"/wh/bizWhMySendExpress/v2/scan/print/label",
	//一件代发-扫码打单-批次数据 /wh/bizWhMySendExpress/v2/query/scan/print/info  PUT  {"batchNo":""} 
	WhDsScanPrintInfo: HY_BASEURL+"/wh/bizWhMySendExpress/v2/query/scan/print/info",
	//查看扫码打单明细 /wh/bizWhMySendExpress/v2/scan/bacth/print/label/info  PUT {"batchNo":""}
	WhDsScanPrintDet: HY_BASEURL+"/wh/bizWhMySendExpress/v2/scan/bacth/print/label/info",
	
	//一件代发-扫码打单-olpn /wh/bizWhMySendExpress/v2/outLpn/scan/print/label
	WhDsScanPrintByOLpn: HY_BASEURL+"/wh/bizWhMySendExpress/v2/outLpn/scan/print/label",
	
	
	//序列号查询合并下载 /wh/download/printseq/pdf/merger/download
	WhDsPrintseqPdfMerger: HY_BASEURL+"/wh/download/printseq/pdf/merger/download",
	
  //打单明细
	//fedex
  //分页 /wh/bizWhLabelInfo/query/page
  WhPrtOrderLabelInfoPageList: HY_BASEURL+"/wh/bizWhLabelInfo/query/page",
  //取消运单 /wh/bizWhLabelInfo/label/cancel/{id}
  WhPrtOrderLabelInfoCancel: HY_BASEURL+"/wh/bizWhLabelInfo/label/cancel",
  //撤销打单 /wh/bizWhLabelInfo/label/remove
  WhPrtOrderLabelInfoRemove: HY_BASEURL+"/wh/bizWhLabelInfo/label/remove",
  //面单查询 /wh/bizWhLabelInfo/query/label
  WhPrtOrderLabelInfoQuery: HY_BASEURL+"/wh/bizWhLabelInfo/query/label",
	//编辑 服务类型 /wh/bizWhLabelInfo/update/lable/serviceLevel    PUT    {"id":"数据ID","carrierServiceLevel":"服务类型"}
	WhPrtOrderLabelServiceLevel: HY_BASEURL+"/wh/bizWhLabelInfo/update/lable/serviceLevel",
	
	//ups
	//分页
	WhPrtOrderLabelUpsInfoPageList: HY_BASEURL+"/wh/bizWhLabelUpsInfo/query/page",
	//取消运单 /wh/bizWhLabelInfo/label/cancel/{id}
	WhPrtOrderLabelUpsInfoCancel: HY_BASEURL+"/wh/bizWhLabelUpsInfo/label/cancel",
	//撤销打单 /wh/bizWhLabelInfo/label/remove
	WhPrtOrderLabelUpsInfoRemove: HY_BASEURL+"/wh/bizWhLabelUpsInfo/label/remove",
	//面单查询 /wh/bizWhLabelInfo/query/label
	WhPrtOrderLabelUpsInfoQuery: HY_BASEURL+"/wh/bizWhLabelUpsInfo/query/label",
	//编辑 服务类型 /wh/bizWhLabelInfo/update/lable/serviceLevel    PUT    {"id":"数据ID","carrierServiceLevel":"服务类型"}
	WhPrtOrderLabelUpsServiceLevel: HY_BASEURL+"/wh/bizWhLabelUpsInfo/update/lable/serviceLevel",
	
	//发货单轨迹查询 /wh/bizWhMySendExpress/query/lgs/exper/track
	WhExpLgsTrackList: HY_BASEURL+"/wh/bizWhMySendExpress/query/lgs/exper/track",
	// 轨迹查询 /wh/lgs/fedex/api/query/lgs/track
	WhFedexLgsTrackQuery: HY_BASEURL+"/wh/lgs/fedex/api/query/lgs/track",
	
	//UPS 轨迹 /wh/lgs/ups/api/get/tracking?trackNo=
	lgsUpsTracking: HY_BASEURL +"/wh/lgs/ups/api/get/tracking",
	///wh/lgs/fedex/api/validata/address   地址校验  PUT [{"streetLines":[],"city":"","stateOrProvinceCode":"","postalCode":"","countryCode":""}]
	WhExpressAddressValidata: HY_BASEURL +"/wh/lgs/fedex/api/validata/address",
	// /wh/lgs/fedex/api/query/zone  PUT {"fromZip":"","toZip":""}
	WhExpressAddressZone: HY_BASEURL +"/wh/lgs/fedex/api/query/zone",
	
	//一件代发批次处理
	// 生成发货批次 /wh/bizWhMySendExpress/v2/create/batch/{userId}
	WhSendExpressBatch: HY_BASEURL+"/wh/bizWhMySendExpress/v2/create/batch",
	//发货批次分页 /wh/bizWhMySendExpressBatch/query/sys/page
	WhSendExpressBatchPageList: HY_BASEURL+"/wh/bizWhMySendExpressBatch/query/sys/page",
	//批次-拣货-自动 /wh/bizWhMySendExpress/v2/auto/batch/pl
	WhDropShippingBatchAutoPl: HY_BASEURL+"/wh/bizWhMySendExpress/v2/auto/batch/pl",
	//批次 拣货打印 /wh/bizWhMySendExpress/v2/print/byBatchNo
	whPriterPickingListByBatchNo : HY_BASEURL+"/wh/bizWhMySendExpress/v2/print/byBatchNo",
	//批次 状态统计 /wh/bizWhMySendExpressBatch/query/batch/status/count?batchNo=
	whSendExpressBatchCountSta : HY_BASEURL+"/wh/bizWhMySendExpressBatch/query/batch/status/count",
	//批次 查询面单 /wh/bizWhMySendExpress/v2/colud/print/ids/label  {"batchNo":""}
	WhWorkLabelUrlListByBatchNo : HY_BASEURL+"/wh/bizWhMySendExpress/v2/colud/print/ids/label",
	
	//自动生成发货批次 /wh/bizWhMySendExpress/v2/auto/create/batch/{userId}   自动生成批次
	WhSendExpressAutoCreateBatch: HY_BASEURL+"/wh/bizWhMySendExpress/v2/auto/create/batch",
	
	//移出该批次 /wh/bizWhMySendExpress/v2/batch/remove/express  PUT  {"id":"数据ID","batchNo":"拣货单号"}
	WhDropShippingBatchMoveOut : HY_BASEURL+"/wh/bizWhMySendExpress/v2/batch/remove/express",
	
	//一键出库 /wh/bizWhMySendExpress/express/onekey/batch/out
	WhDropShippingBatchOut : HY_BASEURL+"/wh/bizWhMySendExpress/express/onekey/batch/out",
	
	//按已打面单-一键出库 /wh/bizWhMySendExpress/express/onekey/batch/out/printed/label  {"batchNo":""} PUT  出库已打面单
	WhDropShippingBatchOutByPrt : HY_BASEURL+"/wh/bizWhMySendExpress/express/onekey/batch/out/printed/label",
	
	//一件代发-指定库位-拣货-废弃
	// WhDSQueryPlace : HY_BASEURL+"/wh/bizWhMySkuStock/query/sku/place",
	//一件代发-指定库位-拣货-新-2024.10.30
	WhDSQuerySkuPlace : HY_BASEURL+"/wh/bizWhMySendExpress/query/sku/place",
	
	
	//已打面单明细 /wh/bizWhMySendExpressPrintSeq/query/page {"batchNo":"","offset":"0","limit":"10"}
	WhPrtSeqDetByBatchNoPageList : HY_BASEURL+"/wh/bizWhMySendExpressPrintSeq/query/page",
	
	//已打面单明细/wh/bizWhMySendExpressSeq/query/list  PUT {"batchNo":"","keyword":""}
	WhPrtSeqDetByBatchNoList : HY_BASEURL+"/wh/bizWhMySendExpressSeq/query/list",
	
	//打印流水
	//分页 /wh/bizWhMySendExpressPrintSeq/query/sys/{exprId}
	WhPrtExpressSheetRecPageList: HY_BASEURL+"/wh/bizWhMySendExpressPrintSeq/query/sys",
	
	//增值服务工单-分页
	WhWorkAddServicePageList:  HY_BASEURL + "/wh/bizWhWorkOrder/query/addsf/page",

  //工单管理
  //分派 /wh/bizWhWorkOrder/update/appoint/task
  WhWorkOrderApportion: HY_BASEURL+"/wh/bizWhWorkOrder/update/appoint/task",
  //分页查询 wh/bizWhWorkOrder/query/sys/page
  WhWorkOrdePageList: HY_BASEURL+"/wh/bizWhWorkOrder/query/sys/page",
  //单条查询/wh/bizWhWorkOrder/query/{id}
  WhWorkOrdeDet: HY_BASEURL+"/wh/bizWhWorkOrder/query",
  //处理完成 /wh/bizWhWorkOrder/update/handle/task
  WhWorkOrdeComplete: HY_BASEURL+"/wh/bizWhWorkOrder/update/handle/task",
  //查询处理日志 /wh/bizWhWorkOrder/query/log/{id}
  WhWorkOrdeLog: HY_BASEURL+"/wh/bizWhWorkOrder/query/log",
  //退回 /wh/bizWhWorkOrder/update/return/task
  WhWorkOrdeReturn: HY_BASEURL+"/wh/bizWhWorkOrder/update/return/task",
	// 工单退回草稿 /wh/bizWhWorkOrder/return/draf/{userId}/{id} 
	WhWorkOrdeReturnNew: HY_BASEURL+"/wh/bizWhWorkOrder/return/draf",
	
	//转运管理
	// 分页 /wh/bizWhTransfer/query/page
	WhTransferPageList:  HY_BASEURL + "/wh/bizWhTransfer/query/page",
	// 删除 /wh/bizWhTransfer/delete/{id}
	WhTransferDel:  HY_BASEURL + "/wh/bizWhTransfer/delete",
	// 新增 /wh/bizWhTransfer/add
	WhTransferAdd:  HY_BASEURL + "/wh/bizWhTransfer/add",
	// 明细删除 /wh/bizWhTransferRecord/delete/{id}
	WhTransferDetDel:  HY_BASEURL + "/wh/bizWhTransferRecord/delete",
	// 编辑 /wh/bizWhTransfer/edit
	WhTransferEdit:  HY_BASEURL + "/wh/bizWhTransfer/edit",
	// 撤销 转运入库撤销: /wh/bizWhTransfer/update/remove  PUT  {"ids":[]}
	// 转运入库明细撤销:/wh/bizWhTransferRecord/update/remove  PUT  {"id":""}
	WhTransferRemove:  HY_BASEURL + "/wh/bizWhTransfer/update/remove",
	// 编辑明细查询 /wh/bizWhTransferRecord/query/List/{trId}
	WhTransferDetQuery:  HY_BASEURL + "/wh/bizWhTransferRecord/query/List",
	// 编辑查询 /wh/bizWhTransfer/query/edit/{trId}
	WhTransferEditQuery:  HY_BASEURL + "/wh/bizWhTransfer/query/edit",
	// 明细全部复核 /wh/bizWhTransferRecord/all/verify/{trId}
	WhTransferAllVerify:  HY_BASEURL + "/wh/bizWhTransferRecord/all/verify",
	// 明细单条复核 /wh/bizWhTransferRecord/single/verify
  WhTransferSingleVerify:  HY_BASEURL + "/wh/bizWhTransferRecord/single/verify",
	//确认收货 /wh/bizWhTransfer/confirm/received
	WhTransferConfirmRec:  HY_BASEURL + "/wh/bizWhTransfer/confirm/received",
	//设置库位 /wh/bizWhTransferRecord/setting/place
	WhTransferSettingPlace:  HY_BASEURL + "/wh/bizWhTransferRecord/setting/place",
	// 打印收货单 /wh/bizWhTransfer/print/receive/list
	WhTransferInWhPrintList:  HY_BASEURL + "/wh/bizWhTransfer/print/receive/list",
	//转运库存 /wh/bizWhTransferRecord/query/stock/page
	WhTransferStockPageList:  HY_BASEURL + "/wh/bizWhTransferRecord/query",
	//查询入库明细的出库信息 /wh/bizWhTransferOutRecord/query/{userId}/stock/out/page
	WhTransferStockOutPageList:  HY_BASEURL + "/wh/bizWhTransferOutRecord/query",
	//明细分页查询 /wh/bizWhTransferRecord/query/page
	WhTransferDetPageList:  HY_BASEURL + "/wh/bizWhTransferRecord/query/page",
	// 提交  /wh/bizWhTransfer/commit
	WhTransferCommit:  HY_BASEURL + "/wh/bizWhTransfer/commit",
	//确认到仓 /wh/bizWhTransfer/arrived/wh 分批转运 PUT {"id":id,"arriveWhDate":"到仓日期"}
	WhTransferArrivedInWh:  HY_BASEURL + "/wh/bizWhTransfer/arrived/wh",
	
	//转运入库-编辑
	// /wh/bizWhTransfer/update/sys/modify
	WhTransferSysEdit:  HY_BASEURL + "/wh/bizWhTransfer/update/sys/modify",
	
	//转运入库-代理--/wh/bizWhTransfer/query/{userId}/page
	WhClientTransferPageList:  HY_BASEURL + "/wh/bizWhTransfer/query",
	
	//转运出库-代理-
	WhClientTransferOutPageList:  HY_BASEURL + "/wh/bizWhTransferOut/query",
	
	//转运出库
	// 分页 /wh/bizWhTransferOut/query/page
	WhTransferOutPageList:  HY_BASEURL + "/wh/bizWhTransferOut/query/page",
	// 转运状态统计
	// /wh/bizWhTransferOut/status/statistics    GET
	WhTransferOutStatusCount:  HY_BASEURL + "/wh/bizWhTransferOut/status/statistics",
	// 删除 /wh/bizWhTransferOut/delete/{trOutId}
	WhTransferOutDel:  HY_BASEURL + "/wh/bizWhTransferOut/delete",
	// 撤销 转运出库撤销: /wh/bizWhTransferOut/update/remove  PUT  {"ids":[]}
	// 转运出库明细撤销:/wh/bizWhTransferOutRecord/update/remove  PUT  {"id":""}
	WhTransferOutRemove:  HY_BASEURL + "/wh/bizWhTransferOut/update/remove",
	// 新增 /wh/bizWhTransferOut/add
	WhTransferOutAdd:  HY_BASEURL + "/wh/bizWhTransferOut/add",
	// 明细删除 /wh/bizWhTransferOutRecord/delete/{trOutRecordId}
	WhTransferOutDetDel:  HY_BASEURL + "/wh/bizWhTransferOutRecord/delete",
	// 新增编辑单条明细 /wh/bizWhTransferOutRecord/eidt/{trOutId}/record
	WhTransferOutDetAdd:  HY_BASEURL + "/wh/bizWhTransferOutRecord/eidt",
	// 编辑 /wh/bizWhTransferOut/eidt
	WhTransferOutEdit:  HY_BASEURL + "/wh/bizWhTransferOut/eidt",
	// 根据主表ID查询明细 /wh/bizWhTransferOutRecord/query/List/{trOutId}
	WhTransferOutDetQuery:  HY_BASEURL + "/wh/bizWhTransferOutRecord/query/List",
	// 编辑查询 /wh/bizWhTransferOut/query/edit/{trOutId}
	WhTransferOutEditQuery:  HY_BASEURL + "/wh/bizWhTransferOut/query/edit",
	// 提交  /wh/bizWhTransferOut/commit
	WhTransferOutCommit:  HY_BASEURL + "/wh/bizWhTransferOut/commit",
	//操作完成 /wh/bizWhTransferOut/wait/out
	WhTransferOutOperCommit:  HY_BASEURL + "/wh/bizWhTransferOut/wait/out",
	// 出库完成 /wh/bizWhTransferOut/out/complete
	WhTransferOutComplete:  HY_BASEURL + "/wh/bizWhTransferOut/out/complete",
	// 打印转运清单 /wh/bizWhTransferOut/print/out/list
	WhTransferOutWhPrintList:  HY_BASEURL + "/wh/bizWhTransferOut/print/out/list",
	
	//转运出库-明细-单条撤销 /wh/bizWhTransferOutRecord/update/remove/{userId} PUT  {"id":1212}
	WhTransferOutWhDelRemove:  HY_BASEURL + "/wh/bizWhTransferOutRecord/update/remove",
	
	//转运出库-编辑
	// /wh/bizWhTransferOut/update/sys/modify
	WhTransferOutSysEdit:  HY_BASEURL + "/wh/bizWhTransferOut/update/sys/modify",
	
	// 查询 fba地址 /wh/bizWhFc/query/fba
	WhTransferQueryFba:  HY_BASEURL + "/wh/bizWhFc/query/fba",
	
  //异常件管理
   //分页查询 /wh/bizWhExceReturn/query/sys/page
   WhExcePageList: HY_BASEURL+"/wh/bizWhExceReturn/query/sys/page",
   //异常件入库 /wh/bizWhReceiveGoods/in/excp/stock
   WhExceInStock: HY_BASEURL+"/wh/bizWhReceiveGoods/in/excp/stock",
   //签收 /wh/bizWhExceReturn/sign/{id}
   WhExceSign: HY_BASEURL+"/wh/bizWhExceReturn/sign",
   //退货运单录入 /wh/bizWhExceReturn/write/orderno
   WhExceWriteOrderno: HY_BASEURL+"/wh/bizWhExceReturn/write/orderno",
	
	//异常件收货记录 /wh/bizWhReceiveGoods/query/excp/page
	WhExceRecPageList: HY_BASEURL+"/wh/bizWhReceiveGoods/query/excp/page",
	
	//客户SKU查询
	WhCusSkuPageList: HY_BASEURL+"/wh/bizWhMyGoodsSku/query/sys/page",
	//客户库存查询
	WhCusSkuStockPageList: HY_BASEURL+"/wh/bizWhMySkuStock/query/sys/page",
	//客户SKU流水 /wh/bizWhMySkuStockSeq/query/{userId}/page
	WhCusSkuRecordPageList: HY_BASEURL+"/wh/bizWhMySkuStockSeq/query",
	
	//SKU库位明细 /wh/bizWhMySkuStockSeq/query/{userId}/page 旧
	//SKU库位明细 /wh/bizWhMySkuStock/query/sku/stock/{userId}?goodsSku= 新
	WhCusSkuStockDet: HY_BASEURL+"/wh/bizWhMySkuStock/query/sku/stock",
	
	//库存修改
	WhCusSkuStockChange: HY_BASEURL+"/wh/bizWhMySkuStock/change/stock",
	//库存锁定
	//分页 /wh/bizWhMySkuStock/query/lock/page
	WhSkuStockLockPageList: HY_BASEURL+"/wh/bizWhMySkuStock/query/lock/page",
	//解锁 /wh/bizWhMySkuStock/unlock   {"id":"","changeStock":"解锁数量"}
	WhSkuStockLockUnlock: HY_BASEURL+"/wh/bizWhMySkuStock/unlock",
	
	//库存锁定-所有 /wh/bizWhMySendLockStock/query/page
	WhMySendStockLockPageList: HY_BASEURL+"/wh/bizWhMySendLockStock/query/page",
	
	//明细库存锁定-所有 /wh/bizWhMySendLockStock/query/{stockId}/page {"offset":"","limit":"10"}
	WhMySendStockLockPageListById: HY_BASEURL+"/wh/bizWhMySendLockStock/query",
	
	//库存盘点 /wh/bizWhMySkuStock/query/stocktaking/info/page  PUT  {"userId":"","whNo":"","minStock":"","maxStock":"","offset":"0","limit":"10"}
	WhStockCheckPageList: HY_BASEURL+"/wh/bizWhMySkuStock/query/stocktaking/info/page",
	
	//库存明细
	// /wh/bizWhMySkuStock/query/stock/detail/page   库存明细列表
	WhStockDetPageList: HY_BASEURL+"/wh/bizWhMySkuStock/query/stock/detail/page",
	//修改库位
	// /wh/bizWhMySkuStock/move/stock/place
	WhStockMovePlace: HY_BASEURL+"/wh/bizWhMySkuStock/move/stock/place",
	//库存明细-修改库存 /wh/bizWhMySkuStock/change/stock/place
	WhStockDetModify: HY_BASEURL+"/wh/bizWhMySkuStock/change/stock/place",
	//批量移库 /wh/bizWhMySkuStock/reader/excel/1741483941457997826?whNo=HYTX15
	WhStockMovePlaceReader: HY_BASEURL+"/wh/bizWhMySkuStock/reader/excel",
	//批量移库-提交 /wh/bizWhMySkuStock/batch/move/place      {"userId":"","whNo":"","moveList":[{"goodsSku":"","place":"","stock":""}]}
	WhStockMovePlaceBatch: HY_BASEURL+"/wh/bizWhMySkuStock/batch/move/place",
	
	//库存补录-原客户端
	//我的装箱单-分页
	WhPackingListPageList:  HY_BASEURL + "/wh/bizWhMyGoodsPacking/query/my/page",
	//我的装箱单-新增编辑
	WhPackingListUpdate:  HY_BASEURL + "/wh/bizWhMyGoodsPacking/updateInsert",
	//我的装箱单-删除/wh/bizWhMyGoodsPacking/delete/{id}
	WhPackingListDel:  HY_BASEURL + "/wh/bizWhMyGoodsPacking/delete",
	//我的装箱单-excel上传读取
	// WhPackingListExUpload:  HY_BASEURL + "/wh/bizWhMyGoodsPacking/reader/excel",
	//我的装箱单-Excel读取提交
	WhPackingListExSubmit:  HY_BASEURL + "/wh/bizWhMyGoodsPacking/save/excel",
	//我的装箱单-明细分页查询
	WhPackingListDetPageList:  HY_BASEURL + "/wh/bizWhMyGoodsPackingRecord/query/my/page",
	//我的装箱单-发货/wh/bizWhMyGoodsPacking/to/{id}
	WhPackingListShipments:  HY_BASEURL + "/wh/bizWhMyGoodsPacking/to",
	
	//SKU补录-原客户端
	//sku信息-分页
	WhMyGoodsSkuPageList:  HY_BASEURL + "/wh/bizWhMyGoodsSku/query/my/page",
	//sku信息-新增编辑
	WhMyGoodsSkuUpdate:  HY_BASEURL + "/wh/bizWhMyGoodsSku/updateInsert",
	//sku信息-删除/wh/bizWhMyGoodsSku/delete/{id}
	WhMyGoodsSkuDel:  HY_BASEURL + "/wh/bizWhMyGoodsSku/delete",
	//sku信息-excel上传读取
	WhMyGoodsSkuExUpload:  HY_BASEURL + "/wh/bizWhMyGoodsSku/batch/add/excel",
	//sku信息-Excel读取提交
	WhMyGoodsSkuExSubmit:  HY_BASEURL + "/wh/bizWhMyGoodsSku/batch/add/list",
	//sku信息-SKU 流水分页
	WhMyGoodsSkuRecPageList:  HY_BASEURL + "/wh/bizWhMySkuStockSeq/query/my/page",
	//sku信息-SKU库存分页查询
	// WhMyGoodsSkuStockPageList:  HY_BASEURL + "/wh/bizWhMySkuStock/query/sys/page",
	//sku-审核 /wh/bizWhMyGoodsSku/verify/{userId} PUT
	WhMyGoodsSkuAudit:  HY_BASEURL + "/wh/bizWhMyGoodsSku/verify",
	
	//报表
	// SKU库存信息统计
	WhReportSkuStockPageList: HY_BASEURL +"/wh/bizWhReportSkuDayStock/query/page",
	WhReportSkuGroupStockPageList: HY_BASEURL +"/wh/bizWhReportSkuDayStock/query/group/sku/page",
	//库存实时统计 /wh/bizWhMySkuStock/now/stock  PUT  {"offset":"0","limit":"10"}
	WhReportNowStockPageList: HY_BASEURL +"/wh/bizWhMySkuStock/now/stock",
	//出库报表 /wh/bizWhMySkuStockSeq/query/{userId}/out/bill
	WhReportOutBillPageList: HY_BASEURL +"/wh/bizWhMySkuStockSeq/query",
	//sku库存趋势统计 /wh/bizWhReportSkuDayStock/query/group/sku/chart
	WhReportSkuCharts: HY_BASEURL +"/wh/bizWhReportSkuDayStock/query/group/sku/chart",
	//仓库费用统计
	WhCostStatisticsPageList: HY_BASEURL +"/warehouse/bizWhFeeRecord/statistics/query/page",
	//每月订单统计 /wh/bizCommon/query/order/year/total   每月总计 PUT {}
	WhOrderStatisticsByMon: HY_BASEURL +"/wh/bizCommon/query/order/year/total",
	//每天订单统计 /wh/bizCommon/query/order/day/detail  每天总计 PUT {}
	WhOrderStatisticsByDay: HY_BASEURL +"/wh/bizCommon/query/order/day/detail",
	
	///wh/bizCommon/addvs/wh/statistics  {} PUT  首页增值服务工单统计
	WhAddValueStatistics: HY_BASEURL +"/wh/bizCommon/addvs/wh/statistics",
	
	//费用标准配置
	//分页 /wh/bizWhMyChargeConfig/query/page
	ChargeConfigPageList: HY_BASEURL +"/wh/bizWhMyChargeConfig/query/page",
	//通用配置SKU分页 /wh/bizWhMyChargeConfig/query/sku/page/{parentId}
	ChargeConfigSkuPageList: HY_BASEURL +"/wh/bizWhMyChargeConfig/query/sku/page",
	//编辑用户通用收费标准 /wh/bizWhMyChargeConfig/update/{userId}
	ChargeConfigBaseUpdate: HY_BASEURL +"/wh/bizWhMyChargeConfig/update",
	//用户SKU收费标准配置 /wh/bizWhMyChargeConfig/update/{userId}/sku
	ChargeConfigSkuUpdate: HY_BASEURL +"/wh/bizWhMyChargeConfig/update",
	
	//用户SKU收费标准删除 /wh/bizWhMyChargeConfig/delete/{id}
	ChargeConfigSkuDel: HY_BASEURL +"/wh/bizWhMyChargeConfig/delete",
	
	//绑定账号和仓库
	//账号分页/bckUser/showUser
	AccountUserPageList: HY_BASEURL +"/bckUser/showUser",
	//分页 /wh/bizWhAccountBind/query/bind/page
	WhAccountBindPageList: HY_BASEURL +"/wh/bizWhAccountBind/query/bind/page",
	//新增 /wh/bizWhAccountBind/add/bind
	WhAccountBindAdd: HY_BASEURL +"/wh/bizWhAccountBind/add/bind",
	//删除 /wh/bizWhAccountBind/delete/bind/{id}
	WhAccountBindDel: HY_BASEURL +"/wh/bizWhAccountBind/delete/bind",
	// 开启 /wh/bizWhCusBind/open/bind PUT {"id":}  开启
	WhAccountBindOpen: HY_BASEURL +"/wh/bizWhCusBind/open/bind",
	//关闭 /wh/bizWhCusBind/close/bind PUT {"id":}  关闭
	WhAccountBindClose: HY_BASEURL +"/wh/bizWhCusBind/close/bind",
	//绑定客户和仓库
	// /wh/bizWhCusBind/add/bind   PUT  {"userId":"","whNos":[]}
	WhCusBindAdd: HY_BASEURL +"/wh/bizWhCusBind/add/bind",
	// /wh/bizWhCusBind/delete/bind PUT {"ids":[]}
	WhCusBindDel: HY_BASEURL +"/wh/bizWhCusBind/delete/bind",
	// /wh/bizWhCusBind/query/page PUT {"offset":"0","limit":"10"}
	WhCusBindPageList: HY_BASEURL +"/wh/bizWhCusBind/query/page",
	
	// 仓库后台获取用户列表：/warehouse/bizWhCustomer/query/sys/cus  GET
	WhCusBindList: HY_BASEURL +"/warehouse/bizWhCustomer/query/sys/cus",
	
	//1：/wh/ttp/add/tripartite/auth/config  PUT  {"tpCode":"","apCode":"","apName":"","appId":"","appKey":"","appSecret":"","authUrl":""}  添加三方应用配置管理
	WhTripartiteAuthConfigAdd: HY_BASEURL +"/wh/ttp/add/tripartite/auth/config",
	// 2：/wh/ttp/query/tripartite/auth/config/page  PUT   {"offset":0,"limit":10} 配置分页查询
	WhTripartiteAuthConfigPageList: HY_BASEURL +"/wh/ttp/query/tripartite/auth/config/page",
	// 3：/wh/ttp/delte/tripartite/auth/{delId}  DELTE  删除配置
	WhTripartiteAuthConfigDel: HY_BASEURL +"/wh/ttp/delte/tripartite/auth",
	
	//账号客户绑定
	// /wh/bizWhCusBindBck/add/bind   [{"userId":"","bckId":""}]   PUT
	WhAccountBindCusAdd: HY_BASEURL +"/wh/bizWhCusBindBck/add/bind",
	// /wh/bizWhCusBindBck/delete/bind  {"ids":[]}   PUT
	WhAccountBindCusDel: HY_BASEURL +"/wh/bizWhCusBindBck/delete/bind",
	// /wh/bizWhCusBindBck/query/page  {"offset":"","limit":"10"}  PUT 
	WhAccountBindCusPage: HY_BASEURL +"/wh/bizWhCusBindBck/query/page",
	
	//仓库列表
	//-旧
	// whNoPageList: HY_BASEURL +"/dic/query/whNo/list",
	//新 /wh/bizWhInfo/query/select/list
	whNoPageList: HY_BASEURL +"/wh/bizWhInfo/query/select/list",
	//2024.12.10 新-防攻击
	// whNoPageList: HY_BASEURL +"/wh/bizWhInfo/query/select/list/do",
	
	
	//仓库费用配置
	// 仓库服务价格查询 /wh/bizWhFeeConfig/service/query?prefix=
	whCostConfigQuery: HY_BASEURL +"/wh/bizWhFeeConfig/service/query",
	//新增编辑接口 /wh/bizWhFeeConfig/service/updateInsert
	whCostConfigUpdate: HY_BASEURL +"/wh/bizWhFeeConfig/service/updateInsert",
	
	//账户信息
	//分页 /wh/bizWhAccount/query/page
	WhAccountPageList: HY_BASEURL +"/wh/bizWhAccount/query/page",
	//编辑-充值 /wh/bizWhAccount/add/recharge
	WhAccountRecharge: HY_BASEURL +"/wh/bizWhAccount/add/recharge",
	//充值 /wh/bizWhRechargeVerify/verified/{id}
	WhVerifiedRecharge: HY_BASEURL +"/wh/bizWhRechargeVerify/verified",
	//后台仓库参数充值记录 /wh/bizWhRechargeVerify/query/page/{userId}
	WhVerifiedRechargeRec: HY_BASEURL +"/wh/bizWhRechargeVerify/query/page",
	//生成预付账户
	// /wh/bizWhAccount/add/account/{userId}
	WhAccountAdd: HY_BASEURL +"/wh/bizWhAccount/add/account",
	
	//客户充值新增编辑 /wh/bizWhRechargeVerify/update
	WhRechargeVerifyUpdate: HY_BASEURL +"/wh/bizWhRechargeVerify/update",
	//客户端删除充值记录 /wh/bizWhRechargeVerify/delete/{id}
	WhRechargeVerifyDelete: HY_BASEURL +"/wh/bizWhRechargeVerify/delete",
	
	// 账户记录分页查询 /wh/bizWhAccountRecord/query/page
	WhAccountRecordPageList: HY_BASEURL +"/wh/bizWhAccountRecord/query/page",
	
	//账单管理
	//分页
	WhBillRecPageList: HY_BASEURL +"/wh/bizWhOpFin/query/sys/page",
	//明细
	WhBillRecDetPageList: HY_BASEURL +"/warehouse/bizWhOpFinRecord/quer/bill/page",
	//明细调整
	WhBillRecDetChange: HY_BASEURL +"/warehouse/bizWhOpFinRecord/change/finamt",
	//账单管理-新
	//一件代发操作
	// 一件代发操作:
	// /wh/bizWhFinBill/query/SDS/op/record/page  费用记录分页
	WhBillSdsOpRecPage:HY_BASEURL +"/wh/bizWhFinBill/query/SDS/op/record/page ",
	// /wh/bizWhFinBill/query/SDS/op/fee/total   费用合计
	WhBillSdsOpTotal:HY_BASEURL +"/wh/bizWhFinBill/query/SDS/op/fee/total",
	// 一件代发出库:
	// 明细查询:/wh/bizWhFinBill/wh/bizWhFinBill/query/SDS/out/record  PUT {"userId":"","whNo":"","opTimeStart":"","opTimeEnd":"","goodsSku":"","opStatus":"","isBill":""}
	WhBillSdsOutWhPage:HY_BASEURL +"/wh/bizWhFinBill/query/SDS/out/record",
	// 合计查询:/wh/bizWhFinBill/query/SDS/out/total PUT {"userId":"","whNo":"","opTimeStart":"","opTimeEnd":"","goodsSku":"","opStatus":"","isBill":""}
	WhBillSdsOutWhTotal:HY_BASEURL +"/wh/bizWhFinBill/query/SDS/out/total",
	// 附加费:
	// /warehouse/bizWhOpRecord/quer/page  明细记录
	WhBillAddFeePage:HY_BASEURL +"/warehouse/bizWhOpRecord/quer/page",
	// /warehouse/bizWhOpRecord/quer/record/total  合计
	WhBillAddFeeTotal:HY_BASEURL +"/warehouse/bizWhOpRecord/quer/record/total",
	// 仓租费:
	// /wh/bizWhFinBill/query/warehouse/fee/record/page  费用记录 {"userId":"","whNo":"","feeDateStart":"","feeDateEnd":""}
	WhBillWhFeePage:HY_BASEURL +"/wh/bizWhFinBill/query/warehouse/fee/record/page",
	// /wh/bizWhFinBil/query/warehouse/fee/totall  费用合计  {"userId":"","whNo":"","feeDateStart":"","feeDateEnd":""}
	WhBillWhFeeTotal:HY_BASEURL +"/wh/bizWhFinBill/query/warehouse/fee/total",
	// 自提单：
	// /wh/bizWhFinBill/query/STF/op/record/page   {"userId":"","whNo":"","opTimeStart":"","opTimeEnd":"","opStatus":"","isBill":""}
	WhBillStfOpPage:HY_BASEURL +"/wh/bizWhFinBill/query/STF/op/record/page",
	// /wh/bizWhFinBill/query/STF/op/fee/total
	// {"userId":"","whNo":"","opTimeStart":"","opTimeEnd":"","opStatus":"","isBill":""}
	WhBillStfOpTotal:HY_BASEURL +"/wh/bizWhFinBill/query/STF/op/fee/total",
	
	// 收货入库：
	// /wh/bizWhFinBill/query/SCC/op/record/page
	WhBillSdsInWhPage:HY_BASEURL +"/wh/bizWhFinBill/query/SCC/op/record/page",
	// /wh/bizWhFinBill/query/SCC/op/fee/total
	WhBillSdsOutInTotal:HY_BASEURL +"/wh/bizWhFinBill/query/SCC/op/fee/total",
	
	//转运入库
	// /wh/bizWhFinBill/query/SCC/op/record/page
	WhBillTSCCInWhPage:HY_BASEURL +"/wh/bizWhFinBill/query/TSCC/op/record/page",
	// /wh/bizWhFinBill/query/SCC/op/fee/total
	WhBillTSCCOutInTotal:HY_BASEURL +"/wh/bizWhFinBill/query/TSCC/op/fee/total",
	//转运出库
	// /wh/bizWhFinBill/query/SCC/op/record/page
	WhBillSTRInWhPage:HY_BASEURL +"/wh/bizWhFinBill/query/STR/op/record/page",
	// /wh/bizWhFinBill/query/SCC/op/fee/total
	WhBillSTROutInTotal:HY_BASEURL +"/wh/bizWhFinBill/query/STR/op/fee/total",
	//增值服务
	// /wh/bizWhFinBill/query/SCC/op/record/page
	WhBillADDVSInWhPage:HY_BASEURL +"/wh/bizWhFinBill/query/ADDVS/op/record/page",
	// /wh/bizWhFinBill/query/SCC/op/fee/total
	WhBillADDVSOutInTotal:HY_BASEURL +"/wh/bizWhFinBill/query/ADDVS/op/fee/total",
	
	// 退货入库：
	WhBillSREInWhPage:HY_BASEURL +"/wh/bizWhFinBill/query/SRE/op/record/page",
	WhBillSREOutInTotal:HY_BASEURL +"/wh/bizWhFinBill/query/SRE/op/fee/total",
	
	// 快速转运：
	WhBillFTROrderPage:HY_BASEURL +"/wh/bizWhFinBill/query/FTR/op/record/page",
	WhBillFTROrderTotal:HY_BASEURL +"/wh/bizWhFinBill/query/FTR/op/fee/total",
	
	// 港口提柜 PCPU：
	WhBillPCPUPage:HY_BASEURL +"/wh/bizWhFinBill/query/PCPU/op/record/page",
	WhBillPCPUTotal:HY_BASEURL +"/wh/bizWhFinBill/query/PCPU/op/fee/total",
	
	// 尾程卡派 TRUCK：
	WhBillTRUCKPage:HY_BASEURL +"/wh/bizWhFinBill/query/TRUCK/op/record/page",
	WhBillTRUCKTotal:HY_BASEURL +"/wh/bizWhFinBill/query/TRUCK/op/fee/total",
	
	//账单首页
	// /wh/bizWhFinBill/query/all/fee/total  {"opTimeStart":"","opTimeEnd":""}
	// /wh/bizWhFinBill/query/warehouse/all/fee/total {"opTimeStart":"","opTimeEnd":""}
	// /wh/bizWhFinBill/query/warehouse/cus/all/fee/total {"whNo":"","opTimeStart":"","opTimeEnd":""}
	WhBillAllFeeTotal:HY_BASEURL +"/wh/bizWhFinBill/query/all/fee/total",
	WhBillAllFeeAndWh:HY_BASEURL +"/wh/bizWhFinBill/query/warehouse/all/fee/total",
	WhBillAllFeeAndWhByCus:HY_BASEURL +"/wh/bizWhFinBill/query/warehouse/cus/all/fee/total",
	// /wh/bizWhFinBill/query/cus/all/fee/total   
	// /wh/bizWhFinBill/query/cus/warehouse/all/fee/total
	WhBillAllFeeAndCus:HY_BASEURL +"/wh/bizWhFinBill/query/cus/all/fee/total",
	WhBillAllFeeAndCusByWh:HY_BASEURL +"/wh/bizWhFinBill/query/cus/warehouse/all/fee/total",
	
	
	//按查询条件全部添加到账单
	WhaddRecordBillByQuery: HY_BASEURL +"/warehouse/bizWhOpRecord/addRecordBill/queryCondi",
	
	//在已有账单添加操作记录 /warehouse/bizWhOpRecord/addRecordBill/merge/{userId}/{billId}
	WhaddRecordBillByBill: HY_BASEURL +"/warehouse/bizWhOpRecord/addRecordBill/merge",
	
	//一件代发，面单label地址查询
	// /wh/bizWhMySendExpress/colud/print/label PUT  {"workNo":""}
	WhWorkLabelUrlList: HY_BASEURL +"/wh/bizWhMySendExpress/colud/print/label",
	// /wh/bizWhMySendExpress/colud/print/ids/label PUT  [1111111111]
	WhLabelUrlListByIds: HY_BASEURL +"/wh/bizWhMySendExpress/colud/print/ids/label ",
	
	//代理操作
	//一件代发-新-导入保存
	WhDropShippingBatchInsert:  HY_BASEURL + "/wh/bizWhMySendExpress/batch/insert",
	//一件代发-单个-保存 /wh/bizWhMySendExpress/update/insert
	WhDropShippingSingleInsert:  HY_BASEURL + "/wh/bizWhMySendExpress/update/insert",
	//一件代发-单个-保存 /wh/bizWhMySendExpress/update/edit
	WhDropShippingSingleEdit:  HY_BASEURL + "/wh/bizWhMySendExpress/update/edit",
	//一件代发-单个-编辑查询 /wh/bizWhMySendExpress/query/edit/{id}
	WhDropShippingDetById:  HY_BASEURL + "/wh/bizWhMySendExpress/query/edit",
	//退货-导入-保存 /wh/bizWhMyReturn/save/excel/{userId} PUT 导入
	WhExchangeReturnExcSave:  HY_BASEURL + "/wh/bizWhMyReturn/save/excel",
	//退货-新增编辑 /wh/bizWhMyReturn/update/insert
	WhExchangeReturnUpdate: HY_BASEURL+"/wh/bizWhMyReturn/update/insert",
	//退货-单个查询 /wh/bizWhMyReturnRecord/{returnId}/record GET
	WhExchangeReturnRecDet:  HY_BASEURL + "/wh/bizWhMyReturnRecord",
	//入库单=提交 /wh/bizWhMyGoodsPacking/commit    {"ids":[]}
	WhPackingListCommit:  HY_BASEURL + "/wh/bizWhMyGoodsPacking/commit",
	//入库单-编辑查询 /wh/bizWhMyGoodsPacking/query/{packId} GET 
	WhPackingListQueryId:  HY_BASEURL + "/wh/bizWhMyGoodsPacking/query",
	//入库单-编辑保存 /wh/bizWhMyGoodsPacking/updateEdit
	WhPackingListEditSave:  HY_BASEURL + "/wh/bizWhMyGoodsPacking/updateEdit",
	//自提单，批量新增 /wh/bizWhMySendGoods/batch/insert
	WhShipmentsOrderBatchUpdate:  HY_BASEURL + "/wh/bizWhMySendGoods/batch/insert",
	//自提单，单条 新增/wh/bizWhMySendGoods/update/insert
	WhShipmentsOrderAdd:  HY_BASEURL + "/wh/bizWhMySendGoods/update/insert",
	//自提单，提交 /wh/bizWhMySendGoods/commit
	WhShipmentsOrderCommit:  HY_BASEURL + "/wh/bizWhMySendGoods/commit",
	
	//自提单 撤销 /wh/bizWhMySendGoods/update/return  {"ids":[]}
	WhShipmentsOrderReturn:  HY_BASEURL + "/wh/bizWhMySendGoods/update/return",
	//代理操作-工单管理
	//工单
	//删除
	///wh/bizWhWorkOrder/delete/{id}
	WhWorkOrderDel:  HY_BASEURL + "/wh/bizWhWorkOrder/delete",
	//单条查询
	// /wh/bizWhWorkOrder/query/{id}
	WhWorkOrderDet:  HY_BASEURL + "/wh/bizWhWorkOrder/query",
	//分页查询
	// /wh/bizWhWorkOrder/query/my/page
	WhWorkOrderPageList:  HY_BASEURL + "/wh/bizWhWorkOrder/query/my/page",
	//提交工单-新增-编辑
	// /wh/bizWhWorkOrder/updateInsert
	WhWorkOrderAdd:  HY_BASEURL + "/wh/bizWhWorkOrder/updateInsert",
	// 查询处理日志
	// /wh/bizWhWorkOrder/query/log/{id}
	WhWorkOrderLog:  HY_BASEURL + "/wh/bizWhWorkOrder/query/log",
	//编辑查询 /wh/bizWhWorkOrder/edit/query/{id}
	WhWorkOrderEditDet:  HY_BASEURL + "/wh/bizWhWorkOrder/edit/query",
	//提交用户工单 /wh/bizWhWorkOrder/commit/{id}
	WhWorkOrderSubmit:  HY_BASEURL + "/wh/bizWhWorkOrder/commit",
	
	//工单-增值服务明细 /wh/bizWhMyAddService/get/workId/{workId} GET
	WhWorkAddServiceDet:  HY_BASEURL + "/wh/bizWhMyAddService/get/workId",
	//删除明细 /wh/bizWhMyAddService/del/{id}  DELETE
	WhWorkAddServiceDetDel:  HY_BASEURL + "/wh/bizWhMyAddService/del",
	
	//一件代发-导入
	WhDropShippingExUpload:  HY_BASEURL + "/wh/bizWhMySendExpress/batch/read/excel",
	//我的发货单-SKU明细导入
	WhShipmentsListExUpload:  HY_BASEURL + "/wh/bizWhMySendGoodsRecord/batch/add/excel",
	//我的装箱单-excel上传读取
	WhPackingListExUpload:  HY_BASEURL + "/wh/bizWhMyGoodsPacking/reader/excel",
	
	//pdf 拆分
	WhPdfSplit: HY_BASEURL+"/wh/download/v2/pdf/split/to/base64",
	//pdf旋转 /wh/download/v2/pdf/degrees   PUT  {"base64":"","degrees":""}
	WhPdfRotate: HY_BASEURL+"/wh/download/v2/pdf/degrees",
	
	//面单匹配保存
	//根据面单PDF名称匹配代发单 /wh/bizWhMySendExpress/matching/order/label/url?workNo=
	whMatchingLabel: HY_BASEURL +"/wh/bizWhMySendExpress/matching/order/label/url",
	
	// 保存匹配信息 /wh/bizWhMySendExpress/save/matching/label/url
	whSaveMatchingLabel: HY_BASEURL +"/wh/bizWhMySendExpress/save/matching/label/url",
	
	//根据运单号，面单自动识别
	whMatchingLabelByTrackingNumber: HY_BASEURL +"/wh/bizWhMySendExpress/pdf/label/match/by/trucking",
	//根据运单号，面单自动识别-新 /wh/bizWhMySendExpressPdfMatch/add/match/pdf
	whMatchingLabelByTrackingNumberPdf: HY_BASEURL +"/wh/bizWhMySendExpressPdfMatch/add/match/pdf",
	//根据运单号，面单自动识别-查询 /wh/bizWhMySendExpressPdfMatch/query/record/page  PUT {"parentId":111111}
	whMatchingLabelPdfRec: HY_BASEURL +"/wh/bizWhMySendExpressPdfMatch/query/record/page",
	//根据运单号，面单自动识别-查询全部 /wh/bizWhMySendExpressPdfMatch/query/source/page 
	whMatchingLabelPdfSourcePage: HY_BASEURL +"/wh/bizWhMySendExpressPdfMatch/query/source/page",
	//根据运单号，面单自动识别-查询-合计 /wh/bizWhMySendExpressPdfMatch/query/record/total/{parentId}
	whMatchingLabelPdfRecTotal: HY_BASEURL +"/wh/bizWhMySendExpressPdfMatch/query/record/total",
	//重置匹配次数 /wh/bizWhMySendExpressPdfMatch/update/resReset/{id}/matchs  PUT {}
	whMatchingResetNum: HY_BASEURL +"/wh/bizWhMySendExpressPdfMatch/update/resReset",
	
	//退货换标
	//分页 /wh/bizWhMyReturn/query/page
	WhExchangeReturnPageList:  HY_BASEURL + "/wh/bizWhMyReturn/query/page",
	//批量签收 /wh/bizWhMyReturnRecord/all/sign/{returnId}
	WhExchangeReturnAllSign:  HY_BASEURL + "/wh/bizWhMyReturnRecord/all/sign",
	//单条签收 /wh/bizWhMyReturnRecord/sign/{id}
	WhExchangeReturnSingleSign:  HY_BASEURL + "/wh/bizWhMyReturnRecord/sign",
	//单条异常签收 /wh/bizWhMyReturnRecord/exce/sign/{id}
	WhExchangeReturnSingleExceSign:  HY_BASEURL + "/wh/bizWhMyReturnRecord/exce/sign",
	//详情 /wh/bizWhMyReturn/query/detail/{id}
	WhExchangeReturnDet:  HY_BASEURL + "/wh/bizWhMyReturn/query/detail",
	//导入-读取 /wh/bizWhMyReturn/reader/excel POST
	WhExchangeReturnExcReader:  HY_BASEURL + "/wh/bizWhMyReturn/reader/excel",
	//导入-保存 /wh/bizWhMyReturn/save/excel/{userId} PUT 导入
	WhExchangeReturnExcSave:  HY_BASEURL + "/wh/bizWhMyReturn/save/excel",
	//单个查询 /wh/bizWhMyReturnRecord/{returnId}/record GET
	WhExchangeReturnRecDet:  HY_BASEURL + "/wh/bizWhMyReturnRecord",
	//删除-明细-记录 /wh/bizWhMyReturnRecord/delete/{id}
	WhExchangeReturnDel:  HY_BASEURL + "/wh/bizWhMyReturnRecord/delete",
	
	//退货换标-代理
	//分页/ /wh/bizWhMyReturn/query/{userId}/page
	WhExchangeReturnPageListProx:  HY_BASEURL + "/wh/bizWhMyReturn/query",
	//撤销 /wh/bizWhMyReturn/return  PUT  {"id":""}   撤销
	WhExchangeReturnRevocation:  HY_BASEURL + "/wh/bizWhMyReturn/return",
	//删除 /wh/bizWhMyReturn/delete/{returnId}  DELTE  删除
	WhExchangeReturnBaseDel:  HY_BASEURL + "/wh/bizWhMyReturn/delete",
	
	//退货入库
	// 分页 /wh/bizWhReceiveGoods/query/return/page
	WhExchangeReturnInStockPageList:  HY_BASEURL + "/wh/bizWhReceiveGoods/query/return/page",
	//入库 /wh/bizWhReceiveGoods/in/return/stock
	WhExchangeReturnInStock:  HY_BASEURL + "/wh/bizWhReceiveGoods/in/return/stock",
	//处理明细 /wh/bizWhReceiveGoodsOpRecord/op/list/{receiveId} Get
	WhExchangeReturnDetList:  HY_BASEURL + "/wh/bizWhReceiveGoodsOpRecord/op/list",
	
	//第三方订单
	// 分页 /wh/bizWhThirdApiOrder/query/my/page
	WhThirdOrderPageList: HY_BASEURL +"/wh/bizWhThirdApiOrder/query/my/page",
	//第三方订单生产发货单 /wh/bizWhThirdApiOrder/work/order/command
	WhThirdOrderCommand: HY_BASEURL +"/wh/bizWhThirdApiOrder/work/order/command",
	//拉取新订单 /wh/bizWhThirdApiOrder/pull/{userId}/ec/order   PUT {"status":"","saleOrderCodes":[],"warehouseOrderCodes":[]]
	WhThirdOrderPullOrder: HY_BASEURL+"/wh/bizWhThirdApiOrder/pull",
	// 更新发货仓库 /wh/bizWhThirdApiOrder/update/whNo    {"ids":[],"warehouseCode":""}
	WhThirdOrderUpdateWh: HY_BASEURL+"/wh/bizWhThirdApiOrder/update/whNo",
	//删除订单 /wh/bizWhThirdApiOrder/delete/orders  PUT  {"ids":[]}
	WhThirdOrderDel: HY_BASEURL+"/wh/bizWhThirdApiOrder/delete/orders",
	//撤销发货单 /wh/bizWhThirdApiOrder/remove/send/expr  PUT  [ids]
	WhThirdOrderRemoveSend: HY_BASEURL+"/wh/bizWhThirdApiOrder/remove/send/expr",
	//发货地址编辑
	WhThirdOrderReceiverEdit: HY_BASEURL+"/wh/bizWhThirdApiOrder/edit/info",
	
	//易仓拉取查询-仓库 /wh/bizWhThirdApiOrder/query/yicang/{userId}/warehouse PUT {}
	WhThirdYiCangWhQuery: HY_BASEURL+"/wh/bizWhThirdApiOrder/query/yicang",
	//易仓 api 分页 /wh/bizWhYicangConf/query/page
	WhThirdYiCangConfPageList: HY_BASEURL+"/wh/bizWhYicangConf/query/page",
	//易仓 api 删除 /wh/bizWhYicangConf/delete/{id}
	WhThirdYiCangConfDel: HY_BASEURL+"/wh/bizWhYicangConf/delete",
	//易仓 api 更新 /wh/bizWhYicangConf/update/warehouse
	WhThirdYiCangConfUpdate: HY_BASEURL+"/wh/bizWhYicangConf/update/warehouse",
	//易仓 api 配置新增 /wh/bizWhYicangConf/add/update/{userId}
	WhThirdYiCangConfAdd: HY_BASEURL+"/wh/bizWhYicangConf/add/update",
	//易仓库存同步 
	//分页 /wh/bizWhMySkuStock/query/yc/stock/page
	WhThirdYiCangInventorySync: HY_BASEURL+"/wh/bizWhMySkuStock/query/yc/stock/page",
	//提交同步 /wh/bizWhThirdApiOrder/yc/send/expr
	WhThirdYiCangSyncExpr: HY_BASEURL+"/wh/bizWhThirdApiOrder/yc/send/expr",
	//库存同步 /wh/bizWhMySkuStock/yc/stock/sync  PUT
	WhThirdYiCangStockSync: HY_BASEURL+"/wh/bizWhMySkuStock/yc/stock/sync",
	//库存导入 /wh/bizWhMySkuStock/imp/ivs/{userId}  PUT  {"impInvVoList":[],"whNo":""}
	WhThirdYiCangStockImpIvs: HY_BASEURL+"/wh/bizWhMySkuStock/imp/ivs",
	
	//SKU拆分
	//拆分分页 /wh/bizWhSkuBreakUp/query/page
	WhSkuSplitPageList: HY_BASEURL +"/wh/bizWhSkuBreakUp/query/page",
	//拆分开始处理 /wh/bizWhSkuBreakUp/break/processing/{id}
	WhSkuSplitStart: HY_BASEURL +"/wh/bizWhSkuBreakUp/break/processing",
	//拆分完成 /wh/bizWhSkuBreakUp/break/complete
	WhSkuSplitComplete: HY_BASEURL +"/wh/bizWhSkuBreakUp/break/complete",
	//回退 /wh/bizWhSkuBreakUp/return/{id}
	WhSkuSplitReturn: HY_BASEURL +"/wh/bizWhSkuBreakUp/return",
	//sku-拆分-明细 /wh/bizWhSkuBreakUpRecord/query/{breakId}
	WhSkuSplitDetList:  HY_BASEURL + "/wh/bizWhSkuBreakUpRecord/query",
	
	//拆分入库分页 /wh/bizWhReceiveGoods/query/skuBreak/page
	WhSkuBreakSplitPageList: HY_BASEURL +"/wh/bizWhReceiveGoods/query/skuBreak/page",
	//入库 /wh/bizWhReceiveGoods/in/skuBreak/stock
	WhSkuSplitInStock: HY_BASEURL +"/wh/bizWhReceiveGoods/in/skuBreak/stock",
	
	//sku-拆分 /wh/bizWhSkuBreakUp/query/page
	// WhSkuSplitPageList:  HY_BASEURL + "/wh/bizWhSkuBreakUp/query/page",
	//sku-拆分-新增 /wh/bizWhSkuBreakUp/update
	WhSkuSplitAdd:  HY_BASEURL + "/wh/bizWhSkuBreakUp/update",
	//sku-拆分-删除 /wh/bizWhSkuBreakUp/delete/{id}
	WhSkuSplitDel:  HY_BASEURL + "/wh/bizWhSkuBreakUp/delete",
	//sku-拆分-取消 /wh/bizWhSkuBreakUp/cancel/{id}
	WhSkuSplitCancel:  HY_BASEURL + "/wh/bizWhSkuBreakUp/cancel",
	//sku-拆分-明细 /wh/bizWhSkuBreakUpRecord/query/{breakId}
	// WhSkuSplitDetList:  HY_BASEURL + "/wh/bizWhSkuBreakUpRecord/query",
	//sku-拆分-提交 /wh/bizWhSkuBreakUp/commit
	WhSkuSplitSubmit:  HY_BASEURL + "/wh/bizWhSkuBreakUp/commit",
	//sku-拆分-分页-代理 /wh/bizWhSkuBreakUp/query/{userId}/page
	WhSkuSplitPageListAgent:  HY_BASEURL + "/wh/bizWhSkuBreakUp/query",
	
	// //快递面单
	// //查询费率 /thirdLogistics/fuerthertry/bizLgsThridOrderInfo/query/rate
	// WhFuerthertryQueryRate: HY_BASEURL +"/thirdLogistics/fuerthertry/bizLgsThridOrderInfo/query/rate",
	// //删除订单 /thirdLogistics/fuerthertry/bizLgsThridOrderInfo/delete/{id}
	// WhFuerthertryDel: HY_BASEURL +"/thirdLogistics/fuerthertry/bizLgsThridOrderInfo/delete",
	// //提交打单 /thirdLogistics/fuerthertry/bizLgsThridOrderInfo/commit/labels
	// WhFuerthertryCommit: HY_BASEURL +"/thirdLogistics/fuerthertry/bizLgsThridOrderInfo/commit/labels",
	// // 新增订单 /thirdLogistics/fuerthertry/bizLgsThridOrderInfo/add/order
	// WhFuerthertryAdd: HY_BASEURL +"/thirdLogistics/fuerthertry/bizLgsThridOrderInfo/add/order",
	// //订单编辑 /thirdLogistics/fuerthertry/bizLgsThridOrderInfo/edit/order
	// WhFuerthertryEdit: HY_BASEURL +"/thirdLogistics/fuerthertry/bizLgsThridOrderInfo/edit/order",
	// //订单分页 /thirdLogistics/fuerthertry/bizLgsThridOrderInfo/query/order/page
	// WhFuerthertryPageList: HY_BASEURL +"/thirdLogistics/fuerthertry/bizLgsThridOrderInfo/query/order/page",
	// // 订单进度明细分页查询 /thirdLogistics/bizLgsThridLabelInfo/query/label/page
	// WhFuerthertryDetPageList: HY_BASEURL +"/thirdLogistics/bizLgsThridLabelInfo/query/label/page",
	// // 取消面单 /thirdLogistics/bizLgsThridLabelInfo/cancel/label
	// WhThridLabelCancelOrder: HY_BASEURL +"/thirdLogistics/bizLgsThridLabelInfo/cancel/label",
	// // 撤销打单 /thirdLogistics/bizLgsThridLabelInfo/remove/label
	// WhThridLabelCancelPrinting: HY_BASEURL +"/thirdLogistics/bizLgsThridLabelInfo/remove/label",
	// // 校验地址 /thirdLogistics/fuerthertry/bizLgsThridOrderInfo/check/addr
	// WhThridOrderInfoCheckAddr: HY_BASEURL +"/thirdLogistics/fuerthertry/bizLgsThridOrderInfo/check/addr",
	
	// //导入运单模板 /thirdLogistics/fuerthertry/bizLgsThridOrderInfo/batch/read/excel
	// WhFuerthertryReadExcel: HY_BASEURL +"/thirdLogistics/fuerthertry/bizLgsThridOrderInfo/batch/read/excel",
	// //导入运单保存 /thirdLogistics/fuerthertry/bizLgsThridOrderInfo/save/excel
	// WhFuerthertryExcelSave: HY_BASEURL +"/thirdLogistics/fuerthertry/bizLgsThridOrderInfo/save/excel",
	
	//快递面单-新-去掉 fuerthertry
	//查询费率 /thirdLogistics/bizLgsThridOrderInfo/query/rate
	WhFuerthertryQueryRate: HY_BASEURL +"/thirdLogistics/bizLgsThridOrderInfo/query/rate",
	//删除订单 /thirdLogistics/bizLgsThridOrderInfo/delete/{id}
	WhFuerthertryDel: HY_BASEURL +"/thirdLogistics/bizLgsThridOrderInfo/delete",
	//提交打单 /thirdLogistics/bizLgsThridOrderInfo/commit/labels
	WhFuerthertryCommit: HY_BASEURL +"/thirdLogistics/bizLgsThridOrderInfo/commit/labels",
	// 新增订单 /thirdLogistics/bizLgsThridOrderInfo/add/order
	WhFuerthertryAdd: HY_BASEURL +"/thirdLogistics/bizLgsThridOrderInfo/add/order",
	//订单编辑 /thirdLogistics/bizLgsThridOrderInfo/edit/order
	WhFuerthertryEdit: HY_BASEURL +"/thirdLogistics/bizLgsThridOrderInfo/edit/order",
	//订单分页 /thirdLogistics/bizLgsThridOrderInfo/query/order/page
	WhFuerthertryPageList: HY_BASEURL +"/thirdLogistics/bizLgsThridOrderInfo/query/order/page",
	// 订单进度明细分页查询 /thirdLogistics/bizLgsThridLabelInfo/query/label/page
	WhFuerthertryDetPageList: HY_BASEURL +"/thirdLogistics/bizLgsThridLabelInfo/query/label/page",
	// 取消面单 /thirdLogistics/bizLgsThridLabelInfo/cancel/label
	WhThridLabelCancelOrder: HY_BASEURL +"/thirdLogistics/bizLgsThridLabelInfo/cancel/label",
	// 撤销打单 /thirdLogistics/bizLgsThridLabelInfo/remove/label
	WhThridLabelCancelPrinting: HY_BASEURL +"/thirdLogistics/bizLgsThridLabelInfo/remove/label",
	// 校验地址 /thirdLogistics/bizLgsThridOrderInfo/check/addr
	WhThridOrderInfoCheckAddr: HY_BASEURL +"/thirdLogistics/bizLgsThridOrderInfo/check/addr",
	//删除 /thirdLogistics/bizLgsThridLabelInfo/label/{id}  DELTE
	WhThridLabelInfoDetDel: HY_BASEURL +"/thirdLogistics/bizLgsThridLabelInfo/label",
	//删除-批量 /thirdLogistics/bizLgsThridLabelInfo/batch/deletelabel  {"ids":[]}
	WhThridLabelInfoDetDelBatch: HY_BASEURL +"/thirdLogistics/bizLgsThridLabelInfo/batch/deletelabel",
	
	//预报-装箱单-打印箱唛 /wh/bizWhMyGoodsPacking/print/mark/{id} get  数据ID
	WhPrinterShippingMark: HY_BASEURL +"/wh/bizWhMyGoodsPacking/print/mark",
	
	//导入运单模板 /thirdLogistics/bizLgsThridOrderInfo/batch/read/excel
	WhFuerthertryReadExcel: HY_BASEURL +"/thirdLogistics/bizLgsThridOrderInfo/batch/read/excel",
	//导入运单保存 /thirdLogistics/bizLgsThridOrderInfo/save/excel
	WhFuerthertryExcelSave: HY_BASEURL +"/thirdLogistics/bizLgsThridOrderInfo/save/excel",
	
	//自提单-excel读取 /wh/bizWhMySendGoods/batch/read/excel  Excel读取
	WhTransferOrderExcelRead:  HY_BASEURL + "/wh/bizWhMySendGoods/batch/read/excel",
	// 自提单- /wh/bizWhMySendGoods/update/edit PUT 编辑更新
	WhTransferOrderEditUpdate:  HY_BASEURL + "/wh/bizWhMySendGoods/update/edit",
	// 自提单-/wh/bizWhMySendGoods/query/edit/{editId}  编辑查询
	WhTransferOrderEditSer:  HY_BASEURL + "/wh/bizWhMySendGoods/query/edit",
	
	//自提单-留言
	// /wh/bizWhMySendGoods/save/comment   PUT {"id":"","comment":""} 保存
	WhSendGoodsCommentSave:  HY_BASEURL + "/wh/bizWhMySendGoods/save/comment",
	// /wh/bizWhMySendGoods/query/comment/{sendId}  GET
	WhSendGoodsCommentDet:  HY_BASEURL + "/wh/bizWhMySendGoods/query/comment",
	
	//快递面单账号
	//分页 /thirdLogistics/bizLgsThridKey/query/page   PUT  {"offset":"0","limit":"10"}
	WhExpreeSheetAccountPageList: HY_BASEURL +"/thirdLogistics/bizLgsThridKey/query/page",
	//更新 /thirdLogistics/bizLgsThridKey/update  PUT {"id":"","userId":"","code":"","codeName":"","logistics":"","clientKey":"","clientSecret":"","isUse":"","descJson":{}}
	WhExpreeSheetAccountUpdate: HY_BASEURL +"/thirdLogistics/bizLgsThridKey/update",
	//删除 /thirdLogistics/bizLgsThridKey/delete/1111111111   delete
	WhExpreeSheetAccountDel: HY_BASEURL +"/thirdLogistics/bizLgsThridKey/delete",
	//余额查询 /thirdLogistics/bizLgsThridOrderInfo/query/balence  PUT  {"lgsKeyCode":""}
	WhExpreeSheetQueryBalence: HY_BASEURL +"/thirdLogistics/bizLgsThridOrderInfo/query/balence",
	
	//客户快递渠道绑定
	//分页 /wh/bizLgsCusBindThrid/query/page  PUT 
	WhLgsCusBindThridPage: HY_BASEURL +"/wh/bizLgsCusBindThrid/query/page",
	//新增 /wh/bizLgsCusBindThrid/add/bind PUT {"lgsCodeId":"","feeType":"","feePrice":""}
	WhLgsCusBindThridAdd: HY_BASEURL +"/wh/bizLgsCusBindThrid/add/bind",
	//更新 /wh/bizLgsCusBindThrid/update/bind PUT
	WhLgsCusBindThridUpdate: HY_BASEURL +"/wh/bizLgsCusBindThrid/update/bind",
	//删除 /wh/bizLgsCusBindThrid/{id}  DEETE
	WhLgsCusBindThridDel: HY_BASEURL +"/wh/bizLgsCusBindThrid",
	
	//首页
	// /wh/bizCommon/SDS/current/day/out/statistics  一件代发 出库情况 PUT {}
	WhSDSDayOutStatistics: HY_BASEURL +"/wh/bizCommon/SDS/current/day/out/statistics",
	// /wh/bizCommon/STF/current/day/out/statistics 自提单 出库情况
	WhSTFDayOutStatistics: HY_BASEURL +"/wh/bizCommon/STF/current/day/out/statistics",
	// /wh/bizCommon/query/order/month/total  订单统计
	WhMonthOrderTotal: HY_BASEURL +"/wh/bizCommon/query/order/month/total",
	// 入库申请 /wh/bizCommon/in/wh/statistics   PUT {} 入库申请
	WhDayInWhStatistics: HY_BASEURL +"/wh/bizCommon/in/wh/statistics",
	//转运 /wh/bizCommon/query/str/statistics
	WhSTRQueryStatistics: HY_BASEURL +"/wh/bizCommon/query/str/statistics",
	//仓库货量
	WhSkuDayStockCurrentVolume: HY_BASEURL +"/wh/bizWhReportSkuDayStock/query/current/volume",
	//未上线快递运单统计
	WhExpressTrunckNotOnlineTotal: HY_BASEURL +"/wh/bizWhMySendExpress/query/trunck/total",
	
	//未上线快递运单-分页 /wh/bizWhMySendExpress/query/trunck/info  PUT {"offset":"","limit":""}
	WhExpressTrunckNotOnlinePage: HY_BASEURL +"/wh/bizWhMySendExpress/query/trunck/info",
	
	//费用类型配置
	// /wh/bizWhFeeConfigType/query/page  分页查询 {"offset":"0","limit":"10"}
	WhFeeTypeConfigPageList : HY_BASEURL +"/wh/bizWhFeeConfigType/query/page",
	// /wh/bizWhFeeConfigType/add/feetype  PUT  {"bizType":"业务类型","opModel":"操作模块","feeType":"费用类型","feeTypeName":"费用名称"}
	WhFeeTypeConfigAdd : HY_BASEURL +"/wh/bizWhFeeConfigType/add/feetype",
	// /wh/bizWhFeeConfigType/update/feetype   {"id":"数据ID","bizType":"业务类型","opModel":"操作模块","feeType":"费用类型","feeTypeName":"费用名称"}
	WhFeeTypeConfigUpdate : HY_BASEURL +"/wh/bizWhFeeConfigType/update/feetype",
	// /wh/bizWhFeeConfigType/delete/{id}  DELETE  id 
	WhFeeTypeConfigDel : HY_BASEURL +"/wh/bizWhFeeConfigType/delete",
	
	//业务收费项
	// /wh/bizWhFeeConfigBus/query/page  分页查询
	WhFeeBusConfigPageList : HY_BASEURL +"/wh/bizWhFeeConfigBus/query/page",
	// /wh/bizWhFeeConfigBus/delete/{id}   DELTE
	WhFeeBusConfigDel : HY_BASEURL +"/wh/bizWhFeeConfigBus/delete",
	// /wh/bizWhFeeConfigBus/add/biz/fee   PUT 
	WhFeeBusConfigAdd : HY_BASEURL +"/wh/bizWhFeeConfigBus/add/biz/fee",
	// /wh/bizWhFeeConfigBus/update/biz/fee  PUT  
	WhFeeBusConfigUpdate : HY_BASEURL +"/wh/bizWhFeeConfigBus/update/biz/fee",
	//复制到指定用户 /wh/bizWhFeeConfigBus/copy/to/{toUserId}  PUT [11111111111111]
	WhFeeBusConfigCopyToUser : HY_BASEURL +"/wh/bizWhFeeConfigBus/copy/to",
	//批量删除 /wh/bizWhFeeConfigBus/batch/delete PUT []
	WhFeeBusConfigDelBatch : HY_BASEURL +"/wh/bizWhFeeConfigBus/batch/delete",
	
	//第三方WMS配置
	// /thirdLogistics/wms/config/query/page PUT  配置分页查询
	WhThirdWmsConfigPageList: HY_BASEURL +"/thirdLogistics/wms/config/query/page",
	// /thirdLogistics/wms/config/add PUT 配置增加 {"userId":"","thridCode":"","whNo":"","thridWhNo":"","apiUrl":"","clientKey":"","clientSecret":""}
	WhThirdWmsConfigAdd: HY_BASEURL +"/thirdLogistics/wms/config/add",
	// /thirdLogistics/wms/config/query/eidt/{id} GET 编辑查询
	WhThirdWmsConfigQuery: HY_BASEURL +"/thirdLogistics/wms/config/query/eidt",
	// /thirdLogistics/wms/config/edit PUT 编辑更新
	WhThirdWmsConfigEdit: HY_BASEURL +"/thirdLogistics/wms/config/edit",
	// /thirdLogistics/wms/config/open/close  PUT {"id":""} 关闭或打开
	WhThirdWmsConfigOpenClose: HY_BASEURL +"/thirdLogistics/wms/config/open/close",
	// /thirdLogistics/wms/delete/{id}  DELETE 删除配置
	WhThirdWmsConfigDel: HY_BASEURL +"/thirdLogistics/wms/delete",
	// /thirdLogistics/wms/async/info/query/page PUT 同步信息查询
	WhThirdWmsAsyncInfoList: HY_BASEURL +"/thirdLogistics/wms/async/info/query/page",
	
	//服务管理，QA
	//分页 /wh/bizWhQa/query/page
	WhQARecPageList : HY_BASEURL +"/wh/bizWhQa/query/page",
	//删除 /wh/bizWhQa/delete/{id}
	WhQARecDel : HY_BASEURL +"/wh/bizWhQa/delete",
	//富文本获取 /wh/bizWhQaProgress/query/more/text?keyId=
	WhQARecMoreText : HY_BASEURL +"/wh/bizWhQaProgress/query/more/text",
	//新建QA /wh/bizWhQa/add/qa/{userId}
	WhQARecAdd : HY_BASEURL +"/wh/bizWhQa/add/qa",
	//添加进度说明 /wh/bizWhQaProgress/add/progress/{qaId}
	WhQARecProgressAdd : HY_BASEURL +"/wh/bizWhQaProgress/add/progress",
	// 进度详情获取 /wh/bizWhQaProgress/query/progress/{qaId}
	WhQARecProgressGet : HY_BASEURL +"/wh/bizWhQaProgress/query/progress",
	//更新跟踪账号 /wh/bizWhQa/update/csTracker  PUT   {"ids":[],"csTracker":"跟踪账号ID"}
	WhQAUpdateTrackingAcc : HY_BASEURL +"/wh/bizWhQa/update/csTracker",
	
	//增值服务分页  /wh/bizWhMyAddService/query/page
	WhValAddServicePageList : HY_BASEURL +"/wh/bizWhMyAddService/query/page",
	//增值服务 新增退货增值服务 /wh/bizWhMyAddService/add/return/{returnId}
	WhValAddReturnServiceAdd : HY_BASEURL +"/wh/bizWhMyAddService/add/return",
	//查询关联ID增值服务工单 /wh/bizWhMyAddService/get/{relationId}
	WhValAddServiceGetByRel : HY_BASEURL +"/wh/bizWhMyAddService/get",
	//增值服务-新增-有关联Id /wh/bizWhMyAddService/add/biz/service  {"relationId":"","addServiceList":[],"attachments":[],"bizType":""}
	WhValAddServiceBizAdd : HY_BASEURL +"/wh/bizWhMyAddService/add/biz/service",
	//增值服务-新增-没有关联Id /wh/bizWhMyAddService/add/other/service  {"addServiceList":[],"attachments":[],"whNo":"","remark":""}
	WhValAddServiceOtherAdd : HY_BASEURL +"/wh/bizWhMyAddService/add/other/service",
	
	//增值服务日志，/wh/bizWhWorkOrderLog/add PUT  {"id":"","remark":"","attachments":"附件"}
	WhValAddServiceLogAdd : HY_BASEURL +"/wh/bizWhWorkOrderLog/add",
	
	//工单打印-增值服务 /wh/bizWhWorkOrder/query/info/list  PUT  {"ids":[]}
	WhPrinterValAddServiceQuery : HY_BASEURL +"/wh/bizWhWorkOrder/query/info/list",
	
	//应付款管理
	//分页 /wh/bizWhFeeBillPayAmt/query/page
	WhAccountPayablePageList : HY_BASEURL +"/wh/bizWhFeeBillPayAmt/query/page",
	//新增 /wh/bizWhFeeBillPayAmt/add
	WhAccountPayableAdd : HY_BASEURL +"/wh/bizWhFeeBillPayAmt/add",
	//编辑 /wh/bizWhFeeBillPayAmt/update/edit
	WhAccountPayableEdit : HY_BASEURL +"/wh/bizWhFeeBillPayAmt/update/edit",
	//编辑查询 /wh/bizWhFeeBillPayAmt/query/edit/{id}
	WhAccountPayableEditQuery : HY_BASEURL +"/wh/bizWhFeeBillPayAmt/query/edit",
	//删除 /wh/bizWhFeeBillPayAmt/delete [id]
	WhAccountPayableDel : HY_BASEURL +"/wh/bizWhFeeBillPayAmt/delete",
	//确认 /wh/bizWhFeeBillPayAmt/confrim [id]
	WhAccountPayableConfrim : HY_BASEURL +"/wh/bizWhFeeBillPayAmt/confrim",
	//作废 /wh/bizWhFeeBillPayAmt/abolish [id]
	WhAccountPayableAbolish : HY_BASEURL +"/wh/bizWhFeeBillPayAmt/abolish",
	
	//应收账单
	//分页 /wh/bizWhFeeBillRecord/query/page 分页
	WhAccountReceivablePageList : HY_BASEURL +"/wh/bizWhFeeBillRecord/query/page",
	//新增 /wh/bizWhFeeBillRecord/create/bill/record  创建应收账单 {"userId":"","whNo":"","opTimeStart":"","opTimeEnd":""}
	WhAccountReceivableAdd : HY_BASEURL +"/wh/bizWhFeeBillRecord/create/bill/record",
	//确认完成 /wh/bizWhFeeBillRecord/comfirm/complete 确认完成  {"id":""}
	WhAccountReceivableSure : HY_BASEURL +"/wh/bizWhFeeBillRecord/comfirm/complete",
	//删除 /wh/bizWhFeeBillRecord/del/{id} DELETE 
	WhAccountReceivableDel : HY_BASEURL +"/wh/bizWhFeeBillRecord/del",
	//日志新增 /wh/bizWhFeeBillRecord/add/log {"billId":"","logType":"","fee":"","remark":""}
	WhAccountReceivableLogAdd : HY_BASEURL +"/wh/bizWhFeeBillRecord/add/log",
	//日志查询 /wh/bizWhFeeBillRecordLog/query/{billId}/log  GET 
	WhAccountReceivableLogDet : HY_BASEURL +"/wh/bizWhFeeBillRecordLog/query",
	//合计 /wh/bizWhFeeBillRecord/query/page/total
	WhAccountReceivableTotal : HY_BASEURL +"/wh/bizWhFeeBillRecord/query/page/total",
	//账单下载
	//账单下载 /wh/bizWhFeeBillRecord/task/{id}
	WhAccountReceivableTask : HY_BASEURL +"/wh/bizWhFeeBillRecord/task",
	//重新下载 /wh/bizWhDownTask/repeat/task/{id}  get 重新下载
	WhAccountReceivableTaskRepeat : HY_BASEURL +"/wh/bizWhDownTask/repeat/task",
	//下载任务列表 /wh/bizWhDownTask/query/task/List/{relationId}  下载任务列表
	WhAccountReceivableTaskList : HY_BASEURL +"/wh/bizWhDownTask/query/task/List",
	//刷新费用 /wh/bizWhFeeBillRecord/update/bill/{id}  PUT  {}
	WhAccountReceivableBillUpdate : HY_BASEURL +"/wh/bizWhFeeBillRecord/update/bill",
	//账单合计 
	// 总汇总 /wh/bizWhFeeBillRecord/all/total PUT {}  总汇总
	WhAccountReceivableAllTotal : HY_BASEURL +"/wh/bizWhFeeBillRecord/all/total",
	//按仓库汇总 /wh/bizWhFeeBillRecord/warehouse/all/total PUT {}  总汇总
	WhAccountReceivableWhTotal : HY_BASEURL +"/wh/bizWhFeeBillRecord/warehouse/all/total",
	//按客户汇总 /wh/bizWhFeeBillRecord/user/all/total  用户汇总
	WhAccountReceivableCusTotal : HY_BASEURL +"/wh/bizWhFeeBillRecord/user/all/total",
	
	//客户端菜单
	// 所有 /wh/bizWhClientMenu/query/allMenu
	WhClientMenuAll : HY_BASEURL +"/wh/bizWhClientMenu/query/allMenu",
	//新增
	// /wh/bizWhClientMenu/insert
	WhClientMenuAdd : HY_BASEURL +"/wh/bizWhClientMenu/insert",
	//编辑
	// /wh/bizWhClientMenu/edit
	WhClientMenuEdit : HY_BASEURL +"/wh/bizWhClientMenu/edit",
	//删除 
	// /wh/bizWhClientMenu//delete/{id}
	WhClientMenuDel : HY_BASEURL +"/wh/bizWhClientMenu/delete",
	//子账号
	//分页 /warehouse/bizWhCustomer/query/page
	WhSubUserPageList: HY_BASEURL +"/warehouse/bizWhCustomer/query/page",
	//子账户-新增 /wh/bizWhCustomerSub/create/{userId}
	WhSubUserAdd: HY_BASEURL +"/wh/bizWhCustomerSub/create",
	//详情 /wh/bizWhCustomerSub/sub/info/{suUserId}
	WhSubUserInfo: HY_BASEURL +"/wh/bizWhCustomerSub/sub/info",
	
	//配置客户菜单
	//查询 /wh/bizWhClientMenu/query/userMenu/{userId}
	WhSubUserMenusQuery: HY_BASEURL +"/wh/bizWhClientMenu/query/userMenu",
	//编辑 /wh/bizWhCustomerRoleMenu/updateRole/{userId}
	WhSubUserMenusEdit: HY_BASEURL +"/wh/bizWhCustomerRoleMenu/updateRole",
	
	//仓库配置管理
	//分页 /wh/bizWhInfo/query/page
	WhWarehousePageList: HY_BASEURL +"/wh/bizWhInfo/query/page",
	//2024.12.10 防攻击
	// WhWarehousePageList: HY_BASEURL +"/wh/bizWhInfo/query/page/do",
	
	//关闭 /wh/bizWhInfo/close/{id}
	WhWarehouseClose: HY_BASEURL +"/wh/bizWhInfo/close",
	//删除 /wh/bizWhInfo/delete/{id}
	WhWarehouseDel: HY_BASEURL +"/wh/bizWhInfo/delete",
	//开放 /wh/bizWhInfo/open/{id}
	WhWarehouseOpen: HY_BASEURL +"/wh/bizWhInfo/open",
	//新增编辑 /wh/bizWhInfo/add/whInfo
	WhWarehouseAdd: HY_BASEURL +"/wh/bizWhInfo/add/whInfo",
	//详情 /wh/tr/bizWhInfo/query/1671083453958373378
	WhWarehouseDet: HY_BASEURL +"/wh/tr/bizWhInfo/query",
	
	//通知
	//分页 后台 /wh/bizWhNoticeMessage/query/page
	WhNoticePageList: HY_BASEURL +"/wh/bizWhNoticeMessage/query/page",
	//关闭 /wh/bizWhNoticeMessage/close
	WhNoticeClose: HY_BASEURL +"/wh/bizWhNoticeMessage/close",
	//删除 /wh/bizWhNoticeMessage/delete
	WhNoticeDel: HY_BASEURL +"/wh/bizWhNoticeMessage/delete",
	//显示 /wh/bizWhNoticeMessage/open
	WhNoticeOpen: HY_BASEURL +"/wh/bizWhNoticeMessage/open",
	//新建 /wh/bizWhNoticeMessage/add/message
	WhNoticeAdd: HY_BASEURL +"/wh/bizWhNoticeMessage/add/message",
	//编辑 /wh/bizWhNoticeMessage/edit
	WhNoticeEdit: HY_BASEURL +"/wh/bizWhNoticeMessage/edit",
	
	//内容查询 /wh/bizCommon/query/more/text?keyId=
	WhNoticeMoreText: HY_BASEURL +"/wh/bizCommon/query/more/text",
	
	// 通知消息分页查询
	// 后端：/wh/bizWhNoticeMessage/query/notice/page
	// 前端：/wh/bizWhNoticeMessage/query/notice/page
	WhNoticeList: HY_BASEURL +"/wh/bizWhNoticeMessage/query/notice/page",
	//详情 /wh/bizWhNoticeMessage/query/notice/{id} GET
	WhNoticeDet: HY_BASEURL +"/wh/bizWhNoticeMessage/query/notice",
	
	//SKU关联
	//分页  /wh/bizWhMyGoodsSkuRelation/query/page
	WhSkuRelationPage: HY_BASEURL +"/wh/bizWhMyGoodsSkuRelation/query/page",
	//新增 /wh/bizWhMyGoodsSkuRelation/add/sku/relation
	WhSkuRelationAdd: HY_BASEURL +"/wh/bizWhMyGoodsSkuRelation/add/sku/relation",
	//编辑 /wh/bizWhMyGoodsSkuRelation/update/edit
	WhSkuRelationEdit: HY_BASEURL +"/wh/bizWhMyGoodsSkuRelation/update/edit",
	//删除 /wh/bizWhMyGoodsSkuRelation/delete
	WhSkuRelationDel: HY_BASEURL +"/wh/bizWhMyGoodsSkuRelation/delete",
	
	//扫码换Sku
	// /wh/bizWhMyGoodsSkuRelation/query/mapper/newSku/{userId}?scanSku=
	WhSkuChangeScanQueryRelation: HY_BASEURL +"/wh/bizWhMyGoodsSkuRelation/query/mapper/newSku",
	//查询打印sku，pdf流
	// /wh/bizWhMyGoodsSkuRelation/query/mapper/newSku/pdf/{userId}
	WhSkuRelationScanQueryPdf: HY_BASEURL +"/wh/bizWhMyGoodsSkuRelation/query/mapper/newSku/pdf",
	
	//调度配置分页
	HyScheduledTasksPage: HY_BASEURL+"/scheduled/read/tasks",
	HyScheduledTasks: HY_BASEURL+"/scheduled",
	//调度配置删除
	HyScheduledTasksDel: HY_BASEURL+"/scheduled",
	//调度配置修改
	HyScheduledTasksRun: HY_BASEURL+"/scheduled/run",
	//暂停
	HyScheduledTasksClose: HY_BASEURL+"/scheduled/close",
	//启动
	HyScheduledTasksOpen: HY_BASEURL+"/scheduled/open",
	
	//托盘标下载
	// /wh/bizCommon/download/pallte/lable PUT 
	HyPallteLableDownload: HY_BASEURL+"/wh/bizCommon/download/pallte/lable",
	//托盘标-入库
	// /wh/bizWhMyGoodsPacking/pallte/lable/{id}  入库预报
	HyPallteLableByInWh: HY_BASEURL+"/wh/bizWhMyGoodsPacking/pallte/lable",
	//托盘标-转运
	// /wh/bizWhTransfer/pallte/lable/{id}   转运
	HyPallteLableByTra: HY_BASEURL+"/wh/bizWhTransfer/pallte/lable",
	
	
	//企业信息分页数据
	HyBizEnterpriseList: HY_BASEURL+"/bizEnterpriseAudit/read/queryEnterpriseList",
	// 企业详情
	HyBizEnterpriseDet: HY_BASEURL+"/bizEnterpriseAudit/read/enterpriseDetail",
	// 企业修改
	HyBizEnterpriseUpdate: HY_BASEURL+"/bizEnterpriseAudit/update/enterprise",
	//企业ca证书
	HyBizEnterpriseCA: HY_BASEURL+"/bizContractTemplate/addCertificateAuthority",
	// 企业审核
	HyBizEnterpriseAudit: HY_BASEURL+"/bizEnterpriseAudit/auditing",
	//企业，解冻冻结
	HyBizEnterpriseFrozen: HY_BASEURL+"/bizEnterpriseAudit/updateEnterpriseState",
	
	//合伙人简要列表
	///bizPartner/query/simaple/page
	HyPartnerSimPage: HY_BASEURL+"/bizPartner/query/simaple/page",
	
	//机构绑定仓库
	//分页 /wh/tr/bizWhEnterperise/query/page
	HyEntBindWhPage: HY_BASEURL+"/wh/tr/bizWhEnterperise/query/page",
	//新增 wh/tr/bizWhEnterperise/add/belong/wh
	HyEntBindWhAdd: HY_BASEURL+"/wh/tr/bizWhEnterperise/add/belong/wh",
	//删除 wh/tr/bizWhEnterperise/remove
	HyEntBindWhDel: HY_BASEURL+"/wh/tr/bizWhEnterperise/remove",
	
	//机构绑定用户
	//分页 /wh/tr/bizWhEnterpriseUser/query/page
	HyEntBindUserPage: HY_BASEURL+"/wh/tr/bizWhEnterpriseUser/query/page",
	//新增 /wh/tr/bizWhEnterpriseUser/add/user
	HyEntBindUserAdd: HY_BASEURL+"/wh/tr/bizWhEnterpriseUser/add/user",
	//删除 /wh/tr/bizWhEnterpriseUser/remove
	HyEntBindUserDel: HY_BASEURL+"/wh/tr/bizWhEnterpriseUser/remove",
	
	//机构仓库报价
	//分页 /wh/tr/bizWhEnterprisePrice/query/page
	HyEntWhPricePage: HY_BASEURL+"/wh/tr/bizWhEnterprisePrice/query/page",
	//新增 /wh/tr/bizWhEnterprisePrice/add/price
	HyEntWhPriceAdd: HY_BASEURL+"/wh/tr/bizWhEnterprisePrice/add/price",
	//删除 /wh/tr/bizWhEnterprisePrice/remove/{id}
	HyEntWhPriceDel: HY_BASEURL+"/wh/tr/bizWhEnterprisePrice/remove",
	//价格模版查询 /wh/tr/bizWhInfo/price/tmp GET
	HyEntWhPriceTmp: HY_BASEURL+"/wh/tr/bizWhInfo/price/tmp",
	//详情 /wh/tr/bizWhEnterprisePrice/query/{id} GET
	HyEntWhPriceDet: HY_BASEURL+"/wh/tr/bizWhEnterprisePrice/query",
	
	//机构绑定合伙人
	//分页 
	HyEntBindPartnerPage: HY_BASEURL+"/wh/tr/bizWhPartner/query/page",
	//新增
	HyEntBindPartnerAdd: HY_BASEURL+"/wh/tr/bizWhPartner/add/wh/partner",
	//删除 
	HyEntBindPartnerDel: HY_BASEURL+"/wh/tr/bizWhPartner/remove",
	
	//导航统计 /wh/bizCommon/query/biz/tip/count
	HyNavTipCount: HY_BASEURL+"/wh/bizCommon/query/biz/tip/count",
	
	
	// 快速转运
	// 分页 /wh/bizWhTransportFba/query/page
	WhTransferFastPageList: HY_BASEURL + "/wh/bizWhTransportFba/query/page",
	// 删除
	// /wh/bizWhTransportFba/delete/{id}
	WhTransferFastDel: HY_BASEURL + "/wh/bizWhTransportFba/delete",
	//单条新增
	// /wh/bizWhTransportFba/add/transprot
	WhTransferFastAdd: HY_BASEURL + "/wh/bizWhTransportFba/add/transprot",
	//批量提交
	// /wh/bizWhTransportFba/batch/add/transprot
	WhTransferFastBatchAdd: HY_BASEURL + "/wh/bizWhTransportFba/batch/add/transprot",
	//读取excel
	// /wh/bizWhTransportFba/batch/read/excel
	WhTransferFastExcelRead: HY_BASEURL + "/wh/bizWhTransportFba/batch/read/excel",
	//编辑提交
	// /wh/bizWhTransportFba/update/eidt
	WhTransferFastEdit: HY_BASEURL + "/wh/bizWhTransportFba/update/eidt",
	//编辑查询
	// /wh/bizWhTransportFba/query/edit/{id}
	WhTransferFastQuery: HY_BASEURL + "/wh/bizWhTransportFba/query/edit",
	//拆柜完成 /wh/bizWhTransportFba/receipt/complete
	WhTransferFastReceiptComplete: HY_BASEURL + "/wh/bizWhTransportFba/receipt/complete",
	//打印拆柜单 /wh/bizWhTransportFba/print/receipt/list/{id}
	WhTransferFastPrinterDevanning: HY_BASEURL + "/wh/bizWhTransportFba/print/receipt/list",
	//快速转运-预约明细 /wh/bizWhTransferOutPlanBatch/query/list
	WhTransferFastPlanList: HY_BASEURL + "/wh/bizWhTransferOutPlanBatch/query/list",
	//快速转运-修改 /wh/bizWhTransportFba/modify/complete 
	WhTransferFastModifyComplete: HY_BASEURL + "/wh/bizWhTransportFba/modify/complete",
	//确认到仓 /wh/bizWhTransportFba/arrived/wh  快速转运  PUT {"id":id,"planArrivalDate":"到仓日期"}
	WhTransferFastArrivedInWh: HY_BASEURL + "/wh/bizWhTransportFba/arrived/wh",
	
	//快速转运-预约出库-预约提货完成
	// /wh/bizWhTransferOutPlanBatch/plan/packup
	WhTransferPlanPackup: HY_BASEURL + "/wh/bizWhTransferOutPlanBatch/plan/packup",
	
	//预约受理 /wh/bizWhTransferOutPlanBatch/accept/appointment
	WhTransferPlanAccept: HY_BASEURL + "/wh/bizWhTransferOutPlanBatch/accept/appointment",
	
	//预约修改
	// /wh/bizWhTransferOutPlanBatch/modify PUT   修改   PallteCount  AddrType  AddrCode  TrWay  PlanDate Isa Pro  Remark
	WhTransferFastPlanModify: HY_BASEURL + "/wh/bizWhTransferOutPlanBatch/modify",
	
	//快速转运-汇总
	// /wh/bizWhTransferOutPlanBatch/query/statistical/table PUT {}
	WhTransferFastStatsPageList: HY_BASEURL + "/wh/bizWhTransferOutPlanBatch/query/statistical/table",
	// /wh/bizWhTransferOutPlanBatch/query/statistical/table/detail PUT {}
	WhTransferFastStatsDet: HY_BASEURL + "/wh/bizWhTransferOutPlanBatch/query/statistical/table/detail",
	
	//pdf合并
	WhDsLabelPdfBase64Merge: HY_BASEURL + "/wh/download/v2/express/pdf/to/base64",
	
	//预约提柜
	// 提柜费用 /wh/bizCommon/query/all/packing  PUT  {}
	WhContainerAllPacking: HY_BASEURL + "/wh/bizCommon/query/all/packing",
	//新增 /wh/bizWhFeeBillOpRecord/add/hand/{userId}
	WhContainerAllPackingAdd: HY_BASEURL + "/wh/bizWhFeeBillOpRecord/add/hand",
	//费用类型字典 /wh/bizWhFeeConfigType/query/feetype/dic  PUT  {}
	WhFeeTypeDic: HY_BASEURL + "/wh/bizWhFeeConfigType/query/feetype/dic",
	
	//库区配置
	// /wh/bizWhZone/add/zone  PUT {"zoneWhNo":"","whNo":""}
	WhPlaceZoneAdd: HY_BASEURL + "/wh/bizWhZone/add/zone",
	// /wh/bizWhZone/query/page PUT 分页
	WhPlaceZonePageList: HY_BASEURL + "/wh/bizWhZone/query/page",
	// /wh/bizWhZone/open/or/close/{id} PUT 关闭或开启
	WhPlaceZoneOpenClose: HY_BASEURL + "/wh/bizWhZone/open/or/close",
	//库区查询 /wh/bizWhZone/query/open/list PUT {}
	WhPlaceZoneOpenList: HY_BASEURL + "/wh/bizWhZone/query/open/list",
	
	//入库 设置库区 /wh/bizWhMyGoodsPacking/udpate/zoneWhNo PUT  {"id":"","whNo":""}
	WhSetZoneByInWn: HY_BASEURL + "/wh/bizWhMyGoodsPacking/udpate/zoneWhNo",
	
	// /wh/bizWhInvConfirmTask/add/inv/task PUT {"title":"","whNo":"盘点仓库"}  添加盘点任务
	WhInvTaskAdd: HY_BASEURL + "/wh/bizWhInvConfirmTask/add/inv/task",
	// /wh/bizWhInvConfirmTask/query/task/page PUT {"offset":"0","limit":"10"} 判断任务分页
	WhInvTaskPageList: HY_BASEURL + "/wh/bizWhInvConfirmTask/query/task/page",
	///wh/bizWhInvConfirmTask/query/inv/info  PUT  {"taskId":"任务ID","isOnlyFlag":"只获取自己提交 0 和 0"}  只获取自己提交 0 ; 1-->获取全部"
	WhInvTaskQueryInfo: HY_BASEURL + "/wh/bizWhInvConfirmTask/query/inv/info",
	// /wh/bizWhInvConfirmTask/inv/complete/{taskId}   PUT {} 盘库完成
	WhInvTaskComplete: HY_BASEURL + "/wh/bizWhInvConfirmTask/inv/complete",
	// /wh/bizWhInvConfirmTask/inv/{taskId}/sync/sysstock  PUT {} 同步盘点库存
	WhInvTaskSyncSysStock: HY_BASEURL + "/wh/bizWhInvConfirmTask/inv/complete",
	//撤销完成 /wh/bizWhInvConfirmTask/cancel/confirmed/{taskId} PUT {}
	WhInvTaskCompleteCancel: HY_BASEURL + "/wh/bizWhInvConfirmTask/cancel/confirmed",
	
	// 盘库详情分页 /wh/bizWhInvConfirmTask/query/task/{taskId}/page  分页
	WhInvTaskQueryInfoPage: HY_BASEURL + "/wh/bizWhInvConfirmTask/query/task",
	
	// 盘库详情分页-编辑 /wh/bizWhInvConfirmTask/upate/batch/edit   PUT  [{"id":"","userId":"","place":"","goodsSku":""}]
	WhInvTaskQueryInfoEdit: HY_BASEURL + "/wh/bizWhInvConfirmTask/upate/batch/edit",
	
	//盘库转提货增值工单 /wh/bizWhInvConfirmTask/convert/to/work/order 
	WhInvToPickupOrder: HY_BASEURL + "/wh/bizWhInvConfirmTask/convert/to/work/order",
	
	//删除 /wh/bizWhInvConfirmTask/clear/inv/info   PUT  {"id":""}  删除
	WhInvTaskDetInfoDel: HY_BASEURL + "/wh/bizWhInvConfirmTask/clear/inv/info",
	//PC补录 /wh/bizWhInvConfirmTask/commit/inv/info/{taskId}  PUT  PC补录   [{"place":"","goodsSku":"","quantity":""}]   status = 40 才能补录
	WhInvTaskDetInfoAdd: HY_BASEURL + "/wh/bizWhInvConfirmTask/commit/inv/info",
	
	//偏远地区配置
	// 新增：/thirdLogistics/bizLgsBaseRemoteZip/add/remote/zip  {"country":"","remoteLevel":"","zipCode":""} PUT
	// 查询：/thirdLogistics/bizLgsBaseRemoteZip/query/page {"offset":"0","limit":"10"}
	// 删除: /thirdLogistics/bizLgsBaseRemoteZip/delte/ids PUT {"ids":[]}
	WhRemoteAreaCodeConfigAdd: HY_BASEURL + "/thirdLogistics/bizLgsBaseRemoteZip/add/remote/zip",
	WhRemoteAreaCodeConfigPageList: HY_BASEURL + "/thirdLogistics/bizLgsBaseRemoteZip/query/page",
	WhRemoteAreaCodeConfigDel: HY_BASEURL + "/thirdLogistics/bizLgsBaseRemoteZip/delte/ids",
	
	//邮编分区配置
	// /thirdLogistics/bizLgsBaseZipZone/add/zip/zone  PUT  [{"country":"","zone":"","fromStartZip":"","fromEndZip":"","toStartZip":"","toEndZip":""}]
	// /thirdLogistics/bizLgsBaseZipZone/delete/zip/zone PUT  [1111111111111]
	// /thirdLogistics/bizLgsBaseZipZone/query/page
	WhZipZoneConfigAdd: HY_BASEURL + "/thirdLogistics/bizLgsBaseZipZone/add/zip/zone",
	WhZipZoneConfigPageList: HY_BASEURL + "/thirdLogistics/bizLgsBaseZipZone/query/page",
	WhZipZoneConfigDel: HY_BASEURL + "/thirdLogistics/bizLgsBaseZipZone/delete/zip/zone",
	
	//渠道费用类型
	// 1：/thirdLogistics/bizLgsBaseCarrierFeeType/query/lgscode/price PUT {"lgsCode":"","carrier":""} 
	WhThirdLogiCarrierFeeTypePriceQuery: HY_BASEURL + "/thirdLogistics/bizLgsBaseCarrierFeeType/query/lgscode/price",
	// 2：添加价格配置
	// /thirdLogistics/bizLgsBaseCarrierFeePrice/add/carrier/price PUT {"lgsCode":"","carrier":"","carrierFeePriceList":[{"feeType":"","zone2Price":"","zone3Price":"","zone4Price":"","zone5Price":"","zone6Price":"","zone7Price":"","zone8Price":"","isSupport":"是否支持","isAddSf":"是否加服务费"}]}
	WhCarrFeePriceConfigAdd: HY_BASEURL + "/thirdLogistics/bizLgsBaseCarrierFeePrice/add/carrier/price",
	// 3：/thirdLogistics/bizLgsBaseCarrierFeePrice/query/page  费用项分页查询
	WhCarrFeePriceConfigPageList: HY_BASEURL + "/thirdLogistics/bizLgsBaseCarrierFeePrice/query/page",
	// 4：/thirdLogistics/bizLgsBaseCarrierFeePrice/update/list  PUT  [{"id":"","feeType":"","zone2Price":"","zone3Price":"","zone4Price":"","zone5Price":"","zone6Price":"","zone7Price":"","zone8Price":"","isSupport":"是否支持","isAddSf":"是否加服务费"}]
	WhCarrFeePriceConfigUpdateList: HY_BASEURL + "/thirdLogistics/bizLgsBaseCarrierFeePrice/update/list",
	// 5：试算
	// /thirdLogistics/bizLgsBaseCarrierFeePrice/test/clac/fee  {"lgsKeyCode":"","carrier":""}
	WhCarrFeePriceTestClac: HY_BASEURL + "/thirdLogistics/bizLgsBaseCarrierFeePrice/test/clac/fee",
	
	//渠道发货地址配置
	// /thirdLogistics/bizLgsThridShipFromAddr/add/carrier/from/addr  PUT  [{"lgsId":"","lgsCode":"","countryCode":"","addresses1":"","addresses2":"","city":"","phone":"","provinceCode":"","postalCode":"","addrName":""}]
	WhCarrierShipFromAddrAdd: HY_BASEURL + "/thirdLogistics/bizLgsThridShipFromAddr/add/carrier/from/addr",
	// /thirdLogistics/bizLgsThridShipFromAddr/update/carrier/from/addr  更新 {"id":"","lgsId":"","lgsCode":"","countryCode":"","addresses1":"","addresses2":"","city":"","phone":"","provinceCode":"","postalCode":"","addrName":""}
	WhCarrierShipFromAddrUpdate: HY_BASEURL + "/thirdLogistics/bizLgsThridShipFromAddr/update/carrier/from/addr",
	// /thirdLogistics/bizLgsThridShipFromAddr/delte/carrier/from/addr  PUT  [111111111]
	WhCarrierShipFromAddrDel: HY_BASEURL + "/thirdLogistics/bizLgsThridShipFromAddr/delte/carrier/from/addr",
	// /thirdLogistics/bizLgsThridShipFromAddr/query/page
	WhCarrierShipFromAddrList: HY_BASEURL + "/thirdLogistics/bizLgsThridShipFromAddr/query/page",
	
	// 快递渠道-5：试算
	// /thirdLogistics/bizLgsBaseCarrierFeePrice/test/clac/fee  {"lgsKeyCode":"","carrier":""}
	WhCarrFeePriceTestClac: HY_BASEURL + "/thirdLogistics/bizLgsBaseCarrierFeePrice/test/clac/fee",
	//快递： 比价 /thirdLogistics/bizLgsBaseCarrierFeePrice/compare/clac/price
	WhCarrFeePriceComparePrice: HY_BASEURL + "/thirdLogistics/bizLgsBaseCarrierFeePrice/compare/clac/price",
	
	//提柜
	// 提柜供应商维护：
	// /wh/bizWhPcpuCarrier/add/carrier     新增  PUT 
	WhCabinetPickupCarrierAdd: HY_BASEURL + "/wh/bizWhPcpuCarrier/add/carrier",
	// /wh/bizWhPcpuCarrier/update/carrier  编辑
	WhCabinetPickupCarrierEdit: HY_BASEURL + "/wh/bizWhPcpuCarrier/update/carrier",
	// /wh/bizWhPcpuCarrier/query/page  查询
	WhCabinetPickupCarrierList: HY_BASEURL + "/wh/bizWhPcpuCarrier/query/page",
	// /wh/bizWhPcpuCarrier/delete 删除
	WhCabinetPickupCarrierDel: HY_BASEURL + "/wh/bizWhPcpuCarrier/delete",
	
	// 提柜记录:
	// /wh/bizWhPcpuRecord/add/recors  PUT  {"containerNo": "","containerCompany": "","MBL": "","conWeight": "","receiveAddress": {"city": "","company": "","province": "", "postcode": "","mobile": "","mail": "","addr1": "","country": ""}}
	WhCabinetPickupRecAdd: HY_BASEURL + "/wh/bizWhPcpuRecord/add/recors",
	// /wh/bizWhPcpuRecord/update/recors  编辑
	WhCabinetPickupRecEdit: HY_BASEURL + "/wh/bizWhPcpuRecord/update/recors",
	// /wh/bizWhPcpuRecord/update/empty  报空
	WhCabinetPickupRecEmpty: HY_BASEURL + "/wh/bizWhPcpuRecord/update/empty",
	// /wh/bizWhPcpuRecord/update/confirm  DO确认
	WhCabinetPickupRecDoConfirm: HY_BASEURL + "/wh/bizWhPcpuRecord/update/confirm",
	// /wh/bizWhPcpuRecord/delete 删除 {"ids":[]}
	WhCabinetPickupRecDel: HY_BASEURL + "/wh/bizWhPcpuRecord/delete",
	// /wh/bizWhPcpuRecord/query/page 分页
	WhCabinetPickupRecList: HY_BASEURL + "/wh/bizWhPcpuRecord/query/page",
	// 提柜日志:
	// /wh/bizWhPcpuRecordLog/query/history/list/{id} GET
	WhCabinetPickupRecLogList: HY_BASEURL + "/wh/bizWhPcpuRecordLog/query/history/list",
	
	//提柜增值新增
	// 新增
	// /wh/bizWhPcpuRecord/update/addsf/fee/type
	// [{"id":1,"containerNo":"","addSfTypeList":["SAA"]}]
	WhCabinetPickupValAddAdd: HY_BASEURL + "/wh/bizWhPcpuRecord/update/addsf/fee/type",
	//类型
	// /wh/bizWhFeeConfigType/query/pcpu/feetype?serviceType=02
	WhCabinetPickupValAddTypeList: HY_BASEURL + "/wh/bizWhFeeConfigType/query/pcpu/feetype?serviceType=02",
	// /wh/bizWhPcpuBill/clac/bill    {"ids":[]}账单计算
	WhCabinetPickupClacBill: HY_BASEURL + "/wh/bizWhPcpuBill/clac/bill",
	
	// 提柜线路配置：
	// /wh/bizWhPcpuLine/add/lines PUT   新增编辑 {"lineCode":"编码","lineName":"名称"，"whNo":"到达仓库","port:"港口""}
	WhCabinetPickupLineConfAdd: HY_BASEURL + "/wh/bizWhPcpuLine/add/lines",
	// /wh/bizWhPcpuLine//query/page PUT  查询  {"offset":0,"limit":10}
	WhCabinetPickupLineConfList: HY_BASEURL + "/wh/bizWhPcpuLine/query/page",
	// /wh/bizWhPcpuLine/delete PUT  {"id":}
	WhCabinetPickupLineDel: HY_BASEURL + "/wh/bizWhPcpuLine/delete",
	
	
	// 提柜线路价格配置：
	// /wh/bizWhPcpuLinePrice/add/{userId}/line/prices  PUT 新增  {"lineCode":"线路Code","userId":"","feeType":"费用类型"}
	WhCabinetPickupLinePriceAdd: HY_BASEURL + "/wh/bizWhPcpuLinePrice/add",
	// /wh/bizWhPcpuLinePrice/copy/line/prices/to/{userId} PUT 复制  {"ids":[]}
	// /wh/bizWhPcpuLinePrice/copy/line/prices PUT 复制  {"ids":[],"userIdList":[]}   
	WhCabinetPickupLinePriceCopy: HY_BASEURL + "/wh/bizWhPcpuLinePrice/copy/line/prices",
	// /wh/bizWhPcpuLinePrice/update/eidt  PUT 更新  {"id":""}
	WhCabinetPickupLinePriceEdit: HY_BASEURL + "/wh/bizWhPcpuLinePrice/update/eidt",
	// /wh/bizWhPcpuLinePrice/query/page  PUT 查询 {"offset":0,"limit":10}
	WhCabinetPickupLinePriceList: HY_BASEURL + "/wh/bizWhPcpuLinePrice/query/page",
	// /wh/bizWhPcpuLinePrice/delete PUT  删除 {"ids":[]}
	WhCabinetPickupLinePriceDel: HY_BASEURL + "/wh/bizWhPcpuLinePrice/delete",
	//提柜线路价格-费用类型 /wh/bizWhFeeConfigType/query/pcpu/feetype get
	WhCabinetPickupLinePriceFeeType: HY_BASEURL + "/wh/bizWhFeeConfigType/query/pcpu/feetype",
	
	//系统显示配置
	// /wh/bizWhSysLogo/add/list   [{"domain":"","owner":"","code":"","logoUrl":""}]  新增  PUT
	WhSysShowAdd: HY_BASEURL + "/wh/bizWhSysLogo/add/list",
	// /wh/bizWhSysLogo/domain/list?domain=  获取
	WhSysShowQuery: HY_BASEURL + "/wh/bizWhSysLogo/domain/list",
	// /wh/bizWhSysLogo/edit/detail    {"id":""}
	WhSysShowEdit: HY_BASEURL + "/wh/bizWhSysLogo/edit/detail",
	// /wh/bizWhSysLogo/delete  {"ids":[]}
	WhSysShowDel: HY_BASEURL + "/wh/bizWhSysLogo/delete",
	// /wh/bizWhSysLogo/query/page  PUT  {"limit":"10","offset":"0"}
	WhSysShowPageList: HY_BASEURL + "/wh/bizWhSysLogo/query/page",
	
	//显示配置-logo /wh/bizWhSysLogo/domain/list?domain=  获取
	WhSysShowConfig: HY_BASEURL + "/wh/bizWhSysLogo/domain/list",
	
	//无主货管理
	// 1：/wh/bizWhUnclaimedGoods/add/reocrd   PUT  {"whNo":"","goodsParamsVo":{"truckingNo":"","skuInfoArr":[{"goodsSku":"","qt":""}]}}
	// {"whNo":"","deliveryTime":"","goodsParamsVo":{"truckingNo":"","skuInfoArr":[{"goodsSku":"","qt":""}]},"attachments":[{}]}
	WhUnclaimedGoodsAdd: HY_BASEURL + "/wh/bizWhUnclaimedGoods/add/reocrd",
	// 2：/wh/bizWhUnclaimedGoods/query/edit/{id} GET 编辑查询
	WhUnclaimedGoodsEditQuery: HY_BASEURL + "/wh/bizWhUnclaimedGoods/query/edit",
	// 3：/wh/bizWhUnclaimedGoods/add/edit PUT
	WhUnclaimedGoodsEdit: HY_BASEURL + "/wh/bizWhUnclaimedGoods/add/edit",
	// 4：/wh/bizWhUnclaimedGoods/delete  PUT  [id]
	WhUnclaimedGoodsDel: HY_BASEURL + "/wh/bizWhUnclaimedGoods/delete",
	// 5：/wh/bizWhUnclaimedGoods/query/page  PUT {"offset":"0","limit":"10"}
	WhUnclaimedGoodsPageList: HY_BASEURL + "/wh/bizWhUnclaimedGoods/query/page",
	// /wh/bizWhUnclaimedGoods/confrim/unclaimed/{userId}   认领  PUT  {"ids":[]}
	WhUnclaimedGoodsClaim: HY_BASEURL + "/wh/bizWhUnclaimedGoods/confrim/unclaimed",
	// /wh/bizWhUnclaimedGoods/unclaimed/destroy   PUT  {"ids":[],"remark":""}
	WhUnclaimedGoodsDestroy: HY_BASEURL + "/wh/bizWhUnclaimedGoods/unclaimed/destroy",
	
	
	//代理用户
	// /wh/bizWhProxyUser/add/proxy/{userId}   PUT [1212121]
	WhProxyUserAdd: HY_BASEURL + "/wh/bizWhProxyUser/add/proxy",
	// /wh/bizWhProxyUser/get/list/{userId} GET
	WhProxyUserList: HY_BASEURL + "/wh/bizWhProxyUser/get/list",
	// /wh/bizWhProxyUser/query/page/{userId} PUT
	WhProxyUserPageList: HY_BASEURL + "/wh/bizWhProxyUser/query/page",
	// /wh/bizWhProxyUser/delete/proxy/{userId} PUT
	WhProxyUserDel: HY_BASEURL + "/wh/bizWhProxyUser/delete/proxy",
	
	//代理-仓租
	// /wh/bizWhFeeRecordProxy/query/page
	WhProxyFeeRecordPage: HY_BASEURL + "/wh/bizWhFeeRecordProxy/query/page",
	// /wh/bizWhFeeRecordProxy/statistics/query/page
	WhProxyFeeRecordStatiPage: HY_BASEURL + "/wh/bizWhFeeRecordProxy/statistics/query/page",
	// /wh/bizWhFeeRecordProxy/reset/wh/fee/{userId}/{proxyUserId}
	WhProxyFeeRecordReset: HY_BASEURL + " /wh/bizWhFeeRecordProxy/reset/wh/fee",
}

// const Vue =  {
//   install: function(Vue) {
//     // Do stuff
//   }
// }

// export default {
// 	urlConfig
// }
export default {
  install: function(Vue) {
    Object.defineProperty(Vue.prototype, '$urlConfig', { value: urlConfig });
  }
}
