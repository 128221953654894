import Vue from 'vue'
import axios from 'axios'
// import VueAxios from 'vue-axios'

// const Vue = require('vue')
// const axios = require('axios')

import stores from '../vuex/store.js'

import router from '../router/index.js'

// Vue.use(VueAxios, axios);
import {
  Message,
} from 'element-ui';

import HYConfig from '../config/config.js'
Vue.use(HYConfig)

Vue.prototype.$message = Message;
// Axios.defaults.withCredentials = true;
// axios.defaults.headers.common['Access-Control-Request-Headers'] = null;
// axios.defaults.headers.common['Access-Control-Request-Method'] = null;
//post请求
const Axios = axios.create({
  // baseURL: 'https://some-domain.com/api/',
  timeout: 60000,
  // headers: {'X-Custom-Header': 'foobar'}
  // 和后端 Content-Type 统一，跨域有option请求
  // headers:{'Content-Type':'application/x-www-form-urlencoded'}
  // headers: {'Content-Type':'application/json'}
  // headers: {'Content-Type':'application/json; charset=utf-8'} //默认
});
// Axios.defaults.withCredentials = true;
// Axios.defaults.headers.common['Access-Control-Request-Headers'] = null;
// Axios.defaults.headers.common['Access-Control-Request-Method'] = null;
//put请求，不跨域直接请求Content-Type application/json
// const Axios2 = axios.create({
//   // baseURL: 'https://some-domain.com/api/',
//     timeout: 60000,
//     // headers: {'X-Custom-Header': 'foobar'}
//     // 和后端 Content-Type 统一，跨域有option请求
//     headers:{'Content-Type':'application/x-www-form-urlencoded'}
//     // headers: {'Content-Type':'application/json'}
// });
// 添加请求拦截器
// Axios.interceptors.request.use(function (config) {
//   console.log("在发送请求之前做某事");
// 　　// 在发送请求之前做些什么
// 　　return config
// }, function (error) {
// 　　// 对请求错误做些什么
// return Promise.reject(error)
// });
// //添加请求拦截器
// Axios.interceptors.request.use((response) =>{
//      //在发送请求之前做某事
//      console.log("在发送请求之前做某事");
//      console.log(response);
//      // console.log(response.url);
//      // console.log(config.url.indexOf('/unauthorized'));
//      // if(response.url.indexOf('/unauthorized')>-1){
//      //  router.replace({name:"login"});
//      // }

//      try {
//         if(response.url.indexOf('/unauthorized')>-1){
//           Vue.prototype.$message({
//             message: '登录权限失效，请重新登录。',
//             type: 'warning'
//           });
//           router.replace({name:"login"});
//         }
//       } catch(ee){
//         console.log(ee);
//       }



//      // let isLoginUrl = (config.url === window.axiosAllUrl.loginUrl);
//      // // console.log(config.url);
//      // // console.log(window.eleAllUrl.loginUrl);
//      // // console.log(isLoginUrl);
//      // if(!isLoginUrl){
//      //    let userInfo = stores.state.login.userInfo;
//      //     // console.log(userInfo);
//      //     if(!userInfo || !userInfo.token || ''==userInfo.token){
//      //        console.log("无登录 信息 !userInfo || !userInfo.token");
//      //         router.replace({name:"Login"});

//      //     } else {
//      //        config.headers.token = userInfo.token;
//      //        config.headers.username= userInfo.userCode;
//      //        config.headers.password= userInfo.password;
//      //     }
//      // } else {
//      //    console.log("登录的请求不做headers验证");
//      // }

//      // console.log(config.headers);
//      // console.log(stores);
//      // stores.dispatch('showloader');
//      // stores.commit('SHOWLOADING');

//      return response;
//    }, (err) =>{
//      //请求错误时做些事
//     console.log("请求错误时做些事");
//     // stores.dispatch('hideloader');
//     // stores.commit('HIDELOADING');
//     // console.log(err.response);
//       console.log(err);
//       try {
//           if(err.response.request.responseURL.indexOf('/unauthorized')>-1){
//             Vue.prototype.$message({
//               message: '登录权限失效，请重新登录..',
//               type: 'warning'
//             });
//             router.replace({name:"login"});
//           }
//         } catch(ee){
//           console.log(ee);
//         }

//       return Promise.reject(err);
//    });


//添加响应拦截器
Axios.interceptors.response.use(function (response) {
  //对响应数据做些事
  console.log("对响应数据做些事");
  console.log(response);
  // console.log(Vue);

  if ('401' === response.data.code) {
    console.log("登录失效 response.data.code");
    Vue.prototype.$message({
      message: '登录失效，请重新登录',
      type: 'warning'
    });
    //清除登录信息
    // stores.dispatch('USER_SIGNOUT');
    router.replace({ name: "login" });
  }
  // console.log(response);
  // stores.dispatch('hideloader');
  // stores.commit('HIDELOADING');
  // console.log(response);
  // 未验证的登录token，跳到登录页
  // if(!stores.state.isTestDev && response.data && "999"===response.data.loginStatus){
  //     console.log(response);
  //     console.log("登录信息验证失败 ‘999’===response.data.loginStatus");
  //     router.replace({name:"Login"});
  // }
  console.log(response.data.code)
  if (response.data.code !== '200') {
    if (response.data.msg) {
      Vue.prototype.$message({
        message: response.data.msg,
        type: 'warning'
      });
    }
  }
  return response;
}, (err) => {
  // stores.dispatch('hideloader');
  // stores.commit('HIDELOADING');
  //响应错误时做些事
  console.log("响应错误时做些事");
  console.log(err);
  console.log(err.response);
  console.log(err.response.status);
  try {
    if (err.response.request.responseURL.indexOf('/unauthorized') > -1) {
      Vue.prototype.$message({
        message: '登录权限失效，请重新登录',
        type: 'warning'
      });
      router.replace({ name: "login" });
    }
  } catch (ee) {
    console.log(ee);
  }


  // if(error.code == 'ECONNABORTED' && error.message.indexOf('timeout')!=-1 ){
  // console.log(error.code);
  // }



  return Promise.reject(err);
});





export default {
  install: function (Vue) {
    Object.defineProperty(Vue.prototype, '$http', { value: Axios });
    // Object.defineProperty(Vue.prototype, '$http2', { value: Axios2 });
  }
}
//设置请求超时
// axios.defaults.timeout =  120000;
//循环请求
//可参考： http://javascript.ctolib.com/ssttm169-use-axios-well.html
//设置全局的请求次数，请求的间隙
// axios.defaults.retry = 4;
// axios.defaults.retryDelay = 1000;
//请求根地址
// window.axiosBaseURL = stores.state.axiosBaseURL;

// //各个请求地址
// window.axiosAllUrl = {

//     loginUrl: window.axiosBaseURL + "/static/login.json"

// }

// if (process.env.NODE_ENV == 'development') {
//     axios.defaults.baseURL = AIbaseURL;
// } else if (process.env.NODE_ENV == 'debug') {
//     axios.defaults.baseURL = AIbaseURL;
// } else if (process.env.NODE_ENV == 'production') {
//     axios.defaults.baseURL = AIbaseURL;
// }

// axiosIns.defaults.headers.post['X-Requested-With'] = 'XMLHttpRequest';
// axiosIns.defaults.headers.get['X-Requested-With'] = 'XMLHttpRequest';
// axiosIns.defaults.responseType = 'json';
// axiosIns.defaults.transformRequest = [function (data) {
//     //数据序列化
//     return qs.stringify(data);
// }
// ];
// axiosIns.defaults.validateStatus = function (status) {
//     return true;
// };
// axiosIns.interceptors.request.use(function (config) {
//     //配置config
//     config.headers.Accept = 'application/json';
//     // config.headers.System = 'vue';
//     // let token = Vue.localStorage.get('token');
//     // if(token){
//     //     config.headers.Token = token;
//     // }
//     return config;
// });
// console.log("console.log(stores);console.log(stores);console.log(stores);");
// console.log(stores);
// 
// //添加请求拦截器
// axios.interceptors.request.use(function(config){
//      //在发送请求之前做某事
//      console.log("在发送请求之前做某事");
//      console.log(config);

//      let isLoginUrl = (config.url === window.axiosAllUrl.loginUrl);
//      // console.log(config.url);
//      // console.log(window.eleAllUrl.loginUrl);
//      // console.log(isLoginUrl);
//      if(!isLoginUrl){
//         let userInfo = stores.state.login.userInfo;
//          // console.log(userInfo);
//          if(!userInfo || !userInfo.token || ''==userInfo.token){
//             console.log("无登录 信息 !userInfo || !userInfo.token");
//              router.replace({name:"Login"});

//          } else {
//             config.headers.token = userInfo.token;
//             config.headers.username= userInfo.userCode;
//             config.headers.password= userInfo.password;
//          }
//      } else {
//         console.log("登录的请求不做headers验证");
//      }

//      // console.log(config.headers);
//      // console.log(stores);
//      // stores.dispatch('showloader');
//      // stores.commit('SHOWLOADING');

//      return config;
//    },function(error){
//      //请求错误时做些事
//     console.log("请求错误时做些事");
//     // stores.dispatch('hideloader');
//     // stores.commit('HIDELOADING');
//     console.log(error);
//     return Promise.reject(error);
//    });


// //添加响应拦截器
// axios.interceptors.response.use(function(response){
//     //对响应数据做些事
//     console.log("对响应数据做些事");
//     // console.log(response);
//     // stores.dispatch('hideloader');
//     // stores.commit('HIDELOADING');
//     // console.log(response);
//     // 未验证的登录token，跳到登录页
//     if(!stores.state.isTestDev && response.data && "999"===response.data.loginStatus){
//         console.log(response);
//         console.log("登录信息验证失败 ‘999’===response.data.loginStatus");
//         router.replace({name:"Login"});
//     }
//     return response;
//    },function(error){
//     // stores.dispatch('hideloader');
//     // stores.commit('HIDELOADING');
//     console.log("Axios.interceptors error");
//     console.log(error);
//     // if(error.code == 'ECONNABORTED' && error.message.indexOf('timeout')!=-1 ){
//         // console.log(error.code);
//     // }

//      //响应错误时做些事
//      console.log("响应错误时做些事");

//      return Promise.reject(error);
//    });



// export default axios