import Vue from 'vue'
// 函数防抖
// 定义：多次触发事件后，事件处理函数只执行一次，并且是在触发操作结束时执行。
// const on = Vue.prototype.$on;
// // 防抖处理
// Vue.prototype.$on = function (event, func) {
//   let timer
//   let newFunc = func
//   if (event === 'click') {
//     newFunc = function () {
//       clearTimeout(timer)
//       timer = setTimeout(function () {
//         func.apply(this, arguments)
//       }, 500)
//     }
//   }
//   on.call(this, event, newFunc)
// }

// 函数节流
// 定义：触发函数事件后，短时间间隔内无法连续调用，只有上一次函数执行后，过了规定的时间间隔，才能进行下一次的函数调用。
//在vue中对click添加节流处理
const on = Vue.prototype.$on;
// 节流
Vue.prototype.$on = function (event, func) {
  let previous = 0;
  let throttle = func;
  if (event === 'click') {
    throttle = function () {
			// console.log('22');
      const now = new Date().getTime()
      if (previous + 500 <= now) {
        func.apply(this, arguments)
				// console.log('33');
        previous = now;
      }
    }
  }
  on.call(this, event, throttle)
}