import Vue from 'vue'

import AxiosPlugin from '../../axios/axios.js'
Vue.use(AxiosPlugin, '$axios');

import HYConfig from '../../config/config.js'
Vue.use(HYConfig)

import {
  Message,
} from 'element-ui';

Vue.prototype.$message = Message;

// import VueCookies from 'vue-cookies'
// Vue.use(VueCookies)

const state = {
  provinceData: [], //省数据
  footerHelpData: [], //底部帮助中心
  footerAboutData: [], //底部关于我们
  headerGoodsNavData: {}, //顶部导航商品分类
  phoneAreaCodeData:{}//区域代码
}

const mutations = {
  UPDATE_PROVINCE_DATA(state, provinceData) {
    state.provinceData = provinceData;
  },
  UPDATE_FOOTER_HELP_DATA(state, footerHelpData) {
    state.footerHelpData = footerHelpData;
  },
  UPDATE_FOOTER_ABOUT_DATA(state, footerAboutData) {
    state.footerAboutData = footerAboutData;
  },
  UPDATE_HEADER_GOODSNAV_DATA(state, headerGoodsNavData) {
    state.headerGoodsNavData = headerGoodsNavData;
  },
  UPDATE_PHONE_AREACODE_DATA(state, phoneAreaCodeData) {
    state.phoneAreaCodeData = phoneAreaCodeData;
  },
}

const getters = {
  provinceData: state => state.provinceData, 
  footerHelpData: state => state.footerHelpData, 
  footerAboutData: state => state.footerAboutData, 
  headerGoodsNavData: state => state.headerGoodsNavData, 
  phoneAreaCodeData: state => state.phoneAreaCodeData, 
}

const actions = {
  //更新获得省数据
  UPDATE_PROVINCE_DATA: ({ commit }) => {
    //异步Promise
    return new Promise(function(resolve, reject) {
      if (!state.provinceData || state.provinceData.length <= 0) {
        Vue.prototype.$http.put(Vue.prototype.$urlConfig.ProvinceListData, {})
          .then(({ data }) => {
            console.log("省份信息请求成功vuex");
            console.log(data);
            if (200 == data.code) {
              resolve(data.rows);
              commit("UPDATE_PROVINCE_DATA", data.rows)
            } else {
              reject(data);
              // Vue.prototype.$message.warning('获取省份信息失败vuex');
            }
          })
          .catch((error) => {
            console.log(error);
            reject(error);
            console.log("省份信息请求失败vuex");
            // Vue.prototype.$message.warning('省份信息请求失败vuex');
          });
      } else {
        console.log("省份信息从vuex缓存读取成功");
        resolve(state.provinceData);
      }
    }).catch(function(reason) {
      console.log('Promise Failed vuex: ', reason);
    });
  },
  //更新获得，底部帮助中心
  UPDATE_FOOTER_HELP_DATA: ({ commit }, { url, params }) => {
    //异步Promise
    return new Promise(function(resolve, reject) {
      if (!state.footerHelpData || !state.footerHelpData.data) {
        Vue.prototype.$http.get(url, {
            params: params
          })
          .then(({ data }) => {
            console.log("底部帮助中心请求成功vuex");
            console.log(data);
            if (!!data.data) {
              resolve(data);
              commit("UPDATE_FOOTER_HELP_DATA", data)
            } else {
              reject(data);
              // Vue.prototype.$message.warning('获取省份信息失败vuex');
            }
          })
          .catch((error) => {
            console.log(error);
            reject(error);
            console.log("底部帮助中心请求失败vuex");
            // Vue.prototype.$message.warning('省份信息请求失败vuex');
          });
      } else {
        console.log("底部帮助中心从vuex缓存读取成功");
        resolve(state.footerHelpData);
      }
    }).catch(function(reason) {
      console.log('Promise Failed vuex: ', reason);
    });
  },
  //更新获得，底部关于我们
  UPDATE_FOOTER_ABOUT_DATA: ({ commit }, { url, params }) => {
    //异步Promise
    return new Promise(function(resolve, reject) {
      if (!state.footerAboutData || !state.footerAboutData.data) {
        Vue.prototype.$http.get(url, {
            params: params
          })
          .then(({ data }) => {
            console.log("底部关于我们请求成功vuex");
            console.log(data);
            if (!!data.data) {
              resolve(data);
              commit("UPDATE_FOOTER_ABOUT_DATA", data)
            } else {
              reject(data);
              // Vue.prototype.$message.warning('获取省份信息失败vuex');
            }
          })
          .catch((error) => {
            console.log(error);
            reject(error);
            console.log("底部帮助中心请求失败vuex");
            // Vue.prototype.$message.warning('省份信息请求失败vuex');
          });
      } else {
        console.log("底部关于我们从vuex缓存读取成功");
        resolve(state.footerAboutData);
      }
    }).catch(function(reason) {
      console.log('Promise Failed vuex: ', reason);
    });
  },
  //更新获得，商品分类导航
  UPDATE_HEADER_GOODSNAV_DATA: ({ commit }, { url, params }) => {
    //异步Promise
    return new Promise(function(resolve, reject) {
      if (!state.headerGoodsNavData || !state.headerGoodsNavData.data) {
        Vue.prototype.$http.put(url, params)
          .then(({ data }) => {
            console.log("商品分类导航请求成功vuex");
            console.log(data);
            if (200==data.code && !!data.data) {
              resolve(data);
              commit("UPDATE_HEADER_GOODSNAV_DATA", data)
            } else {
              reject(data);
            }
          })
          .catch((error) => {
            console.log(error);
            reject(error);
            console.log("商品分类导航请求失败vuex");
          });
      } else {
        console.log("商品分类导航从vuex缓存读取成功");
        resolve(state.headerGoodsNavData);
      }
    }).catch(function(reason) {
      console.log('Promise Failed vuex: ', reason);
    });
  },
  //更新获得，手机区域代码
  UPDATE_PHONE_AREACODE_DATA: ({ commit }) => {
    //异步Promise
    return new Promise(function(resolve, reject) {
      if (!state.phoneAreaCodeData || !state.phoneAreaCodeData.data) {
        Vue.prototype.$http.put(Vue.prototype.$urlConfig.HyPhoneAreaCode, {})
          .then(({ data }) => {
            console.log("手机区域代码请求成功vuex");
            console.log(data);
            if (200==data.code && !!data.data) {
              
              let resolveData = [
              {
                label: '常用',
                options: data.data.CommonAreaCode
              },{
                label: '全部',
                options: data.data.allAreaCode
              }, {
                label: '如果没有找到您的所在归属地，请拨打客服电话4008005550解决  ',
                options: []
              }];

              resolve(resolveData);
              commit("UPDATE_PHONE_AREACODE_DATA", resolveData)
            } else {
              reject(data);
            }
          })
          .catch((error) => {
            console.log(error);
            reject(error);
            console.log("手机区域代码请求失败vuex");
          });
      } else {
        console.log("手机区域代码从vuex缓存读取成功");
        resolve(state.phoneAreaCodeData);
      }
    }).catch(function(reason) {
      console.log('Promise Failed vuex: ', reason);
    });
  },

}

export default {
  state,
  mutations,
  getters,
  actions
}
