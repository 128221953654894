/*全局组件*/
import Vue from 'vue'
import HyPagePagination from './HyPagePagination.vue'

const hyPagePagination = {
  install:function (Vue) {
    Vue.component('hyPagePagination',HyPagePagination)
  }
}

export default hyPagePagination

